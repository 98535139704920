import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import circleCooperStore from "../../stores/circleCooperStore";
import State from "./State";
import Info from "./Info";

interface Props {}

const Main: React.FC<Props> = () => {
  const { combinesDetail } = useContext(circleCooperStore);

  return (
    <div className="lenovo-cooperation-detail__content-wrap">
      {!(!combinesDetail.creator && combinesDetail.schedule === "pending") &&
        combinesDetail.schedule !== "unchecked" && <State />}
      {!(combinesDetail.creator && combinesDetail.schedule === "rejected") &&
        combinesDetail.schedule !== "unchecked" && <Info />}
      <div className="lenovo-cooperation-detail__content-main">
        <div className="content-title">{combinesDetail.title}</div>
        <div className="content-content">{combinesDetail.content}</div>
        <div className="content-tags">
          {combinesDetail.categories &&
            combinesDetail.categories.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
          {combinesDetail.scenarios &&
            combinesDetail.scenarios.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
        </div>
        <div className="content-info">
          <div className="info-time">
            <p>更新时间：{combinesDetail.updatedTime}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Main);
