import React from "react";

const SVG = ({
  fill = "#34A853",
  width = "100%",
  className = "",
  viewBox = "0 0 19 19",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>success_01</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="List_02" transform="translate(-136.000000, -33.000000)">
        <g id="success_01">
          <g transform="translate(136.000000, 33.000000)">
            <g>
              <rect
                id="Rectangle"
                fill="#000000"
                fillRule="nonzero"
                opacity="0"
                x="0"
                y="0"
                width="19.2"
                height="19.2"
              />
              <path
                d="M17.8983641,9.59919414 C17.8983641,14.184367 14.1843958,17.8975679 9.5999904,17.8975679 C5.01804096,17.8975679 1.30161669,14.184367 1.30161669,9.59919414 C1.30161669,5.01561381 5.01804096,1.30243215 9.5999904,1.30243215 C14.1843958,1.30243215 17.8983641,5.01561381 17.8983641,9.59919414 Z"
                id="Shape"
                stroke="#34A853"
                fill={fill}
                fillRule="nonzero"
              />
              <polygon
                id="Path"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="14.457465 6.93786253 13.1673317 5.64853509 7.78795178 11.036837 6.03188158 9.27502993 4.74255414 10.5619206 7.73371014 13.5514649 7.78797096 13.4955348 7.84144509 13.5514649"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
