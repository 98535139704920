import React from "react";

const SVG = ({
  fill = "#c72c21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Data_detail_iocn_3</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Polaris-icon/Major/2-Tone/Label-Printer">
        <rect id="Fill" fill="#FFFFFF" x="4" y="0.8" width="8" height="8" />
        <path
          d="M15.2,4.8 L12.8,4.8 L12.8,0.8 C12.8,0.3576 12.4416,0 12,0 L4,0 C3.5584,0 3.2,0.3576 3.2,0.8 L3.2,4.8 L0.8,4.8 C0.3584,4.8 0,5.1576 0,5.6 L0,15.2 C0,15.6424 0.3584,16 0.8,16 L15.2,16 C15.6416,16 16,15.6424 16,15.2 L16,5.6 C16,5.1576 15.6416,4.8 15.2,4.8 L15.2,4.8 Z M5.6,11.2 L4,11.2 C3.5584,11.2 3.2,11.5576 3.2,12 C3.2,12.4424 3.5584,12.8 4,12.8 L5.6,12.8 C6.0416,12.8 6.4,12.4424 6.4,12 C6.4,11.5576 6.0416,11.2 5.6,11.2 M12,11.2 C11.5584,11.2 11.2,11.5576 11.2,12 C11.2,12.4424 11.5584,12.8 12,12.8 C12.4416,12.8 12.8,12.4424 12.8,12 C12.8,11.5576 12.4416,11.2 12,11.2 M14.4,14.4 L1.6,14.4 L1.6,6.4 L3.2,6.4 L3.2,8.8 C3.2,9.2424 3.5584,9.6 4,9.6 L12,9.6 C12.4416,9.6 12.8,9.2424 12.8,8.8 L12.8,6.4 L14.4,6.4 L14.4,14.4 Z M4.8,8 L11.2,8 L11.2,1.6 L4.8,1.6 L4.8,8 Z"
          id="Mask"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
