import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Tag, IconHeading, Collapse } from "../shared";
import DatabaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";

const ExpertHeader: React.FC = () => {
  const {
    data: { header },
  } = useContext(DatabaseDetailStore);

  return (
    <div className="expert-header">
      <div className="expert-header__base-info">
        <div className="expert-header__base-info__avatar">
          <img src={header.avatarUrl} alt="avatar" />
        </div>
        <div className="expert-header__base-info__text">
          <p>{header.name}</p>
          {/* <p>{header.categoryCn}</p> */}
        </div>
      </div>
      <div className="expert-header__tag-area">
        <div>
          <span>技术类型：</span>
          <div className="expert-header__tag-wrapper">
            {header.technologies.map((item, index) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </div>
        </div>
        <div>
          <span className="expert-header__label">行业</span>：
          <div className="expert-header__tag-wrapper">
            {header.industries.map((item, index) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </div>
        </div>
      </div>
      <div className="expert-header__desc">{header.summary}</div>
      {header.honor && (
        <div className="expert-header__block">
          <IconHeading icon="lenovo-honors" title="成果与荣誉" />
          <p>{header.honor}</p>
        </div>
      )}
      {header.position && (
        <div className="expert-header__block">
          <IconHeading icon="lenovo-position" title="职务" />
          <p>{header.position}</p>
        </div>
      )}

      {header.institution && (
        <div className="solution-header__producer expert-header__institution">
          <span className="solution-header__producer__title">所属企业</span>
          <div className="solution-header__producer-content">
            <Link
              to={`/library/institutions/${header.institution.id}`}
              className="solution-header__producer__avtar"
              target="_blank"
            >
              <img src={header.institution.logoUrl} alt="" />
            </Link>
            <div className="solution-header__producer__brief">
              <Link
                to={`/library/institutions/${header.institution.id}`}
                target="_blank"
              >
                {header.institution.name}
              </Link>
              {/* {header.institution.categoryCn && (
                <span className="institution-header__title__tags--category solution-header__title__tags--category">
                  {header.institution.categoryCn}
                </span>
              )} */}
              {[...header.institution.field, ...header.institution.industries]
                .slice(0, 5)
                .map((field, i) => (
                  <Tag key={i}>{field}</Tag>
                ))}
              <Collapse
                line="one"
                height={22}
                className="solution-header__producer__summary"
              >
                {header.institution.summary}
              </Collapse>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ExpertHeader);
