import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import QRCode from "qrcode.react";

import { Dialog } from "../";
import globalStore from "../../../stores/globalStore";
import lenovoRight from "../../../assets/images/lenovo-right.png";
import lenovoWait from "../../../assets/images/lenovo-wait.png";

interface Props {}

const LenovoWxDialog: React.FC<Props> = (props: Props) => {
  const { visibleTypes, setVisibleState, DialogProps } = useContext(
    globalStore
  );

  useEffect(() => {
    // api();
  }, []);

  const onCancel = () => {
    setVisibleState("lenovoWx", false);
    DialogProps.lenovoWxEvent && DialogProps.lenovoWxEvent();
  };

  return (
    <Dialog
      {...props}
      style={{ padding: "4px 15px 24px", borderRadius: 0 }}
      visible={visibleTypes.lenovoWx}
      hideBg={DialogProps.lenovoWxHideBg}
      onCancel={onCancel}
    >
      <div className="lenovo-wx-dialog">
        {DialogProps.lenovoWxIconed && (
          <p className="lenovo-wx-dialog-right">
            <img
              src={
                DialogProps.lenovoWxType === "success"
                  ? lenovoRight
                  : lenovoWait
              }
              width="40px"
              alt="icon"
            />
          </p>
        )}
        <p className="lenovo-wx-dialog-title">{DialogProps.lenovoWxTitle}</p>
        {DialogProps.lenovoWxSubTitle && (
          <p className="lenovo-wx-dialog-title">
            {DialogProps.lenovoWxSubTitle}
          </p>
        )}
        {DialogProps.lenovoWxContent && (
          <p className="lenovo-wx-dialog-content">
            {DialogProps.lenovoWxContent}
          </p>
        )}
        {!DialogProps.lenovoWxCodeId && (
          <div className="lenovo-wx-dialog-block">
            <img src={DialogProps.lenovoWxCodeImg} alt="qr_code" />
          </div>
        )}
        {DialogProps.lenovoWxCodeId && (
          <div className="lenovo-wx-dialog-block">
            <QRCode
              value={`${window.location.origin}/sign-up?id=${DialogProps.lenovoWxCodeId}`} // 生成二维码的内容
              size={180} // 二维码的大小
              fgColor="#000000" // 二维码的颜色
            />
          </div>
        )}
        {DialogProps.lenovoWxBtned && (
          <div className="lenovo-wx-dialog-btn" onClick={onCancel}>
            知道了
          </div>
        )}
        {DialogProps.lenovoWxBottomTip && (
          <p className="lenovo-wx-dialog-tips">
            {DialogProps.lenovoWxBottomTip}
          </p>
        )}
        {DialogProps.lenovoWxSmallTip && (
          <p className="lenovo-wx-dialog-small-tips">
            {DialogProps.lenovoWxSmallTip}
          </p>
        )}
        {DialogProps.lenovoWxBottomDom && DialogProps.lenovoWxBottomDom}
      </div>
    </Dialog>
  );
};

export default observer(LenovoWxDialog);
