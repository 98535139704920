import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="9noeusydsa"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#D0D9F2" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="vyqafc2fub">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="xnuzv9b80c">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="j4hsp8ht9d">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="nzv1hexbse">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="s4k229appf">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="m8cjw17n4g">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="qdue774tfh">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="26.912%"
          x2="80.248%"
          y2="70.113%"
          id="9k2a098yai"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#DFE2FF" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="1cupkmba0k"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.737254902 0 0 0 0 0.760784314 0 0 0 0 0.850980392 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
        <path
          d="M4.575 19.957.762 16.161A2.46 2.46 0 0 1 0 14.338c0-.715.254-1.322.762-1.823.509-.5 1.126-.75 1.853-.75.726 0 1.343.25 1.852.75l.239.215.24-.215c.508-.5 1.126-.75 1.851-.75.727 0 1.344.25 1.852.75.509.501.763 1.108.763 1.823 0 .716-.254 1.322-.763 1.824l-3.812 3.795a.335.335 0 0 1-.131.043.335.335 0 0 1-.13-.043z"
          id="lod3cu4cuj"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#9noeusydsa)" cx="16.471" cy="3.529" r="3.529" />
        <path
          d="M7.163 7.182c2.094 0 4.188-2.323 4.188-2.323l.331-.331 3.967 4.202c.772-.774 1.984-2.212 1.984-3.981.11-1.217-.44-2.323-1.433-3.318-2.975-3.096-6.06-.22-6.171-.11l-4.85 4.976c.552.553 1.213.885 1.984.885z"
          fill="url(#vyqafc2fub)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="M4.96 9.061c.11-.11.11-.22.11-.331 0-.221-.11-.332-.22-.443-.221-.22-.442-.22-.662-.22-.11 0-.33.11-.441.22L1.984 10.5c-.22.332-.22.774.11.995.22.221.551.221.771 0v-.11L4.63 9.172c.11 0 .22 0 .33-.11z"
          fill="url(#xnuzv9b80c)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="M6.502 10.61c0-.11.11-.332.11-.443 0-.22-.11-.331-.22-.442-.22-.221-.33-.221-.551-.221-.11 0-.22 0-.33.11v.111l-1.764 2.212c0 .11-.11.11-.22.22-.11.333-.11.664.22.885.11.111.33.111.551.111.11 0 .22-.11.33-.11l1.764-2.212c0-.11 0-.221.11-.221z"
          fill="url(#j4hsp8ht9d)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="M8.045 12.047c.11-.332.11-.663-.22-.884-.11-.111-.22-.111-.441-.111h-.11c-.11 0-.22.11-.33.11L5.18 13.375l-.11.11c-.11.111-.11.333-.11.443 0 .221.11.332.22.443.11.11.33.22.55.11.111 0 .331-.11.442-.221 0 0 0-.11.11-.11l1.763-2.102z"
          fill="url(#nzv1hexbse)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="M9.919 12.932c0-.221-.11-.332-.22-.442-.111-.111-.331-.111-.442-.111-.22 0-.33.11-.44.221 0 0 0 .11-.11.11l-1.764 2.212v.111c-.11.11-.11.221-.11.332 0 .22.11.332.22.442.33.221.772.221.992-.11l1.763-2.212c0-.221.11-.332.11-.553z"
          fill="url(#s4k229appf)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="m9.919 14.48-.992 1.217.55.552c.111.111.331.222.552.222.22 0 .44-.111.55-.222.111-.11.221-.331.221-.552a.846.846 0 0 0-.22-.553l-.661-.664z"
          fill="url(#m8cjw17n4g)"
          transform="translate(1.176 1.765)"
        />
        <path
          d="M13.004 7.182s0-.111 0 0l-1.432-1.549c-.661.774-2.425 2.323-4.409 2.323-1.102 0-2.093-.443-2.865-1.438l-.22-.332 4.077-4.091-.44-.443S6.282.215 4.408.215c-1.102 0-2.204.442-3.196 1.437C.331 2.426 0 3.422 0 4.417c0 1.99 1.653 3.76 2.204 4.423l.882-1.106c.22-.331.661-.552.992-.552.44 0 .771.11 1.102.331.33.221.55.664.55.996v.11c.331 0 .772.11.993.332.33.221.55.663.55.995v.11c.331 0 .772.111.993.332.33.222.55.664.55.996.441-.11.882 0 1.213.331.33.222.55.664.55.996 0 .331 0 .663-.22.995l.772.774c.11.11.33.221.551.221.22 0 .44-.11.551-.22.11-.111.22-.333.22-.554a.846.846 0 0 0-.22-.553l-.11-.11-2.755-2.876c-.11-.22-.11-.442 0-.552.11-.111.33-.111.55 0 0 0 .11 0 .11.11l2.756 2.875a.998.998 0 0 0 1.102 0c.33-.22.33-.663.11-.995l-.11-.11-2.755-2.876c-.11-.22-.11-.442 0-.553.11-.11.33-.11.44-.11.111 0 .111 0 .221.11l2.755 2.876s0 .11.11.11c.331.221.662.221.992-.11.11-.111.22-.332.22-.553a.846.846 0 0 0-.22-.553l-2.645-2.875z"
          fill="url(#qdue774tfh)"
          transform="translate(1.176 1.765)"
        />
        <g>
          <use
            fillOpacity=".2"
            fill="url(#9k2a098yai)"
            xlinkHref="#lod3cu4cuj"
          />
          <use fill="#000" filter="url(#1cupkmba0k)" xlinkHref="#lod3cu4cuj" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
