import React, { useContext } from "react";

import { IconHeading } from "../shared";
import ExpertMember from "./ExpertMember";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { INSTITUTION_EXPERTS } from "../../constants/pro_constants";

const InstitutionExpert = () => {
  const {
    data: { tabs },
  } = useContext(databaseDetailStore);

  return (
    <div className="database-detail__block" id={INSTITUTION_EXPERTS}>
      <IconHeading icon="lenovo-expert" title="专家信息" />
      <div className="database-detail__block__content">
        <ExpertMember
          experts={tabs.experts}
          extraClassName="expert__container--institution"
        />
      </div>
    </div>
  );
};

export default InstitutionExpert;
