import application from "../sites/application";

window.addEventListener("unhandledrejection", function(event) {
  console.warn(
    "WARNING: Unhandled promise rejection. Reason: " + event.reason,
    event
  );
});

application();
