import React, { useContext } from "react";
import { IconHeading } from "../shared";
import { SOLUTION_BUSINESS_CASE } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";
import SolutionsBusinessCaseItem from "./SolutionsBusinessCaseItem";

const SolutionBusinessCases = () => {
  // const [isExpand, setIsExpand] = useState(false);
  const {
    data: {
      tabs: { businessCases },
    },
  } = useContext(databaseDetailStore);

  return (
    <div className="database-detail__block" id={SOLUTION_BUSINESS_CASE}>
      <IconHeading icon="lenovo-business-case" title="应用案例" />
      <div className="database-detail__block__content solution-business-cases">
        {businessCases.map((item, i) => {
          return <SolutionsBusinessCaseItem {...item} key={i} />;
        })}
      </div>
    </div>
  );
};

export default SolutionBusinessCases;
