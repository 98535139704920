import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="21.563%"
          y1="16.647%"
          x2="61.413%"
          y2="64.589%"
          id="882jm0k0fa"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="18vjw9cnad">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="xmwxyuanke">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="17.097%"
          y1="19.845%"
          x2="75.774%"
          y2="76.269%"
          id="21n5mmv09f"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-45.8%"
          width="199.2%"
          height="191.5%"
          filterUnits="objectBoundingBox"
          id="c55qgu71nc"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-3.5%"
          y="-3.3%"
          width="107.1%"
          height="106.5%"
          filterUnits="objectBoundingBox"
          id="t88blumq0h"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <path id="4imjo9w54b" d="M0 0h14.118v15.294H0z" />
        <path id="edh9py16lg" d="M25.882 24.706H40V40H25.882z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#882jm0k0fa)" xlinkHref="#4imjo9w54b" />
          <use fill="#000" filter="url(#c55qgu71nc)" xlinkHref="#4imjo9w54b" />
        </g>
        <path
          d="M0 34.648v-2.554c0-.352.284-.638.637-.642.385-.004.76-.009 1.24-.009V9.585c0-2.783.78-3.928 3.385-4.966 3.565-1.422 7.123-2.861 10.7-4.252 2.487-.965 4.679.018 5.43 2.423.177.571.18 1.21.183 1.818.01 8.518.006 17.036.006 25.554v1.267h3.982v-1.252c0-5.337-.002-10.674.002-16.01.002-1.894.72-2.075 2.737-.913 1.204.695 2.478 1.252 3.755 1.798 1.045.445 1.441 1.078 1.426 2.22-.06 4.308-.024 8.62-.024 12.93v1.237h1.192c.354 0 .643.288.643.642v2.563a.643.643 0 0 1-.643.642H.64A.637.637 0 0 1 0 34.648zm7.77-18.955h7.916c1.07 0 1.938-.865 1.938-1.931a1.936 1.936 0 0 0-1.938-1.932H7.77c-1.069 0-1.937.866-1.937 1.932 0 1.066.868 1.931 1.937 1.931zm.009 9.855h7.875a1.95 1.95 0 0 0 1.952-1.947 1.95 1.95 0 0 0-1.952-1.946H7.78A1.95 1.95 0 0 0 5.827 23.6c0 .517.205 1.012.57 1.378.367.365.864.57 1.382.57z"
          fill="url(#18vjw9cnad)"
          transform="translate(2.353 2.353)"
        />
        <path
          d="M23.365 31.435H21.58v-1.267c0-8.517.005-17.035-.006-25.553 0-.608-.004-1.25-.184-1.819C20.641.391 18.45-.592 15.962.374 12.385 1.76 8.827 3.2 5.262 4.624 2.658 5.661 1.877 6.806 1.877 9.588v21.859c-.48 0-.857.004-1.24.008a.643.643 0 0 0-.637.642v2.554c0 .354.289.642.644.642h15.848a26.86 26.86 0 0 0 6.873-3.86zM7.77 11.828h7.916c1.072 0 1.938.865 1.938 1.931a1.936 1.936 0 0 1-1.938 1.932H7.77a1.936 1.936 0 0 1-1.937-1.932c0-1.066.868-1.931 1.937-1.931zM5.827 23.6a1.95 1.95 0 0 1 1.952-1.946h7.875a1.95 1.95 0 0 1 1.952 1.946 1.95 1.95 0 0 1-1.952 1.947H7.78a1.953 1.953 0 0 1-1.381-.57 1.942 1.942 0 0 1-.571-1.377zm19.736 5.928a26.558 26.558 0 0 0 7.565-13.674c-.229-.33-.575-.586-1.071-.8-1.279-.545-2.551-1.104-3.755-1.797-2.016-1.164-2.735-.98-2.737.913-.004 5.12-.002 10.239-.002 15.358z"
          fill="url(#xmwxyuanke)"
          transform="translate(2.353 2.353)"
        />
        <path
          d="M12.279 27.901h-2.147a1.95 1.95 0 0 1-1.952-1.947 1.95 1.95 0 0 1 1.952-1.946h7.875c.237 0 .464.045.676.121a26.775 26.775 0 0 0 5.251-5.575c0-3.864-.002-7.727-.006-11.59 0-.608-.004-1.25-.184-1.82-.75-2.404-2.942-3.387-5.429-2.421-3.577 1.39-7.135 2.831-10.7 4.253C5.011 8.015 4.23 9.16 4.23 11.942v17.897a26.588 26.588 0 0 0 8.049-1.938zm-2.156-13.72h7.916c1.071 0 1.937.865 1.937 1.931a1.936 1.936 0 0 1-1.937 1.932h-7.916a1.936 1.936 0 0 1-1.937-1.932c0-1.066.868-1.931 1.937-1.931z"
          fill="#FBCDCC"
          fillRule="nonzero"
          opacity=".4"
        />
        <path
          d="M8.451 17.085a1.916 1.916 0 0 1-.263-.97c0-1.069.868-1.932 1.938-1.932h2.495a26.652 26.652 0 0 0 8.025-11.79c-.717-.088-1.506.012-2.329.332-3.58 1.388-7.137 2.829-10.702 4.251C5.011 8.015 4.23 9.16 4.23 11.942v7.057a26.63 26.63 0 0 0 4.221-1.914z"
          fill="#FBCDCC"
          fillRule="nonzero"
          opacity=".4"
        />
        <g>
          <use
            fillOpacity=".2"
            fill="url(#21n5mmv09f)"
            xlinkHref="#edh9py16lg"
          />
          <use fill="#000" filter="url(#t88blumq0h)" xlinkHref="#edh9py16lg" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
