import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>summary</title>
    <desc>Created with Sketch.</desc>
    <g id="控件" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="nav" transform="translate(-21.000000, -41.000000)">
        <g transform="translate(0.000000, -46.000000)" id="Group-2">
          <g>
            <g id="机构库" transform="translate(0.000000, 83.000000)">
              <g
                id="Polaris-icon/Major/2-Tone/Text-Block"
                transform="translate(21.000000, 4.000000)"
              >
                <rect
                  id="Fill"
                  fill="#FFFFFF"
                  x="0.8"
                  y="0.8"
                  width="14.4"
                  height="14.4"
                />
                <path
                  d="M15.2,0 L0.8,0 C0.3584,0 0,0.3584 0,0.8 L0,15.2 C0,15.6416 0.3584,16 0.8,16 L15.2,16 C15.6416,16 16,15.6416 16,15.2 L16,0.8 C16,0.3584 15.6416,0 15.2,0 L15.2,0 Z M1.6,14.4 L14.4,14.4 L14.4,1.6 L1.6,1.6 L1.6,14.4 Z M4,8.8 L12,8.8 C12.4416,8.8 12.8,8.4416 12.8,8 C12.8,7.5584 12.4416,7.2 12,7.2 L4,7.2 C3.5584,7.2 3.2,7.5584 3.2,8 C3.2,8.4416 3.5584,8.8 4,8.8 M4,5.6 L12,5.6 C12.4416,5.6 12.8,5.2416 12.8,4.8 C12.8,4.3584 12.4416,4 12,4 L4,4 C3.5584,4 3.2,4.3584 3.2,4.8 C3.2,5.2416 3.5584,5.6 4,5.6 M8,10.4 L4,10.4 C3.5584,10.4 3.2,10.7584 3.2,11.2 C3.2,11.6416 3.5584,12 4,12 L7.2,12 C7.6416,12 8,11.6416 8,11.2 C8,10.7584 7.6416,10.4 7.2,10.4"
                  id="Mask"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
