import React from "react";
import "./style.scss";

import ActivityDateImg from "../../assets/images/activity-date.png";
import ActivityAddressImg from "../../assets/images/activity-address.png";
import { useHistory } from "react-router-dom";

interface Props {
  [key: string]: any;
  data: any;
}

const ActivityCard: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { data } = props;
  return (
    <div
      className="lenovo-activity-card"
      onClick={() =>
        history.push(
          `/activity/${data.id}?state=${
            data.status === "报名中" ? "pending" : "fulfilled"
          }`
        )
      }
    >
      <div className="activity-card__img">
        <img src={data.coverUrl} alt="coverUrl" />
        <div
          className={`card__content-state--mobile ${data.status === "看回放" &&
            "card__content-state--mobile--end"}`}
        >
          {data.status}
        </div>
      </div>
      <div className="activity-card__content">
        <p className="card__content-title">{data.title}</p>
        <p className="card__content-date">
          <img src={ActivityDateImg} alt={ActivityDateImg} />
          {data.date.pc}
        </p>
        <div className="card__content-footer">
          <div className="footer-address">
            <img src={ActivityAddressImg} alt={ActivityAddressImg} />
            {data.address.join(" / ")}
          </div>
          <div
            className={`footer-state ${data.status === "看回放" &&
              "footer-state--end"}`}
          >
            {data.status}
          </div>
        </div>
        <div className="card__content-info--mobile">
          <p className="footer-date--mobile">
            <img src={ActivityDateImg} alt={ActivityDateImg} />
            {data.date.m}
          </p>
          <div className="footer-address--mobile">
            <img src={ActivityAddressImg} alt={ActivityAddressImg} />
            {data.address}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
