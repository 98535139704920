import React from "react";
import "./style.scss";

import img0 from "../../../assets/images/empty_icons/0.svg";
import img1 from "../../../assets/images/empty_icons/1.svg";

interface Props {
  img?: number;
  title?: string;
  subTitle?: string;
}

const Empty: React.FC<Props> = (props: Props) => {
  const { img = 0, title = "空状态", subTitle = "" } = props;
  const map = [img0, img1];
  return (
    <div className="lenovo-empty">
      <div>
        <img src={map[img]} alt="empty" />
        <p>{title}</p>
        {subTitle && <p>{subTitle}</p>}
      </div>
    </div>
  );
};

export default Empty;
