import React from "react";

const SVG = ({
  fill = "#ffffff",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>时间</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="评选填报中心-首页"
        transform="translate(-230.000000, -110.000000)"
        fillRule="nonzero"
      >
        <g id="alarm">
          <g id="Group" transform="translate(230.000000, 106.000000)">
            <g id="时间" transform="translate(0.000000, 4.000000)">
              <rect
                id="Rectangle"
                fill="#000000"
                opacity="0"
                x="0"
                y="0"
                width="20"
                height="20"
              />
              <path
                d="M10,0 C4.45454545,0 0,4.45454545 0,10 C0,15.5454546 4.45454545,20 10,20 C15.5454546,20 20,15.5454546 20,10 C20,4.45454545 15.5454546,0 10,0 Z M10,18.5454546 C5.27272727,18.5454546 1.45454545,14.7272727 1.45454545,10 C1.45454545,5.27272727 5.27272727,1.45454545 10,1.45454545 C14.7272727,1.45454545 18.5454546,5.27272727 18.5454546,10 C18.5454546,14.7272727 14.7272727,18.5454546 10,18.5454546 Z M9.81818182,5.72727273 L8.36363637,5.72727273 L8.36363637,11.8181818 L14.4545454,11.8181818 L14.4545454,10.4545454 L9.81818182,10.4545454 L9.81818182,5.72727273 Z"
                id="Shape"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
