import React, { useContext } from "react";
import { Form, Input } from "antd";
import { observer } from "mobx-react";

import "./style.scss";
import { Button } from "..";
import authStore from "../../../stores/authStore";

interface Props {}

const Login: React.FC<Props> = () => {
  const { onLoginSubmit } = useContext(authStore);

  return (
    <>
      <div className="lenovo-login">
        <Form onFinish={values => onLoginSubmit(values)}>
          <h2>欢迎回来</h2>
          <h2 className="lenovo-login__header">登录账号继续使用</h2>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入正确的邮箱" }]}
            name="username"
          >
            <Input placeholder="请输入登录邮箱" />
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的密码" },
              { min: 6, message: "请输入 6 位密码" },
            ]}
            name="password"
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item className="lenovo-login__submit">
            <Button isFullWidth size="large">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="lenovo-login--mobile">
        <Form onFinish={values => onLoginSubmit(values)}>
          <h2>欢迎回来</h2>
          <h2 className="lenovo-login__header">登录账号继续使用</h2>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入正确的邮箱" }]}
            name="username"
          >
            <Input placeholder="请输入登录邮箱" />
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的密码" },
              { min: 6, message: "请输入 6 位密码" },
            ]}
            name="password"
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item className="lenovo-login__submit">
            <button className="btn btn--primary">登录</button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default observer(Login);
