import React, { useState, useContext, useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import "./style.scss";
import { Menu, Layout, Avatar, Divider, Popover } from "antd";
import { Icon } from "../../shared";
import NAV from "../../../constants/nav";
import logo from "../../../assets/images/logo@3x.png";
import emptyAvatar from "../../../assets/images/empty-avatar.png";
import { openNewTab } from "../../../utils/tools";
import lWxCode from "../../../assets/images/l-wx-img.png";
import authStore from "../../../stores/authStore";
import { ROOT_PATH } from "../../../constants/pro_constants";
import globalStore from "../../../stores/globalStore";
import getBaseName from "../../../utils/getBaseName";

const { Header, Content } = Layout;

interface Props {
  children?: React.ReactNode;
  bgc?: string;
  onScrollCapture?(): any;
}

const Nav: React.FC<Props> = (props: Props) => {
  const basename = getBaseName();
  const { children, bgc = "#f5f6f7", onScrollCapture = () => {} } = props;
  const history = useHistory();
  const location = useLocation();
  const { isLogin, currentUser, syncUserInfo, isAuthUser } = useContext(
    authStore
  );
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    globalStore
  );

  // const reload = () => {
  //   (window as any).location.reload();
  // };

  const activeCheck = (nowPath: string, activePath: any) =>
    activePath.some((path: string) => nowPath.includes(path));

  const searchKey = () => {
    const res = NAV.find(
      (item: any) =>
        location.pathname === item.path ||
        (item.path !== "/" &&
          (location.pathname + location.search).includes(item.path)) ||
        activeCheck(location.pathname + location.search, item.activePath)
    );
    return res ? res.path : "";
  };

  const [clientWidth, setClientWidth] = useState(document.body.clientWidth);
  const [current, setCurrent] = useState(searchKey());
  const [showPanel, setShowPanel] = useState(false);

  window.addEventListener("resize", () => {
    setClientWidth(document.body.clientWidth);
  });

  useEffect(() => {
    syncUserInfo();
  }, []);

  console.log(
    `${(window as any).location.origin}${
      (window as any).location.pathname
    }${encodeURIComponent(`${(window as any).location.search}`)}`,
    "1"
  );

  useEffect(() => {
    setCurrent(searchKey());
    console.log("useful?");
  }, [(window as any).location.href]);

  return (
    <>
      {clientWidth > 767 ? (
        <Layout className={`layout ${children && "layout__children"}`}>
          <Header className="header">
            <div className="menu">
              <Link to={ROOT_PATH} className="menu-logo-text">
                <img src={logo} alt="logo" />
              </Link>
              <Menu
                onClick={(e: any) => {
                  setCurrent(e.key);
                }}
                selectedKeys={[current]}
                mode="horizontal"
              >
                {NAV.map((item: any) => (
                  <Menu.Item key={item.path}>
                    <Link to={item.path} className="nav-item">
                      {item.svg}
                      {item.name}
                      {/* {item.child.length > 0 && (
                        <i className="iconfont icon-Input-drop more" />
                      )} */}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
              <Popover
                overlayClassName="lenovo-wx-code--popover"
                content={
                  <div className="lenovo-wx-code">
                    <p>联想未来CLUB</p>
                    <p>扫描下方关注，报名联想未来Club活动</p>
                    <img src={lWxCode} alt="wx-code" />
                  </div>
                }
                // trigger="click"
                getPopupContainer={() =>
                  document.querySelector(".header") || document.body
                }
              >
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  role="menuitem"
                >
                  <span className="nav-item">关注联想未来CLUB</span>
                </li>
              </Popover>
              {isLogin ? (
                <div className="menu__user-area">
                  {currentUser.avatarUrl ? (
                    <Avatar src={currentUser.avatarUrl} />
                  ) : (
                    <Icon name="cooperation-card-user" size={16} />
                  )}
                  <span className="menu__user-name">
                    {currentUser.name ||
                      currentUser.full_name ||
                      currentUser.username}
                  </span>
                  <div className="menu__user-area__panel">
                    <div
                      className="menu__user-area__panel-item"
                      onClick={() => {
                        history.push("/profile?type=edit");
                      }}
                    >
                      修改个人信息
                    </div>

                    <a
                      className="menu__user-area__panel-item"
                      onClick={() => {
                        localStorage.removeItem("currentUser");
                      }}
                      href="/info/logout"
                    >
                      退出登录
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  {/* <a
                    className="menu-btn sign-in-btn"
                    href={`${basename}/auth/wechat?origin=${`${location.origin}${
                      location.pathname
                    }${encodeURIComponent(`${location.search}`)}`}`}
                  >
                    登录
                  </a> */}
                  <span
                    className="menu-btn sign-in-btn"
                    onClick={() => {
                      revertDialogProps();
                      setDialogProp("lenovoWxTitle", "使用微信扫码进入");
                      setDialogProp("lenovoWxSubTitle", "「联想未来Club」");
                      setDialogProp("lenovoWxCodeImg", lWxCode);
                      setDialogProp("lenovoWxBtned", false);
                      setDialogProp("lenovoWxIconed", false);
                      setVisibleState("lenovoWx", true);
                    }}
                  >
                    登录
                  </span>
                  <span
                    className="hide-btn"
                    onClick={() => {
                      setDialogProp("lenovoWxHideBg", true);
                    }}
                  >
                    hide element
                  </span>
                </>
              )}
            </div>
          </Header>
          {children && (
            <Content
              className="content"
              style={{ background: bgc }}
              onScrollCapture={onScrollCapture}
            >
              {children}
            </Content>
          )}
        </Layout>
      ) : (
        <Layout
          className={`layout--mobile ${children && "layout__children--mobile"}`}
        >
          <Header className="header">
            <Icon
              name="lenovo-menu"
              onClick={() => setShowPanel(true)}
              size={22}
            />
            <Link to={ROOT_PATH} className="menu-logo-text--mobile">
              <img src={logo} alt="logo" />
            </Link>
          </Header>
          <div className={`menu-panel ${showPanel ? "menu-panel--show" : ""}`}>
            <div className="menu-panel__header">
              <Link
                to={ROOT_PATH}
                className="menu-logo-text--mobile"
                onClick={() => setShowPanel(false)}
              >
                <img src={logo} alt="logo" />
              </Link>
              <Icon
                name="lenovo-menu"
                onClick={() => setShowPanel(false)}
                size={22}
              />
            </div>
            <div className="menu-panel__content">
              {isLogin ? (
                <div className="menu-panel__user--login">
                  <p className="user-name">
                    {currentUser.name ||
                      currentUser.full_name ||
                      currentUser.username}
                  </p>
                  {isAuthUser && (
                    <div>
                      <span className="user-group">
                        {currentUser.department}
                      </span>
                      {currentUser.position_show && (
                        <span className="user-job">{currentUser.position}</span>
                      )}
                    </div>
                  )}
                  <div className="user-handle">
                    <span
                      className="edit-profile"
                      onClick={() => {
                        history.push("/profile?type=edit");
                      }}
                    >
                      修改个人信息
                    </span>
                    <a
                      className="log-out"
                      onClick={() => {
                        localStorage.removeItem("currentUser");
                      }}
                      href="/info/logout"
                    >
                      退出登录
                    </a>
                  </div>
                </div>
              ) : (
                <a
                  className="menu-panel__user"
                  href={`${basename}/auth/wechatservice?origin=${`${
                    (window as any).location.origin
                  }${(window as any).location.pathname}${encodeURIComponent(
                    `${(window as any).location.search}`
                  )}`}`}
                >
                  <Avatar size={56} src={emptyAvatar} />
                  <p>登录账号</p>
                </a>
              )}
              <Divider dashed type="horizontal" style={{ color: "#e2e2eb" }} />
              {NAV.map((item, index) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`nav-item ${
                    item.path === current ? "nav-item--active" : ""
                  } ${index === NAV.length - 1 ? "nav-item--last" : ""}`}
                  onClick={() => setShowPanel(false)}
                >
                  {item.svg}
                  {item.name}
                  {/* {item.child.length > 0 && (
                    <i className="iconfont icon-Input-drop more" />
                  )} */}
                </Link>
              ))}
              <Divider dashed type="horizontal" style={{ color: "#e2e2eb" }} />
            </div>
            <div className="menu-panel__entry">
              <p className="menu-panel__entry-title">快速入口</p>
              <div className="menu-panel__entry-info">
                <div
                  onClick={() => openNewTab("/library/institutions", history)}
                >
                  <Icon size={20} name="lenovo-entry-1" />
                  <p>解决方案信息库</p>
                </div>
                <div
                  onClick={() => {
                    localStorage.setItem("tbd", "true");
                    openNewTab("/library/institutions", history);
                  }}
                >
                  <Icon size={20} name="lenovo-entry-2" />
                  <p>新投企业介绍</p>
                </div>
                <div onClick={() => openNewTab("/activity", history)}>
                  <Icon size={20} name="lenovo-entry-3" />
                  <p>往期活动</p>
                </div>
                <div onClick={() => openNewTab("/activity", history)}>
                  <Icon size={20} name="lenovo-entry-4" />
                  <p>最新活动</p>
                </div>
                <div onClick={() => openNewTab("/cooperation", history)}>
                  <Icon size={20} name="lenovo-entry-5" />
                  <p>生态合作</p>
                </div>
                <div onClick={() => openNewTab("/circle", history)}>
                  <Icon size={20} name="lenovo-entry-6" />
                  <p>参与圈子</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`menu-layer ${showPanel ? "menu-layer--show" : ""}`}
            onClick={() => setShowPanel(false)}
          />
          {children && (
            <Content
              className="content"
              style={{ background: bgc }}
              onScrollCapture={onScrollCapture}
            >
              {children}
            </Content>
          )}
        </Layout>
      )}
    </>
  );
};

export default observer(Nav);
