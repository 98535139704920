import {
  INSTITUTION,
  INDUSTRIES,
  TECHNOLOGIES,
  SOLUTION,
  APPLY_FORMATS,
  EXPERT,
} from "../constants/pro_constants";

const initFilter = [{ name: "不限", active: true }];

const formatDatabaseData = (type, initData) => {
  if (type === INSTITUTION) {
    return {
      filters: {
        [INDUSTRIES]: initFilter.concat(
          initData && initData[INDUSTRIES]
            ? initData[INDUSTRIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
        [APPLY_FORMATS]: initFilter.concat(
          initData && initData[APPLY_FORMATS]
            ? initData[APPLY_FORMATS].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
        [TECHNOLOGIES]: initFilter.concat(
          initData && initData[TECHNOLOGIES]
            ? initData[TECHNOLOGIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
      },
    };
  }
  if (type === SOLUTION) {
    return {
      filters: {
        [INDUSTRIES]: initFilter.concat(
          initData && initData[INDUSTRIES]
            ? initData[INDUSTRIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
        [APPLY_FORMATS]: initFilter.concat(
          initData && initData[APPLY_FORMATS]
            ? initData[APPLY_FORMATS].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
        [TECHNOLOGIES]: initFilter.concat(
          initData && initData[TECHNOLOGIES]
            ? initData[TECHNOLOGIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
      },
    };
  }
  if (type === EXPERT) {
    return {
      filters: {
        [INDUSTRIES]: initFilter.concat(
          initData && initData[INDUSTRIES]
            ? initData[INDUSTRIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
        [TECHNOLOGIES]: initFilter.concat(
          initData && initData[TECHNOLOGIES]
            ? initData[TECHNOLOGIES].map(item => ({
                name: item.name,
                id: item.id,
                active: false,
              }))
            : []
        ),
      },
    };
  }
};

const formatDataBaseListData = (type, initData) => {
  if (type === INSTITUTION) {
    return {
      data:
        initData &&
        initData.data.map(data => ({
          ...data,
          name: {
            name: data.name,
            avatarUrl: data.logoUrl,
            tbd: data.tbd,
            // categoryCn: data.categoryCn,
          },
          summary: data.summary,
          key: data.id,
          roles: data.companyTypes,
          foundedAt: data.foundedAt,
          region: data.region,
          companySize: data.companySize,
          developStage: data.developStage,
        })),
      count: initData && initData.totalCount,
      currentTotalCount: initData && initData.totalCount,
      pageSize: initData && initData.pageSize,
      current: initData && initData.currentPage,
    };
  }

  if (type === SOLUTION) {
    return {
      data:
        initData &&
        initData.data.map(data => ({
          key: data.id,
          name: [data.name, data.brand],
          summary: data.summary,
          applyFormats: data.applyFormats,
          producer: {
            name: data.institution.name,
            avatarUrl: data.institution.logoUrl,
          },
          region: data.institution.location,
        })),
      count: initData && initData.totalCount,
      currentTotalCount: initData && initData.totalCount,
      pageSize: initData && initData.pageSize,
      current: initData && initData.currentPage,
    };
  }

  if (type === EXPERT) {
    return {
      data:
        initData &&
        initData.data.map(data => ({
          key: data.id,
          name: {
            name: data.name,
            avatarUrl: data.avatarUrl,
          },
          employer: data.institution.name,
          industries: data.industries,
          technologies: data.technologies,
        })),
      count: initData && initData.totalCount,
      currentTotalCount: initData && initData.totalCount,
      pageSize: initData && initData.pageSize,
      current: initData && initData.currentPage,
    };
  }
};

const formatDatabaseKeywordData = initData => {
  return (
    initData &&
    initData.map(item => ({
      name: item.name,
      avatarUrl: item.logoUrl,
    }))
  );
};

export {
  formatDatabaseData,
  formatDataBaseListData,
  formatDatabaseKeywordData,
};
