import React from "react";

const SVG = ({
  fill = "#393B42",
  width = "100%",
  className = "",
  viewBox = "0 0 20 21",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill} fillRule="nonzero">
      <path d="M4.643 7c-1.378 0-2.5 1.099-2.5 2.45v7c0 1.35 1.122 2.45 2.5 2.45h10.714c1.378 0 2.5-1.1 2.5-2.45v-7c0-1.351-1.122-2.45-2.5-2.45H4.643zm10.714 14H4.643C2.083 21 0 18.959 0 16.45v-7C0 6.941 2.083 4.9 4.643 4.9h10.714C17.917 4.9 20 6.941 20 9.45v7c0 2.509-2.083 4.55-4.643 4.55z" />
      <path d="M14.286 6.3a1.06 1.06 0 0 1-1.072-1.05v-1.4c0-.965-.8-1.75-1.785-1.75H8.57c-.984 0-1.785.785-1.785 1.75v1.4c0 .375-.204.722-.536.91a1.09 1.09 0 0 1-1.071 0 1.046 1.046 0 0 1-.536-.91v-1.4C4.643 1.727 6.405 0 8.57 0h2.858c2.166 0 3.928 1.727 3.928 3.85v1.4c0 .58-.48 1.05-1.071 1.05M10 17.15a1.06 1.06 0 0 1-1.071-1.05V9.8c0-.58.48-1.05 1.071-1.05.592 0 1.071.47 1.071 1.05v6.3c0 .58-.48 1.05-1.071 1.05" />
    </g>
  </svg>
);

export default SVG;
