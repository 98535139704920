import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { Tag, Collapse, Collapses } from "../shared";
import DatabaseDetailStore from "../../stores/databaseDetailStore";
import { isMobile } from "../../utils/tools";

const BusinessCaseHeader: React.FC = () => {
  const {
    data: { header },
  } = useContext(DatabaseDetailStore);

  const hasTechnologies = header.technologies.length > 0;
  const hasSolutions = header.solutions && header.solutions.length > 0;
  const hasKeyData = header.keyData && header.keyData.length > 0;
  const hasWebsiteUrl = header.websiteUrl && header.websiteUrl.length > 0;

  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const styles =
    isShowCollapse && !isShowAll
      ? { overflow: "hidden", maxHeight: "76px" }
      : {};
  const stylesMobile =
    isShowCollapse && !isShowAll
      ? { overflow: "hidden", maxHeight: "58px" }
      : {};
  useEffect(() => {
    const listDom = document.getElementsByClassName(
      "business-case__header-solutions__list"
    )[0];
    const categoryHeight = listDom && listDom.clientHeight;
    if (categoryHeight > 76 && header.solutions.length > 2) {
      setIsShowCollapse(true);
    }
  }, []);

  return (
    <div className="business-case-header">
      <h2>{header.name}</h2>
      <div className="business-case-header__tags">
        {header.fields &&
          header.fields.map((item, index) => {
            return <Tag key={index}>{item}</Tag>;
          })}
      </div>
      <Collapse line="three" height={50} className="business-case__summary">
        {header.summary}
      </Collapse>
      {hasTechnologies && (
        <div className="business-case-header__keywords business-case-header__technologies">
          热门技术：
          {header.technologies.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )}
      {hasWebsiteUrl && (
        <div className="business-case-header__link">
          介绍网站：
          <a href={header.websiteUrl} target="_blank">
            {header.websiteUrl}
          </a>
        </div>
      )}
      {(hasSolutions || hasKeyData) && (
        <div className="business-case__header-solutions">
          {hasSolutions && (
            <div className="business-case__header-solutions__list">
              <span>涉及解决方案</span>
              <div className="business-case__header-solutions__info-items">
                <div
                  className="business-case__header-solutions__list"
                  style={isMobile() ? stylesMobile : styles}
                >
                  {header.solutions.map(item => (
                    <div
                      key={item.id}
                      className="business-case__header-solutions__list-item"
                    >
                      <Link
                        to={`/library/solutions/${item.id}`}
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                      <div className="business-case__header-solutions__list-item-icon">
                        <img src={item.avatarURL} alt="" />
                        {item.producerName}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {hasSolutions && isShowCollapse && (
                <Collapses
                  openedText="收起"
                  closedText="查看更多"
                  isOpen={false}
                  onChange={() => setIsShowAll(!isShowAll)}
                />
              )}
            </div>
          )}

          {hasKeyData && (
            <>
              <div className="lenovo-divider" />
              <div className="business-case__header-solutions__info">
                <span>关键数据</span>
                <div className="business-case__header-solutions__info-items">
                  {header.keyData.map(item => {
                    return (
                      <div
                        key={item.key}
                        className="business-case__header-solutions__info-item"
                      >
                        <span className="business-case__header-solutions__info-count">
                          {item.value}
                        </span>
                        <span className="business-case__header-solutions__info-item-key">
                          {item.key}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(BusinessCaseHeader);
