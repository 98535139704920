import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 18 18"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="数据库桌面端"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="联想创投--收藏---Chrome"
        transform="translate(-1390.000000, -107.000000)"
      >
        <g id="Group-9" transform="translate(996.000000, 98.000000)">
          <g id="close" transform="translate(394.000000, 9.000000)">
            <circle id="Oval" fill="#DDDDDD" cx="9" cy="9" r="9" />
            <path
              d="M10.1738656,8.99929899 L13.7537394,5.42724975 C13.9649174,5.21604208 14.0473919,4.90820019 13.9700953,4.61968515 C13.8927988,4.33117011 13.6674745,4.10581421 13.3789999,4.02850684 C13.0905253,3.95119946 12.7827266,4.03368545 12.5715485,4.24489312 L9,7.82526882 L5.4284515,4.24489312 C5.10199851,3.91839435 4.57271362,3.91839435 4.24626062,4.24489312 C3.91980762,4.57139189 3.91980762,5.10075098 4.24626062,5.42724975 L7.82613441,8.99929899 L4.24626062,12.5713482 C4.08865239,12.7276912 4,12.940512 4,13.1625265 C4,13.3845411 4.08865239,13.5973618 4.24626062,13.7537049 C4.40258172,13.9113352 4.61537264,14 4.83735606,14 C5.05933949,14 5.2721304,13.9113352 5.4284515,13.7537049 L9,10.1733292 L12.5715485,13.7537049 C12.7278696,13.9113352 12.9406605,14 13.1626439,14 C13.3846274,14 13.5974183,13.9113352 13.7537394,13.7537049 C13.9113476,13.5973618 14,13.3845411 14,13.1625265 C14,12.940512 13.9113476,12.7276912 13.7537394,12.5713482 L10.1738656,8.99929899 Z"
              id="Path"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
