import { action, observable } from "mobx";
import { createContext } from "react";

import {
  fetchActivityNewest,
  fetchActivityFilters,
  fetchActivityList,
} from "../api/index";
import {
  formatFilterData,
  formatListData,
  formatNewestData,
} from "../adapter/formatActivityData";

class ActivityStore {
  @observable public params: any = {};
  @observable public nowActivity: any = {};
  @observable public activityFilter: any = {};
  @observable public activityNewest: any = [];
  @observable public activityList: any = [];
  @observable public pagination = {
    currentPage: 1,
    // pageSize: 8,
    pageSize: 100,
    totalCount: 0,
    hasNextPage: false,
  };
  @observable public loading = true;
  @observable public newsLoading = true;

  @action
  public fetchFilter = async () => {
    const res = await fetchActivityFilters();
    this.activityFilter = formatFilterData({
      status: res.data.status,
      tags: res.data.tags,
    });
  };

  @action
  public setParams = (data: any) => {
    this.params = data;
  };

  @action
  public setPageNum = (page: number) => {
    this.pagination.currentPage = page;
  };

  @action
  public setActivity = (data: any) => {
    this.nowActivity = data;
  };

  @action
  public getActivityNewest = async () => {
    this.newsLoading = true;
    const res = await fetchActivityNewest();
    this.activityNewest = formatNewestData(res.data.data);
    this.newsLoading = false;
  };

  @action
  public fetchList = async (isMobile?: boolean) => {
    this.loading = true;
    const res = await fetchActivityList({
      pagination: this.pagination,
      params: this.params,
    });
    const { data, currentPage, totalCount, hasNextPage } = res.data;
    this.activityList = isMobile
      ? [...this.activityList, ...formatListData(data)]
      : formatListData(data);
    this.pagination = {
      ...this.pagination,
      currentPage,
      hasNextPage,
      totalCount,
    };
    this.loading = false;
  };
}

export default createContext(new ActivityStore());
