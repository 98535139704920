import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { Nav, Footer, CodeEntry } from "../shared/index";
import { useQuery } from "../../utils/tools";
import { Form, Input, Checkbox } from "antd";
import globalStore from "../../stores/globalStore";
import WxImg from "../../assets/images/l-wx-img.png";
import authStore from "../../stores/authStore";
import signIn from "../../utils/sign_in";
import getBrowserInfo from "../../utils/getBrowserInfo";
import { ROOT_PATH } from "../../constants/pro_constants";

interface Props {
  [key: string]: any;
}

const Profile: React.FC<Props> = () => {
  // add edit post
  const query = useQuery();
  const type = query.get("type");
  const old = query.get("old");
  const [form] = Form.useForm();
  const history = useHistory();
  const [isPosted, setIsPosted] = useState(false);
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    globalStore
  );
  const {
    currentUser,
    updateUserInfo,
    isVisitor,
    isAuthUser,
    loginLoading,
    passLogin,
  } = useContext(authStore);
  const BrowserInfo = getBrowserInfo();

  const typeOrm = {
    // 补充
    add: {
      title: "提交身份信息",
      btnText: "提交信息,进入平台",
    },
    // 修改
    edit: {
      title: "提交身份信息",
      btnText: "保存修改",
    },
    // 外链
    post: {
      title: "提交身份信息",
      btnText: "提交信息,进入平台",
    },
    // 认证
    auth: {
      title: "提交身份信息",
      btnText: "提交信息,进入平台",
    },
  };

  const goBack = (num: number) => {
    window.history.go(num);
  };

  console.log("back-old:", old);
  old && sessionStorage.setItem("isOld", "true");
  console.log("come-old:", sessionStorage.getItem("isOld"));

  useEffect(() => {
    if (!loginLoading) {
      if (isVisitor) {
        if (BrowserInfo.type !== "WeixinBrowser") {
          history.push("/join-group?type=login");
        } else {
          const old = !!sessionStorage.getItem("isOld");
          signIn(old);
        }
      }
      // 是认证用户,进来认证,触发登录
      if (type === "auth" && isAuthUser) {
        const from = localStorage.getItem("from") || ROOT_PATH;
        history.push(from);
      }
    }
  }, [loginLoading]);

  useEffect(() => {
    form.setFieldsValue(currentUser);
  }, [currentUser]);

  const onFinish = async (data: any) => {
    if (isPosted) {
      return false;
    }
    setIsPosted(true);
    await updateUserInfo(data);
    revertDialogProps();
    setDialogProp("lenovoWxTitle", "身份认证审核中");
    setDialogProp(
      "lenovoWxContent",
      "您已成功提交身份信息，平台审核后将通过服务号告知您身份认证结果"
    );
    setDialogProp("lenovoWxCodeImg", WxImg);
    setDialogProp(
      "lenovoWxBottomTip",
      "长按识别二维码返回服务号，留意后续通知"
    );
    setDialogProp("lenovoWxEvent", () => {
      const from = localStorage.getItem("from") || ROOT_PATH;
      const fn = passLogin ? () => history.push(from) : () => goBack(-1);
      sessionStorage.getItem("isOld") ? fn() : history.push(ROOT_PATH);
    });
    setVisibleState("lenovoWx", true);
  };

  return (
    <div className="lenovo-profile">
      <Helmet>
        <title>提交身份信息 | 联想数字生态平台</title>
      </Helmet>
      <Nav>
        <div className="lenovo-profile__content">
          <CodeEntry />
          <div className="profile__content-form">
            <p className="form-title">{typeOrm[type].title}</p>
            <p className="form-theme">我的联想未来 Club 身份</p>
            <Form
              colon={false}
              requiredMark={false}
              size="large"
              form={form}
              initialValues={currentUser}
              onFinish={onFinish}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label={
                  <>
                    <span>昵</span>
                    <span>称</span>
                  </>
                }
                rules={[{ required: true, message: "输入您的昵称" }]}
              >
                <Input placeholder="输入您的昵称" />
              </Form.Item>
              <div className="form-block">
                <p className="form-sub-title">我的基本信息</p>
                <p className="form-tips">
                  该部分信息仅用于平台与您联系，不会对外公开
                </p>
              </div>
              <Form.Item
                name="full_name"
                label="真实姓名"
                rules={[{ required: true, message: "输入您的真实姓名" }]}
              >
                <Input placeholder="输入您的真实姓名" />
              </Form.Item>
              <Form.Item
                name="mobile"
                label="联系电话"
                rules={[
                  { required: true, message: "输入您的联系电话" },
                  {
                    pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                    message: "请输入正确的联系电话",
                  },
                ]}
              >
                <Input placeholder="输入您的联系电话" />
              </Form.Item>
              <Form.Item
                name="email"
                label="邮箱地址"
                rules={[
                  { required: true, message: "输入您的邮箱地址" },
                  {
                    pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                    message: "请输入正确的邮箱地址",
                  },
                ]}
              >
                <Input placeholder="输入您的邮箱地址" />
              </Form.Item>
              <div className="form-block">
                <p className="form-sub-title">我的职业身份</p>
                <p className="form-tips">
                  您的职业身份将用于平台认证，并将在平台内进行展示
                </p>
              </div>
              <Form.Item
                name="company"
                label="所在公司"
                rules={[{ required: true, message: "输入您的所在公司" }]}
              >
                <Input placeholder="输入您的所在公司" />
              </Form.Item>
              <Form.Item
                name="department"
                label="所属部门"
                rules={[{ required: true, message: "输入您的所属部门" }]}
              >
                <Input placeholder="输入您的所属部门" />
              </Form.Item>
              <Form.Item
                name="position"
                label={
                  <>
                    <span>职</span>
                    <span>位</span>
                  </>
                }
                rules={[{ required: true, message: "输入您的职位" }]}
              >
                <Input placeholder="输入您的职位" />
              </Form.Item>
              <Form.Item
                name="position_show"
                valuePropName="checked"
                className="form-item--left"
              >
                <Checkbox>公开我的职位</Checkbox>
              </Form.Item>
              <Form.Item className="form-item--left">
                <div
                  className={`form-submit ${isPosted && "opt-5"}`}
                  onClick={() => form.submit()}
                >
                  {typeOrm[type].btnText}
                </div>
              </Form.Item>
              <p className="pt-xy">
                进入平台均视为已同意
                <a
                  target="_blank"
                  href="https://lenovo-assets.jiqizhixin.com/%E8%81%94%E6%83%B3%E6%95%B0%E5%AD%97%E7%94%9F%E6%80%81%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf"
                >
                  《用户协议》
                </a>
              </p>
            </Form>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(Profile);
