import React, { useContext } from "react";
import { IconHeading, EmptyImport } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { PROJECT_BACKGROUND } from "../../constants/pro_constants";

const ProjectBackgroundIntro = () => {
  const {
    data: {
      tabs: { projectBackground },
    },
    isProjectBackgroundTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <div
      className="database-detail__intro database-detail__block"
      id={PROJECT_BACKGROUND}
    >
      <IconHeading icon="project-background" title="项目背景及客户痛点" />
      {isProjectBackgroundTabContentEmpty ? (
        <div className="database-detail__block__content">
          <EmptyImport />
        </div>
      ) : (
        <div
          className="database-detail__block__content"
          dangerouslySetInnerHTML={{ __html: `${projectBackground}` }}
        />
      )}
    </div>
  );
};

export default observer(ProjectBackgroundIntro);
