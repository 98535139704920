import React from "react";

const SVG = ({
  fill = "#C72C21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  fill="#FFF"
                  d="M11.2 2.4L9.6 4 6.401 4 4.8 2.4 2.4 2.4 2.4 15.2 13.601 15.2 13.601 2.4z"
                  transform="translate(-250 -655) translate(220 235) translate(30 305) translate(0 111) translate(0 4)"
                />
                <path
                  fill={fill}
                  d="M7.2 6.4H.8c-.442 0-.8-.358-.8-.8V.8C0 .358.358 0 .8 0h6.4c.442 0 .8.358.8.8v4.8c0 .442-.358.8-.8.8zM1.6 4.8h4.8V1.6H1.6v3.2zM7.2 16H.8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h6.4c.442 0 .8.358.8.8 0 .442-.358.8-.8.8zm8-3.2H.8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h14.4c.442 0 .8.358.8.8 0 .442-.358.8-.8.8m0-3.2H.8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h14.4c.442 0 .8.358.8.8 0 .442-.358.8-.8.8m0-3.2h-4.8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h4.8c.442 0 .8.358.8.8 0 .442-.358.8-.8.8m0-3.2h-4.8c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h4.8c.442 0 .8.358.8.8 0 .442-.358.8-.8.8"
                  transform="translate(-250 -655) translate(220 235) translate(30 305) translate(0 111) translate(0 4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
