import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./style.scss";
import { ROOT_PATH } from "../../constants/pro_constants";
import { Icon, Sidebar } from "../shared";
import FilterSearchPanel from "./FilterSearchPanel";
import databaseStore from "../../stores/databaseStore";
import SelectedConditionsPanel from "./SelectedConditionsPanel";
import ResultListPanel from "./ResultListPanel";
import {
  EXPERT,
  INSTITUTION,
  PRO,
  SOLUTION,
} from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
declare const wx;
const Logo = `${window.location.origin}/database-share.png`;

interface Props {
  type: "institution" | "solution";
}

const Database: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const contentRef = useRef();

  const {
    initializeType,
    initializeFilters,
    fetchDatabaseList,
    resetPaginationMobile,
    isSelectedConditionsPanelShow,
    resetFilters,
    shareInWechat,
    resultList: { count },
  } = useContext(databaseStore);

  useEffect(() => {
    initializeType(props.type);
    initializeFilters();
    resetFilters();
    resetPaginationMobile();
    fetchDatabaseList();
  }, [props.type]);

  const messageOnShare = {
    [INSTITUTION]: {
      title: "企业库 | 联想创投成员企业信息库",
      desc: `共收录 ${count} 家成员企业信息，快来寻找你感兴趣的企业吧！`,
      link: "",
      imgUrl: Logo,
      success: () => {
        shareInWechat();
      },
    },
    [SOLUTION]: {
      title: " 方案库 | 联想创投成员企业信息库",
      desc: `共收录 ${count} 份解决方案信息，快来浏览你感兴趣的方案吧！`,
      link: "",
      imgUrl: Logo,
      success: () => {
        shareInWechat();
      },
    },
    [EXPERT]: {
      title: " 专家库 | 联想创投成员企业信息库",
      desc: `共收录 ${count} 家成员专家信息，快来寻找你感兴趣的专家吧！`,
      link: "",
      imgUrl: Logo,
      success: () => {
        shareInWechat();
      },
    },
  };

  wx.ready(() => {
    wx.onMenuShareTimeline(messageOnShare[props.type]);
    wx.onMenuShareAppMessage(messageOnShare[props.type]);
    wx.error(res => {
      console.log("ready.error", res.errMsg);
    });
  });

  return (
    <div className="detail-layout">
      <Helmet>
        <title>
          {labelToChinese[props.type]} ｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="lenovo-layout__sidebar-wrapper">
        <Sidebar />
      </div>
      <div className="lenovo-layout__content-wrapper">
        <div
          className="database-layout__back"
          onClick={() => history.push(ROOT_PATH)}
        >
          <Icon name="lenovo-back" />
          返回数字生态平台
        </div>
        <div className="database-layout__content" ref={contentRef}>
          <FilterSearchPanel type={props.type} />
          {isSelectedConditionsPanelShow && (
            <SelectedConditionsPanel type={props.type} parentRef={contentRef} />
          )}
          <ResultListPanel />
        </div>
      </div>
    </div>
  );
};

export default observer(Database);
