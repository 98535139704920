import React from "react";
import { Route } from "react-router-dom";

const renderRouter = (routers: any) => {
  const checkRoleRouter = routers.filter(item => !item.isHide);

  return (
    <>
      {checkRoleRouter.map(
        ({ exact, strict, path, params = {}, Component }, index) => (
          <Route
            key={index}
            exact={exact}
            strict={strict}
            path={path}
            render={props => (
              <Component
                type={params.type}
                header={params.header}
                icon={params.icon}
                id={props.match.params.id}
              />
            )}
          />
        )
      )}
    </>
  );
};

export default renderRouter;
