import { observer } from "mobx-react";
import React from "react";

import "./style.scss";
import logo from "../../../assets/images/lenovo-logo.png";

interface Props {
  bottomSize?: string;
}

const Logo: React.FC<Props> = (props: Props) => {
  return (
    <div className="logo-wrap" style={{ bottom: props.bottomSize }}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export default observer(Logo);
