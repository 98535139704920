// eslint-disable-next-line
import React, { useEffect, useContext, RefObject, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import { observer } from "mobx-react";
import "./style.scss";
import { Nav, Footer, Breadcrumbs, CodeEntry } from "../shared/index";
import Icon from "../shared/icon/Icon";
import { isMobile } from "../../utils/tools";
import postCooperStore from "../../stores/postCooperStore";
import { Form, Input, Checkbox, Select, DatePicker } from "antd";
import {
  Form as MForm,
  Input as MInput,
  TextArea,
  DatePicker as MDatePicker,
  CheckList,
  Popup,
} from "antd-mobile";
// eslint-disable-next-line
import type { DatePickerRef } from 'antd-mobile/es/components/date-picker'
import dayjs from "dayjs";
import moment from "moment";
import globalStore from "../../stores/globalStore";
// import authStore from "../../stores/authStore";
import lWxCode from "../../assets/images/l-wx-img.png";

interface Props {}

const PostCooperation: React.FC<Props> = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [mForm] = MForm.useForm();
  const { breadcrumbs, formFields, cooperateInfo, filters, initValues, goCooperate, getFilters, fetchData } = useContext(postCooperStore);
  const { setVisibleState, revertDialogProps, setDialogProp } = useContext(globalStore);
  const [isPosted, setIsPosted] = useState(false);

  const [visible, setVisible] = useState({
    scene: false,
    type: false,
    area: false,
  });

  useEffect(() => {
    getFilters();
    if(cooperateInfo.type === "rePub") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if(cooperateInfo.type === "pub" && !cooperateInfo.isCom) {
      const d = new Date();
      d.setFullYear(d.getFullYear() + 1);
      const obj = {
        circle_ids: ["发布到大厅", ...filters.circles.map((item: any) => item.id)],
        effective_date: isMobile()
        ? d
        : moment(d),
      };
      if(isMobile()) {
        mForm.setFieldsValue(obj);
        if(filters.circles.length) {
          setTimeout(() => {
            obj.circle_ids.forEach((item: any) => {
              (document.querySelector(`.checkbox-${item}`) as any).click();
            });
          }, 100);
        }
        
      } else {
        form.setFieldsValue(obj);
      }
    }
  }, [cooperateInfo.isCom, filters]);

  useEffect(() => {
    if(cooperateInfo.type === "rePub" && isMobile() && Object.keys(initValues).length > 0) {
      mForm.setFieldsValue(initValues);
      setVisible({
        scene: true,
        type: false,
        area: false,
      })
      setVisible({
        scene: false,
        type: false,
        area: false,
      })
      initValues.circle_ids.forEach((item: any) => {
        (document.querySelector(`.checkbox-${item}`) as any).click();
      });
    } else {
      form.setFieldsValue(initValues);
    }
    // initialValues 移动端无法自动初始化 需要set
  }, [initValues])

  const onFinish = async (data: any) => {
    if (isPosted) {
      return false;
    }
    setIsPosted(true);
    await goCooperate(data);
    if(cooperateInfo.isCom) {
      revertDialogProps();
      setDialogProp("lenovoWxTitle", "合作交流需求审核中");
      setDialogProp(
        "lenovoWxContent",
        `您提交的合作交流需求将在审核通过后发布，后续流程请留意服务号通知`
      );
      setDialogProp(
        "lenovoWxBottomTip",
        "长按识别二维码返回服务号，留意后续通知"
      );
      setDialogProp("lenovoWxCodeImg", lWxCode);
      setDialogProp("lenovoWxEvent", () => {
        history.goBack();
      });
      setVisibleState("lenovoWx", true);
    } else {
      revertDialogProps();
      setDialogProp("lenovoWxTitle", "合作需求发布审核中");
      setDialogProp(
        "lenovoWxContent",
        `您提交的合作需求将在审核通过后发布，后续流程请留意服务号通知`
      );
      setDialogProp(
        "lenovoWxBottomTip",
        "长按识别二维码返回服务号，留意后续通知"
      );
      setDialogProp("lenovoWxCodeImg", lWxCode);
      setDialogProp("lenovoWxEvent", () => {
        history.goBack();
      });
      setVisibleState("lenovoWx", true);
    } 
  };

  return (
    <div className="lenovo-post-cooperation">
      <Helmet>
        <title>寻求合作 | 联想数字生态平台</title>
      </Helmet>
      <Nav bgc={`${isMobile() ? "#fff" : "#f5f6f7"}`}>
        <div className="lenovo-post-cooperation__content">
          <CodeEntry />
          <Breadcrumbs
            items={breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          <div className="content__form">
            <p className="content__form-title">寻求合作</p>
            <Form
              colon={false}
              requiredMark={false}
              size="large"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item
                name={formFields[0].name}
                label={formFields[0].label}
                rules={[{ required: true, message: formFields[0].message }]}
              >
                <Input placeholder={formFields[0].placeholder} />
              </Form.Item>
              <Form.Item
                name={formFields[1].name}
                label={formFields[1].label}
                rules={[{ required: true, message: formFields[1].message }]}
              >
                <Input.TextArea
                  placeholder={formFields[1].placeholder}
                  showCount
                  maxLength={200}
                />
              </Form.Item>
              {formFields.slice(2, 4).map((item: any) => (
                <Form.Item
                  key={item.name}
                  name={item.name}
                  label={item.label}
                  rules={[{ required: true, message: item.message }]}
                >
                  <Select
                    placeholder={item.placeholder}
                    mode="multiple"
                    showArrow
                  >
                    {
                      filters[item.filter].map((item: any) => <Select.Option key={item} value={item}>{item}</Select.Option>)
                    }
                  </Select>
                </Form.Item>
              ))}
              {!cooperateInfo.isCom && <Form.Item
                name={formFields[4].name}
                label={formFields[4].label}
                // rules={[{ required: true, message: formFields[4].message }]}
              >
                <Input placeholder={formFields[4].placeholder} />
              </Form.Item>}
              {!cooperateInfo.isCom && <Form.Item
                name={formFields[5].name}
                label={formFields[5].label}
                rules={[{ required: true, message: formFields[5].message }]}
              >
                <DatePicker placeholder={formFields[5].placeholder} />
              </Form.Item>}
              {!cooperateInfo.isCom && <Form.Item
                name={formFields[6].name}
                label={formFields[6].label}
                rules={[{ required: true, message: formFields[6].message }]}
              >
                <Checkbox.Group>
                  <div className="form-item--line">
                    {
                      filters.circles.map((item: any) => <Checkbox key={item.id} value={item.id}>{item.name}</Checkbox>)
                    }
                    <p className="form-item--tips">*只能发布到您已加入的圈子</p>
                  </div>
                  <Checkbox value="发布到大厅">发布到大厅</Checkbox>
                </Checkbox.Group>
              </Form.Item>}
              <Form.Item className="form-item--left">
                <div className={`form-submit ${isPosted && "opt-5"}`} onClick={() => form.submit()}>
                  发布合作需求
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className="content__form--mobile">
            <p className="title">寻求合作</p>
            <MForm layout="horizontal" onFinish={onFinish} form={mForm} autoComplete="off">
              <MForm.Item
                name={formFields[0].name}
                rules={[{ required: true, message: formFields[0].message }]}
              >
                <MInput placeholder={formFields[0].placeholder} />
              </MForm.Item>
              <MForm.Item
                name={formFields[1].name}
                rules={[{ required: true, message: formFields[1].message }]}
              >
                <TextArea
                  placeholder={formFields[1].placeholder}
                  showCount
                  maxLength={200}
                />
              </MForm.Item>
              {formFields.slice(2, 4).map((item: any) => (
                <MForm.Item
                  key={item.name}
                  label={item.placeholder}
                  name={item.name}
                  rules={[{ required: true, message: item.message }]}
                  onClick={() => {
                    setVisible({
                      ...{
                        scene: false,
                        type: false,
                        area: false,
                      },
                      ...{
                        [item.name]: true,
                      },
                    });
                    setTimeout(() => {
                      document.querySelectorAll(".adm-popup-close-icon").forEach((item: any) => {
                        item.addEventListener("click", () => {
                          setVisible({
                            scene: false,
                            type: false,
                            area: false,
                          });
                        }, false);
                      })
                    })
                  }}
                >
                  <Popup
                   getContainer={() => document.querySelector(".content__form--mobile")}
                   showCloseButton
                   visible={visible[item.name]}
                   onMaskClick={() => {
                      setVisible({
                        scene: false,
                        type: false,
                        area: false,
                      });
                    }}
                    bodyStyle={{ height: '70vh', borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px', }}
                  >
                    <div className="custom-picker">
                    <p className="custom-picker-title">{item.label}</p>
                    <div className="custom-picker-list">
                    <CheckList defaultValue={cooperateInfo.type === "rePub" && isMobile() ? initValues[item.name] : []}  multiple onChange={(values: any) => {
                          mForm.setFieldsValue({
                            [item.name]: values,
                          });
                        }}>
                      {
                        filters[item.filter].map((item: any) => <CheckList.Item key={item} value={item}>{item}</CheckList.Item>)
                      }
                    </CheckList>
                    </div>
                    </div>
                  </Popup>
                  {mForm.getFieldValue(item.name) && <p className="custom-picker-value">{mForm.getFieldValue(item.name).join("、")}</p>}
                </MForm.Item>
              ))}
              {!cooperateInfo.isCom && <MForm.Item
                name={formFields[4].name}
                // rules={[{ required: true, message: formFields[4].message }]}
              >
                <MInput placeholder={formFields[4].placeholder} />
              </MForm.Item>}
             {!cooperateInfo.isCom &&  <MForm.Item
                name={formFields[5].name}
                rules={[{ required: true, message: formFields[5].message }]}
                onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                  datePickerRef.current?.open();
                }}
              >
                <MDatePicker onConfirm={(value: any) => {
                    mForm.setFieldsValue({
                      [formFields[5].name]: value,
                    });
                  }}>
                  {value =>
                    value
                      ? <div className="mobile-date-picker">
                        <p>{formFields[5].placeholder}</p>
                        <p>{dayjs(value).format("YYYY-MM-DD")}</p>
                      </div>
                      : formFields[5].placeholder
                  }
                </MDatePicker>
              </MForm.Item>}
              {!cooperateInfo.isCom && <MForm.Item
                name={formFields[6].name}
                rules={[{ required: true, message: formFields[6].message }]}
              >
                <div className="mobile-checkbox">
                  <p>发布到圈子</p>
                  <p className="form-item--tips">*只能发布到您已加入的圈子</p>
                </div>
                <Checkbox.Group onChange={(value: any) => {
                  console.log(value, 1);
                    mForm.setFieldsValue({
                      [formFields[6].name]: value,
                    });
                  }}>
                  <div className="form-item--line">
                    {
                      filters.circles.map((item: any) => <Checkbox className={`checkbox-${item.id}`} key={item.id} value={item.id}>{item.name}</Checkbox>)
                    }
                  </div>
                  <Checkbox className="checkbox-发布到大厅" value="发布到大厅">发布到大厅</Checkbox>
                </Checkbox.Group>
              </MForm.Item>}
            </MForm>
            
            <div className={`form-submit ${isPosted && "opt-5"}`} onClick={() => mForm.submit()}>
              发布合作需求
            </div>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(PostCooperation);
