import React from "react";
import "./style.scss";
import Icon from "../icon/Icon";

interface Props {
  list: string[];
}

const Keywords: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props.list.length > 0 && (
        <div className="pro-keywords">
          <Icon name="lenovo-keywords-tag" size={12} />
          {props.list.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <span>{item}</span>
                <span className="pro-keywords__divider">·</span>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Keywords;
