import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";

import "./style.scss";
import { Notify } from "../shared/index";
import GlobalStore from "../../stores/globalStore";
import cooperationStore from "../../stores/cooperationStore";
import {
  postCooperateRepliesProceed,
  postCooperateRepliesRejected,
} from "../../api";
import lWxCode from "../../assets/images/l-wx-img.png";

interface Props {}

const Handle: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    GlobalStore
  );
  const { creator, reply } = useContext(cooperationStore);

  useEffect(() => {
    (document.querySelector(".lenovo-footer") as any).style.marginBottom = "0";
  }, []);

  const nextProceed = async () => {
    try {
      await postCooperateRepliesProceed({ id: id });
      revertDialogProps();
      setDialogProp("lenovoWxTitle", "您的合作意向已收到");
      setDialogProp(
        "lenovoWxContent",
        "即将为本次合作建立沟通群，建群成功后，将通过服务号通知您"
      );
      setDialogProp("lenovoWxCodeImg", lWxCode);
      setDialogProp("lenovoWxBottomTip", "长按识别二维码，留意服务号通知");
      setDialogProp("lenovoWxEvent", () => {
        history.push("/cooperation");
      });
      setVisibleState("lenovoWx", true);
    } catch (error) {
      error.message &&
        Notify.error({
          message: error.message,
          duration: 3,
        });
    }
  };

  const rejected = async () => {
    try {
      await postCooperateRepliesRejected({ id: id });
      Notify.success({
        message: "放弃该合作申请已收到！",
        description: "您拒绝该合作的消息会通知到对方",
        duration: 3,
      });
    } catch (error) {
      error.message &&
        Notify.error({
          message: error.message,
          duration: 3,
        });
    }
  };

  return (
    <div className="response-btn-wrap--position-static">
      {creator && reply.schedule === "pending" && (
        <div className="btn-list">
          <div className="waive-btn" onClick={rejected}>
            放弃该申请
          </div>
          <div className="next-btn" onClick={nextProceed}>
            进一步聊聊看
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Handle);
