import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import cooperationStore from "../../stores/cooperationStore";
import closeSvg from "../../assets/images/circle-state-close.svg";
import rightSvg from "../../assets/images/circle-state-right.svg";
import questionSvg from "../../assets/images/circle-state-question.svg";

interface Props {}

const State: React.FC<Props> = () => {
  const { reply } = useContext(cooperationStore);

  return (
    <div
      className={`
    cooperation-detail__state 
    ${
      reply.schedule === "rejected"
        ? "cooperation-detail__state--bg-red"
        : reply.schedule === "success"
        ? "cooperation-detail__state--bg-green"
        : reply.schedule === "proceed"
        ? "cooperation-detail__state--bg-gray"
        : ""
    }
    `}
    >
      <img
        src={
          reply.schedule === "rejected"
            ? closeSvg
            : reply.schedule === "success"
            ? rightSvg
            : reply.schedule === "proceed"
            ? questionSvg
            : ""
        }
        width="16px"
        alt="state"
      />
      {reply.schedule === "rejected"
        ? "未达成合作"
        : reply.schedule === "success"
        ? "已达成合作"
        : reply.schedule === "proceed"
        ? "等待平台建群，请留意服务号通知"
        : ""}
    </div>
  );
};

export default observer(State);
