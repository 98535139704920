import { action, observable } from "mobx";
import { createContext } from "react";

import {
  formatFilterData,
  formatCooperationData,
  formatCooperationDetailData,
} from "../adapter/formatCooperationData";
import {
  fetchCooperatesData,
  fetchCooperatesFilterData,
  fetchCooperatesDetail,
  fetchCooperateReplies,
} from "../api";

class CooperationStore {
  @observable public loading = true;
  @observable public cooperates: any = [];
  @observable public expresses: any = [];
  @observable public cooperationFilter: any = {
    scenarios: [],
    categories: [],
  };
  @observable public cooperatesDetail: any = {};
  @observable public pagination = {
    currentPage: 1,
    pageSize: 9,
    totalCount: 0,
    hasNextPage: false,
  };
  @observable public reply: any = {};
  @observable public creator = false;

  @action
  public setPageNum = (page: number) => {
    this.pagination.currentPage = page;
  };

  @action
  public fetchCooperationData = async (params?: any, pagination?: any) => {
    this.loading = true;
    const result = await fetchCooperatesData({
      pagination: pagination || this.pagination,
      params: params || {
        scenarios: [],
        categories: [],
      },
    });
    const { currentPage, totalCount, hasNextPage } = result.data;
    this.cooperates = formatCooperationData(result.data).cooperates;
    this.pagination = {
      ...this.pagination,
      currentPage,
      hasNextPage,
      totalCount,
    };
    this.loading = false;
  };

  @action
  public fetchCooperationFilterData = async () => {
    const result = await fetchCooperatesFilterData();
    this.cooperationFilter = formatFilterData({
      scenarios: result.data.scenarios,
      categories: result.data.categories,
    });
  };

  @action
  public fetchCooperatesDetailData = async (id: string) => {
    const result = await fetchCooperatesDetail(id);
    this.cooperatesDetail = formatCooperationDetailData(result.data);
  };

  @action
  public fetchCooperatesRepliesData = async (id: string) => {
    const result = await fetchCooperateReplies(id);
    this.cooperatesDetail = formatCooperationDetailData(result.data).cooperate;
    this.reply = result.data.reply;
    this.creator = result.data.creator;
  };
}

export default createContext(new CooperationStore());
