import React, { useContext, useRef, useEffect, useState } from "react";

import { Tag, Icon } from "../shared";
import DatabaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import InstitutionDefaultSvg from "../../assets/images/institution-default.svg";

const InstitutionHeader: React.FC = () => {
  const [descStyle, setDescStyle] = useState({});
  const {
    data: { header },
  } = useContext(DatabaseDetailStore);

  const hasTechnologies = header.technologies && header.technologies.length > 0;
  const hasApplyFormats = header.applyFormats && header.applyFormats.length > 0;
  const hasInfomation =
    header.registerName ||
    header.region ||
    header.officialWebsite ||
    header.foundedAt;

  const descRef = useRef(null);

  useEffect(() => {
    setDescStyle({
      height: `${descRef.current && descRef.current.scrollHeight}px`,
    });
  }, [descRef]);
  return (
    <div className="institution-header">
      <div className="institution-header__title">
        <div className="institution-header__title-left">
          <a>
            <img
              src={header.avatar ? header.avatar : InstitutionDefaultSvg}
              alt={header.name}
            />
          </a>
          <div className="institution-header__title-text">
            <div className="institution-header__title-text__synced">
              <h3>{header.name}</h3>
              {header.invest && <p>{header.invest}</p>}
              <span>{header.aliasName}</span>
              {/* <span>|</span>
              <span className="institution-header__avatar-desc text-limit--one">
                {header.avatarDesc}
              </span> */}
            </div>
            <div className="institution-header__title__tags">
              {/* {header.categoryCn && (
                <span className="institution-header__title__tags--category">
                  {header.categoryCn}
                </span>
              )} */}
              {header.tags &&
                header.tags.map((item, index) => (
                  <span
                    key={index}
                    className={`filter-panel__tag filter-panel__tag--smalls ${item.key.includes(
                      "|"
                    ) && "filter-panel__tag--small"} `}
                  >
                    {item.key.includes("|") ? (
                      <>
                        <span>{item.key.split("|")[0]}</span>
                        <span className="panel__tag--small">
                          {item.key.split("|")[1] === "国" && (
                            <Icon
                              name="lenovo-country"
                              size={10}
                              color="#fff"
                            />
                          )}
                          {item.key.split("|")[1] === "省" && (
                            <Icon
                              name="lenovo-province"
                              size={10}
                              color="#fff"
                            />
                          )}
                        </span>
                      </>
                    ) : (
                      item.key
                    )}
                  </span>
                ))}
              {header.fields.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))}
              {header.companySize && <Tag>{header.companySize}</Tag>}
            </div>
          </div>
          <div className="mobile-institution-header__title-text">
            <div className="mobile-institution-header__title-text__synced">
              <h3>{header.name}</h3>
              {header.invest && <p>{header.invest}</p>}
              <span>{header.aliasName}</span>
            </div>
            {/* <span className="mobile-institution-header__avatar-desc">
              {header.avatarDesc}
            </span> */}
          </div>
        </div>
      </div>
      <div className="mobile-institution-header__title__tags">
        {/* {header.categoryCn && (
          <span className="institution-header__title__tags--category">
            {header.categoryCn}
          </span>
        )} */}
        {header.tags &&
          header.tags.map((item, index) => (
            <p
              key={index}
              className={`filter-panel__tag filter-panel__tag--smalls ${item.key.includes(
                "|"
              ) && "filter-panel__tag--small"} `}
            >
              {item.key.includes("|") ? (
                <>
                  <span>{item.key.split("|")[0]}</span>
                  <span className="panel__tag--small">
                    {item.key.split("|")[1] === "国" && (
                      <Icon name="lenovo-country" size={10} color="#fff" />
                    )}
                    {item.key.split("|")[1] === "省" && (
                      <Icon name="lenovo-province" size={10} color="#fff" />
                    )}
                  </span>
                </>
              ) : (
                item.key
              )}
            </p>
          ))}
        {header.fields &&
          header.fields.map((item, index) => {
            return <Tag key={index}>{item}</Tag>;
          })}
        {header.companySize && <Tag>{header.companySize}</Tag>}
      </div>
      <p ref={descRef} style={descStyle}>
        {header.summary}
      </p>
      <div>
        {hasTechnologies && (
          <div className="institution-header__keywords">
            热门技术：
            {header.technologies.map((item, index) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
        )}
        {hasApplyFormats && (
          <div className="institution-header__keywords">
            产品和解决方案：
            {header.applyFormats.map((item, index) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
        )}
      </div>
      {hasInfomation && (
        <div className="institution-header__information">
          <div className="institution-header__information-item">
            <label htmlFor="">注册名称：</label>
            <span>{header.registerName}</span>
          </div>
          <div className="institution-header__information-item">
            <label htmlFor="">官网：</label>
            <a href={header.officialWebsite} target="_blank">
              {header.officialWebsite}
            </a>
          </div>
          <div className="institution-header__information-item">
            <label htmlFor="">成立时间：</label>
            <span>{header.foundedAt}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(InstitutionHeader);
