import { getFromWeek, weekToString } from "../utils/tools";

const formatCircleData = initData =>
  initData.map((item: any) => ({
    ...item,
    topic: {
      ...item.topic,
      week: getFromWeek(item.topic.beginAt),
      dateDesc: weekToString(getFromWeek(item.topic.beginAt)),
    },
  }));

export { formatCircleData };
