import React from "react";

const SVG = ({ fill = "#ffffff", className = "", viewBox = "0 0 12 16" }) => (
  <svg
    width="12px"
    height="16px"
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>文档</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="评选填报中心-首页"
        transform="translate(-1092.000000, -115.000000)"
        fillRule="nonzero"
      >
        <g id="review">
          <g id="查看评选信息" transform="translate(1090.000000, 109.000000)">
            <g id="文档" transform="translate(0.000000, 6.000000)">
              <rect
                id="Rectangle"
                fill="#000000"
                opacity="0"
                x="0"
                y="0"
                width="16"
                height="16"
              />
              <path
                d="M10.1666667,0.666666672 C10.2459328,0.666827824 10.3240449,0.685670648 10.3946667,0.721666672 L10.4166667,0.733666672 C10.4395846,0.747106874 10.4615277,0.762144057 10.4823333,0.778666672 L10.4906667,0.785666672 L10.5136667,0.806666672 L13.8603333,4.15333333 L13.8706667,4.16433333 L13.8733333,4.16733333 L13.8533333,4.14633333 L13.881,4.17633333 L13.892,4.18966667 C13.9024191,4.2027479 13.912208,4.21631889 13.9213333,4.23033333 L13.933,4.25 L13.945,4.27166667 C13.9803333,4.34033334 14,4.41766667 14,4.5 L14,14.6666667 C14,15.0348565 13.7015232,15.3333333 13.3333333,15.3333333 L2.66666667,15.3333333 C2.29847684,15.3333333 2,15.0348565 2,14.6666667 L2,1.33333333 C2,0.965143498 2.29847684,0.666666672 2.66666667,0.666666672 L10.1666667,0.666666672 Z M9,1.66666667 L3,1.66666667 L3,14.3333333 L13,14.3333333 L13,5.66666667 L9.66666667,5.66666667 C9.29847684,5.66666667 9,5.36818984 9,5 L9,1.66666667 Z M8.5,10.3333333 C8.77614237,10.3333333 9,10.557191 9,10.8333333 C9,11.1094757 8.77614237,11.3333333 8.5,11.3333333 L5.83333333,11.3333333 C5.55719095,11.3333333 5.33333333,11.1094757 5.33333333,10.8333333 C5.33333333,10.557191 5.55719095,10.3333333 5.83333333,10.3333333 L8.5,10.3333333 Z M10.2223333,7.33333333 C10.4676667,7.33333333 10.6666667,7.55733333 10.6666667,7.83333333 C10.6666667,8.10933333 10.4676667,8.33333333 10.2223333,8.33333333 L5.77766667,8.33333333 C5.53233333,8.33333333 5.33333333,8.10933333 5.33333333,7.83333333 C5.33333333,7.55733333 5.53233333,7.33333333 5.77766667,7.33333333 L10.2223333,7.33333333 L10.2223333,7.33333333 Z M10,1.707 L10,4.66666667 L12.9596667,4.66666667 L10,1.707 Z"
                id="Shape"
                fill={fill}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
