import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Input } from "antd";
import { Dialog, Notify } from "..";
import { isMobile } from "../../../utils/tools";
import globalStore from "../../../stores/globalStore";
import activityDetailStore from "../../../stores/activityDetailStore";
import circleStore from "../../../stores/circleStore";
import { postCircleChat } from "../../../api";
// import authStore from "../../../stores/authStore";
import lWxCode from "../../../assets/images/l-wx-img.png";

interface Props {
  visible: boolean;
  style?: React.CSSProperties;
  onCancel: () => void;
  type?: string;
}

const MessageBoardDialog: React.FC<Props> = (props: Props) => {
  const id = (location as any).href.match(/activity\/.*\?.*/)
    ? (location as any).href
        .match(/activity\/.*\?.*/)[0]
        .split("/")[1]
        .split("?")[0]
    : (location as any).href.match(/circle\/.*/)
    ? (location as any).href.match(/circle\/.*/)[0].split("/")[1]
    : "";
  const { TextArea } = Input;
  // const { isLogin } = useContext(authStore);
  const {
    setVisibleState,
    visibleTypes,
    DialogProps,
    setDialogProp,
    revertDialogProps,
  } = useContext(globalStore);
  const { addMessage, fetchData } = useContext(activityDetailStore);
  const { fetchCirclesDetailData } = useContext(circleStore);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (visibleTypes.activityMessage) {
      (document.querySelector(".message-board-text") as any).focus();
    }
  }, [visibleTypes.activityMessage]);

  return (
    <Dialog
      {...props}
      onCancel={() => setVisibleState("activityMessage", false)}
      style={
        isMobile()
          ? {
              padding: "13px 15px 30px",
              borderRadius: 0,
              top: "40%",
            }
          : { padding: "18px 24px 24px", borderRadius: 0 }
      }
    >
      <div className="message-board-dialog">
        <p className="message-board-title">
          {DialogProps.messageType === "activity" ? "留言板" : "发起圈子群聊"}
        </p>
        <TextArea
          className="message-board-text"
          bordered={false}
          placeholder={
            DialogProps.messageType === "activity"
              ? "写下尊重、理性、友好的留言，有助于彼此更好地交流～"
              : "请写下您发起群聊的想法？"
          }
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
        <div
          className={`message-board-btn ${
            value ? "message-board__active-btn" : ""
          }`}
          onClick={async () => {
            if (value) {
              if (DialogProps.messageType === "activity") {
                await addMessage(
                  {
                    id,
                    content: value,
                  },
                  Notify.success({
                    message: "发布留言成功！",
                    description: "您的留言已提交，将在审核通过后进行展示",
                    duration: 3,
                  })
                );
                fetchData(id);
              } else if (DialogProps.messageType === "applyGroup") {
                try {
                  await postCircleChat({
                    id,
                    reason: value,
                  });
                  revertDialogProps();
                  setDialogProp("lenovoWxTitle", "发起群聊申请已提交");
                  setDialogProp(
                    "lenovoWxContent",
                    "发起群聊的申请结果将通过服务号通知，请留意服务号消息"
                  );
                  setDialogProp("lenovoWxCodeImg", lWxCode);
                  setDialogProp("lenovoWxBtned", false);
                  setVisibleState("lenovoWx", true);
                  fetchCirclesDetailData(id);
                } catch (error) {
                  error.message &&
                    Notify.error({
                      message: error.message,
                      duration: 3,
                    });
                }
              }
            }
            setVisibleState("activityMessage", false);
            setValue("");
          }}
        >
          {DialogProps.messageType === "activity" ? "提交留言" : "提交"}
        </div>
      </div>
    </Dialog>
  );
};

export default observer(MessageBoardDialog);
