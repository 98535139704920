import { action, observable } from "mobx";
import { createContext } from "react";

import {
  fetchHomeCooperates,
  fetchHomeCircles,
  fetchExpressLatest,
} from "../api";
import { formatCircleData } from "../adapter/formatHomeData";
import { formatCooperationData } from "../adapter/formatCooperationData";

class HomeStore {
  @observable public homeData: any = {
    circle: [],
    cooperation: [],
    library: {},
  };
  @observable public expressLatest: any = {};
  @observable public cooLoading = true;

  @action
  public fetchCircleData = async () => {
    const result = await fetchHomeCircles();
    this.homeData.circle = formatCircleData(result.data.data);
  };

  @action
  public fetchCooperationData = async () => {
    this.cooLoading = true;
    const result = await fetchHomeCooperates();
    this.homeData.cooperation = formatCooperationData(result.data).cooperates;
    this.cooLoading = false;
  };

  @action
  public fetchHomeData = () => {
    this.fetchCooperationData();
    this.fetchCircleData();
  };

  @action
  public fetchExpressData = async () => {
    const res = await fetchExpressLatest();
    this.expressLatest = res.data.data;
  };
}

export default createContext(new HomeStore());
