import React from "react";

const SVG = ({
  fill = "#333",
  width = "100%",
  className = "",
  viewBox = "0 0 20 14",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill} fillRule="nonzero">
      <path d="M19 2H1.02c-.51 0-.98-.416-1.016-.926A1 1 0 0 1 1 0h17.98c.511 0 .98.416 1.017.926A1 1 0 0 1 19.001 2zM10 8H1.02c-.51 0-.98-.416-1.016-.926A1 1 0 0 1 1 6h8.98c.51 0 .98.416 1.017.926A1 1 0 0 1 10 8zM19 14H1.02c-.51 0-.98-.416-1.016-.926A1 1 0 0 1 1 12h17.98c.511 0 .98.416 1.017.926A1 1 0 0 1 19.001 14z" />
    </g>
  </svg>
);

export default SVG;
