import React from "react";

const SVG = ({
  fill = "#DA0010",
  width = "100%",
  className = "",
  viewBox = "0 0 10 10",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h10v10H0z" />
      <path
        d="M3.338 9.78a.808.808 0 0 1-1.108 0 .73.73 0 0 1 0-1.062L6.108 5 2.23 1.282a.73.73 0 0 1 0-1.062.808.808 0 0 1 1.108 0L7.77 4.469a.728.728 0 0 1 .061.997l-.06.065L3.337 9.78z"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SVG;
