import React from "react";

import "./style.scss";
import banner from "../../../assets/images/lenovo-banner.svg";
import mobileBanner from "../../../assets/images/lenovo-banner-mobile.png";
import { observer } from "mobx-react";

const Banner = () => {
  return (
    <a
      className="lenovo__banner"
      onClick={() => open("/library/institutions", "_self")}
    >
      <img className="lenovo__banner__img" src={banner} alt="banner" />
      <img
        className="lenovo__banner__img--mobile"
        src={mobileBanner}
        alt="banner"
      />
    </a>
  );
};

export default observer(Banner);
