import React from "react";
import "./style.scss";

interface Props {
  type?:
    | "primary"
    | "ghost"
    | "transparent"
    | "grey"
    | "text"
    | "link"
    | "second-primary"
    | "yellow";
  size?: string;
  grid?: number;
  shape?: "round";
  disabled?: boolean;
  isFullWidth?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<Props> = (props: Props) => {
  return (
    <button
      className={`btn btn--${props.type} size--${props.size} ${
        props.isFullWidth ? "btn--full-width" : ""
      } ${props.disabled ? "btn--disabled" : ""} btn-shape--${props.shape}`}
      style={{
        paddingLeft: 4 * props.grid,
        paddingRight: 4 * props.grid,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  type: "primary",
  size: "medium",
  grid: 2,
  isFullWidth: false,
};

export default Button;
