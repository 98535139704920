import React from "react";

const SVG = ({
  fill = "#FFD4AE",
  width = "100%",
  className = "",
  viewBox = "0 0 45 36",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        x="-5.5%"
        y="-7.7%"
        width="111%"
        height="115.5%"
        filterUnits="objectBoundingBox"
        id="k801kkakza"
      >
        <feMorphology
          radius=".5"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology radius="1" in="SourceAlpha" result="shadowInner" />
        <feOffset dy="2" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur
          stdDeviation="4"
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      filter="url(#k801kkakza)"
      transform="translate(-310 0)"
      fill="none"
      fillRule="evenodd"
    >
      <path fill={fill} fillRule="evenodd" d="M310 0h45v36l-22.5-10L310 36z" />
      <text
        fontFamily="SourceHanSansSC-Regular, Source Han Sans SC"
        fontSize="14"
        fill="#BF702B"
        transform="translate(310)"
      >
        <tspan x="8" y="21">
          开放
        </tspan>
      </text>
    </g>
  </svg>
);

export default SVG;
