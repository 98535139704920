import React, { useEffect, useState } from "react";
import Icon from "../icon/Icon";
import "./style.scss";

interface Props {
  styles: any;
  count: number;
  isLiked?: boolean;
  link: string;
  onLike: () => boolean;
  resetIsLike: () => void;
}
const SuspendedPanel = ({ styles, isLiked, onLike, count, link }) => {
  const [isShowPlus, setIsShowPlus] = useState(false);
  useEffect(() => {
    if (isLiked) {
      setIsShowPlus(true);
    }
  }, [isLiked]);

  return (
    <div className="suspended-panel" style={styles}>
      <div
        className="suspended-panel__item like"
        onClick={() => {
          onLike();
        }}
      >
        <Icon name="like" size={18} color={isLiked ? "#d8110a" : "#92929d"} />
        <span style={{ color: isLiked ? "#c72c21" : "#666666" }}>
          点赞 {count}
        </span>
        {isShowPlus && <span className="like--plus">+1</span>}
      </div>
      <a className="suspended-panel__item contact" target="_blank" href={link}>
        <Icon name="contact" size={18} />
        <span>业务咨询</span>
      </a>
    </div>
  );
};

SuspendedPanel.defaultProps = {
  count: 0,
  link: "",
};

export default SuspendedPanel;
