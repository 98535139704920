import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import "./style.scss";
import {
  Nav,
  Footer,
  // Filter,
  CooperationCard,
  Icon,
  CodeEntry,
  Empty,
} from "../shared/index";
import { Carousel } from "antd";
import GlobalStore from "../../stores/globalStore";
import CooperationStore from "../../stores/cooperationStore";
import { isMobile } from "../../utils/tools";
// import reportImg from "../../assets/images/cooperation-report.png";
import arrowRightImg from "../../assets/images/icon-arrow-right@3x.png";
import authStore from "../../stores/authStore";
// import lockedImg from "../../assets/images/filter-locked-icon.svg";
import lockedIcon from "../../assets/images/locked-icon.svg";
import postCooperStore from "../../stores/postCooperStore";
import ExpressListStore from "../../stores/expressListStore";
import _ from "underscore";
import ExpressCard from "../expressList/ExpressCard";

interface Props {
  [key: string]: any;
}

const Cooperation: React.FC<Props> = () => {
  const history = useHistory();
  const {
    isAuthUser,
    // isLogin
  } = useContext(authStore);
  const { banners, fetchGlobalBannersData, setVisibleState } = useContext(
    GlobalStore
  );
  const {
    cooperates,
    fetchCooperationData,
    fetchCooperationFilterData,
    loading,
  } = useContext(CooperationStore);
  const { setCooperateInfo } = useContext(postCooperStore);
  const { data: expresses, fetchData, initPage } = useContext(ExpressListStore);

  useEffect(() => {
    fetchGlobalBannersData();
    initPage();
    fetchData();
    fetchCooperationData();
    fetchCooperationFilterData();
  }, []);

  return (
    <div className="lenovo-cooperation">
      <Helmet>
        <title>生态合作 | 联想数字生态平台</title>
      </Helmet>
      <Nav>
        <div className="lenovo-cooperation__content">
          <CodeEntry />
          {banners && banners.length > 0 && (
            <Carousel
              autoplay
              infinite
              className={`content__main-banner banner-carousel ${expresses.expresses &&
                expresses.expresses.length === 0 &&
                "content__main-banner--empty"}`}
            >
              {banners &&
                banners.map((item: any, index: number) => (
                  <a
                    key={index}
                    className="content__carousel-item"
                    href={item.url}
                    target="_blank"
                  >
                    <img src={item.coverUrl} alt="" />
                  </a>
                ))}
            </Carousel>
          )}
          {!isMobile() &&
            expresses.expresses &&
            expresses.expresses.length > 0 && (
              <Carousel
                autoplay
                infinite
                autoplaySpeed={5000}
                className="content__sub-banner"
              >
                {expresses.expresses &&
                  _.chunk(expresses.expresses, 3).map(
                    (item: any, index: number) => (
                      <div key={index} className="content__sub-carousel-item">
                        <div className="sub-carousel-item-header">
                          <span className="sub-carousel-item-title">
                            合作快报
                          </span>
                          <span
                            className="item-title-2"
                            onClick={() => history.push("/express")}
                          >
                            查看全部
                            <img src={arrowRightImg} alt="arrow" />
                          </span>
                        </div>
                        <div className="sub-carousel-item-body">
                          {item.map((child: any, index: number) => (
                            <ExpressCard key={index} data={child} />
                          ))}
                        </div>
                      </div>
                    )
                  )}
              </Carousel>
            )}
          {isMobile() && expresses.expresses && expresses.expresses.length > 0 && (
            <div className="express__list">
              <div className="express__list-header">
                <p className="header-title">合作快报</p>
                <p
                  className="header-all"
                  onClick={() => history.push("/express")}
                >
                  查看全部
                  <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
                </p>
              </div>
              <div className="express__list-wrapper">
                {_.chunk(expresses.expresses, 3)[0].map(
                  (item: any, index: number) => (
                    <ExpressCard key={index} data={item} />
                  )
                )}
              </div>
            </div>
          )}
          <div className="content__list">
            <div className="content__list-header">
              <p className="content__list-header-title">寻求合作</p>
              {isMobile() && (
                <p
                  className="content__list-header-all"
                  onClick={() => history.push("/cooperation/seek")}
                >
                  查看全部
                  <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
                </p>
              )}
              {!isMobile() && (
                <div
                  className={`content__list-header-btn ${!isAuthUser &&
                    "content__list-header-btn--disable"}`}
                  onClick={() => {
                    if (isAuthUser) {
                      setCooperateInfo({
                        isCom: false,
                        type: "pub",
                        params: {},
                      });
                      history.push("/cooperation/post");
                    } else {
                      setVisibleState("toAuth", true);
                    }
                  }}
                >
                  {!isAuthUser && <img src={lockedIcon} alt="lockedIcon" />}
                  寻求合作
                </div>
              )}
            </div>
            <div
              className={`content__list-body ${cooperates.length === 0 &&
                "content__list-body--empty"}`}
            >
              {cooperates && cooperates.length > 0
                ? isMobile()
                  ? cooperates.slice(0, 3).map((item: any, index: number) => (
                      <div key={index}>
                        <CooperationCard data={item} />
                      </div>
                    ))
                  : cooperates.map((item: any, index: number) => (
                      <div key={index}>
                        <CooperationCard data={item} />
                      </div>
                    ))
                : !loading && <Empty title="合作需求收集中" />}
            </div>
            {!isMobile() && cooperates && cooperates.length > 0 && (
              <div
                className="content__list-all"
                onClick={() => history.push("/cooperation/seek")}
              >
                查看全部合作
              </div>
            )}
          </div>
        </div>
        {isMobile() && (
          <div
            className="lenovo-cooperation__btn"
            onClick={() => {
              if (isAuthUser) {
                setCooperateInfo({
                  isCom: false,
                  type: "pub",
                  params: {},
                });
                history.push("/cooperation/post");
              } else {
                setVisibleState("toAuth", true);
              }
            }}
          >
            <Icon name="Cooperation" size={16} />
            寻求合作
          </div>
        )}
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(Cooperation);
