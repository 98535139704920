import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";

import "./style.scss";
import { useQuery } from "../../utils/tools";
import cooperationStore from "../../stores/cooperationStore";
import circleCooperStore from "../../stores/circleCooperStore";
import circleStore from "../../stores/circleStore";
import { Nav, Footer, CodeEntry } from "../shared/index";
import WxImg from "../../assets/images/l-wx-img.png";
import homeImg from "../../assets/images/home-img.png";

interface Props {
  [key: string]: any;
}

const JoinGroup: React.FC<Props> = () => {
  const id = useQuery().get("id");
  const type = useQuery().get("type");
  const qrcode = useQuery().get("qrcode");
  const { reply, fetchCooperatesRepliesData } = useContext(cooperationStore);
  const { combinesDetail, fetchCombinesDetailData } = useContext(
    circleCooperStore
  );
  const { circleDetailData, fetchCirclesDetailData } = useContext(circleStore);
  const [data, setData] = useState({
    title: "",
    content: "",
    qrcode: WxImg,
  });

  useEffect(() => {
    if (type === "login") {
      setData({
        title: "请使用微信扫码打开页面",
        content: "微信扫码进入联想创投数字生态平台，使用平台功能",
        qrcode: homeImg,
      });
    } else if (type === "register") {
      setData({
        title: "请用微信扫码进行报名",
        content: "在微信中完成后续报名流程，第一时间了解活动信息",
        qrcode: qrcode,
      });
    } else if (type === "respond" && id) {
      fetchCooperatesRepliesData(id);
    } else if (type === "circle" && id) {
      fetchCombinesDetailData(id);
    } else if (type === "group" && id) {
      fetchCirclesDetailData(id);
    }
  }, []);

  useEffect(() => {
    if (type === "respond" && id) {
      setData({
        title: "加入合作沟通群",
        content: "识别二维码加入合作沟通群，立即开始合作交流",
        qrcode: reply.qrcodeUrl,
      });
    }
  }, [reply]);

  useEffect(() => {
    if (type === "circle" && id) {
      setData({
        title: "加入合作沟通群",
        content: "识别二维码加入合作沟通群，立即开始合作交流",
        qrcode: combinesDetail.qrcodeUrl,
      });
    }
  }, [combinesDetail]);

  useEffect(() => {
    if (type === "group" && id) {
      setData({
        title: `加入「${circleDetailData.groups[0].name}」群聊`,
        content: "识别二维码加入群聊，讨论热门话题",
        qrcode: circleDetailData.groups[0].qrcodeUrl,
      });
    }
  }, [circleDetailData]);

  return (
    <div className="lenovo-join-group">
      <Helmet>
        <title>{`开始合作交流：${data.title} | 联想数字生态平台`}</title>
      </Helmet>
      <Nav>
        <div className="lenovo-join-group__content">
          <CodeEntry />
          <div className="join-group__content-main">
            <p className="content-main-title">{data.title}</p>
            <p className="content-main-content">{data.content}</p>
            <img
              className="content-main-qrcode"
              src={data.qrcode}
              alt="qrcode"
            />
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(JoinGroup);
