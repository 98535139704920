import { observer } from "mobx-react";
import React from "react";
// import authStore from "../../../stores/authStore";

import Icon from "../icon/Icon";
import "./style.scss";

interface Props {
  type: string;
  name: string;
  url: string;
  onDownload?: () => void;
}

const FileCard: React.FC<Props> = (props: Props) => {
  const isCurrentUserEmpty = false;
  return (
    <a
      className={`file-card ${isCurrentUserEmpty ? "guest" : ""}`}
      href={!isCurrentUserEmpty ? props.url : "/login"}
      download={!isCurrentUserEmpty ? `${props.name}.${props.type}` : false}
      onClick={() => props.onDownload()}
    >
      <Icon name={`lenovo-${props.type}`} />
      <span>{props.name}</span>
      {isCurrentUserEmpty && (
        <div className="file-card__login-hint">
          仅限内部平台用户使用，登录后即可下载
          <button>前往登录</button>
        </div>
      )}
    </a>
  );
};

export default observer(FileCard);
