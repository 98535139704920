import React from "react";

import "./style.scss";
import { Popover } from "antd";
import entryImg from "../../../assets/images/show-code-img.svg";
import lWxCode from "../../../assets/images/l-wx-img.png";

interface Props {
  [key: string]: any;
}

const CodeEntry: React.FC<Props> = () => {
  return (
    <div className="code-entry">
      <Popover
        content={
          <div className="lenovo-wx-code">
            <p>联想未来CLUB</p>
            <p>扫描下方关注，报名联想未来Club活动</p>
            <img src={lWxCode} alt="wx-code" />
          </div>
        }
        placement="left"
        getPopupContainer={() => document.body}
      >
        <img src={entryImg} alt="entryImg" />
      </Popover>
    </div>
  );
};

export default CodeEntry;
