import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="63.981%"
          y1="17.233%"
          x2="44.558%"
          y2="53.914%"
          id="l1kjnnhn8a"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="4j9xr6093d">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ciku96bnne">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="97szgbjpwf">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="bkzs6ur21g">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="nnkvbp9m2h">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="tdw7dyo15i">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="azrkjmfurj">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="26.912%"
          x2="80.248%"
          y2="70.113%"
          id="w27r1ozztk"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-49.6%"
          width="199.2%"
          height="199.2%"
          filterUnits="objectBoundingBox"
          id="dbyxcb4r4c"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-2.7%"
          y="-3%"
          width="105.3%"
          height="106.1%"
          filterUnits="objectBoundingBox"
          id="ajt3tt6jcm"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="1t6h10z8sb" cx="32.941" cy="7.059" r="7.059" />
        <path
          d="m9.15 39.915-7.625-7.593C.508 31.32 0 30.107 0 28.677c0-1.43.508-2.644 1.525-3.647 1.016-1 2.251-1.5 3.704-1.5 1.453 0 2.686.5 3.705 1.5l.478.43.48-.43c1.016-1 2.251-1.5 3.702-1.5 1.454 0 2.688.5 3.705 1.5 1.016 1.003 1.525 2.216 1.525 3.647 0 1.43-.509 2.644-1.525 3.646l-7.626 7.592a.67.67 0 0 1-.261.085.67.67 0 0 1-.262-.085z"
          id="wu1voy379l"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#l1kjnnhn8a)" xlinkHref="#1t6h10z8sb" />
          <use fill="#000" filter="url(#dbyxcb4r4c)" xlinkHref="#1t6h10z8sb" />
        </g>
        <path
          d="M14.327 14.363c4.188 0 8.376-4.644 8.376-4.644l.66-.664L31.3 17.46c1.543-1.548 3.967-4.423 3.967-7.962.22-2.432-.881-4.644-2.865-6.635-5.951-6.192-12.123-.442-12.343-.22l-9.699 9.952c1.103 1.106 2.425 1.77 3.968 1.77z"
          fill="url(#4j9xr6093d)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="M9.919 18.123c.22-.221.22-.442.22-.664 0-.442-.22-.663-.44-.884-.442-.443-.882-.443-1.323-.443-.22 0-.661.222-.882.443l-3.527 4.423c-.44.664-.44 1.548.22 1.99.442.443 1.103.443 1.544 0v-.22l3.526-4.424c.22 0 .441 0 .662-.221z"
          fill="url(#ciku96bnne)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="M13.004 21.22c0-.222.22-.664.22-.885 0-.443-.22-.664-.44-.885-.44-.442-.661-.442-1.102-.442-.22 0-.44 0-.661.22v.222l-3.527 4.423c0 .221-.22.221-.44.443-.221.663-.221 1.327.44 1.769.22.221.661.221 1.102.221.22 0 .441-.221.661-.221l3.527-4.423c0-.222 0-.443.22-.443z"
          fill="url(#97szgbjpwf)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="M16.09 24.094c.22-.663.22-1.327-.44-1.769-.221-.221-.441-.221-.882-.221h-.22c-.221 0-.441.221-.662.221L10.36 26.75l-.22.22c-.22.222-.22.664-.22.885 0 .443.22.664.44.885.22.221.662.442 1.103.221.22 0 .66-.221.881-.442 0 0 0-.221.22-.221l3.527-4.203z"
          fill="url(#bkzs6ur21g)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="M19.837 25.864c0-.443-.22-.664-.44-.885-.22-.221-.662-.221-.882-.221-.441 0-.661.221-.882.442 0 0 0 .221-.22.221l-3.527 4.424v.221c-.22.221-.22.442-.22.664 0 .442.22.663.44.884.662.443 1.543.443 1.984-.221l3.527-4.423c0-.443.22-.664.22-1.106z"
          fill="url(#nnkvbp9m2h)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="m19.837 28.96-1.983 2.433 1.102 1.106c.22.221.66.442 1.102.442.44 0 .881-.221 1.102-.442.22-.221.44-.664.44-1.106 0-.442-.22-.885-.44-1.106l-1.323-1.327z"
          fill="url(#tdw7dyo15i)"
          transform="translate(2.353 3.53)"
        />
        <path
          d="M26.009 14.363s0-.221 0 0l-2.865-3.096c-1.323 1.548-4.85 4.644-8.817 4.644-2.204 0-4.188-.884-5.73-2.875l-.442-.663 8.156-8.184-.882-.884S12.564.43 8.817.43c-2.205 0-4.409.884-6.392 2.875C.66 4.853 0 6.843 0 8.834c0 3.981 3.306 7.52 4.408 8.847l1.764-2.212c.44-.664 1.322-1.106 1.983-1.106.882 0 1.543.221 2.204.664.662.442 1.103 1.327 1.103 1.99v.221c.66 0 1.542.221 1.983.664.662.442 1.102 1.327 1.102 1.99v.221c.662 0 1.543.222 1.984.664.661.442 1.102 1.327 1.102 1.99.882-.22 1.763 0 2.425.664.661.442 1.102 1.327 1.102 1.99 0 .664 0 1.328-.441 1.991l1.543 1.548c.22.221.661.443 1.102.443.44 0 .882-.222 1.102-.443.22-.221.44-.663.44-1.106 0-.442-.22-.884-.44-1.105l-.22-.222-5.51-5.75c-.221-.442-.221-.885 0-1.106.22-.221.66-.221 1.101 0 0 0 .22 0 .22.221l5.511 5.75a1.996 1.996 0 0 0 2.204 0c.661-.442.661-1.326.22-1.99l-.22-.221-5.51-5.75c-.22-.443-.22-.885 0-1.106.22-.221.661-.221.882-.221.22 0 .22 0 .44.22l5.51 5.751s0 .221.221.221c.661.443 1.323.443 1.984-.22.22-.222.44-.664.44-1.107 0-.442-.22-.884-.44-1.106l-5.29-5.75z"
          fill="url(#azrkjmfurj)"
          transform="translate(2.353 3.53)"
        />
        <g>
          <use
            fillOpacity=".2"
            fill="url(#w27r1ozztk)"
            xlinkHref="#wu1voy379l"
          />
          <use fill="#000" filter="url(#ajt3tt6jcm)" xlinkHref="#wu1voy379l" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
