import React from "react";

const SVG = ({
  fill = "#c72c21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Data_detail_iocn_1</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Polaris-icon/Major/2-Tone/Categories">
        <rect
          id="Fill"
          fill="#FFFFFF"
          x="0.8"
          y="7.2"
          width="14.4"
          height="8"
        />
        <path
          d="M1.6,14.4 L14.4,14.4 L14.4,8 L1.6,8 L1.6,14.4 Z M15.2,16 L0.8,16 C0.3584,16 0,15.6416 0,15.2 L0,7.2 C0,6.7584 0.3584,6.4 0.8,6.4 L15.2,6.4 C15.6416,6.4 16,6.7584 16,7.2 L16,15.2 C16,15.6416 15.6416,16 15.2,16 Z M13.6,4.8 L2.4,4.8 C1.9584,4.8 1.6,4.4416 1.6,4 C1.6,3.5584 1.9584,3.2 2.4,3.2 L13.6,3.2 C14.0416,3.2 14.4,3.5584 14.4,4 C14.4,4.4416 14.0416,4.8 13.6,4.8 Z M12,1.6 L4,1.6 C3.5584,1.6 3.2,1.2416 3.2,0.8 C3.2,0.3584 3.5584,0 4,0 L12,0 C12.4416,0 12.8,0.3584 12.8,0.8 C12.8,1.2416 12.4416,1.6 12,1.6 Z"
          id="Shape"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
