import { action, observable } from "mobx";
import { createContext } from "react";

import {
  formatCircleData,
  formatCircleDetailData,
} from "../adapter/formatCircleData";
import { fetchCirclesData, fetchCirclesDetail } from "../api";

class CircleStore {
  @observable public loading = true;
  @observable public detailLoading = true;
  @observable public circleData: any = {
    list: [],
  };
  @observable public circleDetailData: any = {};
  @observable public circleInfoData: any = {};

  @action
  public fetchCircleData = async () => {
    this.loading = true;
    const result = await fetchCirclesData();
    this.circleData = formatCircleData({
      list: result.data.data,
    });
    this.loading = false;
  };

  @action
  public fetchCirclesDetailData = async (id: string) => {
    this.detailLoading = true;
    const result = await fetchCirclesDetail(id);
    this.circleDetailData = formatCircleDetailData(result.data);
    this.detailLoading = false;
  };
}

export default createContext(new CircleStore());
