import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Icon from "../icon/Icon";

import "./style.scss";

interface Props {
  line: string;
  height: number;
  className?: string | "";
  children: React.ReactNode;
}
const Collapse: React.FC<Props> = (props: Props) => {
  const [isCollapse, setIsCollapse] = useState(true);
  const [descStyle, setDescStyle] = useState({});
  const [descContainerStyle, setDescContainerStyle] = useState({});

  const descRef = useRef(null);
  const descContainerRef = useRef(null);

  useLayoutEffect(() => {
    if (!isCollapse) {
      setDescContainerStyle({
        height:
          descContainerRef.current && descContainerRef.current.scrollHeight,
      });
      setDescStyle({
        height: `${descRef.current && descRef.current.scrollHeight}px`,
      });
    }
  }, [isCollapse]);

  useEffect(() => {
    if (
      document.querySelector(`.${props.className}`).offsetHeight >= props.height
    ) {
      setIsCollapse(true);
      document
        .querySelector(`.${props.className}`)
        .classList.add(`text-limit--${props.line}`);
    } else {
      setIsCollapse(false);
    }
  }, [props.height]);

  return (
    <div
      ref={descContainerRef}
      className="collapse-container"
      style={descContainerStyle}
    >
      <div
        className={`${props.className} ${
          !isCollapse ? "collapse-container__content-expand" : ""
        } expand-content`}
        ref={descRef}
        style={isCollapse ? { maxHeight: `${props.height}px` } : descStyle}
      >
        {props.children}
      </div>
      {isCollapse && (
        <span
          className="collapse-container__action"
          onClick={() => setIsCollapse(false)}
        >
          <span>显示更多</span>
          <Icon name="lenovo-arrow-down" color="#6b8aba" />
        </span>
      )}
    </div>
  );
};

export default Collapse;
