import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 16 20",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>delete-n</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="评选填报中心-详情页-填写方案-选择方案-1"
        transform="translate(-1081.000000, -2891.000000)"
      >
        <g
          id="Polaris-icon/Major/2-Tone/Delete"
          transform="translate(1079.000000, 2891.000000)"
        >
          <rect id="Fill" fill="#FFFFFF" x="4" y="5" width="12" height="14" />
          <path
            d="M17,4 L14,4 L14,2 C14,0.897 13.103,0 12,0 L8,0 C6.897,0 6,0.897 6,2 L6,4 L3,4 C2.447,4 2,4.448 2,5 C2,5.552 2.447,6 3,6 L3,19 C3,19.552 3.447,20 4,20 L16,20 C16.553,20 17,19.552 17,19 L17,6 C17.553,6 18,5.552 18,5 C18,4.448 17.553,4 17,4 L17,4 Z M5,18 L15,18 L15,6 L5,6 L5,18 Z M8,4 L12,4 L12,2 L8,2 L8,4 Z M8,16 C8.553,16 9,15.552 9,15 L9,9 C9,8.448 8.553,8 8,8 C7.447,8 7,8.448 7,9 L7,15 C7,15.552 7.447,16 8,16 M12,16 C12.553,16 13,15.552 13,15 L13,9 C13,8.448 12.553,8 12,8 C11.447,8 11,8.448 11,9 L11,15 C11,15.552 11.447,16 12,16"
            id="Mask"
            fill={fill}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
