import React from "react";

const SVG = ({
  fill = "#999",
  width = "100%",
  className = "",
  viewBox = "0 0 13 16",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill} fillRule="evenodd">
      <path d="M6.5 9.097a2.625 2.625 0 0 1-2.624-2.616 2.625 2.625 0 0 1 5.248 0A2.625 2.625 0 0 1 6.5 9.097zm0-3.754c-.63 0-1.142.51-1.141 1.139a1.142 1.142 0 0 0 2.13.569 1.136 1.136 0 0 0 0-1.139 1.142 1.142 0 0 0-.989-.569z" />
      <path d="M6.5 16a.734.734 0 0 1-.422-.133C5.833 15.697 0 11.581 0 6.481 0 2.904 2.913 0 6.5 0S13 2.904 13 6.481c0 5.1-5.826 9.209-6.078 9.386A.734.734 0 0 1 6.5 16zm0-14.522c-2.765 0-5.018 2.247-5.018 5.003 0 3.614 3.736 6.851 5.018 7.856 1.282-1.005 5.018-4.25 5.018-7.856 0-2.756-2.253-5.003-5.018-5.003z" />
    </g>
  </svg>
);

export default SVG;
