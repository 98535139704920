import { observer } from "mobx-react";
import React from "react";
import Icon from "../icon/Icon";

import "./style.scss";

interface Props {
  style?: React.CSSProperties;
  isBold?: boolean;
  isTime?: string; //"br":换行，"show":显示，"blank":不显示
  buzzItem: any;
}

const BuzzLine: React.FC<Props> = (props: Props) => {
  const { style, isBold, isTime, buzzItem } = props;

  return (
    <div
      className="buzz-line"
      style={style}
      onClick={() => {
        // TODO 跳转到对应详情页面
      }}
    >
      <Icon name="buzz" size={14} />
      <div
        className={`buzz-main ${isTime === "br" ? "dis-block" : "dis-flex"}`}
      >
        <p className={`buzz-title ${isBold ? "word-bold" : ""}`}>
          {buzzItem.content}
        </p>
        <p
          className={`buzz-time ${
            !isTime || isTime === "blank"
              ? "dis-none"
              : isTime === "br"
              ? "mar-top5"
              : ""
          }`}
        >
          {buzzItem.time}
        </p>
      </div>
    </div>
  );
};

export default observer(BuzzLine);
