import React from "react";
import "./style.scss";

import logo from "../../../assets/images/lenovo-logo@3x.png";

interface Props {
  [key: string]: any;
}

const Footer: React.FC<Props> = () => {
  return (
    <div className="lenovo-footer">
      <div>
        <p>
          © 2016-2022 Lenovo Capital & Incubator Group All rights reserved.
          京ICP备11035381 | 京公网安备110108007970号
        </p>
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default Footer;
