import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Table, Skeleton } from "antd";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
} from "../../constants/pro_constants";
import databaseStore from "../../stores/databaseStore";
import DatabaseListPanel from "./DatabaseListPanel";

const customSkeleton = (
  <Skeleton title={false} paragraph={{ rows: 1, width: "80%" }} active />
);

const loader = <Skeleton paragraph={{ rows: 2 }} avatar active />;

interface Person {
  name: string;
  age?: number;
}

const ExpertResultPanel = (props: any) => {
  const {
    resultList: { data, count },
    pagination,
    isLoading,
    hasMore,
    paginationMobile,
    fetchDatabaseList,
  } = useContext(databaseStore);
  const { usePagination = true, useTitle = true } = props;

  const columns = [
    {
      title: "专家名称",
      dataIndex: "name",
      ellipsis: true,
      sort: true,
      width: "20%",
      className: "database-list-panel__expert__brief",
      render: data =>
        !isLoading ? (
          <>
            <div
              style={{
                background: `url(${data.avatarUrl})`,
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                border: "1px solid #e2e2ea",
                verticalAlign: "middle",
                display: "inline-block",
              }}
            />
            <span>{data.name}</span>
          </>
        ) : (
          customSkeleton
        ),
    },
    {
      title: "所属企业",
      dataIndex: "employer",
      ellipsis: true,
      sort: true,
      width: "20%",
      render: data => (!isLoading ? data : customSkeleton),
    },
    {
      title: "技术类型",
      dataIndex: "technologies",
      ellipsis: true,
      width: "30%",
      render: data => (!isLoading ? data && data.join("、") : customSkeleton),
    },
    {
      title: "行业",
      dataIndex: "industries",
      ellipsis: true,
      width: "30%",
      render: data => (!isLoading ? data && data.join("、") : customSkeleton),
    },
  ];
  return (
    <>
      <div className="database-list-panel">
        <Table
          columns={columns}
          dataSource={data}
          locale={{
            triggerDesc: labelToChinese[TRIGGER_DESC],
            triggerAsc: labelToChinese[TRIGGER_ASC],
            cancelSort: labelToChinese[CACCEL_SORT],
          }}
          tableLayout="fixed"
          pagination={usePagination ? pagination : false}
          onChange={(pagination, filters, sorter) =>
            fetchDatabaseList(pagination, sorter)
          }
          title={
            useTitle
              ? () => (
                  <div className="database-list-panel__title">
                    找到 {count} 条结果
                  </div>
                )
              : false
          }
          onRow={record => {
            return {
              onClick: () => {
                window.open(`/library/experts/${record.key}`);
              },
            };
          }}
        />
      </div>
      <DatabaseListPanel
        loader={loader}
        isLoading={isLoading}
        hasMore={hasMore}
        pagination={paginationMobile}
        dataLength={data ? data.length : 0}
        next={fetchDatabaseList}
      >
        {data &&
          data.map((item, index) => (
            <div
              onClick={() => window.open(`/library/experts/${item.key}`)}
              key={index}
              className="database-list-panel__content-item--mobile experts"
            >
              <div className="content-item__avatar--mobile expert">
                <img src={item.name.avatarUrl} alt="avatar" />
              </div>
              <div className="content-item__content--mobile">
                <div>
                  <p className="content-item__name--mobile">{item.name.name}</p>
                  <p className="content-item__type--mobile">{item.employer}</p>
                </div>
                <div className="content-item__industries--mobile">
                  <span>行业</span>：
                  {item.technologies && item.technologies.join("、")}
                </div>
                <div className="content-item__technologies--mobile">
                  <span>技术类型</span>：
                  {item.industries && item.industries.join("、")}
                </div>
              </div>
            </div>
          ))}
      </DatabaseListPanel>
    </>
  );
};

export default observer(ExpertResultPanel);
