import React from "react";

const SVG = ({
  fill = "#DA0010",
  width = "100%",
  className = "",
  viewBox = "0 0 14 14",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M4.408 10.808v-.304h5.184v.304h.768V3.744H3.64v7.064h.768zm5.184-1.016H4.408V4.464h5.184v5.328zm-.384-.632v-.688h-.816l.432-.192a4.702 4.702 0 0 0-.48-.832l-.6.192c.16.24.312.512.456.832h-.816V7.36h1.344v-.672H7.384v-.984h1.592v-.688h-3.96v.688h1.6v.984H5.272v.672h1.344v1.112H4.8v.688h4.408z"
      />
      <circle stroke={fill} strokeWidth=".5" cx="7" cy="7" r="6.75" />
    </g>
  </svg>
);

export default SVG;
