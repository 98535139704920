import React from "react";
import { Breadcrumb } from "antd";
import { observer } from "mobx-react";

import "./style.scss";

interface Items {
  title: string;
  path: string;
}

interface Props {
  items: Items[];
  separator?: React.ReactNode;
}

const Breadcrumbs = (props: Props) => {
  const { items = [], separator = ">" } = props;
  return (
    <div className="pro-breadcrumbs">
      <Breadcrumb separator={separator}>
        {items.map(item => {
          return item.path && item.path !== "/lenovo" ? (
            <Breadcrumb.Item href={item.path} key={item.path}>
              {item.title}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={item.path}>{item.title}</Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default observer(Breadcrumbs);
