import React from "react";

const SVG = ({
  fill = "#B2B9D2",
  width = "100%",
  className = "",
  viewBox = "0 0 12 15",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M11.4,6.25 L9.6,6.25 L9.6,3.75 C9.6,1.671875 7.995,0 6,0 C4.005,0 2.4,1.671875 2.4,3.75 L2.4,6.25 L0.6,6.25 C0.27,6.25 0,6.53125 0,6.875 L0,14.375 C0,14.71875 0.27,15 0.6,15 L11.4,15 C11.73,15 12,14.71875 12,14.375 L12,6.875 C12,6.53125 11.73,6.25 11.4,6.25 Z M6,11.875 C5.34,11.875 4.8,11.3125 4.8,10.625 C4.8,9.9375 5.34,9.375 6,9.375 C6.66,9.375 7.2,9.9375 7.2,10.625 C7.2,11.3125 6.66,11.875 6,11.875 Z M8.4,6.25 L3.6,6.25 L3.6,3.75 C3.6,2.375 4.68,1.25 6,1.25 C7.32,1.25 8.4,2.375 8.4,3.75 L8.4,6.25 z"
    />
  </svg>
);

export default SVG;
