import dayjs from "dayjs";
import { formatSpDate } from "../utils/tools";
import parseRichStr from "../utils/parseRichStr";

const formatData = initData => ({
  ...initData,
  date: formatSpDate(initData.beginAt, true),
  contented: parseRichStr(initData.content).length > 0,
  eventMessages: initData.eventMessages.map((item: any) => ({
    ...item,
    publishedAt: dayjs(item.publishedAt).format("YY/MM/DD HH:ss"),
  })),
  breadcrumbs: [
    {
      title: "活动",
      path: "/activity",
    },
    {
      title: initData.title,
      path: `/activity/${initData.id}`,
    },
  ],
});

export { formatData };
