import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { Dialog } from "..";
// import logo from "../../../assets/images/logo@3x.png";
import globalStore from "../../../stores/globalStore";
import authStore from "../../../stores/authStore";
import { goAuth, isMobile } from "../../../utils/tools";

interface Props {}

const ToAuthDialog: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { visibleTypes, setVisibleState } = useContext(globalStore);
  const { isLogin } = useContext(authStore);

  useEffect(() => {
    // api();
  }, []);

  const replace = () => {
    setTimeout(() => {
      const url = (window as any).location.href.replace(
        (window as any).location.origin,
        ""
      );
      history.replace(url);
    }, 300);
  };

  const onCancel = () => {
    if (history.length < 2) {
      if (isMobile()) {
        // history.goBack();
        // replace();
        history.replace("/home");
      } else {
        window.close();
      }
    } else {
      history.goBack();
      replace();
    }
    setTimeout(() => {
      setVisibleState("toAuth", false);
    }, 500);
  };

  return (
    <Dialog
      {...props}
      style={{ padding: "24px 20px", borderRadius: 0 }}
      hideBg
      visible={visibleTypes.toAuth}
      onCancel={onCancel}
    >
      <div className="lenovo-to-auth-dialog">
        {/* <div className="to-auth-logo-text">
          <img src={logo} alt="logo" />
          <span>数字生态平台</span>
        </div> */}
        <p className="to-auth-title">认证成为联想未来 Club 会员</p>
        <p className="to-auth-desc">
          认证成为联想未来CLUB会员，参与生态内合作
          <br />
          在这里，联想未来CLUB将会为认证用户提供专享服务
        </p>
        <div className="to-auth-handle">
          <div className="to-auth-btn to-auth-cancel" onClick={onCancel}>
            查看其他内容
          </div>
          <div
            className="to-auth-btn to-auth-ok"
            onClick={() => {
              goAuth(history, isLogin);
            }}
          >
            去认证
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(ToAuthDialog);
