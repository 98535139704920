import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Customers_n</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="评选填报中心-首页"
        transform="translate(-1261.000000, -178.000000)"
      >
        <g id="Group-8" transform="translate(1246.000000, 163.000000)">
          <g id="Group-6">
            <g id="Group">
              <g
                id="Polaris-icon/Major/2-Tone/Home"
                transform="translate(15.000000, 15.000000)"
              >
                <rect
                  id="Fill"
                  fill="#FFFFFF"
                  x="5.6"
                  y="10.4"
                  width="4.8"
                  height="4.8"
                />
                <path
                  d="M15.7309833,6.6016 L8.53098334,0.2016 C8.22778334,-0.0672 7.77178334,-0.0672 7.46858334,0.2016 L6.39978334,1.152 L6.39978334,0.8 C6.39978334,0.3584 6.04218334,1.17683641e-14 5.59978334,1.17683641e-14 L2.39978334,1.17683641e-14 C1.95738334,1.17683641e-14 1.59978334,0.3584 1.59978334,0.8 L1.59978334,5.4184 L0.268583344,6.6016 C-0.0618166564,6.896 -0.0914166564,7.4008 0.202183344,7.732 C0.494983344,8.0616 1.00058334,8.0896 1.33098334,7.7984 L1.59978334,7.5592 L1.59978334,15.2 C1.59978334,15.6416 1.95738334,16 2.39978334,16 L13.5997833,16 C14.0421833,16 14.3997833,15.6416 14.3997833,15.2 L14.3997833,7.5592 L14.6685833,7.7984 C14.8205833,7.9336 15.0109833,8 15.1997833,8 C15.4197833,8 15.6397833,7.9096 15.7973833,7.732 C16.0909833,7.4008 16.0613833,6.896 15.7309833,6.6016 L15.7309833,6.6016 Z M12.7997833,14.4 L11.1997833,14.4 L11.1997833,10.4 C11.1997833,9.9584 10.8421833,9.6 10.3997833,9.6 L5.59978334,9.6 C5.15738334,9.6 4.79978334,9.9584 4.79978334,10.4 L4.79978334,14.4 L3.19978334,14.4 L3.19978334,6.1368 L7.99978334,1.8704 L12.7997833,6.1368 L12.7997833,14.4 Z M6.39978334,14.4 L9.59978334,14.4 L9.59978334,11.2 L6.39978334,11.2 L6.39978334,14.4 Z M3.19978334,1.6 L4.79978334,1.6 L4.79978334,2.5744 L3.19978334,3.9968 L3.19978334,1.6 Z"
                  id="Mask"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
