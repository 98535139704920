import { action, observable } from "mobx";
import { createContext } from "react";

import { formatCircleCooperData } from "../adapter/formatCircleCooperData";
import { fetchCombinesDetail } from "../api";

class CircleCooperStore {
  @observable public combinesDetail: any = {};

  @action
  public fetchCombinesDetailData = async (id: string) => {
    const result = await fetchCombinesDetail(id);
    this.combinesDetail = formatCircleCooperData(result.data.combine);
  };
}

export default createContext(new CircleCooperStore());
