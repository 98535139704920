/* eslint-disable prettier/prettier */
import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";
import md5 from "md5";

import { fetchDatabaseDetail, like, shareInWechat } from "../api";
import { formatDetailData } from "../adapter/formatDatabaseDetailData";
import {
  CASE_DETAIL,
  RELATED_FILE,
  PROJECT_BACKGROUND,
  INSTITUTION_INTRO,
  PRODUCT_SOLUTIONS,
  INSTITUTION_MATERIAL,
  INSTITUTION_EXPERTS,
  SOLUTION_DESC,
  SOLUTION_FEATURE,
  SOLUTION_TECHNOLOGY,
  SOLUTION_MATERIAL,
  SOLUTION_BUSINESS_CASE,
  INSTITUTION,
  BUSINESS_CASE,
  SOLUTION,
  EXPERT,
} from "../constants/pro_constants";
import { labelToChinese } from "../constants/label_to_chinese";

const SHORT_ID  = "UZBZJv3sqU";
const APP_KEY =  "wjqXVoLW6KfuhDegxm";
const APP_SECRET = "10xTkrncj39JVqCiHuNoEbzO5pPsgRtF";
class DatabseDetailStore {
  @observable id = "";
  @observable type = "";
  @observable data = null;
  @observable assetsData = [];
  @observable scrolledDomId = "";
  @observable isShowAll = false;
  @observable likeCount = 0;
  @observable isLike = false;

  @action
  initializeType = type => {
    this.type = type;
  };

  @action
  initializeId = id => {
    this.id = id;
  };

  upperCapitalLetter = str => {
    return str
      .toLowerCase()
      .replace(/( |^)[a-z]/g, element => element.toUpperCase());
  };

  @action
  initializeData = async () => {
    this.data = null;
    const result = await fetchDatabaseDetail(this.type, this.id);
    this.data = formatDetailData(this.type, result.data);
    // this.isShowAll = this.data.level === "level3";
    this.isShowAll = true;
    this.likeCount = this.data.likeCount;
  };

  @action
  initializeScrolledDomId = () => {
    const item = this.currentTabs.find(item => !item.isHide);
    this.scrolledDomId = item ? item.key : "";
  };

  @action
  scrollTo = key => {
    this.scrolledDomId = key;
    let dom = document.getElementById(this.scrolledDomId);
    let scrollArea = document.querySelector("#database-detail");

    scrollArea.scrollTo({ top: dom.offsetTop - 130, behavior: "smooth" });
  };

  @action
  setRichImgStyle = () => {
    const intro = document.getElementsByClassName(
      "detail-layout__info-tabs"
    )[0];
    const width = intro ? (intro.offsetWidth * 2) / 3 : 0;
    const preview = document.getElementsByClassName("attachment--preview");
    const img =
      preview.length > 0
        ? [...preview].reduce(
            (total, current) =>
              total.concat([...current.getElementsByTagName("img")]),
            []
          )
        : null;
    if (width && img) {
      img.forEach(item => {
        item.style.maxWidth = `${width}px`;
        item.style.height = "auto";
      });
    }
  };

  @action
  likeModel = async id => {
    const result = await like(this.ReplaceFirstUper(this.type), id);
    if (result.data.success) {
      this.likeCount = result.data.amount;
      this.isLike = true;
    }
  };

  @action
  ReplaceFirstUper = str => {
    return str.replace(/\b(\w)|\s(\w)/g, function(m) {
      return m.toUpperCase();
    });
  };

  @action
  shareInWechat = async () => {
    await shareInWechat(this.type, this.id);
  };

  @computed
  get currentTabs() {
    let tabs = [];

    if (this.type === INSTITUTION) {
      tabs = this.institutionTabs;
    }

    if (this.type === BUSINESS_CASE) {
      tabs = this.businessCaseTabs;
    }

    if (this.type === SOLUTION) {
      tabs = this.solutionTabs;
    }

    return tabs;
  }

  @computed
  get institutionTabs() {
    return [
      {
        key: INSTITUTION_INTRO,
        name: labelToChinese[INSTITUTION_INTRO],
        isHide: this.isInstitutionIntroTabContentEmpty,
      },
      {
        key: PRODUCT_SOLUTIONS,
        name: labelToChinese[PRODUCT_SOLUTIONS],
        isHide: this.isProductSolutionsTabContentEmpty,
      },
      {
        key: INSTITUTION_MATERIAL,
        name: labelToChinese[INSTITUTION_MATERIAL],
        isHide: this.isInstitutionMateriaTabContentEmpty,
      },
      {
        key: INSTITUTION_EXPERTS,
        name: labelToChinese[INSTITUTION_EXPERTS],
        isHide: this.isInstitutionExpertTabContentEmpty,
      },
    ];
  }

  @computed
  get isInstitutionIntroTabContentEmpty() {
    return _.isEmpty(this.data.tabs.institutionIntro);
  }

  @computed
  get isProductSolutionsTabContentEmpty() {
    return _.isEmpty(this.data.tabs.productSolutions);
  }

  @computed
  get isInstitutionMateriaTabContentEmpty() {
    return _.isEmpty(
      this.data.tabs.relatedFile && this.data.tabs.relatedFile.name
    );
  }

  @computed
  get isInstitutionExpertTabContentEmpty() {
    return _.isEmpty(this.data.tabs.experts);
  }

  @computed
  get solutionTabs() {
    return [
      {
        key: SOLUTION_DESC,
        name: labelToChinese[SOLUTION_DESC],
        isHide: this.isSolutionDescTabContentEmpty,
      },
      {
        key: SOLUTION_FEATURE,
        name: labelToChinese[SOLUTION_FEATURE],
        isHide: this.isSolutionFeatureTabContentEmpty,
      },
      {
        key: SOLUTION_TECHNOLOGY,
        name: labelToChinese[SOLUTION_TECHNOLOGY],
        isHide: this.isSolutionTechnologyTabContentEmpty,
      },
      {
        key: SOLUTION_MATERIAL,
        name: labelToChinese[SOLUTION_MATERIAL],
        isHide: this.isSolutionMaterialTabContentEmpty,
      },
      {
        key: SOLUTION_BUSINESS_CASE,
        name: labelToChinese[SOLUTION_BUSINESS_CASE],
        isHide: this.isSolutionBusinessCaseTabContentEmpty,
      },
    ];
  }

  @computed
  get isSolutionDescTabContentEmpty() {
    return _.isEmpty(this.data.tabs.solutionIntro);
  }

  @computed
  get isSolutionFeatureTabContentEmpty() {
    return _.isEmpty(this.data.tabs.solutionFeature);
  }

  @computed
  get isSolutionTechnologyTabContentEmpty() {
    return _.isEmpty(this.data.tabs.techArchitecture);
  }

  @computed
  get isSolutionMaterialTabContentEmpty() {
    return _.isEmpty(
      this.data.tabs.relatedFile && this.data.tabs.relatedFile.name
    );
  }

  @computed
  get isSolutionBusinessCaseTabContentEmpty() {
    return _.isEmpty(this.data.tabs.businessCases);
  }

  @computed
  get businessCaseTabs() {
    return [
      {
        key: PROJECT_BACKGROUND,
        name: labelToChinese[PROJECT_BACKGROUND],
        isHide: this.isProjectBackgroundTabContentEmpty,
      },
      {
        key: CASE_DETAIL,
        name: labelToChinese[CASE_DETAIL],
        isHide: this.isCaseDetailTabContentEmpty,
      },
      {
        key: RELATED_FILE,
        name: labelToChinese[RELATED_FILE],
        isHide: this.isRelatedFileTabContentEmpty,
      },
    ];
  }

  @computed
  get isCaseDetailTabContentEmpty() {
    return _.isEmpty(this.data.tabs.caseDetailIntro);
  }

  @computed
  get isProjectBackgroundTabContentEmpty() {
    return _.isEmpty(this.data.tabs.projectBackground);
  }

  @computed
  get isRelatedFileTabContentEmpty() {
    return _.isEmpty(
      this.data.tabs.relatedFile && this.data.tabs.relatedFile.name
    );
  }
  @computed
  get isTabsEmpty() {
    if (this.type === INSTITUTION) {
      return (
        this.data && (this.isInstitutionIntroTabContentEmpty &&
        this.isProductSolutionsTabContentEmpty &&
        this.isInstitutionMateriaTabContentEmpty &&
        this.isInstitutionExpertTabContentEmpty)
      );
    }
    if (this.type === SOLUTION) {
      return (
        this.data && ( this.isSolutionDescTabContentEmpty &&
        this.isSolutionFeatureTabContentEmpty &&
        this.isSolutionTechnologyTabContentEmpty &&
        this.isSolutionBusinessCaseTabContentEmpty &&
        this.isSolutionMaterialTabContentEmpty)
      );
    }
    if (this.type === BUSINESS_CASE) {
      return (
        this.data && ( this.isCaseDetailTabContentEmpty &&
        this.isRelatedFileTabContentEmpty &&
        this.isProjectBackgroundTabContentEmpty)
      );
    }
    return true;
  }

  @computed
  get cooperationLink() {
    let institution = "";
    if(this.type === SOLUTION) {
      institution = `${this.data && this.data.header && this.data.header.producer.name}-`;
    }
    if(this.type === BUSINESS_CASE) {
      institution = `${this.data && this.data.header.solutions[0] && this.data.header.solutions[0].producerName}-`; 
    }
    if(this.type === EXPERT) {
      institution = `${this.data && this.data.header && this.data.header.institution.name}-`;
    }
    const name = `${institution}${labelToChinese[this.type]}-${this.data && this.data.header.name}`;
    const signature = md5(`${APP_KEY}1${name}${APP_SECRET}`);
    return `http://www.wenjuan.com/s/${SHORT_ID}/?wj_appkey=${APP_KEY}&wj_repeat=1&wj_respondent=${name}&wj_signature=${signature}`;
  }
}

export default createContext(new DatabseDetailStore());
