import React from "react";

const SVG = ({
  fill = "#E4E4E4",
  width = "100%",
  className = "",
  viewBox = "0 0 45 36",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={fill} fillRule="evenodd" d="M0 0h45v36L22.5 26 0 36z" />
      <text
        fontFamily="SourceHanSansSC-Regular, Source Han Sans SC"
        fontSize="14"
        fill="#777"
      >
        <tspan x="0" y="21">
          已结束
        </tspan>
      </text>
    </g>
  </svg>
);

export default SVG;
