import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Tooltip } from "antd";
import { useHistory } from "react-router-dom";

import "./style.scss";
import Icon from "../icon/Icon";
import Menu from "./Menu";
import SubMenu from "./SubMenu";
// import AuthStore from "../../../stores/authStore";
import { sidebarList } from "../../../constants/sidebar";
import { labelToChinese } from "../../../constants/label_to_chinese";

const Sidebar: React.FC = () => {
  const history = useHistory();
  const briefLink = "https://capital.lenovo.com/team/lenovoteam.html";
  // const { isCurrentUserEmpty, currentUser } = useContext(AuthStore);

  const [isShowProfilePopover, setIsShowProfilePopover] = useState(false);
  const [profileColor, setProfileColor] = useState("#92929D");
  const [logoutColor, setLogoutColor] = useState("#92929D");
  const isSidebarView = sidebarList
    .map(item => item.link)
    .includes(location.pathname);

  const setClassName = (type: string, className: string): void => {
    const map = {
      logout: setLogoutColor,
      profile: setProfileColor,
    };
    setTimeout(() => {
      map[type](className);
    }, 100);
  };

  const getBackRoute = () => {
    const routes = [
      "library/solution",
      "library/institution",
      "library/expert",
    ];
    return routes.find(item => location.pathname.includes(item));
  };

  return (
    <>
      <div
        className={`lenovo-sidebar ${
          isSidebarView ? "lenovo-sidebar--sidebar-view" : ""
        }`}
      >
        <div className={`lenovo-sidebar__menus`}>
          <a href={briefLink} target="_blank" className="lenovo-sidebar__item">
            <Icon name="lenovo-brief" size={16} />
            <span>创投简介</span>
          </a>
          {sidebarList.map(item => {
            return item.subMenu ? (
              <SubMenu
                key={item.label}
                menuLabel={item.label}
                menuIcon={item.icon}
              >
                <div className="lenovo-sidebar__sub-menu-content">
                  {item.subMenu.map(subItem => {
                    if (!subItem.isEnable) {
                      return (
                        <div
                          className="lenovo-sidebar__sub-menu--ban"
                          key={subItem.label}
                        >
                          <span>{labelToChinese[subItem.label]}</span>
                          <Tooltip placement="bottom" title={"敬请期待"}>
                            <span>
                              <Icon name="lenovo-coming-soon" />
                            </span>
                          </Tooltip>
                        </div>
                      );
                    }
                    return (
                      <NavLink
                        activeClassName="actived"
                        key={subItem.label}
                        to={subItem.link}
                      >
                        {labelToChinese[subItem.label]}
                      </NavLink>
                    );
                  })}
                </div>
              </SubMenu>
            ) : (
              <Menu {...item} key={item.label} />
            );
          })}
        </div>

        <div className="lenovo-sidebar__footer">
          <a
            className="lenovo-sidebar__guest-user"
            href="/info/management"
            target="_blank"
          >
            <span className="lenovo-sidebar__guest-user__text--weight">
              ← 成员企业后台登录
            </span>
          </a>
          <a
            className="lenovo-sidebar__guest-user"
            href="/info/admin"
            target="_blank"
          >
            <span
              className="lenovo-sidebar__guest-user__text--weight"
              style={{ marginTop: "10px" }}
            >
              admin
            </span>
          </a>
          {/* {!isCurrentUserEmpty ? (
            <Tooltip title={currentUser.username}>
              <div className="lenovo-sidebar__guest-user current-user">
                <span className="lenovo-sidebar__guest-user__avatar">
                  <Icon name="lenovo-guest" size={18} color={"#c72c21"} />
                </span>
                <span className="lenovo-sidebar__guest-user__text text-limit--one">
                  {currentUser.username}
                </span>
              </div>
            </Tooltip>
          ) : (
            <>
              <Link className="lenovo-sidebar__guest-user" to="/login">
                <div className="lenovo-sidebar__guest-user__avatar">
                  <Icon name="lenovo-guest" size={16} />
                </div>
                <span className="lenovo-sidebar__guest-user__text">
                  游客你好，请登录
                </span>
              </Link>
            </>
          )} */}
          {isShowProfilePopover && (
            <ul className="lenovo-sidebar__user-center">
              <li
                className="lenovo-sidebar__profile"
                onMouseEnter={() => setClassName("profile", "#c72c21")}
                onMouseLeave={() => setClassName("profile", "#92929D")}
                onClick={() => {
                  setClassName("profile", "#92929D");
                  setIsShowProfilePopover(false);
                }}
              >
                <Link to="/profile">
                  <Icon name="lenovo-profile" color={profileColor} />
                  <span>个人中心</span>
                </Link>
              </li>
              <li
                className="lenovo-sidebar__logout"
                onMouseEnter={() => setClassName("logout", "#eb2835")}
                onMouseLeave={() => setClassName("logout", "#92929D")}
                onClick={() => {
                  setClassName("logout", "#92929D");
                  setIsShowProfilePopover(false);
                }}
              >
                <Icon name="lenovo-logout" color={logoutColor} />
                <a>
                  <span>退出登录</span>
                </a>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="lenovo-sidebar--mobile">
        {isSidebarView ? (
          <div className="lenovo-sidebar___item-container--mobile">
            <a
              href={briefLink}
              target="_blank"
              className="lenovo-sidebar__item--mobile"
            >
              <span>创投简介</span>
            </a>
            {sidebarList.map((item, index) => (
              <NavLink
                key={index}
                className="lenovo-sidebar__item--mobile"
                activeClassName="actived"
                to={item.link}
              >
                <span>{labelToChinese[item.label]}</span>
              </NavLink>
            ))}
          </div>
        ) : (
          <div onClick={() => history.push(`/${getBackRoute()}s`)}>
            <p className="lenovo-sidebar__back--mobile" />
            <span>返回{labelToChinese[getBackRoute()]}</span>
          </div>
        )}

        {/* <Link className="lenovo-sidebar__guest-user--mobile" to="/login">
          <div className="lenovo-sidebar__guest-user__avatar--mobile">
            {isCurrentUserEmpty ? (
              <Icon name="lenovo-guest" size={24} />
            ) : (
              <Icon name="lenovo-guest" size={24} color={"#c72c21"} />
            )}
          </div>
        </Link> */}
      </div>
    </>
  );
};

export default observer(Sidebar);
