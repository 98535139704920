import { useLocation } from "react-router";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

const isMobile = () => {
  const useragent = navigator.userAgent.toLowerCase();
  return /android|iphone|phone/i.test(useragent);
};

const openNewTab = (url: string, history?: any) => {
  if (isMobile()) {
    if (history) {
      history.push(url);
    } else {
      window.open(url, "_blank");
    }
  } else {
    window.open(url, "_blank");
  }
};

const getQuery = (variable): any => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

const formatLateX = (str: any) =>
  str
    ? str.replace(
        /(\$\$[^\$\$]*\$\$|\$[^\$]*\$)/g,
        (match: any) => {
          // console.log(match);
          return match.replace(/\s*/g, "");
        },
        ""
      )
    : "";

const getFromWeek = (happenTime: string) => {
  const hw = Number(dayjs(happenTime).format("w"));
  const nw = Number(dayjs(+new Date()).format("w"));
  const res = Math.abs(nw - hw);
  return res;
};

const weekToString = (week: number) => {
  if (!week) {
    return "本周发起";
  }
  if (week < 4) {
    return `${week} 周前`;
  }
  return `${parseInt(String(week / 4))} 月前`;
};

const filterEmptyParam = (params: any) => {
  let res = {};
  const keys = Object.keys(params);
  if (keys.length > 0) {
    keys.forEach((key: string) => {
      if (
        Array.isArray(params[key]) ||
        Array.isArray(params[key].$mobx.values)
      ) {
        res[key] = [];
        params[key].forEach((value: any) => {
          if (value) {
            res[key].push(value);
          }
        });
      } else {
        if (params[key]) {
          res[key] = params[key];
        }
      }
    });
  }
  return res;
};

const formatSpDate = (str: string, utc?: boolean) => {
  const weekMap = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
  const utcDay = utc ? dayjs.utc : dayjs;
  return {
    pc: `${utcDay(str).format("MM-DD")} ${weekMap[utcDay(str).day()]} ${utcDay(
      str
    ).format("HH:mm")}`,
    m: utcDay(str).format("MM-DD"),
  };
};

const useQuery = () => {
  return new URLSearchParams(new useLocation().search);
};

const timeDifference = (endtime: string) => {
  const difference = new Date(endtime).getTime() - new Date().getTime();
  const days = Math.floor(difference / (24 * 3600 * 1000));
  return days > 365;
};

const goAuth = (history: any, isLogin: boolean) => {
  const goFn = () => {
    localStorage.setItem(
      "from",
      (window as any).location.href.replace((window as any).location.origin, "")
    );
    sessionStorage.setItem("isOld", "true");
    history.push("/profile?type=auth");
  };
  if (isMobile()) {
    goFn();
  } else {
    if (isLogin) {
      goFn();
    } else {
      (document.querySelector(".sign-in-btn") as any).click();
      (document.querySelector(".hide-btn") as any).click();
    }
  }
};

export {
  isMobile,
  openNewTab,
  getQuery,
  formatLateX,
  getFromWeek,
  weekToString,
  filterEmptyParam,
  formatSpDate,
  useQuery,
  timeDifference,
  goAuth,
};
