import React, { useContext } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Table, Skeleton, Tooltip } from "antd";
import { AvatarCard } from "../shared";
import databaseStore from "../../stores/databaseStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
  INSTITUTION,
} from "../../constants/pro_constants";
import DatabaseListPanel from "./DatabaseListPanel";

const customSkeleton = (
  <Skeleton title={false} paragraph={{ rows: 1, width: "80%" }} active />
);

const loader = (
  <Skeleton paragraph={{ rows: 2 }} avatar={{ shape: "square" }} active />
);

const InstitutionResultPanel = (props: any) => {
  const {
    resultList: { data, count },
    pagination,
    isLoading,
    hasMore,
    paginationMobile,
    fetchDatabaseList,
  } = useContext(databaseStore);
  const { usePagination = true, useTitle = true } = props;
  const columns = [
    {
      title: "企业名称",
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      width: "28%",
      className: "database-list-panel__institution__brief",
      render: data =>
        !isLoading ? (
          <>
            <AvatarCard theme="company" data={data} avatarSize={32} />
            {data.tbd && (
              <div className="list-panel__institution-new">新投</div>
            )}
            {/* {data.categoryCn && (
              <span className="database-list-panel__institution__category">
                {data.categoryCn}
              </span>
            )} */}
          </>
        ) : (
          customSkeleton
        ),
    },
    {
      title: "企业简介",
      dataIndex: "summary",
      ellipsis: {
        showTitle: false,
      },
      className: "database-list-panel__solution__summary",
      render: data =>
        !isLoading
          ? (
              <Tooltip
                placement="leftTop"
                title={
                  data
                    ? data.length > 400
                      ? data.substr(0, 400) + "..."
                      : data
                    : ""
                }
              >
                {data}
              </Tooltip>
            ) || "-"
          : customSkeleton,
    },
    // {
    //   title: "企业类型",
    //   ellipsis: true,
    //   width: "16%",
    //   dataIndex: "roles",
    //   render: data => {
    //     return !isLoading ? (data && data.join(",")) || "-" : customSkeleton;
    //   },
    // },
    {
      title: "成立时间",
      ellipsis: true,
      sorter: true,
      width: "14%",
      dataIndex: "foundedAt",
      className: "database-list-panel__institution__founded-at",
      render: data =>
        !isLoading
          ? moment
              .unix(data)
              .utcOffset(480)
              .format("YYYY-MM") || "-"
          : customSkeleton,
    },
  ];
  return (
    <>
      <div className="database-list-panel">
        <Table
          columns={columns}
          dataSource={data}
          locale={{
            triggerDesc: labelToChinese[TRIGGER_DESC],
            triggerAsc: labelToChinese[TRIGGER_ASC],
            cancelSort: labelToChinese[CACCEL_SORT],
          }}
          tableLayout="fixed"
          pagination={usePagination ? pagination : false}
          onChange={(pagination, filters, sorter) =>
            fetchDatabaseList(pagination, sorter)
          }
          title={
            useTitle
              ? () => (
                  <div className="database-list-panel__title">
                    找到 {count} 条结果
                  </div>
                )
              : false
          }
          onRow={record => {
            return {
              onClick: () => {
                window.open(`/library/institutions/${record.key}`);
              },
            };
          }}
        />
      </div>
      <DatabaseListPanel
        loader={loader}
        isLoading={isLoading}
        hasMore={hasMore}
        pagination={paginationMobile}
        dataLength={data ? data.length : 0}
        next={fetchDatabaseList}
        type={INSTITUTION}
      >
        {data &&
          data.map((item, index) => (
            <div
              onClick={() => window.open(`/library/institutions/${item.key}`)}
              key={index}
              className="database-list-panel__content-item--mobile"
            >
              <div className="content-item__avatar--mobile">
                <img src={item.name.avatarUrl} alt="avatar" />
              </div>
              <div className="content-item__content--mobile">
                <div>
                  <p className="content-item__name--mobile">{item.name.name}</p>
                  {item.tbd && (
                    <div className="list-panel__institution-new">新投</div>
                  )}
                  {/* {item.name.categoryCn && (
                    <span className="content-item__category--mobile">
                      {item.name.categoryCn}
                    </span>
                  )} */}
                  {/* <p className="content-item__type--mobile">
                    {item.roles && item.roles.join(",")}
                  </p> */}
                </div>
                <div className="content-item__summary--mobile">
                  {item.summary}
                </div>
              </div>
            </div>
          ))}
      </DatabaseListPanel>
    </>
  );
};

export default observer(InstitutionResultPanel);
