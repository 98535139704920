import React from "react";

const SVG = ({
  fill = "#8D98BC",
  width = "100%",
  className = "",
  viewBox = "0 0 14 14",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill} fillRule="nonzero">
      <path d="M9.945 5.345h-2.37V3.07A.565.565 0 0 0 7 2.518a.565.565 0 0 0-.575.552v2.275h-2.37a.565.565 0 0 0-.575.552c0 .305.258.552.575.552h2.37v2.275c0 .304.258.552.575.552a.565.565 0 0 0 .575-.552V6.449h2.37a.565.565 0 0 0 .575-.552.565.565 0 0 0-.575-.552z" />
      <path d="M10.747 0H3.253C1.46 0 0 1.4 0 3.12v5.705c0 1.72 1.458 3.12 3.25 3.12h1.375l1.593 1.793c.03.034.064.064.1.09.005.006.01.013.016.018.108.1.25.154.398.154.159 0 .306-.06.415-.17l1.886-1.886h1.714c1.794 0 3.253-1.4 3.253-3.12V3.12C14 1.4 12.54 0 10.747 0zm2.102 8.825c0 1.11-.942 2.014-2.102 2.014h-1.96a.591.591 0 0 0-.415.17l-1.64 1.64-1.737-1.825-.987.015H3.25c-1.157 0-2.099-.904-2.099-2.014V3.12c0-1.111.944-2.015 2.103-2.015h7.494c1.16 0 2.102.904 2.102 2.015v5.705z" />
    </g>
  </svg>
);

export default SVG;
