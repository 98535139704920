import React from "react";

const SVG = ({
  fill = "#DA0010",
  width = "100%",
  className = "",
  viewBox = "0 0 14 14",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        fillRule="nonzero"
        d="M7.272 5.464v-2.04h-.744v2.04h.744zm-1.808 5.352v-.32h3.528v.304h.744V6.456H6.904c.992-.352 1.84-.768 2.552-1.256.256.224.48.448.68.672l.528-.536c-.56-.56-1.256-1.128-2.072-1.696l-.512.488c.296.208.576.416.84.624-1.288.832-3.144 1.44-5.56 1.824l.312.688c.352-.064.704-.136 1.056-.208v3.76h.736zM4.056 5.88a6.927 6.927 0 0 0 1.752-1.528l-.536-.472a6.9 6.9 0 0 1-1.656 1.432l.44.568zm4.936 1.712H5.464v-.504h3.528v.504zm0 1.088H5.464v-.488h3.528v.488zm0 1.144H5.464v-.536h3.528v.536z"
      />
      <circle stroke={fill} strokeWidth=".5" cx="7" cy="7" r="6.75" />
    </g>
  </svg>
);

export default SVG;
