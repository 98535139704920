import React, { useContext } from "react";
import { IconHeading, FileCard } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { RELATED_FILE } from "../../constants/pro_constants";
import ahoy from "ahoy.js";

const RelatedFilesIntro = () => {
  const {
    type,
    id,
    data: {
      header: { name },
      tabs: { relatedFile },
    },
  } = useContext(databaseDetailStore);

  return (
    <div className="database-detail__block" id={RELATED_FILE}>
      <IconHeading icon="lenovo-material" title="相关材料" />
      <div className="database-detail__block__content">
        <FileCard
          name={relatedFile.name}
          type={relatedFile.type}
          url={relatedFile.url}
          onDownload={() => {
            ahoy.track("download", {
              name: name,
              id: id,
              type: type,
            });
          }}
        />
      </div>
    </div>
  );
};

export default observer(RelatedFilesIntro);
