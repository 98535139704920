import React, { useContext, useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { Tag, Icon } from "../shared";
import { INSTITUTION } from "../../constants/pro_constants";
import databaseStore from "../../stores/databaseStore";

const SelectedConditionsPanel = props => {
  const [isFixed, setIsFixed] = useState(false);
  const {
    selectedConditionItems,
    selectedKeywordResult,
    removeConditionItem,
    clearSelectedKeywordResult,
  } = useContext(databaseStore);

  useEffect(() => {
    props.parentRef.current.addEventListener("scroll", handleScroll);
    return () =>
      props.parentRef.current &&
      props.parentRef.current.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = useCallback(() => {
    const width = document.getElementsByClassName("database-filter-panel")[0]
      .clientWidth;
    setFixedWidth(width);
  }, []);

  const handleScroll = useCallback(() => {
    if (isNeedToFix()) {
      setIsFixed(true);
      handleResize();
    } else {
      setIsFixed(false);
    }
  }, [setIsFixed]);

  const isNeedToFix = () => {
    const conditionsPanelHeight = document.getElementsByClassName(
      "selected-condition-panel"
    )[0].offsetHeight;
    const listPanelOffsetTop = document
      .getElementsByClassName("database-list-panel")[0]
      .getBoundingClientRect().y;

    return (
      (listPanelOffsetTop > 0 &&
        listPanelOffsetTop <= conditionsPanelHeight + 20) ||
      listPanelOffsetTop < 0
    );
  };

  const setFixedWidth = width => {
    document.getElementsByClassName(
      "selected-condition-panel"
    )[0].style.width = `${width}px`;
  };

  return (
    <div className={`selected-condition-panel ${isFixed ? "fixed" : ""}`}>
      {selectedKeywordResult[INSTITUTION] ? (
        <div className="selected-condition-panel__title institution">
          <p>
            当前结果：<span>「{selectedKeywordResult[INSTITUTION]}」</span>
            相关企业
          </p>
          <span
            className="institution__remove"
            onClick={() => clearSelectedKeywordResult(INSTITUTION)}
          />
        </div>
      ) : (
        <div className="selected-condition-panel__title">已选条件</div>
      )}
      <div className="selected-condition-panel__content">
        {selectedConditionItems.map((ele, i) => {
          return (
            <Tag
              isHasBorder
              isClose
              color={ele.color}
              key={i}
              onRemove={() => {
                removeConditionItem("single", ele);
              }}
            >
              {ele.name}
            </Tag>
          );
        })}
        <span
          className="selected-condition-panel__content__remove"
          onClick={() => {
            removeConditionItem("all");
          }}
        >
          <Icon name="pro-delete" />
          清空选项
        </span>
      </div>
    </div>
  );
};

export default observer(SelectedConditionsPanel);
