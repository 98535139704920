import React, { useContext } from "react";
import "./style.scss";

import { observer } from "mobx-react";
import { Empty } from "../shared/index";
import activityDetailStore from "../../stores/activityDetailStore";

interface Props {
  [key: string]: any;
}

const ActivityMain: React.FC<Props> = () => {
  const { detailData, loading } = useContext(activityDetailStore);
  return (
    <div className="activity-detail-main">
      <p className="detail-title">活动描述</p>
      {detailData.content && detailData.contented ? (
        <div
          className="main__content"
          dangerouslySetInnerHTML={{ __html: detailData.content }}
        />
      ) : (
        !loading && (
          <div className="main__empty">
            <Empty title="资料上传中" img={1} />
          </div>
        )
      )}
    </div>
  );
};

export default observer(ActivityMain);
