import React from "react";

const SVG = ({
  fill = "#FFA600",
  width = "100%",
  className = "",
  viewBox = "0 0 14 14",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>tooltip</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M11.4676925,6.18567428 C11.8802291,5.40643836 12.5536968,5.40311389 12.9679935,6.18567428 L12.9679935,6.18567428 L18.6676925,16.9517724 C19.0802291,17.7310083 18.7032574,18.3687233 17.8217715,18.3687233 L17.8217715,18.3687233 L6.61391439,18.3687233 C5.7319412,18.3687233 5.35369677,17.7343328 5.76799345,16.9517724 L5.76799345,16.9517724 Z M12.217843,15.0118433 L12.1175375,15.0180802 C11.7232252,15.0674741 11.417843,15.4042126 11.417843,15.8118433 L11.417843,15.8118433 L11.4240798,15.9121489 C11.4734737,16.3064611 11.8102122,16.6118433 12.217843,16.6118433 L12.217843,16.6118433 L12.3181485,16.6056065 C12.7124607,16.5562126 13.017843,16.2194741 13.017843,15.8118433 L13.017843,15.8118433 L13.0116061,15.7115378 C12.9622122,15.3172256 12.6254737,15.0118433 12.217843,15.0118433 L12.217843,15.0118433 Z M12.217843,8.61184335 L12.1175375,8.61808021 C11.7232252,8.66747411 11.417843,9.00421258 11.417843,9.41184335 L11.417843,9.41184335 L11.417843,13.4118433 C11.417843,13.8534433 11.776243,14.2118433 12.217843,14.2118433 L12.217843,14.2118433 L12.3181485,14.2056065 C12.7124607,14.1562126 13.017843,13.8194741 13.017843,13.4118433 L13.017843,13.4118433 L13.017843,9.41184335 C13.017843,8.97024335 12.659443,8.61184335 12.217843,8.61184335 L12.217843,8.61184335 Z"
        id="tooltip"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="评选填报中心-首页" transform="translate(-301.000000, -206.000000)">
        <g id="Group-3" transform="translate(286.000000, 193.000000)">
          <g id="alarm" transform="translate(10.000000, 8.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <mask id="tooltip-mask-2" fill="white">
              <use xlinkHref="#tooltip" />
            </mask>
            <g id="Combined-Shape" />
            <rect
              id="color"
              fill={fill}
              mask="url(#tooltip-mask-2)"
              x="0"
              y="0"
              width="24"
              height="24"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
