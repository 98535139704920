import React, { useContext } from "react";
import DatabaseStore from "../../stores/databaseStore";
import { observer } from "mobx-react";
import { INSTITUTION } from "../../constants/pro_constants";
import { AvatarCard } from "../shared";
import EmptyDataImg from "../../assets/images/empty-data.svg";

interface Props {
  isMobile?: boolean;
  type?: string;
}

const KeywordResultPanel: React.FC<Props> = ({ isMobile, type }) => {
  const {
    keyword,
    isGetData,
    keywordResultInstitutionData,
    isKeywordResultInstitutionDataEmpty,
    hideSearchPanel,
    setSelectedKeywordResult,
    controlPanelIndex,
  } = useContext(DatabaseStore);

  return !isKeywordResultInstitutionDataEmpty ? (
    <>
      <div
        className={`keyword-result-panel ${
          isMobile ? "mobile-result-panel" : ""
        } ${type !== INSTITUTION && "keyword-result-panel--origin"}`}
      >
        {!isKeywordResultInstitutionDataEmpty && isGetData && (
          <>
            {keywordResultInstitutionData.map((ele, i) => {
              return (
                <div
                  className="keyword-result-panel__item"
                  key={i}
                  onClick={() => {
                    hideSearchPanel();
                    setSelectedKeywordResult(INSTITUTION, ele.name);
                  }}
                  onMouseEnter={e => controlPanelIndex(e, "mouse", i)}
                >
                  <AvatarCard
                    theme="company"
                    shape="square"
                    avatarSize={36}
                    data={ele}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        className={`keyword-result-panel__layer ${
          isMobile ? "mobile-result-panel__layer" : ""
        }`}
        onClick={() => hideSearchPanel()}
      />
    </>
  ) : (
    isGetData && keyword && (
      <>
        <div
          className={`keyword-result-panel ${
            isMobile ? "mobile-result-panel" : ""
          } ${type !== INSTITUTION && "keyword-result-panel--origin"}`}
        >
          <div className="keyword-result-empty">
            <div>
              <img src={EmptyDataImg} alt="暂无数据" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <div
          className={`keyword-result-panel__layer ${
            isMobile ? "mobile-result-panel__layer" : ""
          }`}
          onClick={() => hideSearchPanel()}
        />
      </>
    )
  );
};

KeywordResultPanel.defautlProps = {
  isMobile: false,
};

export default observer(KeywordResultPanel);
