import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 57 57" }) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="联想创投-UI-0617"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.100000001"
    >
      <path
        d="M4.87184335,18.9403858 L4.8711146,19.2081146 L51.4401146,19.2081146 L51.4403858,18.9403858 L58.1613999,18.9403858 L59.0862366,19.6873632 L57.4284831,20.2510283 L59.9209191,20.6285133 L60.330522,21.4401238 L59.0862366,21.960226 L60.330522,22.6070402 L58.1613999,23.2790378 L59.5085047,23.8313041 L57.4284831,24.5904272 L60.330522,24.8481809 L57.4284831,25.8084172 L59.0862366,26.3005085 L58.1613999,26.9314175 L61.4403858,27.9403858 L58.7578544,28.6160505 L59.9209191,29.4502818 L58.1613999,30.1661072 L60.330522,30.8812257 L58.1613999,31.5460659 L60.330522,32.301301 L58.1613999,33.3590897 L59.5085047,34.2901664 L56.9747625,35.2478403 L59.0862366,36.0116466 L59.0862366,36.9403858 L56.5401146,36.9401146 L56.5410342,37.2081208 L4.50360336,37.2081208 L4.5031146,36.9401146 L-2.77400735,36.9403858 L-2.77400735,36.0116466 L-0.662533289,35.2478403 L-3.19627552,34.2901664 L-1.84917067,33.3590897 L-4.01829278,32.301301 L-1.84917067,31.5460659 L-4.01829278,30.8812257 L-1.84917067,30.1661072 L-3.60868986,29.4502818 L-2.44562519,28.6160505 L-5.12815665,27.9403858 L-1.84917067,26.9314175 L-2.77400735,26.3005085 L-1.11625394,25.8084172 L-4.01829278,24.8481809 L-1.11625394,24.5904272 L-3.19627552,23.8313041 L-1.84917067,23.2790378 L-4.01829278,22.6070402 L-2.77400735,21.960226 L-4.01829278,21.4401238 L-3.60868986,20.6285133 L-1.11625394,20.2510283 L-2.77400735,19.6873632 L-1.84917067,18.9403858 L4.87184335,18.9403858 Z"
        id="blue"
        fill="#0092DA"
        transform="translate(28.156115, 28.156115) rotate(-45.000000) translate(-28.156115, -28.156115) "
      />
    </g>
  </svg>
);

export default SVG;
