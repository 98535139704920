import React from "react";

import "./style.scss";

interface Props {
  size?: string;
  color?: string;
  height?: string;
  svgName: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?(params: any): void;
}

const SvgIcon: React.FC<Props> = (props: Props) => {
  const {
    size = "14px",
    height,
    svgName,
    className = "svg-icon",
    color = "",
    style = {},
    onClick = () => {},
  } = props;

  const svgStyle = { ...style, color };

  return (
    <svg
      onClick={onClick}
      style={svgStyle}
      width={size}
      height={height || size}
      className={`${className} ${className}__${svgName}`}
    >
      <use xlinkHref={`#icon-${svgName}`} />
    </svg>
  );
};

export default SvgIcon;
