import { action, observable, computed } from "mobx";
import { createContext } from "react";
import _ from "lodash";
import { login, fetchUserInfo, postUserInfo } from "../api";
import { Notify } from "../components/shared";

class AuthStore {
  @observable public loginLoading = true;
  @observable public passLogin = false;
  @observable public currentUser: any =
    JSON.parse(localStorage.getItem("currentUser")) || {};

  @action
  public onLoginSubmit = async data => {
    const result = await login(data);
    if (result && result.status === 200) {
      Notify.success({
        message: "系统通知",
        description: "登录成功",
        duration: 3,
      });
      window.location.href = "/library/solutions";
    }
  };

  @action
  public updateCaptcha = async (dom, event) => {
    dom.src = event.target.src;
  };

  @action
  public syncUserInfo = async () => {
    try {
      const res = await fetchUserInfo();
      if (this.currentUser.id) {
        this.passLogin = false;
      } else {
        this.passLogin = true;
      }
      this.currentUser = res.data.user;
      localStorage.setItem("currentUser", JSON.stringify(res.data.user));
    } catch (error) {
      this.currentUser = {};
      localStorage.removeItem("currentUser");
    }
    this.loginLoading = false;
    setTimeout(() => {
      this.loginLoading = true;
    });
  };

  @action
  public updateUserInfo = async (data: any) => {
    try {
      await postUserInfo(data);
    } catch (error) {
      Notify.error({
        message: error.message,
        duration: 3,
      });
    }
    await this.syncUserInfo();
  };

  @computed
  get isLogin() {
    return !_.isEmpty(this.currentUser);
  }

  @computed
  get isHadUserInfo() {
    return this.isLogin && this.currentUser.company;
  }

  @computed
  get isVisitor() {
    return !this.isLogin;
  }

  @computed
  get isNormalUser() {
    return this.isLogin && this.currentUser.status !== "passed";
  }

  @computed
  get isAuthUser() {
    return this.isLogin && this.currentUser.status === "passed";
  }

  @computed
  get isCurrentUserEmpty() {
    return _.isEmpty(this.currentUser);
  }
}

export default createContext(new AuthStore());
