import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";
import "./style.scss";
import {
  Nav,
  Footer,
  BuzzLine,
  CooperationCard,
  CodeEntry,
  Notify,
  Empty,
} from "../shared/index";
import AuthStore from "../../stores/authStore";
import GlobalStore from "../../stores/globalStore";
import circleStore from "../../stores/circleStore";
import postCooperStore from "../../stores/postCooperStore";
import { isMobile } from "../../utils/tools";
import Icon from "../shared/icon/Icon";
import { postCirclesInfo, postJoinGroup } from "../../api";
import lWxCode from "../../assets/images/l-wx-img.png";

interface Props {
  [key: string]: any;
}

const CircleDetail: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { isAuthUser, loginLoading } = useContext(AuthStore);
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    GlobalStore
  );
  const {
    circleDetailData,
    fetchCirclesDetailData,
    detailLoading,
  } = useContext(circleStore);
  const { setCooperateInfo } = useContext(postCooperStore);
  const [maxLength, setMaxLength] = useState(9);
  const btnState =
    circleDetailData.joined && !circleDetailData.checking ? "join" : "notJoin";

  useEffect(() => {
    if (!loginLoading) {
      !isAuthUser && setVisibleState("toAuth", true);
      fetchCirclesDetailData(id);
    }
  }, [loginLoading]);

  useEffect(() => {
    if (circleDetailData.joined) {
      (document.querySelector(".lenovo-footer") as any).style.marginBottom =
        "0";
    } else {
      (document.querySelector(".lenovo-footer") as any).style.marginBottom =
        "0.70667rem";
    }
    setMaxLength(circleDetailData.maxLength);
  }, [circleDetailData]);

  const joinGroup = async () => {
    if (
      circleDetailData.joined &&
      !circleDetailData.checking &&
      circleDetailData.groups.length > 0
    ) {
      if (!circleDetailData.groups[0].audit) {
        // 无需审核
        circleDetailData.groups[0].qrcode &&
          window.open(circleDetailData.groups[0].qrcode, "_self");
      } else if (circleDetailData.groups[0].audit) {
        if (!circleDetailData.pendingGroup && !circleDetailData.passedGroup) {
          try {
            await postJoinGroup({
              id,
              circle_group_id: circleDetailData.groups[0].id,
            });
            revertDialogProps();
            setDialogProp("lenovoWxTitle", "加入群聊审核中");
            setDialogProp(
              "lenovoWxContent",
              "加入群聊的申请结果将通过服务号通知，请留意服务号消息"
            );
            setDialogProp("lenovoWxCodeImg", lWxCode);
            setDialogProp(
              "lenovoWxBottomTip",
              "长按识别二维码，留意服务号通知"
            );
            setVisibleState("lenovoWx", true);
            fetchCirclesDetailData(id);
          } catch (error) {
            error.message &&
              Notify.error({
                message: error.message,
                duration: 3,
              });
          }
        } else if (circleDetailData.passedGroup) {
          revertDialogProps();
          setDialogProp(
            "lenovoWxTitle",
            `加入「${circleDetailData.groups[0].name}」群聊`
          );
          setDialogProp("lenovoWxContent", "识别二维码加入群聊，讨论热门话题");
          setDialogProp(
            "lenovoWxCodeImg",
            circleDetailData.groups[0].qrcodeUrl
          );
          setDialogProp("lenovoWxBtned", false);
          setDialogProp("lenovoWxIconed", false);
          setDialogProp("lenovoWxBottomTip", "");
          setVisibleState("lenovoWx", true);
          fetchCirclesDetailData(id);
        }
      }
    }
  };

  const joinCircle = async () => {
    if (!circleDetailData.checking) {
      try {
        await postCirclesInfo({ id });
        Notify.success({
          message: `加入${circleDetailData.name}圈子成功`,
          duration: 3,
        });
        fetchCirclesDetailData(id);
      } catch (error) {
        error.message &&
          Notify.error({
            message: error.message,
            duration: 3,
          });
      }
    }
  };
  return (
    <div className="lenovo-circle-detail">
      <Helmet>
        <title>{`${circleDetailData.name} | 联想数字生态平台`}</title>
      </Helmet>
      <Nav>
        <div className="lenovo-circle-detail__content">
          <CodeEntry />
          {isMobile() && (
            <img
              className="lenovo-circle-detail__content__header-img"
              src={circleDetailData.coverUrl}
              alt=""
            />
          )}
          <div
            className={`lenovo-circle-detail__content__header
            ${!isMobile() &&
              circleDetailData.joined &&
              !circleDetailData.checking &&
              circleDetailData.users.length > 0 &&
              "pad-bottom-10"}
          `}
          >
            <div className="lenovo-circle-detail__content__header-info">
              <img src={circleDetailData.coverUrl} alt="" />
              <div className="lenovo-circle-detail__content__header-info-word">
                <p className="lenovo-circle-detail__content__header-info-title">
                  {circleDetailData.name}
                </p>
                {isMobile() && (
                  <p className="lenovo-circle-detail__content__header-info-person">{`已有${
                    circleDetailData.users ? circleDetailData.users.length : 0
                  }人加入`}</p>
                )}
                <p className="lenovo-circle-detail__content__header-info-content">
                  {circleDetailData.summary}
                </p>
              </div>
              {!isMobile() &&
                (!circleDetailData.joined || circleDetailData.checking) && (
                  <div
                    className={`lenovo-circle-detail__content__header-info-btn ${
                      circleDetailData.checking ? "header-info-audit-btn" : ""
                    }`}
                    onClick={joinCircle}
                  >
                    {circleDetailData.checking && (
                      <Icon name="circle-red-lock" size={12} />
                    )}
                    {circleDetailData.checking ? "审核中" : "加入圈子"}
                  </div>
                )}
            </div>
            {!isMobile() && (
              <div className="lenovo-circle-detail__content__header-person">
                <div className="lenovo-circle-detail__content__header-person-title">
                  {!circleDetailData.joined || circleDetailData.checking
                    ? "圈子成员"
                    : circleDetailData.users
                    ? `圈子成员（${circleDetailData.users.length}人）`
                    : "圈子成员（0人）"}
                </div>
                {(!circleDetailData.joined || circleDetailData.checking) && (
                  <div className="lenovo-circle-detail__content__header-person-audit">
                    <Icon name="circle-big-lock" size={20} />
                    <p>加入圈子即可与圈内成员合作</p>
                  </div>
                )}
                {circleDetailData.joined &&
                  !circleDetailData.checking &&
                  (circleDetailData.users.length > 0 ? (
                    <div className="lenovo-circle-detail__content__header-person-list">
                      {circleDetailData.users &&
                        circleDetailData.users
                          .slice(0, maxLength)
                          .map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`lenovo-circle-detail__content__header-person-item ${
                                item.creator ? "item-dis-hover" : ""
                              }`}
                            >
                              <Icon name="cooperation-card-user" size={16} />
                              <div className="item-person-info">
                                <p className="info-name">{item.fullName}</p>
                                <p className="info-department">
                                  {item.company}
                                  {item.positionShow && (
                                    <span>{item.position}</span>
                                  )}
                                </p>
                              </div>
                              {!item.creator && (
                                <div
                                  className={`item-person-btn ${
                                    item.combine ? "dis-btn" : ""
                                  }`}
                                  onClick={() => {
                                    if (!item.combine && !item.creator) {
                                      isAuthUser &&
                                        setCooperateInfo({
                                          isCom: true,
                                          type: "pub",
                                          params: {
                                            partner_id: item.id,
                                          },
                                        });
                                      isAuthUser &&
                                        history.push("/cooperation/post");
                                    }
                                  }}
                                >
                                  {item.combine ? "等待响应" : "与他合作"}
                                </div>
                              )}
                            </div>
                          ))}
                      {circleDetailData.users &&
                        circleDetailData.users.length > 10 &&
                        maxLength !== circleDetailData.users.length && (
                          <div
                            className="lenovo-circle-detail__content__header-person-more"
                            onClick={() => {
                              setMaxLength(circleDetailData.users.length);
                            }}
                          >
                            展开所有成员
                            <Icon
                              className="arrow-right-normal"
                              size={20}
                              name="lenovo-arrow-down"
                              color="#8d98bc"
                            />
                          </div>
                        )}
                    </div>
                  ) : (
                    !detailLoading && (
                      <div className="header-person--empty">
                        <Empty title="该圈子暂无人加入，快来加入成为首位成员吧！" />
                      </div>
                    )
                  ))}
              </div>
            )}
          </div>
          <p className="lenovo-circle-detail__content__header-info-content opcity0">
            {circleDetailData.summary}
          </p>
          <div className="lenovo-circle-detail__content__main">
            {circleDetailData.topics && circleDetailData.topics.length > 0 && (
              <div className="lenovo-circle-detail__content__main__buzz">
                <div className="content__main__buzz-header">
                  <p className="content__main__buzz-header-title">圈内热议</p>
                  <div className="content__main__buzz-header-btn-list">
                    <p
                      className={`content__main__buzz-header-group-btn ${
                        circleDetailData.joined && !circleDetailData.checking
                          ? ""
                          : "dis-group-btn"
                      }`}
                      onClick={() => {
                        if (
                          circleDetailData.joined &&
                          !circleDetailData.checking
                        ) {
                          revertDialogProps();
                          setDialogProp("messageType", "applyGroup");
                          setVisibleState("activityMessage", true);
                        }
                      }}
                    >
                      {(!circleDetailData.joined ||
                        circleDetailData.checking) && (
                        <Icon name="circle-small-lock" size={12} />
                      )}
                      {circleDetailData.joined &&
                        !circleDetailData.checking && (
                          <Icon name="circle-apply-group" size={14} />
                        )}
                      申请发起圈子群聊
                    </p>
                    {!isMobile() && (
                      <p
                        className={`content__main__buzz-header-btn ${
                          btnState === "notJoin"
                            ? "dis-btn"
                            : btnState === "join" &&
                              circleDetailData.pendingGroup
                            ? "aduit-btn"
                            : ""
                        }`}
                        onClick={joinGroup}
                      >
                        {(!circleDetailData.joined ||
                          circleDetailData.checking) && (
                          <Icon name="circle-small-lock" size={12} />
                        )}
                        {btnState === "notJoin"
                          ? "加入群聊"
                          : btnState === "join" && circleDetailData.pendingGroup
                          ? "加入群聊审核中"
                          : btnState === "join" && circleDetailData.passedGroup
                          ? "立即加入群聊"
                          : btnState === "join" &&
                            !circleDetailData.pendingGroup &&
                            !circleDetailData.passedGroup
                          ? "申请加入群聊"
                          : ""}
                      </p>
                    )}
                  </div>
                </div>
                <div className="content__main__buzz-main">
                  {circleDetailData.topics &&
                    circleDetailData.topics.map(
                      (topicItem: any, topicIndex: number) => (
                        <BuzzLine
                          key={topicIndex}
                          isBold
                          isTime="show"
                          buzzItem={{
                            content: topicItem.title,
                            time: topicItem.beginDate,
                          }}
                        />
                      )
                    )}
                  {isMobile() && (
                    <div
                      className={`content__main__buzz-main-btn ${
                        btnState === "notJoin"
                          ? "dis-btn"
                          : btnState === "join" && circleDetailData.pendingGroup
                          ? "aduit-btn"
                          : ""
                      } `}
                      onClick={joinGroup}
                    >
                      {btnState === "notJoin"
                        ? "加入群聊"
                        : btnState === "join" && circleDetailData.pendingGroup
                        ? "加入群聊审核中"
                        : btnState === "join" && circleDetailData.passedGroup
                        ? "立即加入群聊"
                        : btnState === "join" &&
                          !circleDetailData.pendingGroup &&
                          !circleDetailData.passedGroup
                        ? "申请加入群聊"
                        : ""}
                    </div>
                  )}
                </div>
              </div>
            )}
            {circleDetailData.cooperates &&
              circleDetailData.cooperates.length > 0 && (
                <div className="lenovo-circle-detail__content__main__cooperation">
                  <p className="content__main__cooperation-title">圈内合作</p>
                  <div
                    className={`content__main__cooperation-main ${circleDetailData
                      .cooperates.length === 1 &&
                      "content__main__cooperation-main1"}`}
                  >
                    {circleDetailData.cooperates.map(
                      (item: any, index: number) => (
                        <CooperationCard
                          key={index}
                          data={item}
                          type="circle"
                          join={circleDetailData.joined}
                          checking={circleDetailData.checking}
                        />
                      )
                    )}
                  </div>
                </div>
              )}
            {isMobile() && (
              <div className="lenovo-circle-detail__content__main__person">
                <p className="content__main__person-title">圈子成员</p>
                {circleDetailData.joined &&
                  !circleDetailData.checking &&
                  (circleDetailData.users.length > 0 ? (
                    <div className="content__main__person-main">
                      {circleDetailData.users &&
                        circleDetailData.users.map(
                          (item: any, index: number) => (
                            <div
                              key={index}
                              className="content__main__person-main-item"
                            >
                              <Icon name="cooperation-card-user" size={16} />
                              <div className="item-person-info">
                                <p className="info-name">{item.fullName}</p>
                                <p className="info-department">
                                  {item.company}
                                  <span>{item.position}</span>
                                </p>
                              </div>
                              {!item.creator && (
                                <div
                                  className={`item-person-btn ${
                                    item.combine ? "dis-btn" : ""
                                  }`}
                                  onClick={() => {
                                    if (!item.combine && !item.creator) {
                                      isAuthUser &&
                                        setCooperateInfo({
                                          isCom: true,
                                          type: "pub",
                                          params: {
                                            partner_id: item.id,
                                          },
                                        });
                                      isAuthUser &&
                                        history.push("/cooperation/post");
                                    }
                                  }}
                                >
                                  {item.combine ? "等待响应" : "与他合作"}
                                </div>
                              )}
                            </div>
                          )
                        )}
                    </div>
                  ) : (
                    !detailLoading && (
                      <div className="header-person--empty">
                        <Empty title="该圈子暂无人加入，快来加入成为首位成员吧！" />
                      </div>
                    )
                  ))}
                {(!circleDetailData.joined || circleDetailData.checking) && (
                  <div className="content__main__person-main-audit">
                    <Icon name="circle-big-lock" size={20} />
                    <p>加入圈子即可与圈内成员合作</p>
                  </div>
                )}
              </div>
            )}
          </div>
          {isMobile() &&
            (circleDetailData.checking || !circleDetailData.joined) && (
              <div
                className={`lenovo-circle-detail__content__btn ${
                  circleDetailData.checking
                    ? "lenovo-circle-detail__content__audit-btn"
                    : ""
                }`}
                onClick={joinCircle}
              >
                {circleDetailData.checking
                  ? "加入圈子审核中"
                  : `立即加入「${circleDetailData.name} 」圈子 →`}
              </div>
            )}
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(CircleDetail);
