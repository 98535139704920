import React, { useContext } from "react";
import { observer } from "mobx-react";
import SolutionHeader from "./SolutionHeader";
import InstitutionHeader from "./InstitutionHeader";
import BusinessCaseHeader from "./BusinessCaseHeader";
import ExpertHeader from "./ExpertHeader";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { Skeleton } from "antd";

const DetailHeader: React.FC = () => {
  const { data, type } = useContext(databaseDetailStore);

  const Header = {
    institution: <InstitutionHeader />,
    solution: <SolutionHeader />,
    businessCase: <BusinessCaseHeader />,
    expert: <ExpertHeader />,
  };

  return (
    <div className="detail-layout__header">
      {data ? (
        Header[type]
      ) : (
        <Skeleton
          active
          paragraph={{ rows: 3, width: ["25%", "80%", "60%"] }}
        />
      )}
    </div>
  );
};

export default observer(DetailHeader);
