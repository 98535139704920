import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Menu } from "../shared";
import CaseDetailIntro from "./CaseDetailIntro";
import ProjectBackgroundIntro from "./ProjectBackgroundIntro";
import RelatedFilesIntro from "./RelatedFilesIntro";
import databaseDetailStore from "../../stores/databaseDetailStore";

const BusinessCaseTabs = () => {
  const {
    isCaseDetailTabContentEmpty,
    isProjectBackgroundTabContentEmpty,
    isRelatedFileTabContentEmpty,
    businessCaseTabs,
    scrolledDomId,
    scrollTo,
  } = useContext(databaseDetailStore);

  return (
    <div>
      <Menu
        items={businessCaseTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      {!isProjectBackgroundTabContentEmpty && <ProjectBackgroundIntro />}
      {!isCaseDetailTabContentEmpty && <CaseDetailIntro />}
      {!isRelatedFileTabContentEmpty && <RelatedFilesIntro />}
    </div>
  );
};

export default observer(BusinessCaseTabs);
