import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import "./style.scss";

import { isMobile } from "../../utils/tools";
import signIn from "../../utils/sign_in";
import getBrowserInfo from "../../utils/getBrowserInfo";
import shapeLeftImg from "../../assets/images/shape-left@3x.png";
import shapeRightImg from "../../assets/images/shape-right@3x.png";
import ActivityDateImg from "../../assets/images/activity-date.png";
import ActivityAddressImg from "../../assets/images/activity-address.png";
import lWxCode from "../../assets/images/l-wx-img.png";
import AuthStore from "../../stores/authStore";
import GlobalStore from "../../stores/globalStore";
// import { postBm } from "../../api/index";
import { useParams } from "react-router-dom";
// import activityDetailStore from "../../stores/activityDetailStore";

interface Props {
  [key: string]: any;
  data: any;
  single?: boolean;
  type?: string;
  setActivity?: any;
}

const ActivityBanner: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    data = {},
    single = false,
    type = "",
    setActivity = () => {},
  } = props;
  const { isLogin } = useContext(AuthStore);
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    GlobalStore
  );
  // const { fetchData } = useContext(activityDetailStore);
  const BrowserInfo = getBrowserInfo();

  const joinFn = async () => {
    revertDialogProps();
    setDialogProp("lenovoWxType", "success");
    setDialogProp("lenovoWxTitle", "活动报名成功");
    setDialogProp("lenovoWxCodeImg", lWxCode);
    setDialogProp("lenovoWxBottomTip", "");
    setDialogProp(
      "lenovoWxBottomDom",
      <p className="lenovo-wx-dialog-tips" style={{ fontSize: "20px" }}>
        <span>扫码接收后续活动通知</span>
      </p>
    );
    setVisibleState("lenovoWx", true);
    // await postBm(data);
    // if (type === "detail") {
    //   fetchData(id);
    // }
  };

  const jumpLink = () => {
    if (data.external) {
      window.open(data.externalUrl, "_blank");
    } else {
      if (type === "detail") {
        if (isMobile()) {
          if (BrowserInfo.type !== "WeixinBrowser") {
            history.push(`/join-group?type=register&qrcode=${data.qrcodeUrl}`);
          } else {
            if (!isLogin) {
              signIn();
            } else if (isLogin && data.enroll) {
              joinFn();
            } else if (isLogin && !data.enroll) {
              setActivity(data);
              history.push(`/sign-up?id=${id}`);
            }
            // else if (isLogin && !isHadUserInfo) {
            //   setActivity(data);
            //   history.push(`/sign-up?id=${id}`);
            // } else if (isLogin && isHadUserInfo) {
            //   // joinFn();
            //   setActivity(data);
            //   history.push(`/sign-up?id=${id}`);
            // }
          }
        } else {
          revertDialogProps();
          setDialogProp("lenovoWxTitle", "请用微信扫码进行报名");
          setDialogProp(
            "lenovoWxContent",
            "在微信中完成后续报名流程，第一时间了解活动信息"
          );
          setDialogProp("lenovoWxCodeId", data.id);
          setDialogProp("lenovoWxBtned", false);
          setDialogProp("lenovoWxIconed", false);
          setDialogProp("lenovoWxBottomTip", "");
          setVisibleState("lenovoWx", true);
        }
      } else {
        history.push(`/activity/${data.id}?state=pending`);
      }
    }
  };

  return (
    <div
      className="lenovo-activity-banner"
      style={{
        paddingTop: single ? "30px" : "30px",
        paddingBottom: single ? "30px" : "40px",
      }}
      onClick={jumpLink}
    >
      {!single && (
        <div className="sub-carousel-item-header">
          <img src={shapeLeftImg} alt="shapeLeftImg" />
          <p>正在进行的活动</p>
          <img src={shapeRightImg} alt="shapeRightImg" />
        </div>
      )}

      <div className="sub-carousel-item-body">
        <div className="item-body__img">
          <img src={data.coverUrl} alt="coverUrl" />
        </div>
        <div className="item-body__info">
          <p className="item-body__info-title">{data.title}</p>
          {data.tags && (
            <div className="item-body__info-tags">
              {data.tags.length > 0 &&
                data.tags.map((tag: any) => (
                  <div key={tag} className="item-body__info-tag">
                    {tag}
                  </div>
                ))}
            </div>
          )}
          <div
            className="item-body__info-line-wrap"
            style={single ? { position: "absolute", bottom: 0 } : {}}
          >
            <p className="item-body__info-line">
              <img
                className="item-body__info-img-1"
                src={ActivityDateImg}
                alt="ActivityDateImg"
              />
              {data.date && data.date.pc}
            </p>
            <p className="item-body__info-line">
              <img
                className="item-body__info-img-2"
                src={ActivityAddressImg}
                alt="ActivityAddressImg"
              />
              {data.address && data.address.join(" / ")}
            </p>
          </div>
          <div
            className={`item-body__info-btn ${data.enroll &&
              `item-body__info-btn--${type === "detail" ? "success" : "sp"}`}`}
          >
            {data.enroll
              ? type === "detail"
                ? "报名成功"
                : "已报名"
              : "立即报名"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ActivityBanner);
