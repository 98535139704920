import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Icon from "../icon/Icon";
import { labelToChinese } from "../../../constants/label_to_chinese";

const Menu = props => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsMenuActive(props.link && location.pathname.indexOf(props.link) > -1);
  }, [location]);

  return (
    <NavLink
      className="lenovo-sidebar__item"
      activeClassName="actived"
      to={props.link}
    >
      <Icon
        name={props.icon}
        size={16}
        color={isMenuActive ? "#c72c21" : "#92929D"}
      />
      <span>{labelToChinese[props.label]}</span>
    </NavLink>
  );
};

export default Menu;
