import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Pagination } from "antd";

import "./style.scss";
import {
  Nav,
  Breadcrumbs,
  Footer,
  Filter,
  CooperationCard,
  Icon,
  CodeEntry,
  Empty,
} from "../shared/index";
import GlobalStore from "../../stores/globalStore";
import CooperationStore from "../../stores/cooperationStore";
import { isMobile, goAuth } from "../../utils/tools";
import authStore from "../../stores/authStore";
import lockedImg from "../../assets/images/filter-locked-icon.svg";
import lockedIcon from "../../assets/images/locked-icon.svg";
import postCooperStore from "../../stores/postCooperStore";

interface Props {
  [key: string]: any;
}

const CooperationSeek: React.FC<Props> = () => {
  const history = useHistory();
  const { isAuthUser, isLogin } = useContext(authStore);
  const { setVisibleState } = useContext(GlobalStore);
  const {
    cooperates,
    cooperationFilter,
    fetchCooperationData,
    fetchCooperationFilterData,
    loading,
    pagination,
    setPageNum,
  } = useContext(CooperationStore);
  const { setCooperateInfo } = useContext(postCooperStore);
  const [params, setParams] = useState({});

  const breadcrumbs = [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: "寻求合作",
      path: `/cooperation/seek`,
    },
  ];

  useEffect(() => {
    isMobile()
      ? fetchCooperationData({}, { currentPage: 1, pageSize: 1000 })
      : fetchCooperationData();
    fetchCooperationFilterData();
  }, []);

  return (
    <div className="lenovo-cooperation-seek">
      <Helmet>
        <title>生态合作 | 联想数字生态平台</title>
      </Helmet>
      <Nav>
        <div className="lenovo-cooperation-seek__content">
          <CodeEntry />
          {!isMobile() && (
            <Breadcrumbs
              items={breadcrumbs}
              separator={<Icon name="breadcrumbs-triangle" size={5} />}
            />
          )}
          <div className="content__list">
            <div className="content__list-header">
              <p className="content__list-header-title">
                {isMobile() ? "合作大厅" : "寻求合作"}
              </p>
              {!isMobile() && (
                <div
                  className={`content__list-header-btn ${!isAuthUser &&
                    "content__list-header-btn--disable"}`}
                  onClick={() => {
                    if (isAuthUser) {
                      setCooperateInfo({
                        isCom: false,
                        type: "pub",
                        params: {},
                      });
                      history.push("/cooperation/post");
                    } else {
                      setVisibleState("toAuth", true);
                    }
                  }}
                >
                  {!isAuthUser && <img src={lockedIcon} alt="lockedIcon" />}
                  寻求合作
                </div>
              )}
            </div>
            {isAuthUser ? (
              <Filter
                layout="horizontal"
                categoryMap={{
                  scenarios: "行业场景",
                  categories: "合作类型",
                }}
                model="multiple"
                data={cooperationFilter}
                onChange={res => {
                  setParams(res);
                  setPageNum(1);
                  fetchCooperationData(res);
                }}
              />
            ) : (
              <div className="filter-locked-panel">
                <img src={lockedImg} alt="lockedImg" />
                <p>成为联想创投数字生态认证用户即可参与生态内合作</p>
                <p
                  onClick={() => {
                    goAuth(history, isLogin);
                  }}
                >
                  去认证→
                </p>
              </div>
            )}

            <div
              className={`content__list-body ${cooperates.length === 0 &&
                "content__list-body--empty"}`}
            >
              {cooperates && cooperates.length > 0
                ? cooperates.map((item: any, index: number) => (
                    <div key={index}>
                      <CooperationCard data={item} />
                    </div>
                  ))
                : !loading && <Empty title="合作需求收集中" />}
            </div>
            {!isMobile() && cooperates && cooperates.length > 0 && (
              <Pagination
                hideOnSinglePage
                showSizeChanger={false}
                total={pagination.totalCount}
                current={pagination.currentPage}
                defaultCurrent={1}
                defaultPageSize={pagination.pageSize}
                onChange={(page: number) => {
                  setPageNum(page);
                  fetchCooperationData(params);
                }}
              />
            )}
          </div>
        </div>
        {isMobile() && (
          <div
            className="lenovo-cooperation-seek__btn"
            onClick={() => {
              if (isAuthUser) {
                setCooperateInfo({
                  isCom: false,
                  type: "pub",
                  params: {},
                });
                history.push("/cooperation/post");
              } else {
                setVisibleState("toAuth", true);
              }
            }}
          >
            <Icon name="Cooperation" size={16} />
            寻求合作
          </div>
        )}
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(CooperationSeek);
