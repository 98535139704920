import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import "./style.scss";

import ActivityDateImg from "../../assets/images/activity-date.png";
import ActivityAddressImg from "../../assets/images/activity-address.png";
import { Input } from "antd";
import { isMobile } from "../../utils/tools";
import { Notify, Empty } from "../shared/index";
import commentSvg from "../../assets/images/video-comment.svg";
import giveSvg from "../../assets/images/video-give.svg";
import givedSvg from "../../assets/images/video-gived.svg";
import globalStore from "../../stores/globalStore";
import activityDetailStore from "../../stores/activityDetailStore";
import authStore from "../../stores/authStore";

interface Props {
  [key: string]: any;
}

const ActivityVideo: React.FC<Props> = () => {
  const { id } = useParams();
  const { isLogin } = useContext(authStore);
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    globalStore
  );
  const { addMessage, detailData, toStar, fetchData } = useContext(
    activityDetailStore
  );
  const [value, setValue] = useState("");
  console.log("detailData", detailData);

  const handle = (style?: any) => (
    <div className="video-content__comment" style={style ? style : {}}>
      <Input.Group compact>
        <Input
          placeholder="留下你对活动的想法"
          bordered={false}
          onClick={() => {
            if (isMobile()) {
              revertDialogProps();
              setDialogProp("messageType", "activity");
              setVisibleState("activityMessage", true);
            }
          }}
          prefix={<img src={commentSvg} width="19px" alt="comment" />}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
        <div
          className="video-content__send"
          onClick={async () => {
            if (value) {
              await addMessage(
                {
                  id,
                  content: value,
                },
                Notify.success({
                  message: "发布留言成功！",
                  description: "您的留言已提交，将在审核通过后进行展示",
                  duration: 3,
                })
              );
            }
            fetchData(id);
          }}
        >
          发送
        </div>
      </Input.Group>
      <div
        className={`video-content__handle ${
          detailData.stared ? "video-content__handle-red" : ""
        }`}
      >
        {detailData.stared ? (
          <img src={givedSvg} width="24px" alt="givedSvg" />
        ) : (
          <img
            src={giveSvg}
            width="24px"
            alt="giveSvg"
            onClick={async () => {
              await toStar({
                id,
              });
              fetchData(id);
            }}
          />
        )}
        <p
          className="video-content__handle-tap"
          onClick={async () => {
            await toStar({
              id,
            });
            fetchData(id);
          }}
        >
          {detailData.stared ? "已赞" : "点赞"}
        </p>
        {detailData.usersCount > 0 && (
          <p className="video-content__handle-count">{detailData.usersCount}</p>
        )}
      </div>
    </div>
  );

  return (
    <div className="activity-detail-video">
      <div className="detail-video-header">
        <p className="video-header__title">{detailData.title}</p>
        <div className="video-header__info">
          <div className="header__info-line">
            <img
              className="header__info-img-1"
              src={ActivityDateImg}
              alt="ActivityDateImg"
            />
            {detailData.date.pc}
          </div>
          <div className="header__info-line">
            <img
              className="header__info-img-2"
              src={ActivityAddressImg}
              alt="ActivityAddressImg"
            />
            {detailData.address.join(" / ")}
          </div>
        </div>
      </div>
      {isLogin && (
        <div
          className={`detail-video-body ${!detailData.review &&
            "detail-video-body--empty"}`}
        >
          {!detailData.review ? (
            <>
              <div className="detail-video--empty">
                <Empty title="回顾视频上传中，请耐心等待" img={1} />
              </div>
              {handle({
                background: "black",
                padding: isMobile()
                  ? "10px 15px"
                  : `18px ${(document.body.clientWidth - 1180) / 2}px`,
              })}
            </>
          ) : (
            <div className="detail-video-content">
              <video src={detailData.review} controls controlsList="nodownload">
                your browser does not support the video tag
              </video>
              {handle()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ActivityVideo);
