// eslint-disable-next-line
import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import "./style.scss";
import { Nav, Footer, Breadcrumbs, CodeEntry, Notify } from "../shared/index";
import Icon from "../shared/icon/Icon";
import { isMobile } from "../../utils/tools";
import { Form, Input } from "antd";
import { Form as MForm, TextArea } from "antd-mobile";
// eslint-disable-next-line
import { useParams, useHistory } from "react-router-dom";
import GlobalStore from "../../stores/globalStore";
import authStore from "../../stores/authStore";
import editImg from "../../assets/images/cooperation-edit.svg";
import lWxCode from "../../assets/images/l-wx-img.png";
import { postCooperateReply } from "../../api";

interface Props {}

const PostscriptCooperation: React.FC<Props> = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useContext(authStore);
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    GlobalStore
  );
  const [isPosted, setIsPosted] = useState(false);
  const [form] = Form.useForm();
  const [mForm] = MForm.useForm();
  const breadcrumbs = [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: "响应合作",
      path: `/cooperation/postscript`,
    },
  ];
  useEffect(() => {
    // initialValues 移动端无法自动初始化 需要set
  }, []);

  const onFinish = async (data: any) => {
    if (isPosted) {
      return false;
    }
    setIsPosted(true);
    try {
      await postCooperateReply({
        id: id,
        content: data.content,
      });
      revertDialogProps();
      setDialogProp("lenovoWxTitle", "响应合作申请审核中");
      setDialogProp(
        "lenovoWxContent",
        "您提交的响应合作申请将在审核通过后告知合作发布者，后续流程请留意服务号通知"
      );
      setDialogProp("lenovoWxCodeImg", lWxCode);
      setDialogProp(
        "lenovoWxBottomTip",
        "长按识别二维码返回服务号，留意后续通知"
      );
      setDialogProp("lenovoWxEvent", () => {
        history.push("/cooperation");
      });
      setVisibleState("lenovoWx", true);
    } catch (error) {
      error.message &&
        Notify.error({
          message: error.message,
          duration: 3,
        });
    }
  };

  return (
    <div className="lenovo-postscript-cooperation">
      <Helmet>
        <title>响应合作 | 联想数字生态平台</title>
      </Helmet>
      <Nav bgc={`${isMobile() ? "#fff" : "#f5f6f7"}`}>
        <div className="lenovo-postscript-cooperation__content">
          <CodeEntry />
          <Breadcrumbs
            items={breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          <div className="content__form">
            <p className="content__form-title">响应合作</p>
            <Form
              colon={false}
              requiredMark={false}
              size="large"
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item
                name="content"
                label="附言"
                rules={[{ required: true, message: "请输入合作细节" }]}
              >
                <Input.TextArea
                  placeholder="请输入合作细节"
                  showCount
                  maxLength={200}
                />
              </Form.Item>
              <div className="content__identity">
                <p className="identity-title">我的身份</p>
                <div className="identity-info">
                  <Icon name="cooperation-card-user" size={16} />
                  <div className="identity-person-info">
                    <p className="info-name">{currentUser.full_name}</p>
                    <p className="info-department">
                      {currentUser.company}
                      <span>
                        {currentUser.department}
                        {currentUser.position_show && currentUser.position
                          ? "-" + currentUser.position
                          : ""}
                      </span>
                    </p>
                  </div>
                  <img
                    src={editImg}
                    alt="editImg"
                    onClick={() => history.push("/profile?type=edit")}
                  />
                </div>
              </div>
              <Form.Item className="form-item--left">
                <div
                  className={`form-submit ${isPosted && "opt-5"}`}
                  onClick={() => form.submit()}
                >
                  提交合作申请
                </div>
              </Form.Item>
            </Form>
          </div>
          <div className="content__form--mobile">
            <p className="title">寻求合作</p>
            <MForm
              layout="horizontal"
              onFinish={onFinish}
              form={mForm}
              autoComplete="off"
            >
              <MForm.Item
                name="content"
                label="附言："
                rules={[{ required: true, message: "请输入附言" }]}
              >
                <TextArea placeholder="请输入附言" showCount maxLength={200} />
              </MForm.Item>
            </MForm>
            <div className="content__identity">
              <p className="identity-title">我的身份</p>
              <div className="identity-info">
                <Icon name="cooperation-card-user" size={16} />
                <div className="identity-person-info">
                  <p className="info-name">{currentUser.full_name}</p>
                  <p className="info-department">
                    {currentUser.company}
                    <span>
                      {currentUser.department}
                      {currentUser.position_show && currentUser.position
                        ? "-" + currentUser.position
                        : ""}
                    </span>
                  </p>
                </div>
                <img
                  src={editImg}
                  alt="editImg"
                  onClick={() => history.push("/profile?type=edit")}
                />
              </div>
            </div>
            <div
              className={`form-submit ${isPosted && "opt-5"}`}
              onClick={() => mForm.submit()}
            >
              提交合作申请
            </div>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(PostscriptCooperation);
