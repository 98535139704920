import React, { useContext } from "react";
import { IconHeading } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { CASE_DETAIL } from "../../constants/pro_constants";

const CaseDetailIntro = () => {
  const {
    data: {
      tabs: { caseDetailIntro },
    },
  } = useContext(databaseDetailStore);

  return (
    <div
      className="database-detail__intro database-detail__block"
      id={CASE_DETAIL}
    >
      <IconHeading icon="case-detail" title="案例详解" />
      <div
        className="database-detail__block__content"
        dangerouslySetInnerHTML={{ __html: `${caseDetailIntro}` }}
      />
    </div>
  );
};

export default observer(CaseDetailIntro);
