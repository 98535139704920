import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";

import "./style.scss";
import { Nav, Footer, Icon, Breadcrumbs, CodeEntry } from "../shared/index";
import ActivityBanner from "../activity/ActivityBanner";
import ActivityVideo from "./ActivityVideo";
import ActivityMain from "./ActivityMain";
import ActivityAside from "./ActivityAside";
import ActivityBoard from "./ActivityBoard";
import { getQuery, isMobile } from "../../utils/tools";
import signIn from "../../utils/sign_in";
import getBrowserInfo from "../../utils/getBrowserInfo";
import authStore from "../../stores/authStore";
import activityDetailStore from "../../stores/activityDetailStore";
import ActivityStore from "../../stores/activityStore";

interface Props {
  [key: string]: any;
}

const ActivityDetail: React.FC<Props> = () => {
  const history = useHistory();
  const state: "pending" | "fulfilled" = getQuery("state");
  const { id } = useParams();
  const { isVisitor } = useContext(authStore);
  const { fetchData, detailData } = useContext(activityDetailStore);
  console.log("state:", state);
  const { setActivity } = useContext(ActivityStore);
  const BrowserInfo = getBrowserInfo();

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <div className="lenovo-activity-detail">
      <Helmet>
        <title>{`${detailData.title} | 联想数字生态平台`}</title>
      </Helmet>
      <Nav>
        <div className="lenovo-activity-detail__content">
          <CodeEntry />
          <Breadcrumbs
            items={detailData.breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          {state === "pending" && (
            <div className="detail__content-banner">
              <ActivityBanner
                setActivity={setActivity}
                data={detailData}
                single
                type="detail"
              />
            </div>
          )}
          {state === "fulfilled" && detailData.id && <ActivityVideo />}
          {/* {isMobile() && <ActivityAside />} */}
          <div
            className={`detail__content-body ${
              state === "fulfilled" && isVisitor
                ? "detail__content-noauth-body"
                : ""
            }`}
          >
            {state === "fulfilled" && isVisitor && (
              <div className="noauth-body__main">
                <Icon name="circle-big-lock" size={30} color="#8d98bc" />
                <p>活动回顾内容以及精选留言仅登录用户查看</p>
                <div
                  onClick={() => {
                    if (isMobile() && BrowserInfo.type !== "WeixinBrowser") {
                      history.push("/join-group?type=login");
                    } else {
                      console.log("去登录");
                      signIn();
                    }
                  }}
                >
                  去登录
                </div>
              </div>
            )}
            {state === "pending" && <ActivityMain />}
            {state === "fulfilled" &&
              detailData.id &&
              detailData.eventMessages &&
              !isVisitor && <ActivityBoard />}
            {/* {!isMobile() && <ActivityAside />} */}
            {!(state === "fulfilled" && isVisitor) && <ActivityAside />}
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(ActivityDetail);
