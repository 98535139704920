import React, { useState, useEffect } from "react";

import "./style.scss";
import Icon from "../icon/Icon";

interface Props {
  visible: boolean;
  style?: React.CSSProperties;
  layerStyle?: React.CSSProperties;
  closeStyle?: React.CSSProperties;
  children?: React.ReactNode;
  onCancel: () => void;
  keepAlive?: boolean;
  isRed?: boolean;
  hideBg?: boolean;
}

const Dialog: React.FC<Props> = (props: Props) => {
  const {
    visible,
    style,
    layerStyle,
    closeStyle,
    onCancel = () => {},
    children,
    keepAlive = false,
    isRed = true,
    hideBg = false,
  } = props;
  const [show, setShow] = useState(visible);

  useEffect(() => {
    setShow(visible);
  }, [visible]);

  return keepAlive || visible ? (
    <div className="dialog-root">
      <div
        className={`dialog ${show ? "dialog--show" : ""} ${
          isRed ? "dialog-top-red" : ""
        }`}
        style={style}
      >
        <span
          style={closeStyle}
          className="close-icon"
          onClick={() => {
            onCancel();
          }}
        >
          <Icon name="lenovo-close" color="#92929d" />
        </span>
        {children}
      </div>
      <div
        style={layerStyle}
        className={`dialog-layer ${show ? "dialog-layer--show" : ""}`}
        onClick={() => {
          onCancel();
        }}
      />
      {hideBg && (
        <div
          className={`dialog-hide-bg ${show ? "dialog-hide-bg--show" : ""}`}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default Dialog;
