import React, { useState, useEffect } from "react";
import "./style.scss";

interface Props {
  data: any;
  categoryMap: any;
  model?: "single" | "multiple";
  layout?: "inline" | "horizontal";
  columnGap?: string;
  rowGap?: string;
  onChange?(res: any): void;
}

const Filter: React.FC<Props> = (props: Props) => {
  const {
    data = {},
    categoryMap = {},
    model = "single",
    layout = "inline",
    columnGap = "26px",
    rowGap = "4px",
    onChange = () => {},
  } = props;

  const [localData, setLocalData] = useState(JSON.parse(JSON.stringify(data)));
  const categories = Object.keys(localData);

  useEffect(() => {
    setLocalData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  const getStyle = (isLast: boolean) => {
    const categoryStyle = {};
    if (layout === "inline" && !isLast) {
      Object.assign(categoryStyle, { marginRight: columnGap });
    }
    if (layout === "horizontal" && !isLast) {
      Object.assign(categoryStyle, { marginBottom: rowGap });
    }
    return categoryStyle;
  };

  const getCheckedList = () => {
    const res = {};
    categories.forEach((key: string) => {
      res[key] = localData[key]
        .filter((item: any) => item.checked)
        .map((item: any) => item.value);
    });
    return res;
  };

  const onTagClick = (key: string, value: string, clear?: boolean) => {
    if (model === "single") {
      localData[key].forEach((item: any) => {
        item.checked = false;
        if (item.value === value) {
          item.checked = !item.checked;
        }
      });
    } else {
      localData[key].forEach((item: any) => {
        if (value === localData[key][0].value) {
          if (item.value === localData[key][0].value) {
            item.checked = clear ? true : !item.checked;
          } else {
            item.checked = false;
          }
        } else {
          if (item.value === localData[key][0].value) {
            item.checked = false;
          } else {
            if (item.value === value) {
              item.checked = !item.checked;
            }
          }
        }
      });
    }
    setLocalData({ ...localData });
    const res = getCheckedList();
    console.log(res, "filter");
    onChange(res);
  };

  return (
    <div
      className={`lenovo-filter ${
        layout === "inline"
          ? "lenovo-filter--inline"
          : "lenovo-filter--horizontal"
      }`}
    >
      {categories.map(
        (key: string, index: number) =>
          localData[key].length > 1 && (
            <div
              className="lenovo-filter__categories"
              style={getStyle(index === categories.length - 1)}
              key={key}
            >
              <p className="lenovo-filter__category">{categoryMap[key]}：</p>
              <div className="lenovo-filter__tags">
                {localData[key].map((tag: any, tagIndex: number) => (
                  <p
                    className={`lenovo-filter__tag ${tag.checked &&
                      "lenovo-filter__tag--checked"} ${tagIndex ===
                      localData[key].length - 1 && "lenovo-filter__tag--last"}`}
                    key={tag.value}
                    onClick={() => onTagClick(key, tag.value)}
                  >
                    {tag.label}
                  </p>
                ))}
                {model === "multiple" &&
                  localData[key].slice(1).some((item: any) => item.checked) && (
                    <p
                      className="lenovo-filter__clear"
                      onClick={() =>
                        onTagClick(key, localData[key][0].value, true)
                      }
                    >
                      清除全部
                    </p>
                  )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default Filter;
