import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="16.626%"
          y1="16.647%"
          x2="63.395%"
          y2="64.589%"
          id="7jas7d21ea"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="5vl1cel5xd">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="26.912%"
          x2="80.248%"
          y2="70.113%"
          id="uyhhmy6fbe"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-49.6%"
          width="199.2%"
          height="199.2%"
          filterUnits="objectBoundingBox"
          id="89u3t8m9nc"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-2.7%"
          y="-3%"
          width="105.3%"
          height="106.1%"
          filterUnits="objectBoundingBox"
          id="6kd9p0l7tg"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="9zjlxjqxhb" cx="32.941" cy="7.059" r="7.059" />
        <path
          d="m9.15 39.915-7.625-7.593C.508 31.32 0 30.107 0 28.677c0-1.43.508-2.644 1.525-3.647 1.016-1 2.251-1.5 3.704-1.5 1.453 0 2.686.5 3.705 1.5l.478.43.48-.43c1.016-1 2.251-1.5 3.702-1.5 1.454 0 2.688.5 3.705 1.5 1.016 1.003 1.525 2.216 1.525 3.647 0 1.43-.509 2.644-1.525 3.646l-7.626 7.592a.67.67 0 0 1-.261.085.67.67 0 0 1-.262-.085z"
          id="0cmzv8s2kf"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#7jas7d21ea)" xlinkHref="#9zjlxjqxhb" />
          <use fill="#000" filter="url(#89u3t8m9nc)" xlinkHref="#9zjlxjqxhb" />
        </g>
        <g transform="translate(2.353)">
          <circle fill="url(#5vl1cel5xd)" cx="17.647" cy="17.647" r="17.647" />
          <path
            d="M29.373 13.101c-.485-1.124-2.206-1.74-4.617-1.824-1.687-1.867-4.095-3.042-6.778-3.042-4.5 0-8.241 3.284-9.059 7.627-2.443 1.63-3.346 3.385-2.945 4.756.354 1.21 1.895 1.887 4.18 2.008 1.63 2.659 4.521 4.433 7.824 4.433 5.096 0 9.23-4.214 9.23-9.412 0-.169-.016-.334-.025-.503 1.582-1.307 2.425-2.699 2.19-4.043zM17.978 9.897c3.776 0 6.912 2.814 7.51 6.488-.628.436-1.4.9-2.338 1.38a2.4 2.4 0 0 0-1.658-.672c-1.306 0-2.364 1.049-2.43 2.364-.266.088-.53.178-.806.26-3.022.921-5.402 1.242-7.15 1.256a7.804 7.804 0 0 1-.747-3.329c0-4.27 3.416-7.747 7.619-7.747zm4.346 9.687a.831.831 0 0 1-1.66 0 .83.83 0 0 1 1.66 0zm-14.807.554c-.109-.377.186-1.161 1.252-2.1.04.99.229 1.94.545 2.825-1.12-.163-1.726-.475-1.797-.725zm10.46 5.26c-2.35 0-4.455-1.091-5.854-2.798 1.922-.129 4.172-.553 6.59-1.29.276-.084.543-.171.813-.261a2.42 2.42 0 0 0 1.966 1.026c1.35 0 2.441-1.114 2.441-2.491 0-.12-.02-.233-.035-.349.591-.303 1.15-.615 1.663-.936-.325 3.97-3.6 7.098-7.583 7.098zm8.917-10.146a9.388 9.388 0 0 0-.864-2.198c1.333.205 1.821.601 1.873.722.028.194-.24.737-1.009 1.476z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
        <g>
          <use
            fillOpacity=".2"
            fill="url(#uyhhmy6fbe)"
            xlinkHref="#0cmzv8s2kf"
          />
          <use fill="#000" filter="url(#6kd9p0l7tg)" xlinkHref="#0cmzv8s2kf" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
