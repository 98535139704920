import moment from "moment";
import { isMobile } from "../utils/tools";

const formatDetailData = (initData: any) => ({
  title: initData.title,
  summary: initData.summary || initData.content,
  scenarios: initData.scenarios,
  categories: initData.categories,
  address: initData.address ? initData.address.join(",") : "",
  effective_date: initData.effectiveDate
    ? isMobile()
      ? new Date(initData.effectiveDate)
      : moment(initData.effectiveDate)
    : isMobile()
    ? new Date()
    : moment(new Date()),
  circle_ids: initData.circles
    ? initData.public
      ? [...initData.circles.map((item: any) => item.id), "发布到大厅"]
      : initData.circles.map((item: any) => item.id)
    : [],
});

export { formatDetailData };
