import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 16 16" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>document_1</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="document_1">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <path
          d="M2.5,0.5 C2.3125,0.5 2.1125,0.575 1.975,0.725 C1.8375,0.875 1.75,1.0625 1.75,1.25 L1.75,14.75 C1.75,14.9375 1.825,15.1375 1.975,15.275 C2.125,15.425 2.3125,15.5 2.5,15.5 L13.5,15.5 C13.6875,15.5 13.8875,15.425 14.025,15.275 C14.175,15.125 14.25,14.9375 14.25,14.75 L14.25,4.75 L10,0.5 L2.5,0.5 Z"
          id="Path"
          fill="#8199AF"
        />
        <path
          d="M6.656,10.9672131 L4.256,10.9672131 C4.128,10.9672131 4,10.8688525 4,10.704918 C4,10.6393443 4.032,10.5737705 4.064,10.5409836 L6.176,7.45901639 L4.192,7.45901639 C4.096,7.45901639 4,7.36065574 4,7.2295082 C4,7.13114754 4.096,7.03278689 4.192,7.03278689 L6.592,7.03278689 C6.72,7.03278689 6.816,7.13114754 6.816,7.29508197 C6.816,7.36065574 6.816,7.42622951 6.784,7.45901639 L4.64,10.5409836 L6.656,10.5409836 C6.784,10.5409836 6.88,10.6393443 6.88,10.7377049 C6.88,10.8688525 6.784,10.9672131 6.656,10.9672131 Z M7.936,11 C7.808,11 7.712,10.9016393 7.712,10.7704918 L7.712,7.2295082 C7.712,7.09836066 7.808,7 7.968,7 C8.096,7 8.192,7.09836066 8.192,7.2295082 L8.192,10.7704918 C8.192,10.9016393 8.096,11 7.936,11 Z M10.784,9.39344262 L9.728,9.39344262 L9.728,10.7704918 C9.728,10.9016393 9.6,11 9.472,11 C9.344,11 9.248,10.9016393 9.248,10.7704918 L9.248,7.39344262 C9.248,7.19672131 9.376,7.03278689 9.568,7.03278689 L10.784,7.03278689 C11.552,7.03278689 12,7.59016393 12,8.21311475 C12,8.83606557 11.552,9.39344262 10.784,9.39344262 Z M10.72,7.45901639 L9.728,7.45901639 L9.728,8.96721311 L10.72,8.96721311 C11.168,8.96721311 11.488,8.67213115 11.488,8.21311475 C11.488,7.78688525 11.168,7.45901639 10.72,7.45901639 L10.72,7.45901639 Z"
          id="Shape"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M14.25,4.75 L10.75,4.75 C10.5625,4.75 10.3625,4.675 10.225,4.525 C10.075,4.3875 10,4.1875 10,4 L10,0.5 L14.25,4.75 Z"
          id="Path"
          fill="#C0CCD7"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
