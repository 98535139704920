import React, { useContext } from "react";
import { observer } from "mobx-react";
import InstitutionResultPanel from "./InstitutionResultPanel";
import SolutionResultPanel from "./SolutionResultPanel";
import databaseStore from "../../stores/databaseStore";
import { EXPERT, INSTITUTION, SOLUTION } from "../../constants/pro_constants";
import ExpertResultPanel from "./ExpertResultPanel";

const ResultListPanel = () => {
  const { type } = useContext(databaseStore);
  return (
    <>
      {type === INSTITUTION && <InstitutionResultPanel />}
      {type === SOLUTION && <SolutionResultPanel />}
      {type === EXPERT && <ExpertResultPanel />}
    </>
  );
};

export default observer(ResultListPanel);
