import React from "react";

const SVG = ({
  fill = "#D8110A",
  width = "100%",
  className = "",
  viewBox = "0 0 16 17",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M14.262 12.234c.218.215.294.532.196.819l-.812 2.4c-.11.327-.421.547-.77.547h-9.75c-.35 0-.661-.22-.772-.547l-.812-2.4c-.098-.287-.021-.604.196-.819C1.848 12.126 4.454 9.6 8 9.6c3.546 0 6.153 2.526 6.262 2.634zM12.29 14.4l.456-1.35C12 12.45 10.183 11.2 8 11.2c-2.196 0-4.003 1.246-4.747 1.848L3.71 14.4h8.58zM8 1.6C6.656 1.6 5.563 2.677 5.563 4S6.656 6.4 8 6.4c1.344 0 2.438-1.077 2.438-2.4S9.344 1.6 8 1.6zM8 8C5.76 8 3.938 6.206 3.938 4S5.76 0 8 0s4.063 1.794 4.063 4S10.24 8 8 8z"
                  transform="translate(-250 -1241) translate(0 180) translate(220 360) translate(30 697) translate(0 5)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
