import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 84 67" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        id="ntv853jcba"
        x1="22.453%"
        x2="100%"
        y1="22.865%"
        y2="84.586%"
      >
        <stop offset="0%" stopColor="#FFAF84" />
        <stop offset="100%" stopColor="#FF6D30" />
      </linearGradient>
      <linearGradient
        id="rfcb5s7qyb"
        x1="22.453%"
        x2="100%"
        y1="23.195%"
        y2="84.167%"
      >
        <stop offset="0%" stopColor="#FFAF84" />
        <stop offset="100%" stopColor="#FF6D30" />
      </linearGradient>
      <linearGradient
        id="53batdwqxc"
        x1="29.905%"
        x2="86.473%"
        y1="21.72%"
        y2="86.046%"
      >
        <stop offset="0%" stopColor="#FFAF84" />
        <stop offset="100%" stopColor="#FF6D30" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#E4F7FF"
                fillRule="nonzero"
                d="M.05 57.639c-.03 4.715 17.953 8.543 40.169 8.55 22.215.006 40.25-3.81 40.282-8.526v-.025c.031-4.715-17.952-8.543-40.168-8.55C18.117 49.081.083 52.898.05 57.614v.025z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#8EC9FF"
                fillRule="nonzero"
                d="M40.858 19.162L12.815 26.158 13.654 42.092 40.858 34.972 68.064 42.092 68.064 26.158z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#C9E5FF"
                fillRule="nonzero"
                d="M63.291 20.668c0-2.717-2.248-2.744-3.008-1.421-3.317 5.774-5.89 12.084-7.72 18.928-2.248 8.414-6.104 13.476-11.569 15.189-11.374-1.766-19.686-4.644-24.937-8.634 6.739-2.697 8.175-5.959 9.866-10.216 1.69-4.256 1.817-17.735 9.619-23.835 4.684-3.663 14.538-.227 25.86 3.405 1.084.348 7.25.825 1.89 6.584z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#98CDFF"
                fillRule="nonzero"
                d="M65.902 19.22c-.181 1.48-.33 2.445-.444 2.896-.215.847-.795 1.309-1.386 1.813-1.814 1.547-4.58 2.64-7.353 2.346.368-.799.788-1.768 1.71-3.614.413-.829.73-2.155 1.4-3.47.893.558 1.316.265 2.945.265 1.086 0 2.129-.079 3.128-.236z"
                opacity=".44"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#98CDFF"
                fillRule="nonzero"
                d="M57.79 24.031c1.277-3.67 2.08-5.888 2.41-6.653.736-1.71 1.734-2.443 3.43-2.443 1.694 0 3.875 3.193 1.679 7.624-.463-1.158-2.61-2.22-4.298-1.798-1.124.28-2.198 1.37-3.222 3.27h.001zm-3.22-4.643c-1.508 2.22-2.262 4.16-2.262 5.818-1.704-.56-3.702-1.107-5.996-1.64.05-.779.224-1.676.52-2.689.297-1.013.744-2.001 1.341-2.962l6.397 1.472zm-10.527-2.386l-7.667-1.485c-.428-.083-.842.197-.926.625l-.023.119c-.04.206.003.42.12.594.118.174.3.294.506.334l7.666 1.486c.206.04.42-.004.593-.121.174-.117.294-.299.334-.505l.023-.118c.04-.206-.003-.42-.12-.594-.118-.174-.3-.294-.505-.334h-.001v-.001zm-.854 2.554l-7.666-1.486c-.428-.083-.843.197-.926.625l-.024.12c-.04.206.004.419.12.593.118.174.3.294.506.334l7.667 1.486c.428.083.843-.197.926-.625l.024-.12c.04-.205-.003-.419-.12-.593-.118-.174-.3-.294-.506-.334z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M51.74 29.008l-19.282-3.736c-.253-.048-.511.06-.653.275-.143.215-.142.495.002.71.094.14.24.236.407.269l19.28 3.736c.254.048.511-.06.654-.275.142-.215.142-.494-.001-.709-.095-.14-.242-.237-.408-.269v-.001zm-1.393 3.338L31.485 28.69c-.254-.049-.512.06-.654.275-.143.215-.142.495.002.71.094.14.241.236.407.269l18.862 3.655c.253.049.51-.06.653-.274.143-.215.142-.495-.001-.71-.095-.14-.242-.237-.408-.269zm-5.282 2.58l-14.67-2.843c-.254-.049-.512.06-.654.274-.143.215-.142.495 0 .709.096.14.242.237.409.27l14.67 2.842c.253.049.511-.06.654-.274.142-.215.142-.495-.002-.71-.094-.14-.241-.236-.407-.268zm-4.313 3.687l-11.737-2.275c-.338-.048-.654.176-.72.51-.065.335.144.662.475.744l11.738 2.275c.253.048.51-.06.653-.275.143-.215.142-.495-.002-.71-.094-.14-.24-.236-.407-.269zm5.562 4.786l-18.862-3.655c-.253-.049-.51.06-.652.275-.143.214-.142.494.001.708.095.14.24.238.407.27l18.862 3.655c.253.048.51-.06.653-.275.142-.215.141-.494-.002-.708-.094-.14-.24-.237-.407-.27z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="url(#ntv853jcba)"
                d="M18.471 4.005c.22 0 .431.087.587.243.156.155.243.366.243.586v4.92c0 .46-.371.83-.83.83-.458 0-.83-.37-.83-.83v-4.92c0-.22.087-.43.243-.586.156-.156.367-.243.587-.243zm-8.628 8.418h4.921c.45.011.81.38.81.83 0 .45-.36.819-.81.83h-4.92c-.46 0-.831-.372-.831-.83 0-.459.372-.83.83-.83zm1.868-4.535c-.298-.33-.285-.837.029-1.152.314-.316.82-.33 1.152-.034l3.469 3.498c.327.327.327.858 0 1.186-.328.327-.859.327-1.186 0l-3.463-3.498z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#3D96FE"
                fillRule="nonzero"
                d="M13.802 55.555c-.973-.241-1.703-1.205-1.703-2.169l.717-27.228 27.265 7.228v28.915L13.8 55.555z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#E4F7FF"
                fillRule="nonzero"
                d="M33.174 47.869l-22.904-6.03c-1.212-.362-1.938-1.569-1.575-2.775l4.12-12.906 27.266 7.237-4.241 13.026c-.363 1.086-1.576 1.69-2.666 1.448"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#1E7EEE"
                fillRule="nonzero"
                d="M40.081 62.302l26.28-6.747c.973-.241 1.703-1.205 1.703-2.169V26.158L40.08 33.386v28.915z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="#E4F7FF"
                fillRule="nonzero"
                d="M69.892 41.839c1.212-.362 1.94-1.569 1.576-2.775l-3.404-12.906-27.983 7.237 3.878 12.907c.364 1.206 1.576 1.809 2.787 1.568l23.147-6.031z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="url(#rfcb5s7qyb)"
                d="M1.388 42.538l-.78-.779c-.182-.183-.225-.415-.036-.605.19-.19.422-.146.606.036l.778.78.805-.806c.19-.19.47-.194.653-.011.183.183.179.462-.011.652l-.806.806.687.687c.184.182.226.415.037.604-.19.19-.422.147-.605-.036l-.687-.687-.711.711c-.19.19-.47.194-.652.01-.183-.182-.179-.462.011-.651l.71-.71z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
              <path
                fill="url(#53batdwqxc)"
                d="M75.497 10.69l-.72-.758c-.24-.252-.24-.59 0-.842.114-.122.273-.19.44-.19.167 0 .326.068.44.19l.72.758.72-.758c.114-.122.274-.19.44-.19.167 0 .326.068.44.19.24.253.24.59 0 .842l-.72.759.72.758c.24.253.24.59 0 .843-.16.084-.24.168-.4.168-.16 0-.32-.084-.4-.168l-.72-.759-.72.759c-.08.084-.24.168-.4.168-.16 0-.32-.084-.4-.168-.24-.253-.24-.59 0-.843l.56-.758zM49.396 3.888c-1.09 0-1.944-.873-1.944-1.904 0-1.11.855-1.983 1.944-1.983 1.088 0 1.943.873 1.943 1.903 0 1.11-.855 1.984-1.943 1.984zm0-2.721c-.467 0-.778.311-.778.777 0 .467.311.777.778.777.466 0 .776-.31.776-.777 0-.466-.31-.777-.776-.777zm32.278 41.458c.579 0 1.133-.228 1.543-.636.409-.409.64-.963.64-1.541-.001-.578-.231-1.133-.64-1.54-.41-.409-.965-.638-1.543-.637-.578 0-1.133.228-1.542.637-.409.408-.64.962-.64 1.54 0 .578.231 1.132.64 1.54.41.409.964.638 1.542.637zm0-.949c-.326.001-.64-.128-.87-.358-.232-.23-.362-.543-.363-.87 0-.326.13-.639.362-.87.231-.23.544-.359.87-.358h.002c.68-.001 1.231.548 1.232 1.228 0 .68-.552 1.23-1.232 1.228h-.001z"
                transform="translate(-1229 -339) translate(1027 222) translate(132 117) translate(70)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
