import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="74.029%"
          y1="13.625%"
          x2="17.604%"
          y2="82.489%"
          id="8jhfh79c2a"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#D0D9F2" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="3qgn8gpinb">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="c7maox82oc"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#DFE2FF" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="fr32av5cae"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.737254902 0 0 0 0 0.760784314 0 0 0 0 0.850980392 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="rt8q92oyyd" cx="15.588" cy="15.588" r="4.412" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#8jhfh79c2a)" cx="3.529" cy="3.529" r="3.529" />
        <g transform="translate(1.176)">
          <circle fill="url(#3qgn8gpinb)" cx="8.824" cy="8.824" r="8.824" />
          <path
            d="m12.352 6.341-.628-.019c-.4-.012-1.585-.144-2.249-.601-1.317-.908-3.13-.09-3.207-.055l-.386.178v8.274h.906v-3.79c.462-.13 1.527-.223 1.973.083 1.112.767 2.854.824 2.928.826l.664.018V6.341z"
            fill="#FFF"
          />
        </g>
        <use fillOpacity=".2" fill="url(#c7maox82oc)" xlinkHref="#rt8q92oyyd" />
        <use fill="#000" filter="url(#fr32av5cae)" xlinkHref="#rt8q92oyyd" />
      </g>
    </>
  </svg>
);

export default SVG;
