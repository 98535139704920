import React from "react";

const SVG = ({
  fill = "#c72c21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Data_detail_iocn_2</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Polaris-icon/Major/2-Tone/Column-Image-With-Text">
        <path
          d="M6.4,0.8 L6.4,5.6 L0.8,5.6 L0.8,0.8 L6.4,0.8 Z M15.2,0.8 L15.2,5.6 L9.6,5.6 L9.6,0.8 L15.2,0.8 Z"
          id="Fill"
          fill="#FFFFFF"
        />
        <path
          d="M15.2,0 L9.6,0 C9.1576,0 8.8,0.3584 8.8,0.8 L8.8,5.6 C8.8,6.0416 9.1576,6.4 9.6,6.4 L15.2,6.4 C15.6424,6.4 16,6.0416 16,5.6 L16,0.8 C16,0.3584 15.6424,0 15.2,0 L15.2,0 Z M6.4,0 L0.8,0 C0.3576,0 0,0.3584 0,0.8 L0,5.6 C0,6.0416 0.3576,6.4 0.8,6.4 L6.4,6.4 C6.8424,6.4 7.2,6.0416 7.2,5.6 L7.2,0.8 C7.2,0.3584 6.8424,0 6.4,0 L6.4,0 Z M10.4,4.8 L14.4,4.8 L14.4,1.6 L10.4,1.6 L10.4,4.8 Z M1.6,4.8 L5.6,4.8 L5.6,1.6 L1.6,1.6 L1.6,4.8 Z M6.4,8 L0.8,8 C0.3576,8 0,8.3584 0,8.8 C0,9.2416 0.3576,9.6 0.8,9.6 L6.4,9.6 C6.8424,9.6 7.2,9.2416 7.2,8.8 C7.2,8.3584 6.8424,8 6.4,8 M6.4,11.2 L0.8,11.2 C0.3576,11.2 0,11.5584 0,12 C0,12.4416 0.3576,12.8 0.8,12.8 L6.4,12.8 C6.8424,12.8 7.2,12.4416 7.2,12 C7.2,11.5584 6.8424,11.2 6.4,11.2 M2.4,14.4 L0.8,14.4 C0.3576,14.4 0,14.7584 0,15.2 C0,15.6416 0.3576,16 0.8,16 L2.4,16 C2.8424,16 3.2,15.6416 3.2,15.2 C3.2,14.7584 2.8424,14.4 2.4,14.4 M15.2,8 L9.6,8 C9.1576,8 8.8,8.3584 8.8,8.8 C8.8,9.2416 9.1576,9.6 9.6,9.6 L15.2,9.6 C15.6424,9.6 16,9.2416 16,8.8 C16,8.3584 15.6424,8 15.2,8 M15.2,11.2 L9.6,11.2 C9.1576,11.2 8.8,11.5584 8.8,12 C8.8,12.4416 9.1576,12.8 9.6,12.8 L15.2,12.8 C15.6424,12.8 16,12.4416 16,12 C16,11.5584 15.6424,11.2 15.2,11.2 M11.2,14.4 L9.6,14.4 C9.1576,14.4 8.8,14.7584 8.8,15.2 C8.8,15.6416 9.1576,16 9.6,16 L11.2,16 C11.6424,16 12,15.6416 12,15.2 C12,14.7584 11.6424,14.4 11.2,14.4"
          id="Mask"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
