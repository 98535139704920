import React from "react";

const SVG = ({
  fill = "#92929d",
  width = "100%",
  className = "",
  viewBox = "0 0 18 19",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <filter
        id="prefix__a"
        width="100.1%"
        height="104%"
        x="0%"
        y="-1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 0.933333333 0 0 0 0 0.933333333 0 0 0 0 0.933333333 0 0 0 1 0"
        />
      </filter>
      <path
        id="prefix__b"
        d="M4 0h1192c2.21 0 4 1.79 4 4v46H0V4c0-2.21 1.79-4 4-4z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0H1440V1000H0z" transform="translate(-240 -36)" />
      <path
        fill="#F4F4F6"
        d="M200 0H1440V1135H200z"
        transform="translate(-240 -36)"
      />
      <g transform="translate(-240 -36) translate(220 20)">
        <rect width="1200" height="457" fill="#FFF" rx="4" />
        <g>
          <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
          <use fill="#FFF" xlinkHref="#prefix__b" />
          <g>
            <path d="M0 0L18 0 18 18 0 18z" transform="translate(20 16)" />
            <path
              fill={fill}
              d="M4.573 4.573c-2.097 2.097-2.097 5.497 0 7.594 2.097 2.097 5.497 2.097 7.594 0 2.097-2.097 2.097-5.497 0-7.594-2.097-2.097-5.497-2.097-7.594 0zm-1.06 8.655c-2.684-2.683-2.684-7.033 0-9.716 2.682-2.683 7.032-2.683 9.715 0 2.452 2.452 2.663 6.297.632 8.989l2.71 2.71c.708.708-.353 1.769-1.06 1.062l-2.678-2.679c-2.7 2.31-6.765 2.188-9.32-.366z"
              transform="translate(20 16)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
