import { INSTITUTION, SOLUTION, EXPERT } from "./pro_constants";

export const sidebarList = [
  {
    icon: "institutions-menu",
    label: INSTITUTION,
    link: "/library/institutions",
    isEnable: true,
  },
  {
    icon: "solution-menu",
    label: SOLUTION,
    link: "/library/solutions",
    isEnable: true,
  },
  {
    icon: "expert-menu",
    label: EXPERT,
    link: "/library/experts",
    isEnable: true,
  },
];
