import { action, observable } from "mobx";
import { createContext } from "react";

import { formatGlobalData } from "../adapter/formatGlobalData";
import { fetchBannersData, fetchExpress } from "../api";

class GlobalStore {
  @observable public globalVar: any = {
    inLibrary: false,
    inList: true,
  };

  @observable public originVisibleTypes: any = {
    activityMessage: false,
    lenovoWx: false,
    toAuth: false,
  };

  @observable public DialogOriginProps: any = {
    lenovoWxType: "",
    lenovoWxTitle: "合作申请已提交",
    lenovoWxSubTitle: "",
    lenovoWxContent: "",
    lenovoWxCodeImg: "",
    lenovoWxCodeId: "",
    lenovoWxBtned: false,
    lenovoWxIconed: true,
    lenovoWxBottomTip: "长按识别二维码，留意服务号通知",
    lenovoWxBtnBack: false,
    lenovoWxHideBg: false,
    messageType: "",
  };

  @observable public visibleTypes: any = JSON.parse(
    JSON.stringify(this.originVisibleTypes)
  );

  @observable public DialogProps: any = JSON.parse(
    JSON.stringify(this.DialogOriginProps)
  );

  @observable public banners: any = [];
  @observable public cooperateExpress: any = [];

  @action
  public setGlobalVar = (key: string, value: boolean) => {
    this.globalVar[key] = value;
  };

  @action
  setVisibleState = (type: string, value: boolean) => {
    this.visibleTypes[type] = value;
  };

  @action
  setDialogProp = (key: string, value: any) => {
    this.DialogProps[key] = value;
  };

  @action
  revertDialogProps = () => {
    this.DialogProps = JSON.parse(JSON.stringify(this.DialogOriginProps));
  };

  @action
  hideAllDialog = () => {
    this.visibleTypes = JSON.parse(JSON.stringify(this.originVisibleTypes));
  };

  @action
  public fetchGlobalBannersData = async () => {
    const result = await fetchBannersData();
    this.banners = formatGlobalData(result.data).banners;
  };

  @action
  getExpress = async () => {
    const res = await fetchExpress({
      currentPage: 1,
      pageSize: 9,
    });
    this.cooperateExpress = res.data.expresses;
  };
}

export default createContext(new GlobalStore());
