import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="16.626%"
          y1="16.647%"
          x2="63.395%"
          y2="64.589%"
          id="4c5uz6la8a"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="q3ul0mtmgd">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="gcj8w9mxqe"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-49.6%"
          width="199.2%"
          height="199.2%"
          filterUnits="objectBoundingBox"
          id="up7inozqec"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-2.8%"
          y="-2.8%"
          width="105.7%"
          height="105.7%"
          filterUnits="objectBoundingBox"
          id="9yuuu4knzg"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="g99mc28n1b" cx="7.059" cy="7.059" r="7.059" />
        <circle id="dght43p32f" cx="31.176" cy="31.176" r="8.824" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#4c5uz6la8a)" xlinkHref="#g99mc28n1b" />
          <use fill="#000" filter="url(#up7inozqec)" xlinkHref="#g99mc28n1b" />
        </g>
        <g transform="translate(2.353)">
          <circle fill="url(#q3ul0mtmgd)" cx="17.647" cy="17.647" r="17.647" />
          <path
            d="M22.588 18.118a4.47 4.47 0 1 1-4.47 4.47v-3.002a1.465 1.465 0 0 1 1.467-1.468h3.003zm-5.412 4.47a4.47 4.47 0 1 1-4.47-4.47h3.003a1.465 1.465 0 0 1 1.467 1.467v3.003zm.942-9.883a4.47 4.47 0 1 1 4.47 4.471h-3.002a1.465 1.465 0 0 1-1.468-1.467v-3.003zm-5.412 4.471a4.47 4.47 0 1 1 4.47-4.47v3.003a1.465 1.465 0 0 1-1.467 1.467h-3.003z"
            fill="#FFF"
            fillRule="nonzero"
            stroke="#FFF"
            strokeWidth=".5"
          />
        </g>
        <g>
          <use
            fillOpacity=".2"
            fill="url(#gcj8w9mxqe)"
            xlinkHref="#dght43p32f"
          />
          <use fill="#000" filter="url(#9yuuu4knzg)" xlinkHref="#dght43p32f" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
