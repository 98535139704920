import { isMobile } from "./tools";

const signIn = (old?: boolean) => {
  const origin = `${location.origin}${location.pathname}${encodeURIComponent(
    `${location.search}${old ? "&old=true" : ""}`
  )}`;
  const href = isMobile()
    ? `/info/auth/wechatservice?origin=${origin}`
    : `/info/auth/wechat?origin=${origin}`;
  console.log("go-wx-href:", href);
  if (isMobile()) {
    console.log(href, "href");
    location.href = href;
  } else {
    (document.querySelector(".sign-in-btn") as any).click();
  }
};

export default signIn;
