import { action, observable } from "mobx";
import { createContext } from "react";

import {
  fetchActivityDetail,
  addActivityMessage,
  starActivity,
} from "../api/index";
import { formatData } from "../adapter/formatActivityDetailData";

class ActivityDetailStore {
  @observable public loading = true;
  @observable public detailData: any = {};

  @action
  public fetchData = async (id: string) => {
    this.loading = true;
    const res = await fetchActivityDetail(id);
    this.detailData = formatData(res.data);
    this.loading = false;
  };

  @action
  public addMessage = async (data: any, cb?: any) => {
    await addActivityMessage(data);
    cb && cb();
  };

  @action
  public toStar = async (data: any, cb?: any) => {
    await starActivity(data);
    cb && cb();
  };
}

export default createContext(new ActivityDetailStore());
