import React from "react";

const SVG = ({
  fill = "#DA0010",
  width = "100%",
  className = "",
  viewBox = "0 0 12 16",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M11.4 6.983H9.6v-2.5C9.6 2.405 7.995.733 6 .733s-3.6 1.672-3.6 3.75v2.5H.6c-.33 0-.6.282-.6.625v7.5c0 .344.27.625.6.625h10.8c.33 0 .6-.28.6-.625v-7.5a.615.615 0 0 0-.6-.625zM6 12.608c-.66 0-1.2-.562-1.2-1.25 0-.687.54-1.25 1.2-1.25.66 0 1.2.563 1.2 1.25 0 .688-.54 1.25-1.2 1.25zm2.4-5.625H3.6v-2.5c0-1.375 1.08-2.5 2.4-2.5 1.32 0 2.4 1.125 2.4 2.5v2.5z"
    />
  </svg>
);

export default SVG;
