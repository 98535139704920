import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";

import "./style.scss";
import { Nav, Footer, Filter, CodeEntry, Empty } from "../shared/index";
import { Carousel, Pagination } from "antd";

import ActivityCard from "./ActivityCard";
import ActivityStore from "../../stores/activityStore";
import globalStore from "../../stores/globalStore";
import { isMobile } from "../../utils/tools";
import _ from "underscore";
import ActivityBanner from "./ActivityBanner";

interface Props {
  [key: string]: any;
}

const Activity: React.FC<Props> = () => {
  const {
    fetchFilter,
    fetchList,
    getActivityNewest,
    setParams,
    setPageNum,
    activityNewest,
    activityFilter,
    activityList,
    pagination,
    loading,
    setActivity,
  } = useContext(ActivityStore);
  const { banners, fetchGlobalBannersData } = useContext(globalStore);

  useEffect(() => {
    fetchGlobalBannersData();
    getActivityNewest();
    fetchFilter();
    fetchList();
  }, []);

  const onScrollEvent = _.debounce(() => {
    const dom = document.querySelector(".content");
    if (
      isMobile() &&
      dom &&
      pagination.hasNextPage &&
      !loading &&
      dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.85
    ) {
      setPageNum(pagination.currentPage + 1);
      fetchList(true);
    }
  }, 500);

  return (
    <div className="lenovo-activity">
      <Helmet>
        <title>生态活动 | 联想数字生态平台</title>
      </Helmet>
      <Nav onScrollCapture={onScrollEvent}>
        <div className="lenovo-activity__content">
          <CodeEntry />
          <Carousel
            autoplay
            infinite
            className={`content__main-banner banner-carousel ${(!activityNewest ||
              !activityNewest.length) &&
              "content__main-banner--empty"}`}
          >
            {banners &&
              banners.map((item: any, index: number) => (
                <a
                  key={index}
                  className="content__carousel-item"
                  href={item.url}
                  target="_blank"
                >
                  <img src={item.coverUrl} alt="ad" />
                </a>
              ))}
          </Carousel>
          <Carousel
            autoplay
            infinite
            autoplaySpeed={5000}
            className={`content__sub-banner`}
          >
            {activityNewest.length > 0 &&
              activityNewest.map((item: any) => (
                <ActivityBanner
                  setActivity={setActivity}
                  key={item.id}
                  data={item}
                />
              ))}
          </Carousel>
          <div className="content__list">
            <p className="content__list-title">全部活动</p>
            <Filter
              categoryMap={{
                status: "状态",
                tags: "类型",
              }}
              layout={isMobile() ? "horizontal" : "inline"}
              data={activityFilter}
              onChange={(res: any) => {
                setParams(res);
                fetchList();
              }}
            />
            {activityList && !!activityList.length ? (
              <div className="lenovo-activity__list">
                {activityList.map((item: any, index: number) => (
                  <ActivityCard data={item} key={index} />
                ))}
              </div>
            ) : (
              !loading && (
                <div
                  style={{
                    width: isMobile() ? "345px" : "100%",
                    height: "189px",
                    background: "white",
                    margin: isMobile() ? "0 auto" : 0,
                    marginTop: isMobile() ? "10px" : "20px",
                  }}
                >
                  <Empty
                    title="暂无活动"
                    subTitle="关注「联想未来 CLUB」了解活动最新消息"
                  />
                </div>
              )
            )}
            {activityList && !!activityList.length && (
              <Pagination
                className="lenovo-activity__pagination"
                hideOnSinglePage
                showSizeChanger={false}
                total={pagination.totalCount}
                current={pagination.currentPage}
                defaultCurrent={1}
                defaultPageSize={8}
                onChange={(page: number) => {
                  setPageNum(page);
                  fetchList();
                }}
              />
            )}
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(Activity);
