import React, { useContext } from "react";

import { IconHeading, FileCard } from "../shared";
import { INSTITUTION_MATERIAL } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";
import ahoy from "ahoy.js";

const InstitutionMateria = () => {
  const {
    type,
    id,
    data: {
      header: { name },
      tabs: { relatedFile },
    },
  } = useContext(databaseDetailStore);

  return (
    <div className="database-detail__block" id={INSTITUTION_MATERIAL}>
      <IconHeading icon="lenovo-material" title="相关材料" />
      <div className="database-detail__block__content">
        <FileCard
          name={relatedFile.name}
          type={relatedFile.type}
          url={relatedFile.url}
          onDownload={() => {
            ahoy.track("download", {
              name: name,
              id: id,
              type: type,
            });
          }}
        />
      </div>
    </div>
  );
};

export default InstitutionMateria;
