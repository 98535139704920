import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Keywords, IconHeading, EmptyImport } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { PRODUCT_SOLUTIONS } from "../../constants/pro_constants";

const ProductSolutionIntro = () => {
  const {
    data: {
      tabs: { productSolutions },
    },
    isProductSolutionsTabContentEmpty,
  } = useContext(databaseDetailStore);

  const hasBusinessCase = item => item.businessCases.length > 0;

  return (
    <div
      className="database-detail__intro database-detail__block"
      id={PRODUCT_SOLUTIONS}
    >
      <IconHeading icon="lenovo-package" title="产品及解决方案" />
      {isProductSolutionsTabContentEmpty ? (
        <div className="database-detail__block__content">
          <EmptyImport />
        </div>
      ) : (
        <div className="database-detail__block__content">
          {productSolutions.map(item => (
            <div className="product-solution__item" key={item.name}>
              <div className="product-solution__title">
                <Link to={`/library/solutions/${item.id}`} target="_blank">
                  {item.name}
                </Link>
              </div>
              <Keywords list={item.fields} />
              {hasBusinessCase(item) && (
                <div className="product-solution__case">
                  <span className="product-solution__case-text">案例</span>
                  {item.businessCases.map((item, i) => {
                    return (
                      <Link
                        key={i}
                        className="product-solution__case-title"
                        to={`/library/business-cases/${item.id}`}
                        target="_blank"
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(ProductSolutionIntro);
