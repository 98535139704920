import dayjs from "dayjs";
import { timeDifference } from "../utils/tools";

const formatFilterData = initData => ({
  ...initData,
  scenarios: [
    {
      label: "全部",
      value: "",
      checked: true,
    },
    ...initData.scenarios.map((item: any) => ({
      label: item,
      value: item,
    })),
  ],
  categories: [
    {
      label: "全部",
      value: "",
      checked: true,
    },
    ...initData.categories.map((item: any) => ({
      label: item,
      value: item,
    })),
  ],
});

const formatCooperationData = (initData: any) => ({
  ...initData,
  cooperates: initData.cooperates
    ? initData.cooperates.map(item => ({
        ...item,
        userPosition: !item.user.positionShow
          ? item.user.company
          : item.user.company && item.user.position
          ? item.user.company + "-" + item.user.position
          : item.user.company + item.user.position,
        deadline: item.effectiveDate
          ? timeDifference(item.effectiveDate)
            ? "长期"
            : `至${dayjs(item.effectiveDate).format("YYYY-MM-DD")}`
          : "",
      }))
    : [],
  expresses: initData.expresses
    ? initData.expresses.map(item => ({
        ...item,
        pubyears: dayjs(item.publishedAt).format("YYYY-MM-DD"),
      }))
    : [],
});

const formatCooperationDetailData = (initData: any) => ({
  ...initData,
  startTime:
    initData.startDate && dayjs(initData.startDate).format("YYYY-MM-DD"),
  deadline:
    initData.effectiveDate &&
    dayjs(initData.effectiveDate).format("YYYY-MM-DD"),
  breadcrumbs: [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: initData.title,
      path: `/cooperation/detail/${initData.id}`,
    },
  ],
});

export { formatFilterData, formatCooperationData, formatCooperationDetailData };
