import React from "react";

const SVG = ({
  fill = "#07C160",
  width = "100%",
  className = "",
  viewBox = "0 0 64 64",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M32 5.333c14.728 0 26.667 11.94 26.667 26.667 0 14.728-11.94 26.667-26.667 26.667-14.728 0-26.667-11.94-26.667-26.667C5.333 17.272 17.273 5.333 32 5.333zm11.794 17.44L28.862 37.705l-7.375-7.376-2.82 2.82 8.316 8.323.134.125a2.666 2.666 0 0 0 3.637-.124l15.873-15.866-2.833-2.834z"
    />
  </svg>
);

export default SVG;
