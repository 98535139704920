import axios from "axios";
import { Notify } from "../components/shared";
import { filterEmptyParam } from "../utils/tools";
import { ROOT_PATH } from "../constants/pro_constants";
// import getBaseName from "../utils/getBaseName";

axios.defaults.headers = {
  "Content-Type": "application/json;charset=utf-8",
};
// const basename = getBaseName();
// https://lenovo.almosthuman.cn
// https://lenovo.jiqizhixin.com
// const PREFIX = basename;
axios.defaults.baseURL = `/info/api/v1/`;

const getRequestDealWithAuth = url => {
  return axios.get(`${url}&?${Math.random()}`).catch(err => {
    if (err.statusCode != 422 && err.statusCode != 403) {
      return;
    }

    Notify.error({
      message: "无权限访问",
      description: "请您重新登录",
      duration: 3,
    });

    window.setTimeout(() => {
      window.location.href = ROOT_PATH;
    }, 1500);
  });
};

const handleRequestWithToken = (url, params, method = "POST") => {
  let csrfToken = document.querySelector("meta[name=csrf-token]").content;

  return axios({
    method: method,
    url: `${url}?${Math.random()}`,
    data: params,
    headers: {
      "X-CSRF-Token": csrfToken,
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
    withCredentials: true,
    json: true,
  }).catch(error => {
    Notify.error({
      message: "系统通知",
      description: error.response.data.message,
      duration: 3,
    });
  });
};

const http = rest => {
  const { method = "GET", params = {} } = rest;
  const _params = ["GET", "get"].includes(method)
    ? filterEmptyParam(params)
    : params;
  return axios({
    ...rest,
    params: _params,
  });
};

export { getRequestDealWithAuth, handleRequestWithToken, http };
