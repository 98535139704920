import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 16 16" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>document_3</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="document_3">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <path
          d="M2.5,0.5 C2.3125,0.5 2.1125,0.575 1.975,0.725 C1.8375,0.875 1.75,1.0625 1.75,1.25 L1.75,14.75 C1.75,14.9375 1.825,15.1375 1.975,15.275 C2.125,15.425 2.3125,15.5 2.5,15.5 L13.5,15.5 C13.6875,15.5 13.8875,15.425 14.025,15.275 C14.175,15.125 14.25,14.9375 14.25,14.75 L14.25,4.75 L10,0.5 L2.5,0.5 Z"
          id="Path"
          fill="#FF5562"
        />
        <path
          d="M11.7375,11.2125 L10.175,8.78750003 C10.125,8.71250003 10.05,8.67500003 9.9625,8.67500003 C9.875,8.67500003 9.8,8.72500003 9.75,8.78750003 L8.9125,10.0875 L7.0375,7.05000003 C6.9875,6.97500003 6.9125,6.93750003 6.825,6.93750003 C6.7375,6.93750003 6.6625,6.98750003 6.6125,7.05000003 L4.0375,11.2125 C3.9875,11.2875 3.9875,11.3875 4.0375,11.4625 C4.0875,11.55 4.1625,11.5875 4.25,11.5875 L11.525,11.5875 C11.6125,11.5875 11.7,11.5375 11.75,11.4625 C11.7875,11.375 11.7875,11.275 11.7375,11.2125 L11.7375,11.2125 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M9.6125,6.62500003 C9.6125,6.84829103 9.73162432,7.05462042 9.92499999,7.16626593 C10.1183757,7.27791143 10.3566243,7.27791143 10.55,7.16626593 C10.7433757,7.05462042 10.8625,6.84829103 10.8625,6.62500003 C10.8625,6.40170903 10.7433757,6.19537963 10.55,6.08373413 C10.3566243,5.97208862 10.1183757,5.97208862 9.92499999,6.08373413 C9.73162432,6.19537963 9.6125,6.40170903 9.6125,6.62500003 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M14.25,4.75 L10.75,4.75 C10.5625,4.75 10.3625,4.675 10.225,4.525 C10.075,4.3875 10,4.1875 10,4 L10,0.5 L14.25,4.75 Z"
          id="Path"
          fill="#FFBBC0"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
