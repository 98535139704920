import React from "react";
import "./style.scss";

// import { useHistory } from "react-router-dom";

interface Props {
  [key: string]: any;
  data: any;
}

const ExpressCard: React.FC<Props> = (props: Props) => {
  // const history = useHistory();
  const { data } = props;
  return (
    <a className="lenovo-express-card" href={data.url} target="_blank">
      <img src={data.coverUrl} alt="coverUrl" />
      <div className="card-body">
        <p className="card-body__title">{data.title}</p>
        <p className="card-body__summary">{data.summary}</p>
        <p className="card-body__date">{data.date}</p>
      </div>
    </a>
  );
};

export default ExpressCard;
