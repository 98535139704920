import React, { useEffect, useContext, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useLocation, useHistory } from "react-router-dom";

import { ROOT_PATH } from "../../constants/pro_constants";
import { Sidebar, Breadcrumbs, SuspendedPanel, Icon } from "../shared";
import DetailHeader from "./DetailHeader";
import DetailTabs from "./DetailTabs";
import "./style.scss";
import DatabaseDetailStore from "../../stores/databaseDetailStore";
import BreadcrumbStore from "../../stores/breadcrumbStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  BUSINESS_CASE,
  EXPERT,
  INSTITUTION,
  SOLUTION,
} from "../../constants/pro_constants";
declare const wx;

interface Props {
  type: "institution" | "solution" | "businessCase" | "expert";
  id?: string;
  DatabaseDetailStore?: {
    initializeData: (arg) => void;
  };
}

const DatabaseDetail: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const currentPath = location.pathname;
  const [styles, setStyles] = useState(null);
  const tabsRef = useRef(null);
  const {
    data,
    initializeType,
    initializeId,
    initializeData,
    likeModel,
    shareInWechat,
    isTabsEmpty,
    likeCount,
    isShowAll,
    isLike,
    cooperationLink,
  } = useContext(DatabaseDetailStore);
  const shareName = data && data.header.name;
  const shareSummary = data && data.header.summary;
  let belongInstitution = { name: "", avatar: "" };
  if (props.type === SOLUTION) {
    belongInstitution = {
      name: data && data.header.producer.name,
      avatar: data && data.header.producer.shareLogoUrl,
    };
  }
  if (props.type === BUSINESS_CASE) {
    belongInstitution = {
      name: data && data.header.solutions[0].producerName,
      avatar: data && data.header.solutions[0].shareLogoUrl,
    };
  }
  if (props.type === EXPERT) {
    belongInstitution = {
      name: data && data.header.institution.name,
      avatar: data && data.header.avatarUrl,
    };
  }

  const { breadcrumbMap, initializeBreadcrumbs } = useContext(BreadcrumbStore);

  useEffect(() => {
    initializeType(props.type);
    initializeId(props.id);
    initializeData();
    window.sessionStorage.setItem(
      "path",
      `/database/${props.type}s/${props.id}`
    );
  }, [props.type, props.id]);

  useEffect(() => {
    if (data) {
      initializeBreadcrumbs(currentPath, data.header.name);
    }
  }, [data]);

  useEffect(() => {
    const headerDom = document.querySelector(".detail-layout__header");
    const bannerDom = document.querySelector(".lenovo__banner__img");
    const navHeight = 60;
    const bodyHeight = document.body.clientHeight;
    const footerDom = document.querySelector(".detail-layout__footer");
    let bannerDomHeight;

    if (bannerDom) {
      new Promise(resolve => {
        bannerDomHeight = bannerDom.getBoundingClientRect().height;
        bannerDom.addEventListener("load", () => {
          bannerDomHeight = bannerDom.getBoundingClientRect().height;
          resolve(0);
        });
        if (bannerDomHeight >= 90) {
          resolve(0);
        }
      }).then(() => {
        const contentHeight =
          bannerDomHeight +
          navHeight +
          (headerDom && headerDom.clientHeight) +
          (footerDom && footerDom.clientHeight) +
          (tabsRef.current && tabsRef.current.clientHeight);
        console.log("bannerDomHeight", bannerDomHeight);
        console.log("headerDom", headerDom && headerDom.clientHeight);
        console.log("footerDom", footerDom && footerDom.clientHeight);
        console.log("tabsRef", tabsRef.current && tabsRef.current.clientHeight);
        console.log("contentHeight", contentHeight);
        console.log("bodyHeight", bodyHeight);
        setStyles(
          bodyHeight - contentHeight <= 100
            ? { bottom: "10px" }
            : { top: `${contentHeight + 30}px`, bottom: "auto" }
        );
      });
    }
  }, [props.id, tabsRef, data]);

  const messageOnShare = {
    [INSTITUTION]: {
      title: `${shareName} | 联想创投成员企业信息库`,
      desc: shareSummary,
      link: "",
      imgUrl: data && data.header.shareLogoUrl,
      success: () => {
        shareInWechat();
      },
    },
    [props.type !== INSTITUTION && props.type]: {
      title: `${shareName} | ${belongInstitution.name}`,
      desc: shareSummary,
      link: "",
      imgUrl: belongInstitution.avatar,
      success: () => {
        shareInWechat();
      },
    },
  };
  wx.ready(() => {
    wx.onMenuShareTimeline(messageOnShare[props.type]);
    wx.onMenuShareAppMessage(messageOnShare[props.type]);
    wx.error(res => {
      console.log("ready.error", res.errMsg);
    });
  });

  return (
    <>
      <div className="detail-layout">
        {data && data.header && data.header.name && (
          <Helmet>
            <title>
              {data.header.name} ｜ {labelToChinese[props.type]}
            </title>
          </Helmet>
        )}
        <div className="lenovo-layout__sidebar-wrapper">
          <Sidebar />
        </div>
        <div className="lenovo-layout__content-wrapper" id="database-detail">
          <div
            className="database-layout__back database-detail-layout__back"
            onClick={() => history.push(ROOT_PATH)}
          >
            <Icon name="lenovo-back" />
            返回数字生态平台
          </div>
          <div className="detail-layout__content">
            <Breadcrumbs items={breadcrumbMap} />
            <DetailHeader />

            <div ref={tabsRef}>
              {data && !isTabsEmpty && (
                <div
                  className={`detail-layout__info ${
                    !isShowAll ? "collecting" : ""
                  }`}
                >
                  <DetailTabs />
                </div>
              )}
            </div>
            <div className="detail-layout__footer">
              <div className="detail-layout__footer--inner" />
            </div>
          </div>
          <SuspendedPanel
            styles={styles}
            isLiked={isLike}
            count={likeCount}
            link={cooperationLink}
            onLike={() => {
              if (!isLike) {
                likeModel(props.id);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default observer(DatabaseDetail);
