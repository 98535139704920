import {
  DATABASE,
  HOME,
  TREND,
  SOLUTION,
  CASE_DETAIL,
  PROJECT_BACKGROUND,
  RELATED_FILE,
  INSTITUTION_INTRO,
  PRODUCT_SOLUTIONS,
  INSTITUTION_MATERIAL,
  INSTITUTION_EXPERTS,
  SOLUTION_DESC,
  SOLUTION_FEATURE,
  SOLUTION_TECHNOLOGY,
  SOLUTION_MATERIAL,
  SOLUTION_BUSINESS_CASE,
  INDUSTRIES,
  DEVELOP_STAGE,
  COMPANY_TYPE,
  REGION,
  TECHNOLOGIES,
  COLLECTIONS,
  INSTITUTION,
  APPLY_FORMATS,
  INSTITUION_BRIEF,
  ROLES,
  FOUNDEDAT,
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
  PRO,
  BUSINESS_CASE,
  CHINA,
  ABROAD,
  KEYWORDS,
  EXPERT,
} from "./pro_constants";

export const labelToChinese = {
  [SOLUTION]: "方案库",
  [INSTITUTION]: "企业库",
  [EXPERT]: "专家库",
  ["library/solution"]: "方案库",
  ["library/institution"]: "企业库",
  ["library/expert"]: "专家库",
  [HOME]: "首页",
  [DATABASE]: "市场数据",
  [TREND]: "行业趋势",
  [CASE_DETAIL]: "案例详解",
  [PROJECT_BACKGROUND]: "项目背景及客户痛点",
  [RELATED_FILE]: "相关材料",
  [INSTITUTION_INTRO]: "企业介绍",
  [PRODUCT_SOLUTIONS]: "产品及解决方案",
  [INSTITUTION_MATERIAL]: "相关材料",
  [INSTITUTION_EXPERTS]: "专家信息",
  [SOLUTION_DESC]: "方案描述",
  [SOLUTION_FEATURE]: "功能简述",
  [SOLUTION_TECHNOLOGY]: "技术架构",
  [SOLUTION_MATERIAL]: "相关材料",
  [SOLUTION_BUSINESS_CASE]: "应用案例",
  [COLLECTIONS]: "热门场景",
  [INDUSTRIES]: "行业",
  [DEVELOP_STAGE]: "发展阶段",
  [COMPANY_TYPE]: "企业类型",
  [REGION]: "所在地区",
  [TECHNOLOGIES]: "热门技术",
  [APPLY_FORMATS]: "产品和解决方案",
  [INSTITUION_BRIEF]: "企业名称",
  [ROLES]: "供应链角色",
  [FOUNDEDAT]: "成立时间",
  [TRIGGER_DESC]: "点击降序排列",
  [TRIGGER_ASC]: "点击升序排列",
  [CACCEL_SORT]: "取消排序",
  [PRO]: "联想创投成员企业信息库",
  [BUSINESS_CASE]: "应用案例",
  [CHINA]: "中国",
  [ABROAD]: "海外",
  [KEYWORDS]: "关键词",
};
