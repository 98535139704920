import React from "react";
import { render } from "react-dom";

import App from "./App";

const application = () => {
  render(<App />, document.getElementById("lenovo"));
};

export default application;
