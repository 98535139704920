import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { labelToChinese } from "../../../constants/label_to_chinese";
import { Icon } from "..";

interface Props {
  menuLabel: string;
  menuIcon: string;
  children: React.ReactNode;
}

const SubMenu: React.FC<Props> = (props: Props) => {
  const [isToggle, setIsToggle] = useState(true);
  const [isMenuActive, setIsMenuActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsMenuActive(location.pathname.indexOf(props.menuLabel) > -1);
  }, [location]);

  return (
    <>
      <div
        onClick={() => setIsToggle(!isToggle)}
        tabIndex={0}
        className={`lenovo-sidebar__sub-menu ${isMenuActive ? "actived" : ""}`}
      >
        <Icon
          name={props.menuIcon}
          size={16}
          color={isMenuActive ? "#c72c21" : "#92929D"}
        />
        <span>{labelToChinese[props.menuLabel]}</span>
        {isToggle ? (
          <Icon name="lenovo-arrow-up" size={16} />
        ) : (
          <Icon name="lenovo-arrow-down" size={16} />
        )}
      </div>
      {isToggle && props.children}
    </>
  );
};

export default SubMenu;
