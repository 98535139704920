export const CHINA = "china";
export const ABROAD = "abroad";

export const PRO = "pro";

export const LOGIN = "login";
export const REGISTER = "register";

export const OTHER_USER = 0;
export const TRIAL_USER = 1;
export const PAID_USER = 2;

export const DATABASE = "database";
export const HOME = "home";
export const TREND = "trend";
export const INSTITUTION = "institution";
export const SOLUTION = "solution";
export const BUSINESS_CASE = "businessCase";
export const EXPERT = "expert";

/* Business case Tabs label on database detail page*/
export const CASE_DETAIL = "caseDetail";
export const PROJECT_BACKGROUND = "projectBackground";
export const RELATED_FILE = "relatedFile";

/* Institution Tabs label on database detail page*/
export const INSTITUTION_INTRO = "institutionIntro";
export const PRODUCT_SOLUTIONS = "productSolutions";
export const INSTITUTION_MATERIAL = "institutionMaterial";
export const INSTITUTION_EXPERTS = "institutionExperts";

/* Solution Tabs label on database detail page*/
export const SOLUTION_DESC = "solutionDesc";
export const SOLUTION_FEATURE = "solutionFeature";
export const SOLUTION_TECHNOLOGY = "solutionTechnology";
export const SOLUTION_MATERIAL = "solutionMaterial";
export const SOLUTION_BUSINESS_CASE = "solutionBusinessCase";

/* Institution filter key on database page */
export const COLLECTIONS = "collections";
export const INDUSTRIES = "industries";
export const DEVELOP_STAGE = "developStage";
export const COMPANY_TYPE = "companyTypes";
export const REGION = "region";
export const AREAS = "areas";
export const TECHNOLOGIES = "techFields";
export const APPLY_FORMATS = "applyFormats";
export const KEYWORDS = "keywords";

/* Institution filter key on database page */
export const INSTITUION_BRIEF = "institutionBrief";
export const ROLES = "roles";
export const FOUNDEDAT = "foundedAt";

/* table textwrite */
export const TRIGGER_DESC = "triggerDesc";
export const TRIGGER_ASC = "triggerAsc";
export const CACCEL_SORT = "cancelSort";

export const ROUTE_PREFIX_PRO = "/info";
export const ROUTE_PREFIX_DEV = "/info-staging";
export const ROOT_PATH = "/home";
