import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 16 16" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>document_4</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="document_4">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <path
          d="M2.5,0.5 C2.3125,0.5 2.1125,0.575 1.975,0.725 C1.8375,0.875 1.75,1.0625 1.75,1.25 L1.75,14.75 C1.75,14.9375 1.825,15.1375 1.975,15.275 C2.125,15.425 2.3125,15.5 2.5,15.5 L13.5,15.5 C13.6875,15.5 13.8875,15.425 14.025,15.275 C14.175,15.125 14.25,14.9375 14.25,14.75 L14.25,4.75 L10,0.5 L2.5,0.5 Z"
          id="Path"
          fill="#FF8976"
        />
        <path
          d="M6.025,7.025 L8.775,7.025 C9.875,7.025 10.225,7.7625 10.225,8.55 C10.225,9.3 9.7875,10.0625 8.7875,10.0625 L6.9625,10.0625 L6.9625,11.9375 L6.025,11.9375 L6.025,7.025 Z M6.9625,9.3 L8.475,9.3 C9.0125,9.3 9.3,9.1375 9.3,8.5625 C9.3,7.9625 8.9125,7.8125 8.55,7.8125 L6.9625,7.8125 L6.9625,9.3 Z"
          id="Shape"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M14.25,4.75 L10.75,4.75 C10.5625,4.75 10.3625,4.675 10.225,4.525 C10.075,4.3875 10,4.1875 10,4 L10,0.5 L14.25,4.75 Z"
          id="Path"
          fill="#FFD0C8"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
