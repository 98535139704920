/*
 * @Author: your wh.q
 * @Date: 2020-12-15 10:48:06
 * @LastEditTime: 2021-03-24 13:34:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/routes/config.ts
 */
import { ROOT_PATH } from "../constants/pro_constants";
import {
  Database,
  DatabaseDetail,
  Home,
  Cooperation,
  CooperationDetail,
  CooperationSeek,
  Circle,
  CircleDetail,
  Activity,
  ActivityDetail,
  Profile,
  ActivitySignUp,
  JoinGroup,
  PostCooperation,
  RespondCooperation,
  PostscriptCooperation,
  CircleCooperation,
  ExpressList,
} from "../components";
import {
  INSTITUTION,
  SOLUTION,
  BUSINESS_CASE,
  EXPERT,
} from "../constants/pro_constants";
import SvgPage from "../icons/svg-page/SvgPage";

export interface Props {
  path: string;
  auth?: boolean;
  exact?: boolean;
  strict?: boolean;
  params?: any;
  isHide?: boolean;
  Component?: React.ReactNode;
  isCheckRole?: boolean;
}

const config: Array<Props> = [
  {
    path: ROOT_PATH,
    exact: true,
    strict: false,
    Component: Home,
  },
  {
    path: "/cooperation/respond/:id",
    exact: true,
    strict: false,
    Component: RespondCooperation,
  },
  {
    path: "/cooperation/post",
    exact: true,
    strict: false,
    Component: PostCooperation,
  },
  {
    path: "/cooperation/circle/:id",
    exact: true,
    strict: false,
    Component: CircleCooperation,
  },
  {
    path: "/cooperation/postscript/:id",
    exact: true,
    strict: false,
    Component: PostscriptCooperation,
  },
  {
    path: "/sign-up",
    exact: true,
    strict: false,
    Component: ActivitySignUp,
  },
  {
    path: "/profile",
    exact: true,
    strict: false,
    Component: Profile,
  },
  {
    path: "/join-group",
    exact: true,
    strict: false,
    Component: JoinGroup,
  },
  {
    path: "/activity",
    exact: true,
    strict: false,
    Component: Activity,
  },
  {
    path: "/activity/:id",
    exact: true,
    strict: false,
    Component: ActivityDetail,
  },
  {
    path: "/cooperation",
    exact: true,
    strict: false,
    Component: Cooperation,
  },
  {
    path: "/cooperation/detail/:id",
    exact: true,
    strict: false,
    Component: CooperationDetail,
  },
  {
    path: "/cooperation/seek",
    exact: true,
    strict: false,
    Component: CooperationSeek,
  },
  {
    path: "/circle",
    exact: true,
    strict: false,
    Component: Circle,
  },
  {
    path: "/circle/:id",
    exact: true,
    strict: false,
    Component: CircleDetail,
  },
  {
    path: "/express",
    exact: true,
    strict: false,
    Component: ExpressList,
  },
  {
    path: "/library/institutions",
    exact: true,
    strict: false,
    Component: Database,
    params: {
      type: INSTITUTION,
    },
  },
  {
    path: "/library/solutions",
    exact: true,
    strict: false,
    Component: Database,
    params: {
      type: SOLUTION,
    },
  },
  {
    path: "/library/institutions/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: INSTITUTION,
    },
  },
  {
    path: "/library/solutions/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: SOLUTION,
    },
  },
  {
    path: "/library/business-cases/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: BUSINESS_CASE,
    },
  },
  {
    path: "/library/experts",
    exact: true,
    strict: false,
    Component: Database,
    params: {
      type: EXPERT,
    },
  },
  {
    path: "/library/experts/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: EXPERT,
    },
  },
  // {
  //   path: "/login",
  //   exact: true,
  //   strict: true,
  //   Component: Auth,
  //   params: {
  //     type: "login",
  //   },
  // },
];

const svgRoute = {
  path: "/svg",
  exact: true,
  strict: false,
  Component: SvgPage,
};
// console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
if (process.env.NODE_ENV === "development") config.push(svgRoute);

export default config;
