import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { IconHeading, Icon } from "../shared";
import parseRichStr from "../../utils/parseRichStr";
import databaseDetailStore from "../../stores/databaseDetailStore";

const SolutionsBusinessCaseItem = item => {
  const { setRichImgStyle } = useContext(databaseDetailStore);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    setRichImgStyle();
  }, [isExpand]);

  return (
    <div className="solution-business-cases__item">
      <div className="solution-business-cases__title">
        <Link
          to={`/library/business-cases/${item.id}`}
          className="text-limit--one"
          target="_blank"
        >
          {item.name}
        </Link>
        <div className="solution-business-cases__title__key-data">
          {item.keyData.map((ele, i) => {
            return (
              <span
                className="solution-business-cases__title__key-data--item"
                key={i}
              >
                <span className="solution-business-cases__title__key-data--label">
                  {ele.key}：
                </span>
                <span className="solution-business-cases__title__key-data--value">
                  {ele.value}
                </span>
              </span>
            );
          })}
        </div>
      </div>
      <p className="solution-business-cases__summary">{item.summary}</p>
      <div className="mobile-solution-business-cases__title__key-data">
        {item.keyData.map((ele, i) => {
          return (
            <span
              className="mobile-solution-business-cases__title__key-data--item"
              key={i}
            >
              <span className="mobile-solution-business-cases__title__key-data--label">
                {ele.key}：
              </span>
              <span className="mobile-solution-business-cases__title__key-data--value">
                {ele.value}
              </span>
            </span>
          );
        })}
      </div>
      {item.technologies && item.technologies.length > 0 && (
        <span className="solution-business-cases__technologies">
          核心技术：
          {item.technologies.map((ele, i) => (
            <span key={i}>{ele.name}</span>
          ))}
        </span>
      )}

      {((item.insititutions && item.insititutions.length > 0) ||
        parseRichStr(item.background) ||
        parseRichStr(item.desc)) &&
        !isExpand && (
          <div
            className="solution-business-cases__expand"
            onClick={() => {
              setIsExpand(true);
            }}
          >
            显示更多 <Icon name="lenovo-arrow-down" size={16} color="#6b8aba" />
          </div>
        )}

      {isExpand && (
        <>
          {parseRichStr(item.background) && (
            <div className="solution-business-cases__background solution-business-cases__block">
              <IconHeading
                icon="project-background"
                title="项目背景与客户痛点"
              />
              <div
                className="solution-business-cases__block-content"
                dangerouslySetInnerHTML={{ __html: `${item.background}` }}
              />
            </div>
          )}
          {parseRichStr(item.desc) && (
            <div className="solution-business-cases__detail-intro solution-business-cases__block">
              <IconHeading icon="case-detail" title="案例详解" />
              <div
                className="solution-business-cases__block-content"
                dangerouslySetInnerHTML={{ __html: `${item.desc}` }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SolutionsBusinessCaseItem;
