import React from "react";

import ArrowLeftWithCircle from "./ArrowLeftWithCircle";
import Alarm from "./Alarm";
import Review from "./Review";
import Tooltip from "./Tooltip";
import Success from "./Success";
import Admin from "./Admin";
import LogoutIcon from "./LogoutIcon";
import LenovoClose from "./LenovoClose";
import Remove from "./Remove";
import NotifySuccess from "./NotifySuccess";
import NotifyWarn from "./NotifyWarn";
import NotifyError from "./NotifyError";
import Add from "./Add";
import Hint from "./Hint";
import Empty from "./Empty";
import NotifyClose from "./NotifyClose";
import SolutionMenu from "./SolutionMenu";
import InstitutionsMenu from "./InstitutionsMenu";
import ExpertMenu from "./ExpertMenu";
import LenovoArrowUp from "./LenovoArrowUp";
import LenovoArrowDown from "./LenovoArrowDown";
import LenovoComingSoon from "./LenovoComingSoon";
import LenovoProfile from "./LenovoProfile";
import LenovoLogout from "./LenovoLogout";
import LenovoGuest from "./LenovoGuest";
import LenovoSearch from "./LenovoSearch";
import LenovoInstitutionIntro from "./LenovoInstitutionIntro";
import LenovoPackage from "./LenovoPackage";
import LenovoKeywordsTag from "./LenovoKeywordsTag";
import LenovoMaterial from "./LenovoMaterial";
import LenovoDoc from "./LenovoDoc";
import LenovoExcel from "./LenovoExcel";
import LenovoPdf from "./LenovoPdf";
import LenovoPng from "./LenovoPng";
import LenovoPpt from "./LenovoPpt";
import LenovoZip from "./LenovoZip";
import CaseDetail from "./CaseDetail";
import ProjectBackground from "./ProjectBackground";
import LenovoSolutionFeature from "./LenovoSolutionFeature";
import LenovoSolutionTech from "./LenovoSolutionTech";
import LenovoBusinessCase from "./LenovoBusinessCase";
import LenovoBrief from "./LenovoBrief";
import LenovoHonors from "./LenovoHonors";
import LenovoPosition from "./LenovoPosition";
import LenovoExpert from "./LenovoExpert";
import Contact from "./Contact";
import Like from "./Like";
import Arrow from "./Arrow";
import Chrome from "./Chrome";
import Close from "./Close";
import Ie from "./IE";
import InfoDatabase from "./InfoDatabase";
import Star from "./Star";
import Cooperation from "./Cooperation";
import CooperationCardUser from "./CooperationCardUser";
import CooperationCardOpen from "./CooperationCardOpen";
import CooperationCardOver from "./CooperationCardOver";
import LenovoMenu from "./LenovoMenu";
import LenovoEntry1 from "./LenovoEntry1";
import LenovoEntry2 from "./LenovoEntry2";
import LenovoEntry3 from "./LenovoEntry3";
import LenovoEntry4 from "./LenovoEntry4";
import LenovoEntry5 from "./LenovoEntry5";
import LenovoEntry6 from "./LenovoEntry6";
import LenovoEntryHover1 from "./LenovoEntryHover1";
import LenovoEntryHover2 from "./LenovoEntryHover2";
import LenovoEntryHover3 from "./LenovoEntryHover3";
import LenovoEntryHover4 from "./LenovoEntryHover4";
import LenovoEntryHover5 from "./LenovoEntryHover5";
import LenovoEntryHover6 from "./LenovoEntryHover6";
import Buzz from "./Buzz";
import LenovoArrowRight from "./LenovoArrowRight";
import Location from "./Location";
import BreadcrumbsTriangle from "./BreadcrumbsTriangle";
import ActivityRed from "./ActivityRed";
import ActivityPurple from "./ActivityPurple";
import ActivityGreen from "./ActivityGreen";
import ActivityBlue from "./ActivityBlue";
import LenovoRight from "./LenovoRight";
import LenovoBack from "./LenovoBack";
import LenovoCountry from "./LenovoCountry";
import LenovoProvince from "./LenovoProvince";
import CircleBigLock from "./CircleBigLock";
import CircleRedLock from "./CircleRedLock";
import CircleSmallLock from "./CircleSmallLock";
import CircleApplyGroup from "./CircleApplyGroup";

interface Props {
  name: string;
  color?: string;
  size?: number;
  onClick?(): void;
  className?: string;
}

const Icon: React.FC<Props> = ({
  name,
  color,
  size,
  onClick,
  className,
}: Props) => {
  const svgIcons = {
    alarm: <Alarm width={`${size}px`} />,
    review: <Review />,
    "arrow-left-with-circle": (
      <ArrowLeftWithCircle width={`${size}`} fill={color} />
    ),
    tooltip: <Tooltip width={`${size}px`} />,
    success: <Success width={`${size}px`} />,
    admin: <Admin width={`${size}px`} fill={color} />,
    logout: <LogoutIcon width={`${size}px`} fill={color} />,
    "notify-success": <NotifySuccess width={`${size}`} />,
    "notify-error": <NotifyError width={`${size}`} />,
    "notify-warn": <NotifyWarn width={`${size}`} />,
    remove: <Remove width={`${size}`} fill={color} />,
    add: <Add width={`${size}`} fill={color} />,
    hint: <Hint width={`${size}`} fill={color} />,
    empty: <Empty width={`${size}`} />,
    "notify-close": <NotifyClose width={`${size}`} fill={color} />,
    "solution-menu": <SolutionMenu width={`${size}`} fill={color} />,
    "expert-menu": <ExpertMenu width={`${size}`} fill={color} />,
    "institutions-menu": <InstitutionsMenu width={`${size}`} fill={color} />,
    "lenovo-arrow-up": <LenovoArrowUp width={`${size}`} fill={color} />,
    "lenovo-arrow-down": <LenovoArrowDown width={`${size}`} fill={color} />,
    "lenovo-coming-soon": <LenovoComingSoon />,
    "lenovo-profile": <LenovoProfile width={`${size}`} fill={color} />,
    "lenovo-logout": <LenovoLogout width={`${size}`} fill={color} />,
    "lenovo-guest": <LenovoGuest width={`${size}`} fill={color} />,
    "lenovo-search": <LenovoSearch width={`${size}`} fill={color} />,
    "lenovo-close": <LenovoClose width={`${size}px`} fill={color} />,
    "lenovo-institution-intro": (
      <LenovoInstitutionIntro width={`${size}px`} fill={color} />
    ),
    "lenovo-package": <LenovoPackage width={`${size}px`} fill={color} />,
    "lenovo-keywords-tag": (
      <LenovoKeywordsTag width={`${size}px`} fill={color} />
    ),
    "lenovo-material": <LenovoMaterial width={`${size}px`} fill={color} />,
    "lenovo-doc": <LenovoDoc width={`${size}px`} />,
    "lenovo-xlsx": <LenovoExcel width={`${size}px`} />,
    "lenovo-pdf": <LenovoPdf width={`${size}px`} />,
    "lenovo-png": <LenovoPng width={`${size}px`} />,
    "lenovo-svg": <LenovoPng width={`${size}px`} />,
    "lenovo-jpg": <LenovoPng width={`${size}px`} />,
    "lenovo-jpeg": <LenovoPng width={`${size}px`} />,
    "lenovo-ppt": <LenovoPpt width={`${size}px`} />,
    "lenovo-zip": <LenovoZip width={`${size}px`} />,
    "case-detail": <CaseDetail width={`${size}px`} fill={color} />,
    "project-background": (
      <ProjectBackground width={`${size}px`} fill={color} />
    ),
    "lenovo-solution-feature": <LenovoSolutionFeature width={`${size}`} />,
    "lenovo-solution-tech": <LenovoSolutionTech width={`${size}`} />,
    "lenovo-business-case": <LenovoBusinessCase width={`${size}`} />,
    "lenovo-brief": <LenovoBrief width={`${size}`} fill={color} />,
    "lenovo-honors": <LenovoHonors width={`${size}`} fill={color} />,
    "lenovo-position": <LenovoPosition width={`${size}`} fill={color} />,
    "lenovo-expert": <LenovoExpert width={`${size}`} fill={color} />,
    contact: <Contact width={`${size}`} fill={color} />,
    like: <Like width={`${size}`} fill={color} />,
    arrow: <Arrow width={`${size}`} fill={color} />,
    chrome: <Chrome width={`${size}`} />,
    ie: <Ie width={`${size}`} />,
    close: <Close width={`${size}`} />,
    "info-database": <InfoDatabase width={`${size}`} />,
    star: <Star width={`${size}`} />,
    Cooperation: <Cooperation width={`${size}`} fill={color} />,
    "cooperation-card-user": (
      <CooperationCardUser width={`${size}`} fill={color} />
    ),
    "cooperation-card-open": (
      <CooperationCardOpen width={`${size}`} fill={color} />
    ),
    "cooperation-card-over": (
      <CooperationCardOver width={`${size}`} fill={color} />
    ),
    "lenovo-menu": (
      <LenovoMenu width={`${size}`} fill={color} onClick={onClick} />
    ),
    "lenovo-entry-1": (
      <LenovoEntry1 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-2": (
      <LenovoEntry2 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-3": (
      <LenovoEntry3 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-4": (
      <LenovoEntry4 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-5": (
      <LenovoEntry5 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-6": (
      <LenovoEntry6 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-1": (
      <LenovoEntryHover1 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-2": (
      <LenovoEntryHover2 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-3": (
      <LenovoEntryHover3 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-4": (
      <LenovoEntryHover4 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-5": (
      <LenovoEntryHover5 className={name} width={`${size}`} onClick={onClick} />
    ),
    "lenovo-entry-hover-6": (
      <LenovoEntryHover6 className={name} width={`${size}`} onClick={onClick} />
    ),
    buzz: <Buzz width={`${size}`} />,
    "lenovo-arrow-right": (
      <LenovoArrowRight className={className} width={`${size}`} fill={color} />
    ),
    location: <Location width={`${size}`} fill={color} />,
    "breadcrumbs-triangle": (
      <BreadcrumbsTriangle width={`${size}`} fill={color} />
    ),
    "activity-red": <ActivityRed width={`${size}`} />,
    "activity-purple": <ActivityPurple width={`${size}`} />,
    "activity-green": <ActivityGreen width={`${size}`} />,
    "activity-blue": <ActivityBlue width={`${size}`} />,
    "lenovo-right": <LenovoRight width={`${size}`} fill={color} />,
    "lenovo-back": <LenovoBack width={`${size}`} fill={color} />,
    "lenovo-country": <LenovoCountry width={`${size}`} fill={color} />,
    "lenovo-province": <LenovoProvince width={`${size}`} fill={color} />,
    "circle-big-lock": <CircleBigLock width={`${size}`} fill={color} />,
    "circle-red-lock": <CircleRedLock width={`${size}`} fill={color} />,
    "circle-small-lock": <CircleSmallLock width={`${size}`} fill={color} />,
    "circle-apply-group": <CircleApplyGroup width={`${size}`} fill={color} />,
  };
  return <>{svgIcons[name]}</>;
};

Icon.defaultProps = {
  size: 16,
};

export default Icon;
