// import dayjs from "dayjs";
import axios from "axios";
import { handleRequestWithToken, getRequestDealWithAuth, http } from "./config";

// export const example = () => {
//   return http({
//     method: "GET",
//     url: "/api/example",
//   });
// };

export const fetchFiltersData = type => {
  return getRequestDealWithAuth(`${type}s/filter.json`);
};

export const fetchDatabaseDetail = (type, id) => {
  const map = {
    solution: "solutions",
    institution: "institutions",
    businessCase: "business_cases",
    expert: "experts",
  };
  return axios.get(`${map[type]}/${id}.json`);
};

export const fetchKeywordResultData = (type, keyword) => {
  return getRequestDealWithAuth(`${type}s/base.json?keyword=${keyword}`);
};

export const fetchDatabaseListData = (type, params) => {
  return getRequestDealWithAuth(`${type}s.json?${params}`);
};

export const login = data => {
  return handleRequestWithToken(`sign_in`, data);
};

export const like = (type, id) => {
  return getRequestDealWithAuth(
    `action_stars/like.json?model=${type}&id=${id}`
  );
};

export const shareInWechat = (type, id = "") => {
  return getRequestDealWithAuth(
    `action_shares/share.json?model=${type}&id=${id}`
  );
};

export const fetchCooperatesData = data => {
  const { pagination = {}, params = {} } = data;
  return axios({
    method: "GET",
    url: `/cooperates?page=${pagination.currentPage}&per=${pagination.pageSize}`,
    params,
  });
};

export const fetchCooperatesFilterData = () => {
  return http({
    method: "GET",
    url: "/cooperates/filter",
  });
};

export const fetchBannersData = () => {
  return http({
    method: "GET",
    url: "/banners",
  });
};

export const fetchHomeCooperates = () => {
  return http({
    method: "GET",
    url: "/cooperates/recommend",
  });
};

export const fetchCooperatesDetail = id => {
  return http({
    method: "GET",
    url: `/cooperates/${id}`,
  });
};

export const fetchHomeCircles = () => {
  return http({
    method: "GET",
    url: "/circles/latest",
  });
};

export const fetchCirclesData = () => {
  return http({
    method: "GET",
    url: `/circles`,
  });
};

export const fetchCirclesDetail = id => {
  return http({
    method: "GET",
    url: `/circles/${id}`,
  });
};

export const fetchActivityNewest = () => {
  return http({
    method: "GET",
    url: `/events/latest`,
  });
};

export const fetchActivityFilters = () => {
  return http({
    method: "GET",
    url: `/events/filter`,
  });
};

export const fetchActivityList = data => {
  const { pagination = {}, params = {} } = data;
  return http({
    method: "GET",
    url: `/events?page=${pagination.currentPage}&per=${pagination.pageSize}`,
    params,
  });
};

export const fetchActivityDetail = id => {
  return http({
    method: "GET",
    url: `/events/${id}`,
  });
};

export const addActivityMessage = data => {
  return http({
    method: "POST",
    url: `/events/${data.id}/message`,
    data,
  });
};

export const starActivity = data => {
  return http({
    method: "POST",
    url: `/events/${data.id}/star`,
    data,
  });
};

// export const fetchCooperateExpress = () => {
//   return http({
//     method: "GET",
//     url: `/cooperates/expresses`,
//   });
// };

export const fetchUserInfo = () => {
  return http({
    method: "GET",
    url: `/users/info`,
  });
};

export const postUserInfo = data => {
  return http({
    method: "POST",
    url: `/users/update_info`,
    data,
  });
};

export const postCirclesInfo = data => {
  return http({
    method: "POST",
    url: `circles/${data.id}/join`,
    data,
  });
};

export const postACooperate = data => {
  return http({
    method: "POST",
    url: `/cooperates`,
    data,
  });
};

export const combinesACooperate = data => {
  return http({
    method: "POST",
    url: `/combines`,
    data,
  });
};

export const fetchCooperateFilter = () => {
  return http({
    method: "GET",
    url: `/users/cooperate_filter`,
  });
};

export const fetchCooperateReplies = id => {
  return http({
    method: "GET",
    url: `/cooperate_replies/${id}`,
  });
};

export const fetchCombinesDetail = id => {
  return http({
    method: "GET",
    url: `/combines/${id}`,
  });
};

export const postCooperateReply = data => {
  return http({
    method: "POST",
    url: `cooperates/${data.id}/reply`,
    data,
  });
};

export const postCooperateRepliesProceed = data => {
  return http({
    method: "POST",
    url: `/cooperate_replies/${data.id}/proceed`,
    data,
  });
};

export const postCooperateRepliesRejected = data => {
  return http({
    method: "POST",
    url: `/cooperate_replies/${data.id}/rejected`,
    data,
  });
};

export const postCombinesRejected = data => {
  return http({
    method: "POST",
    url: `/combines/${data.id}/rejected`,
    data,
  });
};

export const postCombinesProceed = data => {
  return http({
    method: "POST",
    url: `/combines/${data.id}/proceed`,
    data,
  });
};

export const postBm = data => {
  return http({
    method: "POST",
    url: `/events/${data.id}/join`,
    data,
  });
};

export const postCircleChat = data => {
  return http({
    method: "POST",
    url: `/circles/${data.id}/circle_chat`,
    data,
  });
};

export const postJoinGroup = data => {
  return http({
    method: "POST",
    url: `/circles/${data.id}/join_group`,
    data,
  });
};

export const fetchExpress = pagination => {
  return http({
    method: "GET",
    url: `/expresses?page=${pagination.currentPage}&per=${pagination.pageSize}`,
  });
};

export const fetchExpressLatest = () => {
  return http({
    method: "GET",
    url: "/expresses/latest",
  });
};
