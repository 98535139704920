import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>out_n</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="评选填报中心-首页"
        transform="translate(-1262.000000, -221.000000)"
        fill={fill}
      >
        <g id="Group-8" transform="translate(1246.000000, 163.000000)">
          <g id="Group-6">
            <g id="Group" transform="translate(0.000000, 45.000000)">
              <g
                id="Polaris-icon/Major/2-Tone/Transfer-In"
                transform="translate(16.000000, 13.000000)"
              >
                <path
                  d="M6.8688,10.4 L1.6,10.4 C1.1576,10.4 0.8,10.0416 0.8,9.6 C0.8,9.1584 1.1576,8.8 1.6,8.8 L6.8688,8.8 L5.8344,7.7656 C5.5216,7.4528 5.5216,6.9472 5.8344,6.6344 C6.1472,6.3216 6.6528,6.3216 6.9656,6.6344 L9.3656,9.0344 C9.6784,9.3472 9.6784,9.8528 9.3656,10.1656 L6.9656,12.5656 C6.8096,12.7216 6.6048,12.8 6.4,12.8 C6.1952,12.8 5.9904,12.7216 5.8344,12.5656 C5.5216,12.2528 5.5216,11.7472 5.8344,11.4344 L6.8688,10.4 L6.8688,10.4 Z M14.4,16 L1.6,16 C1.1576,16 0.8,15.6416 0.8,15.2 L0.8,13.6 C0.8,13.1584 1.1576,12.8 1.6,12.8 C2.0424,12.8 2.4,13.1584 2.4,13.6 L2.4,14.4 L13.6,14.4 L13.6,4.4944 L8,1.6944 L2.4,4.4944 L2.4,5.6 C2.4,6.0416 2.0424,6.4 1.6,6.4 C1.1576,6.4 0.8,6.0416 0.8,5.6 L0.8,4 C0.8,3.6968 0.9712,3.42 1.2424,3.284 L7.6424,0.084 C7.8672,-0.028 8.1328,-0.028 8.3576,0.084 L14.7576,3.284 C15.0288,3.42 15.2,3.6968 15.2,4 L15.2,15.2 C15.2,15.6416 14.8424,16 14.4,16 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
