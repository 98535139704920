import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 36 36",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>delete-n</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="add"
        transform="translate(-260.000000, -2981.000000)"
        fillRule="nonzero"
      >
        <g id="add" transform="translate(230.000000, 2965.000000)">
          <g id="Add" transform="translate(30.000000, 16.000000)">
            <rect
              id="Rectangle"
              fill="#000000"
              opacity="0"
              x="0"
              y="0"
              width="36"
              height="36"
            />
            <path
              d="M18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 Z M18,2.25 C9.30151519,2.25 2.25,9.30151519 2.25,18 C2.25,26.6984848 9.30151519,33.75 18,33.75 C26.6984848,33.75 33.75,26.6984848 33.75,18 C33.75,13.8228403 32.0906298,9.81676616 29.1369318,6.8630682 C26.1832338,3.90937024 22.1771597,2.25 18,2.25 Z M19.125,25.875 L16.875,25.875 L16.875,19.125 L10.125,19.125 L10.125,16.875 L16.875,16.875 L16.875,10.125 L19.125,10.125 L19.125,16.875 L25.875,16.875 L25.875,19.125 L19.125,19.125 L19.125,25.875 Z"
              id="Shape"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
