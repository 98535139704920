// todo: import svg tsx

const NAV = [
  {
    name: "解决方案信息库",
    svg: "",
    path: "/library/institutions",
    activePath: [],
    child: [],
  },
  {
    name: "生态活动",
    svg: "",
    path: "/activity",
    activePath: [],
    child: [],
  },
  {
    name: "生态合作",
    svg: "",
    path: "/cooperation",
    activePath: [],
    child: [],
  },
  {
    name: "圈子",
    svg: "",
    path: "/circle",
    activePath: [],
    child: [],
  },
];

export default NAV;
