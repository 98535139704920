import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 18 18",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>咨询</title>
    <g
      id="数据库桌面端"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="咨询">
        <rect
          id="Rectangle"
          fill="#000000"
          fillRule="nonzero"
          opacity="0"
          x="0"
          y="0"
          width="18"
          height="18"
        />
        <path
          d="M3.9375,8.4375 C3.006,8.4375 2.25,7.430625 2.25,6.1875 C2.25,5.059125 2.87325,4.12425 3.6855,3.96225 C4.4505,1.98225 6.54075,0.5625 9,0.5625 C11.45925,0.5625 13.550625,1.983375 14.3145,3.96225 C15.12675,4.12425 15.75,5.059125 15.75,6.1875 C15.75,7.430625 14.994,8.4375 14.0625,8.4375 L13.5,8.4375 L13.5,5.625 C13.5,3.4785 11.509875,1.6875 9,1.6875 C6.490125,1.6875 4.5,3.4785 4.5,5.625 L4.5,8.4375 L3.9375,8.4375 Z M1.1565,17.4375 C1.13438378,17.2508313 1.12386359,17.0629708 1.1249169,16.875 C1.1249169,14.4 2.67975,12.2355 4.99725,11.061 C6.15615133,11.9167476 7.55939231,12.3773956 9,12.375 C10.4406077,12.3773956 11.8438487,11.9167476 13.00275,11.061 C15.32025,12.2355 16.875,14.4 16.875,16.875 C16.875,17.080875 16.86375,17.26875 16.8435,17.4375 L1.1565,17.4375 Z M9,10.6875 C7.59326673,10.6875 6.29339157,9.93701677 5.59002492,8.71875002 C4.88665828,7.50048327 4.88665828,5.99951673 5.59002492,4.78124998 C6.29339157,3.56298323 7.59326673,2.8125 9,2.8125 C11.1746212,2.8125 12.9375,4.5753788 12.9375,6.75 C12.9375,8.9246212 11.1746212,10.6875 9,10.6875 L9,10.6875 Z"
          id="Shape"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
