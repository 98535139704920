import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Table, Skeleton, Tooltip } from "antd";

import { AvatarCard } from "../shared";
import databaseStore from "../../stores/databaseStore";

import {
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
} from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import DatabaseListPanel from "./DatabaseListPanel";

const customSkeleton = (
  <Skeleton title={false} paragraph={{ rows: 1, width: "80%" }} active />
);

const loader = <Skeleton paragraph={{ rows: 2 }} active />;

const SolutionResultPanel = (props: any) => {
  const {
    resultList: { data, count },
    hasMore,
    pagination,
    fetchDatabaseList,
    isLoading,
    paginationMobile,
  } = useContext(databaseStore);
  const {
    usePagination = true,
    useTitle = true,
    width = ["24%", "44%", "16%", "16%"],
  } = props;

  const columns = [
    {
      title: "方案名称",
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      width: width[0],
      className: "database-list-panel__solution__name",
      render: data =>
        !isLoading
          ? (data[1] ? (
              <span>
                {data[0]}·{data[1]}
              </span>
            ) : (
              <span>{data[0]}</span>
            )) || "-"
          : customSkeleton,
    },
    {
      title: "简介",
      dataIndex: "summary",
      ellipsis: {
        showTitle: false,
      },
      width: width[1],
      className: "database-list-panel__solution__summary",
      render: data =>
        !isLoading
          ? (
              <Tooltip
                placement="leftTop"
                title={data.length > 400 ? data.substr(0, 400) + "..." : data}
              >
                {data}
              </Tooltip>
            ) || "-"
          : customSkeleton,
    },
    {
      title: "产品和解决方案",
      dataIndex: "applyFormats",
      sorter: true,
      ellipsis: true,
      width: width[2],
      render: data =>
        !isLoading ? (data && data.join(",")) || "-" : customSkeleton,
    },
    {
      title: "成员企业",
      dataIndex: "producer",
      sorter: true,
      ellipsis: true,
      width: width[3],
      render: data =>
        !isLoading
          ? (data && (
              <AvatarCard
                theme="company"
                shape="square"
                data={data}
                avatarSize={32}
              />
            )) ||
            "-"
          : customSkeleton,
    },
    // {
    //   title: "所在区域",
    //   dataIndex: "region",
    //   ellipsis: true,
    //   width: "14%",
    //   render: data => (!isLoading ? data || "-" : customSkeleton),
    // },
  ];

  return (
    <>
      <div className="database-list-panel">
        <Table
          columns={columns}
          dataSource={data}
          locale={{
            triggerDesc: labelToChinese[TRIGGER_DESC],
            triggerAsc: labelToChinese[TRIGGER_ASC],
            cancelSort: labelToChinese[CACCEL_SORT],
          }}
          tableLayout="fixed"
          pagination={usePagination ? pagination : false}
          onChange={(pagination, filters, sorter) =>
            fetchDatabaseList(pagination, sorter)
          }
          scroll={{ x: 740 }}
          title={
            useTitle
              ? () => (
                  <div className="database-list-panel__title">
                    找到 {count} 条结果
                  </div>
                )
              : false
          }
          onRow={record => {
            return {
              onClick: () => {
                window.open(`/library/solutions/${record.key}`);
              },
            };
          }}
        />
      </div>
      <DatabaseListPanel
        loader={loader}
        isLoading={isLoading}
        hasMore={hasMore}
        pagination={paginationMobile}
        dataLength={data ? data.length : 0}
        next={fetchDatabaseList}
      >
        {data &&
          data.map((item, index) => (
            <div
              onClick={() => window.open(`/library/solutions/${item.key}`)}
              key={index}
              className="database-list-panel__content-item--mobile"
            >
              <div className="content-item__content--mobile">
                <div>
                  <p className="content-item__name--mobile">
                    {item.name[0]}
                    {item.name[1] ? `·${item.name[1]}` : ``}
                  </p>
                  <p className="content-item__type--mobile">
                    {item.applyFormats && item.applyFormats.join(",")}
                  </p>
                </div>
                <div className="content-item__summary--mobile">
                  {item.summary}
                </div>
              </div>
            </div>
          ))}
      </DatabaseListPanel>
    </>
  );
};

export default observer(SolutionResultPanel);
