import dayjs from "dayjs";

const formatData = initData => ({
  ...initData,
  expresses: initData.expresses
    ? initData.expresses.map(item => ({
        ...item,
        date: dayjs(item.publishedAt).format("YYYY-MM-DD"),
      }))
    : [],
  breadcrumbs: [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: "合作快报",
      path: `/express`,
    },
  ],
});

export { formatData };
