import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { Nav, Footer, CodeEntry } from "../shared/index";
import { Form, Input, Checkbox } from "antd";
import { useQuery } from "../../utils/tools";
import signIn from "../../utils/sign_in";
import getBrowserInfo from "../../utils/getBrowserInfo";
import GlobalStore from "../../stores/globalStore";
import lWxCode from "../../assets/images/l-wx-img.png";
import ActivityDateImg from "../../assets/images/activity-date.png";
import ActivityAddressImg from "../../assets/images/activity-address.png";
import authStore from "../../stores/authStore";
import { postBm } from "../../api/index";
import activityDetailStore from "../../stores/activityDetailStore";

interface Props {
  [key: string]: any;
}

const ActivitySignUp: React.FC<Props> = () => {
  const id = useQuery().get("id");
  const [form] = Form.useForm();
  const history = useHistory();
  const { setVisibleState, setDialogProp, revertDialogProps } = useContext(
    GlobalStore
  );
  const { currentUser, isLogin, loginLoading, isAuthUser } = useContext(
    authStore
  );
  const { fetchData, detailData } = useContext(activityDetailStore);
  const [isPosted, setIsPosted] = useState(false);
  const BrowserInfo = getBrowserInfo();

  useEffect(() => {
    form.setFieldsValue(currentUser);
  }, [currentUser]);

  useEffect(() => {
    fetchData(id);
  }, []);

  useEffect(() => {
    if (BrowserInfo.type !== "WeixinBrowser") {
      history.push(`/join-group?type=register&qrcode=${detailData.qrcodeUrl}`);
    } else {
      if (!loginLoading && !isLogin) {
        setTimeout(() => {
          signIn();
        }, 300);
      }
      if (isLogin && detailData.enroll) {
        revertDialogProps();
        setDialogProp("lenovoWxType", "success");
        setDialogProp("lenovoWxTitle", "您已成功报名该活动");
        setDialogProp("lenovoWxCodeImg", lWxCode);
        setDialogProp("lenovoWxBottomTip", "");
        setDialogProp(
          "lenovoWxBottomDom",
          <p className="lenovo-wx-dialog-tips" style={{ fontSize: "20px" }}>
            <span>扫码接收后续活动通知</span>
          </p>
        );
        setDialogProp("lenovoWxEvent", () => {
          history.replace(`/activity/${id}?state=pending`);
        });
        setVisibleState("lenovoWx", true);
      }
    }
  }, [loginLoading, detailData]);

  // const replace = () => {
  //   setTimeout(() => {
  //     const url = (window as any).location.href.replace(
  //       (window as any).location.origin,
  //       ""
  //     );
  //     history.replace(url);
  //   }, 300);
  // };

  const onFinish = async (data: any) => {
    if (isPosted) {
      return false;
    }
    setIsPosted(true);
    await postBm({
      id: id,
      user: data,
    });
    revertDialogProps();
    setDialogProp("lenovoWxType", "success");
    setDialogProp("lenovoWxTitle", "活动报名成功");
    setDialogProp("lenovoWxCodeImg", lWxCode);
    setDialogProp("lenovoWxBottomTip", "");
    setDialogProp(
      "lenovoWxBottomDom",
      <p className="lenovo-wx-dialog-tips" style={{ fontSize: "20px" }}>
        <span>扫码接收后续活动通知</span>
      </p>
    );
    setDialogProp("lenovoWxEvent", () => {
      history.replace(`/activity/${id}?state=pending`);
    });
    setVisibleState("lenovoWx", true);
  };

  return (
    <div className="lenovo-activity-sign-up">
      <Helmet>
        <title>填写报名信息 | 联想数字生态平台</title>
      </Helmet>
      <Nav>
        <div className="lenovo-activity-sign-up__content">
          <CodeEntry />
          <div
            className="sign-up__activity-detail"
            onClick={() => {
              history.push(`/activity/${id}?state=pending`);
            }}
          >
            <div className="sign-up__activity-detail__img">
              <img src={detailData.coverUrl} alt="coverUrl" />
            </div>
            <div className="sign-up__activity-detail__info">
              <p className="item__info-title">{detailData.title}</p>
              <div className="item__info-line-wrap">
                <p className="item__info-line">
                  <img
                    className="item__info-img-1"
                    src={ActivityAddressImg}
                    alt="ActivityAddressImg"
                  />
                  {detailData.address && detailData.address.join(" / ")}
                </p>
                <p className="item__info-line">
                  <img
                    className="item__info-img-2"
                    src={ActivityDateImg}
                    alt="ActivityDateImg"
                  />
                  {detailData.date && detailData.date.pc}
                </p>
              </div>
            </div>
          </div>
          <div className="sign-up__content-form">
            <p className="form-title">填写报名信息</p>
            <Form
              colon={false}
              requiredMark={false}
              size="large"
              form={form}
              initialValues={currentUser}
              onFinish={onFinish}
              autoComplete="off"
              scrollToFirstError
            >
              <Form.Item
                name="full_name"
                label="姓名"
                rules={[{ required: true, message: "输入您的姓名" }]}
              >
                <Input
                  placeholder="输入您的姓名"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item
                name="mobile"
                label="手机号"
                rules={[
                  { required: true, message: "输入您的联系电话" },
                  {
                    pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                    message: "请输入正确的联系电话",
                  },
                ]}
              >
                <Input
                  placeholder="输入您的手机号"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="邮箱地址"
                rules={[
                  { required: true, message: "输入您的邮箱地址" },
                  {
                    pattern: /^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\.)+[A-Za-z]{2,6}$/,
                    message: "请输入正确的邮箱地址",
                  },
                ]}
              >
                <Input
                  placeholder="输入您的邮箱地址"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item
                name="company"
                label="公司名称"
                rules={[{ required: true, message: "输入公司名称" }]}
              >
                <Input
                  placeholder="输入公司名称"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item
                name="department"
                label="部门"
                rules={[{ required: true, message: "输入您的部门" }]}
              >
                <Input
                  placeholder="输入您的部门"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item
                name="position"
                label={"职位"}
                rules={[{ required: true, message: "输入您的职位" }]}
              >
                <Input
                  placeholder="输入您的职位"
                  onBlur={() => window.scrollTo(0, 0)}
                />
              </Form.Item>
              <Form.Item className="form-item--left">
                <div
                  className={`form-submit ${isPosted && "opt-5"}`}
                  onClick={() => {
                    form.submit();
                  }}
                >
                  提交报名信息
                </div>
              </Form.Item>
              <Form.Item
                name="check"
                valuePropName="checked"
                className={`form-item--center ${isAuthUser && "opcity0"}`}
              >
                <Checkbox>申请加入联想未来 CLUB</Checkbox>
              </Form.Item>
              <p className="pt-xy">
                提交报名信息即代表同意平台
                <a
                  target="_blank"
                  href="https://lenovo-assets.jiqizhixin.com/%E8%81%94%E6%83%B3%E6%95%B0%E5%AD%97%E7%94%9F%E6%80%81%E5%B9%B3%E5%8F%B0%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf"
                >
                  《用户协议》
                </a>
              </p>
            </Form>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(ActivitySignUp);
