import React from "react";

const SVG = ({
  fill = "#8D98BC",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M7.365 14h-2.73C2.045 14 0 14 0 12.654v-.269c0-2.522 2.08-4.573 4.635-4.573h2.73C9.92 7.812 12 9.864 12 12.385v.269C12 14 9.851 14 7.365 14zM5.863 7.264c-2.029 0-3.679-1.63-3.679-3.632C2.184 1.629 3.834 0 5.864 0c2.029 0 3.679 1.629 3.679 3.632 0 2.002-1.651 3.632-3.68 3.632zM2.943 3.62c0 1.583 1.31 2.872 2.92 2.872 1.611 0 2.922-1.289 2.922-2.872 0-1.584-1.31-2.872-2.922-2.872-1.61 0-2.92 1.288-2.92 2.872z"
    />
  </svg>
);

export default SVG;
