import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 16 16" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>document_6</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="document_6">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <path
          d="M2.5,0.5 C2.3125,0.5 2.1125,0.575 1.975,0.725 C1.8375,0.875 1.75,1.0625 1.75,1.25 L1.75,14.75 C1.75,14.9375 1.825,15.1375 1.975,15.275 C2.125,15.425 2.3125,15.5 2.5,15.5 L13.5,15.5 C13.6875,15.5 13.8875,15.425 14.025,15.275 C14.175,15.125 14.25,14.9375 14.25,14.75 L14.25,4.75 L10,0.5 L2.5,0.5 Z"
          id="Path"
          fill="#FF5562"
        />
        <path
          d="M14.25,4.75 L10.75,4.75 C10.5625,4.75 10.3625,4.675 10.225,4.525 C10.075,4.3875 10,4.1875 10,4 L10,0.5 L14.25,4.75 Z"
          id="Path"
          fill="#FFBBC0"
        />
        <path
          d="M10.875,13.175 C10.0875,13.175 9.3875,11.825 9.0125,10.95 C8.3875,10.6875 7.7,10.45 7.0375,10.2875 C6.45,10.675 5.4625,11.25 4.7,11.25 C4.225,11.25 3.8875,11.0125 3.7625,10.6 C3.6625,10.2625 3.75,10.025 3.85,9.9 C4.05,9.625 4.4625,9.4875 5.0875,9.4875 C5.5875,9.4875 6.225,9.575 6.9375,9.75 C7.4,9.425 7.8625,9.05 8.275,8.65 C8.0875,7.775 7.8875,6.3625 8.4,5.7125 C8.65,5.4 9.0375,5.3 9.5,5.4375 C10.0125,5.5875 10.2,5.9 10.2625,6.1375 C10.475,6.9875 9.5,8.1375 8.8375,8.8125 C8.9875,9.4 9.175,10.0125 9.4125,10.575 C10.3625,11 11.4875,11.625 11.6125,12.3125 C11.6625,12.55 11.5875,12.775 11.4,12.9625 C11.2375,13.1 11.0625,13.175 10.875,13.175 Z M9.7125,11.275 C10.1875,12.2375 10.6375,12.6875 10.875,12.6875 C10.9125,12.6875 10.9625,12.675 11.0375,12.6125 C11.125,12.525 11.125,12.4625 11.1125,12.4125 C11.0625,12.1625 10.6625,11.75 9.7125,11.275 L9.7125,11.275 Z M5.0875,9.9875 C4.475,9.9875 4.3,10.1375 4.25,10.2 C4.2375,10.225 4.1875,10.2875 4.2375,10.4625 C4.275,10.6125 4.375,10.7625 4.7,10.7625 C5.1,10.7625 5.675,10.5375 6.35,10.1375 C5.8625,10.0375 5.4375,9.9875 5.0875,9.9875 L5.0875,9.9875 Z M7.5625,9.9125 C7.9625,10.025 8.375,10.1625 8.7625,10.3125 C8.625,9.95 8.5125,9.575 8.4125,9.2125 C8.1375,9.45 7.85,9.6875 7.5625,9.9125 Z M9.1125,5.875 C8.975,5.875 8.875,5.925 8.7875,6.025 C8.525,6.35 8.5,7.175 8.7,8.225 C9.4625,7.4125 9.875,6.6625 9.775,6.2625 C9.7625,6.2 9.7125,6.025 9.3625,5.925 C9.2625,5.8875 9.1875,5.875 9.1125,5.875 L9.1125,5.875 Z"
          id="Shape"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
