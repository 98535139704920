import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import "./style.scss";
import { Nav, Footer, Breadcrumbs, CodeEntry } from "../shared/index";
import Icon from "../shared/icon/Icon";
import { isMobile } from "../../utils/tools";
import circleCooperStore from "../../stores/circleCooperStore";
import globalStore from "../../stores/globalStore";
import authStore from "../../stores/authStore";
import Main from "./Main";
import Handle from "./Handle";

interface Props {}

const CircleCooperation: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { combinesDetail, fetchCombinesDetailData } = useContext(
    circleCooperStore
  );
  const { setVisibleState } = useContext(globalStore);
  const { isAuthUser, loginLoading } = useContext(authStore);

  useEffect(() => {
    if (!loginLoading) {
      !isAuthUser && setVisibleState("toAuth", true);
      fetchCombinesDetailData(id);
    }
  }, [loginLoading]);

  return (
    <div className="lenovo-circle-cooperation lenovo-cooperation-detail">
      <Helmet>
        <title>{`${combinesDetail.title} | 联想数字生态平台`}</title>
      </Helmet>
      <Nav bgc={`${isMobile() ? "#fff" : "#f5f6f7"}`}>
        <div className="lenovo-circle-cooperation__content">
          <CodeEntry />
          <Breadcrumbs
            items={combinesDetail.breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          <Main />
          {((!combinesDetail.creator &&
            combinesDetail.schedule === "pending") ||
            (combinesDetail.creator &&
              combinesDetail.schedule === "rejected")) && <Handle />}
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(CircleCooperation);
