import React from "react";
import { Link } from "react-router-dom";

interface EXPERTS {
  id?: string;
  name?: string;
  avatarUrl?: string;
  categoryCn?: string;
}

interface Props {
  extraClassName?: string | "";
  experts: Array<EXPERTS>;
}
const ExpertMember: React.FC<Props> = (props: Props) => {
  const { extraClassName = "", experts } = props;
  return (
    <div className={`expert__container ${extraClassName}`}>
      {experts.map((item, index) => (
        <Link
          to={`/library/experts/${item.id}`}
          key={index}
          className="expert__content"
          target="_blank"
        >
          <div className="expert__avatar">
            <img src={item.avatarUrl} alt="avatar" />
          </div>
          <div className="expert__text">
            <p>{item.name}</p>
            <p>{item.categoryCn}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ExpertMember;
