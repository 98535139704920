import { createContext } from "react";
import { computed, observable, action } from "mobx";
import _ from "lodash";
import dayjs from "dayjs";
import ahoy from "ahoy.js";
import { TAGS } from "../constants/map";

import {
  formatDatabaseData,
  formatDatabaseKeywordData,
  formatDataBaseListData,
} from "../adapter/formatDatabaseData";
import { INSTITUTION } from "../constants/pro_constants";
import {
  fetchKeywordResultData,
  fetchFiltersData,
  fetchDatabaseListData,
  shareInWechat,
  // fetchCooperateExpress,
} from "../api";
import debounce from "../utils/debounce";
import { labelToChinese } from "../constants/label_to_chinese";

class DatabaseStore {
  @observable type = "";
  @observable filters = {};
  @observable filtersCopy = {};
  @observable isShowAllFilters = false;
  @observable isShowAllFilterItems = false;
  @observable keywordResultData = [];
  @observable keyword = "";
  @observable keywordFilters = [];
  @observable selectedKeywordResult = {};
  @observable resultList = {};
  @observable pagination = {};
  @observable paginationMobile = {
    current: 1,
    pageSize: 20,
    total: 0,
  };
  @observable isCustomizeDate = false;
  @observable sortField = "";
  @observable tempSortField = "";
  @observable sortInfo = {};
  @observable isLoading = false;
  @observable isEnableSelectedInFilters = true;
  @observable timeZone = [];
  @observable isGetData = false;
  @observable selectedField = "";
  @observable hasMore = true;
  @observable panelIndex = -1;
  @observable public cooperateExpress = [];
  @observable public tags = TAGS;

  @action
  setTags = (tags: any) => {
    this.tags = tags;
  };

  // @action
  // getCooperateExpress = async () => {
  //   const res = await fetchCooperateExpress();
  //   this.cooperateExpress = res.data.expresses;
  // };

  @action
  setSelectedField = field => {
    this.selectedField = field;
  };

  @action
  setIsEnableSelectedInFilters = status => {
    this.isEnableSelectedInFilters = status;
  };

  @action
  initializeType = (type: any, clear?: boolean) => {
    this.type = type;
    clear && (this.resultList = {});
  };

  @action
  setTimeZone = timeZone => {
    this.timeZone = timeZone;
  };

  @action
  resetFiltersInMobile = () => {
    this.selectedField = "";
    Object.keys(this.filtersCopy).forEach(category => {
      this.filters[category] = this.filtersCopy[category].map(item =>
        Object.assign({}, item)
      );
    });
    this.fetchDatabaseList();
  };

  @action
  resetFilters = () => {
    this.keyword = "";
    this.keywordResultData = [];
    this.keywordFilters = [];
    this.filters = {};
    this.selectedKeywordResult = {};
    this.tempSortField = this.sortField = "";
    this.timeZone = [];
  };

  @action
  initializeIsShowAllFilters = () => {
    this.isShowAllFilters = false;
    this.selectedField = "";
  };

  @action
  initializeFilters = async () => {
    const result = await fetchFiltersData(this.type);
    this.filters = formatDatabaseData(this.type, result.data).filters;
    Object.keys(this.filters).forEach(category => {
      this.filtersCopy[category] = this.filters[category].map(item =>
        Object.assign({}, item)
      );
    });
  };

  @action
  setSortOrderInfo = sorter => {
    if (sorter && sorter.column) {
      this.sortInfo = sorter;
      this.sortField = "";
    } else {
      this.sortField = this.tempSortField;
      this.sortInfo = {};
    }
  };

  @action
  getConditionStr = () => {
    const conditionObj = {};
    let conditions = "";

    this.selectedConditionItems.map(item => {
      if (conditionObj[labelToChinese[item.field]]) {
        conditionObj[labelToChinese[item.field]] += `&${item.name}`;
      } else {
        conditionObj[labelToChinese[item.field]] = item.name;
      }
    });

    Object.keys(conditionObj).map((key, i) => {
      conditions += `${key}#${conditionObj[key]}${
        Object.keys(conditionObj).length - 1 === i ? "" : "|"
      }`;
    });

    return conditions;
  };

  @action
  fetchDatabaseList = async (pagination?, sorter?, isMobile?) => {
    let params = "";
    console.log(this.tagChecked, "tagChecked");

    this.isLoading = true;

    if (this.tagChecked.length > 0) {
      if (this.tagChecked[0] === "tbd") {
        params += "tbd=true&";
      } else {
        params += "tbd=false&";
        params += `tags[]=${this.tagChecked[0]}&`;
      }
    }

    this.selectedConditionItems.forEach(item => {
      if (item.field === "field") {
        params += `technologies[]=${item.id}&`;
        return;
      }
      if (item.field === "keywords") {
        params += `${item.field}[]=${item.name}&`;
        return;
      }
      params += `${item.field}[]=${item.id}&`;
    });
    if (this.selectedKeywordResult[INSTITUTION]) {
      params += `keyword=${this.selectedKeywordResult[INSTITUTION]}&`;
    }
    if (pagination) {
      params += `size=${pagination.pageSize}&page=${pagination.current}&`;
    }
    if (sorter && sorter.column) {
      params += `order=${sorter.field}&rule=${sorter.order}&`;
    }
    if (this.sortField) {
      params += `order=${this.sortField}&`;
    }
    if (!_.isEmpty(this.timeZone)) {
      params += `beginAt=${this.timeZone[0]}&endAt=${this.timeZone[1]}&`;
    }
    const result = await fetchDatabaseListData(this.type, params);
    const tempResultList = formatDataBaseListData(this.type, result.data);

    if (isMobile) {
      const { data, ...config } = tempResultList;
      this.resultList = { ...this.resultList, ...config };
      this.resultList.data = [...this.resultList.data, ...data];
    } else {
      this.resultList = tempResultList;
    }
    this.pagination = {
      current: this.resultList.current - 0,
      pageSize: this.resultList.pageSize,
      total: this.resultList.currentTotalCount,
      defaultPageSize: 20,
      hideOnSinglePage: true,
      position: ["bottomCenter"],
      showSizeChanger: false,
    };
    this.paginationMobile = {
      current: Number(this.resultList.current) + 1,
      pageSize: this.resultList.pageSize,
      total: this.resultList.currentTotalCount,
    };

    this.isLoading = false;
    if (
      this.resultList.data &&
      this.resultList.data.length < this.resultList.currentTotalCount
    ) {
      this.hasMore = true;
    } else {
      this.hasMore = false;
    }
  };

  @action
  toggleFiltersActive = (field, activeName) => {
    if (activeName === "不限") {
      this.filters[field].find(item => item.name === "不限").active = true;
      this.filters[field].map(item => {
        if (item.name !== "不限") {
          item.active = false;
        }
      });
    } else {
      const selectedFilterItem = this.filters[field].find(
        item => item.name === activeName
      );
      this.setFiltersActiveStatus(
        field,
        activeName,
        !selectedFilterItem.active
      );
      if (selectedFilterItem.active) {
        ahoy.track("filter", {
          name: selectedFilterItem.name,
          type: this.type,
        });
      }
    }
    this.fetchDatabaseList();
  };

  @action
  setFiltersInactive = (field, activeName) => {
    if (activeName === "不限") {
      this.filters[field].find(item => item.name === "不限").active = true;
      this.filters[field].map(item => {
        if (item.name !== "不限") {
          item.active = false;
        }
      });
      this.fetchDatabaseList();
      return;
    }

    if (this.filters[field].find(item => item.name === activeName).active) {
      this.setFiltersActiveStatus(
        field,
        activeName,
        !this.filters[field].find(item => item.name === activeName).active
      );

      this.fetchDatabaseList();
    }
  };

  setFiltersActiveStatus = (field, activeName, isActive) => {
    this.filters[field].find(
      item => item.name === activeName
    ).active = isActive;
    this.filters[field].find(
      item => item.name === "不限"
    ).active = !this.filters[field].some(item => item.active === true);
  };

  @action
  setIsShowAllFilters = () => {
    this.isShowAllFilters = !this.isShowAllFilters;
  };

  @action
  setIsCustomizeDate = name => {
    name === "自定义"
      ? (this.isCustomizeDate = true)
      : (this.isCustomizeDate = false);
  };

  @action
  removeConditionItem = (type, data?) => {
    if (type === "all") {
      this.clearConditionItem();
      return;
    }
    if (data.field === "field") {
      this.clearSelectedKeywordResult(data.field, data.name);
      return;
    }
    if (data.color === "blue") {
      this.setFiltersActiveStatus(data.field, data.name, false);
    } else {
      this.keywordFilters = this.keywordFilters.filter(
        ele => ele.name != data.name
      );
    }
    this.fetchDatabaseList();
  };

  clearConditionItem = () => {
    this.keyword = "";
    this.keywordFilters = [];
    this.selectedKeywordResult = {};
    Object.keys(this.filters).map(key => {
      this.filters[key].map(ele => {
        ele.active = ele.name == "不限";
      });
    });
    this.fetchDatabaseList();
  };

  @action
  setKeyword = value => {
    this.keyword = value;
  };

  @action
  handleKeyDownEvent = value => {
    if (value != "") {
      if (this.panelIndex !== -1) {
        const panelItems = document.querySelectorAll(
          ".keyword-result-panel__item"
        );
        const text = panelItems[this.panelIndex].innerText;
        this.keywordFilters.push({ name: text });
        this.selectedKeywordResult = { [INSTITUTION]: text };
      } else {
        this.keywordFilters.push({ name: value });
        this.selectedKeywordResult = { [INSTITUTION]: value };
      }
      this.fetchDatabaseList();
      this.hideSearchPanel();
      this.keyword = "";
    }
  };

  @action
  fetchKeywordResultData = async () => {
    this.isGetData = false;
    debounce(async () => {
      let result = await fetchKeywordResultData(this.type, this.keyword);
      this.keywordResultData = formatDatabaseKeywordData(
        result.data[`${INSTITUTION}s`]
      );
      ahoy.track("keyword", {
        name: this.keyword,
        type: this.type,
      });
      this.panelIndex = -1;
      this.isGetData = true;
    }, 200);
  };

  @action
  setSelectedKeywordResult = (type, name) => {
    this.keyword = "";
    this.selectedKeywordResult = { [type]: name };

    this.fetchDatabaseList();
    ahoy.track("filter", {
      name: name,
      type: this.type,
    });
  };

  @action
  clearSelectedKeywordResult = (type, name?) => {
    if (type === INSTITUTION) {
      this.selectedKeywordResult[type] = "";
    } else {
      this.selectedKeywordResult[type].splice(
        this.selectedKeywordResult[type].findIndex(element => element === name),
        1
      );
    }
    this.fetchDatabaseList();
  };

  @action
  controlPanelIndex = (e: any, type: "mouse" | "keyboard", index?: any) => {
    const setFocus = () => {
      const panelItems = document.querySelectorAll(
        ".keyword-result-panel__item"
      );
      panelItems.forEach(item =>
        item.classList.remove("keyword-result-panel__item--hover")
      );
      panelItems[this.panelIndex].classList.add(
        "keyword-result-panel__item--hover"
      );
    };

    if (type === "mouse") {
      this.panelIndex = index;
      setFocus();
    } else {
      const action = {
        ArrowDown: () => this.panelIndex < this.max && (this.panelIndex += 1),
        ArrowUp: () => this.panelIndex > 0 && (this.panelIndex -= 1),
      };
      if (action[e.code]) {
        action[e.code]();
        setFocus();
      }
    }
  };

  @action
  hideSearchPanel = () => {
    const mobileSearchResultDom = document.querySelector(
      ".mobile-result-panel"
    );
    const mobileSearchResultLayer = document.querySelector(
      ".mobile-result-panel__layer"
    );

    document.querySelector(".keyword-result-panel").classList.add("hide");
    document
      .querySelector(".keyword-result-panel__layer")
      .classList.add("hide");
    mobileSearchResultDom && mobileSearchResultDom.classList.add("hide");
    mobileSearchResultLayer && mobileSearchResultLayer.classList.add("hide");
  };

  @action
  showSearchPanel = () => {
    const searchResultDom = document.querySelector(".keyword-result-panel");
    const searchResultLayer = document.querySelector(
      ".keyword-result-panel__layer"
    );
    const mobileSearchResultDom = document.querySelector(
      ".mobile-result-panel"
    );
    const mobileSearchResultLayer = document.querySelector(
      ".mobile-result-panel__layer"
    );

    searchResultDom && searchResultDom.classList.remove("hide");
    searchResultLayer && searchResultLayer.classList.remove("hide");
    mobileSearchResultDom && mobileSearchResultDom.classList.remove("hide");
    mobileSearchResultLayer && mobileSearchResultLayer.classList.remove("hide");
  };

  @action
  setSortField = value => {
    this.tempSortField = this.sortField = value;
    this.sortInfo = {};
    this.fetchDatabaseList();
  };

  @action
  resetPaginationMobile = () => {
    this.paginationMobile = {
      current: 1,
      pageSize: 20,
      total: 0,
    };
  };

  @action
  shareInWechat = async () => {
    await shareInWechat(this.type);
  };

  @computed
  get tagChecked() {
    return this.tags
      .filter((item: any) => item.checked)
      .map((item: any) => item.value);
  }

  @computed
  get max() {
    return this.keywordResultData.slice(0, 5).length - 1;
  }

  @computed
  get currentUploadAt() {
    return this.filters.uploadedAt.find(item => item.active).name;
  }

  @computed
  get uploadBeginDate() {
    if (this.currentUploadAt === "一月内") {
      return dayjs()
        .subtract(1, "m")
        .format("YYYY-MM-DD");
    }

    if (this.currentUploadAt === "三月内") {
      return dayjs()
        .subtract(3, "m")
        .format("YYYY-MM-DD");
    }

    return dayjs()
      .subtract(7, "d")
      .format("YYYY-MM-DD");
  }

  @computed
  get uploadEndDate() {
    return dayjs().format("YYYY-MM-DD");
  }

  @computed
  get isSelectedConditionsPanelShow() {
    return (
      !_.isEmpty(this.selectedConditionItems) ||
      !_.isEmpty(this.selectedKeywordResult)
    );
  }

  @computed
  get selectedConditionItems() {
    let arr = [];
    Object.keys(this.filters).map(key => {
      this.filters[key].map(ele => {
        if (ele.name != "不限" && ele.active) {
          arr.push({
            field: key,
            name: ele.name,
            id: ele.id,
            color: "blue",
          });
        }
      });
    });
    if (this.selectedKeywordResult.field) {
      this.selectedKeywordResult.field.forEach(item => {
        arr.push({
          field: "field",
          name: item.name,
          id: item.id,
          color: "blue",
        });
      });
    }
    // this.keywordFilters.map(ele => {
    //   arr.push({
    //     name: ele.name,
    //     color: "orange",
    //     field: "keywords",
    //   });
    // });
    return arr;
  }

  @computed
  get keywordResultInstitutionData() {
    return this.keywordResultData && this.keywordResultData.slice(0, 5);
  }

  @computed
  get isKeywordResultInstitutionDataEmpty() {
    return _.isEmpty(this.keywordResultData);
  }

  @computed
  get filterKeys() {
    return Object.keys(this.filters);
  }
}

export default createContext(new DatabaseStore());
