/*
 * @Author: your name
 * @Date: 2020-12-30 12:06:30
 * @LastEditTime: 2021-01-07 11:41:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Lenovo/frontend/sites/application/utils/parseRichStr.ts
 */
const parseRichStr = (str: string): any => {
  const root = document.createElement("div");
  root.innerHTML = str;
  const element = root.getElementsByClassName("trix-content")[0];
  return element.innerHTML.replace(/↵/g, "").trim();
};

export default parseRichStr;
