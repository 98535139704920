const pollMonitoring = (intervals: number = 2592000000) => {
  const nowTime = +new Date();
  const lastTime = localStorage.getItem("visitTime");

  if (!lastTime) {
    localStorage.setItem("visitTime", String(nowTime));
    return location.pathname.includes("library") && true;
  } else {
    if (nowTime - Number(lastTime) >= intervals) {
      localStorage.setItem("visitTime", String(nowTime));
      return location.pathname.includes("library") && true;
    } else {
      return false;
    }
  }
};

export default pollMonitoring;
