import React from "react";

const SVG = ({
  fill = "#c72c21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Data_detail_iocn_4</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Polaris-icon/Major/2-Tone/Envelope">
        <polygon
          id="Fill"
          fill="#FFFFFF"
          points="0.8 2.4 0.8 13.6 15.2 13.6 12 11.2 12 4.8 15.2 2.4"
        />
        <path
          d="M15.2,1.6 L0.8,1.6 C0.3576,1.6 0,1.9584 0,2.4 L0,13.6 C0,14.0416 0.3576,14.4 0.8,14.4 L15.2,14.4 C15.6424,14.4 16,14.0416 16,13.6 L16,2.4 C16,1.9584 15.6424,1.6 15.2,1.6 L15.2,1.6 Z M7.2,9.6 L4,9.6 C3.5576,9.6 3.2,9.9584 3.2,10.4 C3.2,10.8416 3.5576,11.2 4,11.2 L7.2,11.2 C7.6424,11.2 8,10.8416 8,10.4 C8,9.9584 7.6424,9.6 7.2,9.6 M4,8 L5.6,8 C6.0424,8 6.4,7.6416 6.4,7.2 C6.4,6.7584 6.0424,6.4 5.6,6.4 L4,6.4 C3.5576,6.4 3.2,6.7584 3.2,7.2 C3.2,7.6416 3.5576,8 4,8 M1.6,3.2 L12.8008,3.2 L11.52,4.16 C11.3184,4.3112 11.2,4.548 11.2,4.8 L11.2,11.2 C11.2,11.452 11.3184,11.6888 11.52,11.84 L12.8008,12.8 L1.6,12.8 L1.6,3.2 Z M12.8,10.8 L12.8,5.2 L14.4,4 L14.4,12 L12.8,10.8 Z"
          id="Mask"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
