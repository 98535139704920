import React from "react";

const SVG = ({
  fill = "#c72c21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M11.2 2.4L9.6 4 6.401 4 4.8 2.4 2.4 2.4 2.4 15.2 13.601 15.2 13.601 2.4z"
      />
      <path
        fill={fill}
        d="M13.6 1.6h-2.069L10.166.234C10.016.084 9.813 0 9.6 0H6.4c-.213 0-.416.084-.566.234L4.47 1.6H2.4c-.442 0-.8.358-.8.8v12.8c0 .442.358.8.8.8h11.2c.442 0 .8-.358.8-.8V2.4c0-.442-.358-.8-.8-.8zm-.8 12.8H3.2V3.2h1.269l1.365 1.366c.15.15.353.234.566.234h3.2c.213 0 .416-.084.566-.234L11.53 3.2H12.8v11.2zM6.731 1.6H9.27l.8.8-.8.8H6.73l-.8-.8.8-.8zM5.6 7.2c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m4.8 0H8.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m-4.8 3.2c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m4.8 0H8.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8"
      />
    </g>
  </svg>
);

export default SVG;
