export const TAGS = [
  { key: "新投", value: "tbd" },
  { key: "独角兽", value: "独角兽" },
  { key: "专精特新|国", value: "国家级专精特新" },
  { key: "专精特新|省", value: "省级专精特新" },
  { key: "IPO", value: "IPO" },
  { key: "ESG", value: "ESG" },
];

export const TAGS_OBJ = {
  新投: "tbd",
  独角兽: "独角兽",
  国家级专精特新: "专精特新|国",
  省级专精特新: "专精特新|省",
  IPO: "IPO",
  ESG: "ESG",
};
