import dayjs from "dayjs";
import { timeDifference } from "../utils/tools";

const formatCircleData = initData => ({
  ...initData,

  list: initData.list
    ? initData.list.map(item => ({
        ...item,
        topics: item.topics
          ? item.topics.map(topicsItem => ({
              ...topicsItem,
              beginDate: dayjs(topicsItem.beginAt).format("YYYY-MM-DD"),
            }))
          : [],
      }))
    : [],
});

const formatCircleDetailData = initData => ({
  ...initData,
  btnName: "立即加入「" + initData.name + " 」圈子 →",
  maxLength:
    initData.users && initData.users.length <= 10 ? initData.users.length : 9,
  topics: initData.topics
    ? initData.topics.map(topicsItem => ({
        ...topicsItem,
        beginDate: dayjs(topicsItem.beginAt).format("YYYY-MM-DD"),
      }))
    : [],
  cooperates: initData.cooperates
    ? initData.cooperates.map(item => ({
        ...item,
        userPosition: !item.user.positionShow
          ? item.user.company
          : item.user.company && item.user.position
          ? item.user.company + "-" + item.user.position
          : item.user.company + item.user.position,
        deadline: item.effectiveDate
          ? timeDifference(item.effectiveDate)
            ? "长期"
            : `至${dayjs(item.effectiveDate).format("YYYY-MM-DD")}`
          : "",
      }))
    : [],
});

export { formatCircleData, formatCircleDetailData };
