import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useHistory } from "react-router-dom";

import "./style.scss";
import AuthStore from "../../stores/authStore";
import cooperationStore from "../../stores/cooperationStore";
import postCooperStore from "../../stores/postCooperStore";

interface Props {}

const Handle: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { isAuthUser } = useContext(AuthStore);
  const { cooperatesDetail } = useContext(cooperationStore);
  const { setCooperateInfo } = useContext(postCooperStore);

  useEffect(() => {
    (document.querySelector(".lenovo-footer") as any).style.marginBottom = "0";
  }, []);

  return (
    <div
      className={`${
        cooperatesDetail.creator && cooperatesDetail.approval === "rejected"
          ? "response-btn-wrap--position-static"
          : "response-btn-wrap"
      }`}
    >
      {cooperatesDetail.creator && cooperatesDetail.approval === "rejected" && (
        <div
          className="normal-btn"
          onClick={() => {
            isAuthUser &&
              setCooperateInfo({
                isCom: false,
                type: "rePub",
                params: {
                  id: id,
                },
              });
            isAuthUser && history.push("/cooperation/post");
          }}
        >
          重新发布合作需求
        </div>
      )}
      {!cooperatesDetail.creator && cooperatesDetail.approval === "passed" && (
        <div
          className={`response-btn ${
            cooperatesDetail.status === "开放" ? "start-theme" : "over-theme"
          }`}
          onClick={() => {
            if (cooperatesDetail.status === "开放") {
              history.push(`/cooperation/postscript/${cooperatesDetail.id}`);
            }
          }}
        >
          立即响应
        </div>
      )}
    </div>
  );
};

export default observer(Handle);
