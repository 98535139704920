import React, { useContext } from "react";
import { observer } from "mobx-react";
import "./style.scss";

import ActivityNews from "./ActivityNews";
import { Icon, Empty } from "../shared";

import activityDetailStore from "../../stores/activityDetailStore";
// import boardEmpty from "../../assets/images/board-empty.svg";

interface Props {
  [key: string]: any;
}

const ActivityBoard: React.FC<Props> = () => {
  const { detailData } = useContext(activityDetailStore);
  const tips = [
    "activity-red",
    "activity-purple",
    "activity-green",
    "activity-blue",
  ];

  return (
    <div className="activity-detail-board">
      <p className="activity-detail-board__title">留言板</p>
      {detailData.eventMessages.length > 0 ? (
        <div className="activity-detail-board__body">
          {detailData.eventMessages.map((item: any, index: number) => (
            <div key={index} className="activity-detail-board__item-wrap">
              <div className="activity-detail-board__item">
                <div className="activity-detail-board__item__tip">
                  <Icon name={tips[index % 4]} size={57} />
                </div>
                <div className="activity-detail-board__item__header">
                  <Icon name="cooperation-card-user" size={16} />
                  <p>{item.user.name}</p>
                </div>
                <div className="activity-detail-board__item__content">
                  {item.content}
                </div>
                <div className="activity-detail-board__item__time">
                  {item.publishedAt}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="activity-detail-board__item--empty">
          <div className="activity-detail-board__item__tip--empty">
            <Icon name={tips[0]} size={57} />
          </div>
          <Empty title="暂无留言，快来抢沙发吧~ " />
        </div>
      )}
      {detailData.eventDrafts && detailData.eventDrafts.length > 0 && (
        <ActivityNews />
      )}
    </div>
  );
};

export default observer(ActivityBoard);
