import { action, observable } from "mobx";
import { createContext } from "react";

import { fetchExpress } from "../api/index";
import { formatData } from "../adapter/formatExpressListData";

const pagination = {
  currentPage: 1,
  pageSize: 9,
  totalCount: 0,
  hasNextPage: false,
};

class ExpressListStore {
  @observable public loading = true;
  @observable public data: any = {};
  @observable public pagination = JSON.parse(JSON.stringify(pagination));

  @action
  public initPage = () => {
    this.pagination = JSON.parse(JSON.stringify(pagination));
  };

  @action
  public fetchData = async (isMobile?: boolean) => {
    this.loading = true;
    const res = await fetchExpress({
      currentPage: this.pagination.currentPage,
      pageSize: this.pagination.pageSize,
    });
    const { currentPage, totalCount, hasNextPage } = res.data;
    const _res = formatData(res.data);
    const { expresses, ...other } = _res;
    this.data = {
      ...other,
      expresses: isMobile ? [...this.data.expresses, ...expresses] : expresses,
    };
    this.pagination = {
      ...this.pagination,
      currentPage,
      hasNextPage,
      totalCount,
    };
    this.loading = false;
  };

  @action
  public setPageSize = (size: number) => {
    this.pagination.pageSize = size;
  };

  @action
  public setPageNum = (page: number) => {
    this.pagination.currentPage = page;
  };
}

export default createContext(new ExpressListStore());
