import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import cooperationStore from "../../stores/cooperationStore";
import { Icon } from "../shared";

interface Props {}

const Info: React.FC<Props> = () => {
  const { creator, reply } = useContext(cooperationStore);

  return (
    <div className="cooperation-detail__info">
      {!creator && reply.schedule === "rejected" && (
        <div className="cooperation-detail__info__section cooperation-detail__info__reason">
          <p className="info__reason-title">拒绝的原因：</p>
          <p className="info__reason-content">您的合作我们暂时无法与您对接</p>
        </div>
      )}
      {(reply.schedule === "proceed" ||
        (creator && reply.schedule === "pending") ||
        (creator && reply.schedule === "rejected") ||
        reply.schedule === "success") &&
        reply.user && (
          <div className="cooperation-detail__info__section cooperation-detail__info__partner">
            <p className="info__partner-title">合作者身份:</p>
            <div className="info__partner-info">
              <Icon name="cooperation-card-user" size={16} />
              <div className="partner-info">
                <p className="info-name">{reply.user.fullName}</p>
                <p className="info-department">
                  {reply.user.company}
                  <span>
                    {reply.user.department}
                    {reply.user.positionShow && reply.user.position
                      ? "-" + reply.user.position
                      : ""}
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
      {(reply.schedule === "proceed" ||
        (creator && reply.schedule === "pending") ||
        (creator && reply.schedule === "rejected") ||
        reply.schedule === "success") && (
        <div className="cooperation-detail__info__section cooperation-detail__info__reason">
          <p className="info__reason-title">附言：</p>
          <p className="info__reason-content">{reply.content}</p>
        </div>
      )}
    </div>
  );
};

export default observer(Info);
