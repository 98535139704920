import React from "react";

const SVG = ({
  fill = "#999",
  width = "100%",
  className = "",
  viewBox = "0 0 5 8",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M4.868 4.341.788 7.883c-.191.16-.502.16-.669-.023A.532.532 0 0 1 0 7.54V.457C0 .206.215 0 .477 0c.12 0 .239.046.31.114l4.057 3.542c.191.16.215.457.024.64.024.022 0 .022 0 .045z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

export default SVG;
