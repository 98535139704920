import React from "react";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { INSTITUTION } from "../../constants/pro_constants";

interface Props {
  children?: React.ReactNode;
  isLoading?: boolean;
  hasMore?: boolean;
  dataLength?: number;
  pagination?: any;
  showSkeleton?: boolean;
  next?(pagination: any, sorter: any, isMobile: boolean): void;
  loader?: React.ReactNode;
  type?: string;
}

const DatabaseListPanelMobile: React.FC<Props> = (props: Props) => {
  const {
    children,
    dataLength,
    pagination,
    isLoading,
    hasMore,
    next,
    loader,
  } = props;

  return (
    <div
      id="database-list-panel--mobile"
      className={`database-list-panel--mobile ${props.type !== INSTITUTION &&
        "database-list-panel--mobile--origin"}`}
    >
      <InfiniteScroll
        className="database-list-panel__content--mobile"
        dataLength={dataLength}
        next={() => next(pagination, null, true)}
        scrollThreshold="200px"
        hasMore={hasMore}
        scrollableTarget="database-list-panel--mobile"
        loader={isLoading && loader}
      >
        {pagination.current === 1
          ? !isLoading && children
          : children && children}
      </InfiniteScroll>
    </div>
  );
};

export default observer(DatabaseListPanelMobile);
