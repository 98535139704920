import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 57 57" }) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="联想创投-UI-0617"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.100000001"
    >
      <path
        d="M4.87184335,18.8363094 L4.8711146,19.1040382 L51.4401146,19.1040382 L51.4403858,18.8363094 L58.1613999,18.8363094 L59.0862366,19.5832868 L57.4284831,20.1469518 L59.9209191,20.5244369 L60.330522,21.3360474 L59.0862366,21.8561496 L60.330522,22.5029638 L58.1613999,23.1749614 L59.5085047,23.7272277 L57.4284831,24.4863508 L60.330522,24.7441045 L57.4284831,25.7043408 L59.0862366,26.1964321 L58.1613999,26.8273411 L61.4403858,27.8363094 L58.7578544,28.5119741 L59.9209191,29.3462054 L58.1613999,30.0620308 L60.330522,30.7771493 L58.1613999,31.4419895 L60.330522,32.1972246 L58.1613999,33.2550133 L59.5085047,34.18609 L56.9747625,35.1437639 L59.0862366,35.9075702 L59.0862366,36.8363094 L56.5401146,36.8360382 L56.5410342,37.1040444 L4.50360336,37.1040444 L4.5031146,36.8360382 L-2.77400735,36.8363094 L-2.77400735,35.9075702 L-0.662533289,35.1437639 L-3.19627552,34.18609 L-1.84917067,33.2550133 L-4.01829278,32.1972246 L-1.84917067,31.4419895 L-4.01829278,30.7771493 L-1.84917067,30.0620308 L-3.60868986,29.3462054 L-2.44562519,28.5119741 L-5.12815665,27.8363094 L-1.84917067,26.8273411 L-2.77400735,26.1964321 L-1.11625394,25.7043408 L-4.01829278,24.7441045 L-1.11625394,24.4863508 L-3.19627552,23.7272277 L-1.84917067,23.1749614 L-4.01829278,22.5029638 L-2.77400735,21.8561496 L-4.01829278,21.3360474 L-3.60868986,20.5244369 L-1.11625394,20.1469518 L-2.77400735,19.5832868 L-1.84917067,18.8363094 L4.87184335,18.8363094 Z"
        id="purple"
        fill="#3200DA"
        transform="translate(28.156115, 28.052038) rotate(-45.000000) translate(-28.156115, -28.052038) "
      />
    </g>
  </svg>
);

export default SVG;
