import React from "react";

const SVG = ({
  fill = "#C92B17",
  width = "100%",
  className = "",
  viewBox = "0 0 16 17",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill} stroke="#CB2904" strokeWidth=".4">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M6.369 9.216c1.813 0 3.29-1.475 3.29-3.29 0-1.813-1.477-3.289-3.29-3.289-1.814 0-3.29 1.475-3.29 3.29 0 1.814 1.476 3.29 3.29 3.29zm0-5.64c1.295 0 2.35 1.055 2.35 2.35 0 1.296-1.055 2.35-2.35 2.35-1.296 0-2.35-1.054-2.35-2.35 0-1.295 1.054-2.35 2.35-2.35zm4.523 6.166c1.013-1.199 1.56-2.803 1.355-4.531C11.93 2.507 9.75.344 7.046.043 3.478-.355.446 2.439.446 5.927c0 1.474.544 2.823 1.438 3.86l-1.858 3.38c-.07.126.022.28.165.28h2.181c.127 0 .244.063.314.168l1.285 1.97c.077.118.251.113.321-.008L6.4 11.9l2.11 3.675c.069.122.242.127.32.01l1.286-1.97c.07-.106.187-.17.312-.17h2.183c.143 0 .233-.153.165-.278l-1.884-3.426zM5.718.987c3.255-.415 6.005 2.335 5.59 5.59-.283 2.223-2.066 4.007-4.289 4.29-3.255.414-6.005-2.336-5.59-5.59.283-2.224 2.065-4.007 4.289-4.29h0zM4.006 13.919l-.811-1.243c-.07-.106-.187-.17-.314-.17H1.665c-.043 0-.082-.022-.103-.058-.022-.037-.023-.082-.002-.119l1.02-1.856c.806.674 1.778 1.121 2.814 1.294l-1.232 2.147c-.015.028-.045.046-.077.046-.032.001-.062-.014-.08-.041h0zm5.601-1.243l-.812 1.243c-.017.027-.047.043-.079.041-.032 0-.06-.018-.076-.046l-1.238-2.158c1.034-.183 2-.64 2.799-1.32l1.064 1.935c.016.028.015.063-.001.09-.017.029-.047.046-.08.045H9.922c-.126 0-.244.064-.313.17h0z"
                    transform="translate(-250 -544) translate(220 235) translate(30 305) translate(1) translate(0 5) translate(.6)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
