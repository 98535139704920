import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import Icon from "../shared/icon/Icon";
import { isMobile } from "../../utils/tools";
import cooperationStore from "../../stores/cooperationStore";
import State from "./State";
import Info from "./Info";

interface Props {}

const Main: React.FC<Props> = () => {
  const { cooperatesDetail, creator, reply } = useContext(cooperationStore);

  return (
    <div className="lenovo-cooperation-detail__content-wrap">
      {(reply.schedule === "rejected" ||
        reply.schedule === "proceed" ||
        reply.schedule === "success") && <State />}
      {(reply.schedule === "rejected" ||
        reply.schedule === "proceed" ||
        (creator && reply.schedule === "pending") ||
        (creator && reply.schedule === "success")) && <Info />}
      <div className="lenovo-cooperation-detail__content-main">
        <div className="content-title">{cooperatesDetail.title}</div>
        {!(
          reply.schedule === "rejected" ||
          reply.schedule === "proceed" ||
          (creator && reply.schedule === "pending") ||
          reply.schedule === "success"
        ) && (
          <Icon
            name={`${
              cooperatesDetail.status === "开放"
                ? "cooperation-card-open"
                : cooperatesDetail.status === "已结束"
                ? "cooperation-card-over"
                : ""
            }`}
            size={45}
          />
        )}
        <div className="lenovo-cooperation-detail__circle">
          <p>来自</p>
          {cooperatesDetail.circles &&
            cooperatesDetail.circles.length > 0 &&
            cooperatesDetail.circles
              .slice(0, `${isMobile() ? 3 : cooperatesDetail.circles.length}`)
              .map((item: any, index: number) => (
                <div
                  className="lenovo-cooperation-detail__circle-item"
                  key={index}
                >
                  {item.name}
                </div>
              ))}
          {(!cooperatesDetail.circles ||
            cooperatesDetail.circles.length == 0) && (
            <div className="lenovo-cooperation-detail__circle-item">
              联想未来CLUB
            </div>
          )}
          <p>圈子</p>
        </div>
        <div className="content-content">{cooperatesDetail.summary}</div>
        <div className="content-tags">
          {cooperatesDetail.categories &&
            cooperatesDetail.categories.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
          {cooperatesDetail.scenarios &&
            cooperatesDetail.scenarios.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
        </div>
        <div className="content-info">
          <p
            className={`info-location ${!(
              cooperatesDetail.address && cooperatesDetail.address.length > 0
            ) && "info-opacity0"}`}
          >
            <Icon name="location" size={12} color={"#aaa"} />
            区域：
            {cooperatesDetail.address &&
              cooperatesDetail.address.map((item: any, index: number) => (
                <span key={index}>{item}</span>
              ))}
          </p>
          <div className="info-time">
            <p>更新时间：{cooperatesDetail.startTime}</p>
            <p className="expiry-date">
              有效期：
              {`${
                cooperatesDetail.deadline ? cooperatesDetail.deadline : "一年"
              }`}
            </p>
          </div>
        </div>
        {!creator &&
          (reply.schedule === "rejected" || reply.schedule === "success") &&
          cooperatesDetail.user && (
            <div className="lenovo-cooperation-detail__footer">
              <p className="footer-title">发布者</p>
              <div className="footer-publish-info">
                <Icon name="cooperation-card-user" size={16} />
                <div className="publish-person-info">
                  <p className="info-name">{cooperatesDetail.user.fullName}</p>
                  <p className="info-department">
                    {cooperatesDetail.user.company}
                    {cooperatesDetail.user.positionShow &&
                    cooperatesDetail.user.position
                      ? "-" + cooperatesDetail.user.position
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default observer(Main);
