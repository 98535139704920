import React, {
  useContext,
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { Tooltip, DatePicker, Skeleton, Input, Carousel, Divider } from "antd";

import { Icon, Collapses } from "../shared";
import databaseStore from "../../stores/databaseStore";
import KeywordResultPanel from "./KeywordResultPanel";
import DatabaseStore from "../../stores/databaseStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  TECHNOLOGIES,
  INSTITUTION,
  SOLUTION,
  EXPERT,
} from "../../constants/pro_constants";
import globalStore from "../../stores/globalStore";
import reportImg from "../../assets/images/cooperation-report.svg";

interface Props {
  type: string;
}

const FilterSearchPanel: React.FC<Props> = ({ type }: Props) => {
  const history = useHistory();
  const [placeholder, setPlaceholder] = useState("");
  const {
    tags,
    keyword,
    filterKeys,
    setTags,
    fetchDatabaseList,
    fetchKeywordResultData,
    initializeIsShowAllFilters,
    showSearchPanel,
    setKeyword,
    handleKeyDownEvent,
    controlPanelIndex,
  } = useContext(DatabaseStore);
  const { cooperateExpress } = useContext(globalStore);
  const hasFilterKeys = filterKeys.length > 0;

  useEffect(() => {
    initializeIsShowAllFilters();
    if (type === INSTITUTION) {
      setPlaceholder("请输入关键词查找相关企业");
      if (localStorage.getItem("tbd") === "true") {
        (document.querySelector(
          `.databse-filter-panel__tag--${tags[0].key}`
        ) as any).click();
      }
      localStorage.setItem("tbd", "false");
    }
    if (type === SOLUTION) {
      setPlaceholder("请输入企业名称以查找相关解决方案");
    }
  }, [type]);

  const Banner = (
    <div className="database-filter-panel__banner">
      <div className="panel__banner-left">
        <img src={reportImg} alt="report" />
      </div>
      <Divider type="vertical" />
      <Carousel
        autoplay
        className="database-filter-panel__carousel"
        infinite
        dotPosition="left"
        dots={false}
      >
        {cooperateExpress.map((item: any, index: number) => (
          <div className="panel__banner-right-wrap" key={index}>
            <div
              className="panel__banner-main"
              // onClick={() => history.push(`/cooperation/detail/${item.id}`)}
            >
              <p className="panel__banner-main-title">{item.title}</p>
              <p className="panel__banner-main-desc">{item.summary}</p>
            </div>
            <div
              className="panel__banner-right"
              onClick={() => history.push("/cooperation")}
            >
              查看全部生态合作
              <Icon
                className="arrow-right-normal"
                size={10}
                name="lenovo-arrow-right"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );

  const Tags = (isMobile?: boolean) => (
    <div
      className={`databse-filter-panel__tags ${isMobile &&
        "databse-filter-panel__tags--mobile"}`}
    >
      <Divider type="vertical" />
      {tags.map((item: any, index: number) => (
        <div
          key={index}
          onClick={() => {
            tags.forEach((tag: any, tagIndex: number) => {
              if (index === tagIndex) {
                tag.checked = !tag.checked;
              } else {
                tag.checked = false;
              }
            });
            setTags([...tags]);
            fetchDatabaseList();
          }}
          className={`databse-filter-panel__tag databse-filter-panel__tag--${
            item.key
          } ${item.key.includes("|") &&
            "databse-filter-panel__tag--small"} ${item.checked &&
            "databse-filter-panel__tag--checked"}`}
        >
          {item.key.includes("|") ? (
            <>
              <span>{item.key.split("|")[0]}</span>
              <span className="panel__tag--small">
                {item.key.split("|")[1] === "国" && (
                  <Icon
                    name="lenovo-country"
                    size={14}
                    color={item.checked ? "#fff" : "#DA0010"}
                  />
                )}
                {item.key.split("|")[1] === "省" && (
                  <Icon
                    name="lenovo-province"
                    size={14}
                    color={item.checked ? "#fff" : "#DA0010"}
                  />
                )}
              </span>
            </>
          ) : (
            item.key
          )}
        </div>
      ))}
    </div>
  );

  return (
    <>
      {cooperateExpress.length > 0 && Banner}
      {type !== EXPERT && (
        <>
          <div className="databse-filter-panel__input">
            <Input
              placeholder={placeholder}
              type="text"
              value={keyword}
              onChange={event => {
                setKeyword(event.target.value);
                fetchKeywordResultData();
                showSearchPanel();
              }}
              onPressEnter={event => {
                handleKeyDownEvent(event.target.value);
              }}
              onKeyUp={e => controlPanelIndex(e, "keyboard")}
              prefix={<Icon name="lenovo-search" size={18} color="#c72c21" />}
            />
            {type === INSTITUTION && Tags()}
            <KeywordResultPanel type={type} />
          </div>
          <div className="lenovo-divider" />
        </>
      )}
      {hasFilterKeys ? (
        type === INSTITUTION && Tags(true)
      ) : (
        <Skeleton
          className="database-filter-panel__skeleton--mobile"
          active
          title={false}
          paragraph={{ rows: 1, width: "80%" }}
        />
      )}
      <div
        className={`database-filter-panel ${type !== INSTITUTION &&
          "database-filter-panel--origin"}`}
      >
        {hasFilterKeys ? (
          <>
            {filterKeys.map(key => {
              return <Category key={`${type}${key}`} field={key} />;
            })}
            <MobileCategory filterKeys={filterKeys} type={type} />
            <MobileSeachPanel placeholder={placeholder} />
          </>
        ) : (
          <>
            <Skeleton
              className="database-filter-panel__skeleton"
              active
              title={false}
              paragraph={{ rows: 3, width: ["25%", "50%", "40%"] }}
            />
            <Skeleton
              className="database-filter-panel__skeleton--mobile"
              active
              title={false}
              paragraph={{ rows: 1, width: "80%" }}
            />
          </>
        )}
      </div>
    </>
  );
};

const MobileSeachPanel = observer(({ placeholder }) => {
  const [isShowSearchInput, setIsShowSearchInput] = useState(false);
  const {
    keyword,
    type,
    setKeyword,
    fetchKeywordResultData,
    showSearchPanel,
    handleKeyDownEvent,
    setSelectedField,
    hideSearchPanel,
    controlPanelIndex,
  } = useContext(DatabaseStore);
  return (
    <div className="database-filter-panel__search">
      {type !== EXPERT && !isShowSearchInput && (
        <span
          className="database-filter-panel__search--icon"
          onClick={() => {
            setIsShowSearchInput(true);
            setSelectedField("");
          }}
        >
          <Icon name="lenovo-search" size={20} />
        </span>
      )}
      {isShowSearchInput && (
        <Input
          className="database-filter-panel__search__input"
          placeholder={placeholder}
          type="text"
          bordered={false}
          value={keyword}
          onChange={event => {
            setKeyword(event.target.value);
            fetchKeywordResultData();
            showSearchPanel();
          }}
          onPressEnter={event => {
            handleKeyDownEvent(event.target.value);
          }}
          onKeyUp={e => controlPanelIndex(e, "keyboard")}
          prefix={<Icon name="lenovo-search" size={18} color="#c72c21" />}
          suffix={
            <span
              className="database-filter-panel__search__close-icon"
              onClick={() => {
                setIsShowSearchInput(false);
                hideSearchPanel();
              }}
            >
              <Icon name="lenovo-close" color="#92929d" />
            </span>
          }
        />
      )}
      <KeywordResultPanel isMobile type={type} />
    </div>
  );
});

const MobileCategory = observer(({ filterKeys, type }) => {
  const [isShowContent, setIsShowContent] = useState(false);
  const {
    filters,
    selectedField,
    resultList: { count },
    setSelectedField,
    setIsEnableSelectedInFilters,
    toggleFiltersActive,
    resetFiltersInMobile,
  } = useContext(DatabaseStore);

  return (
    <div className="mobile-filter-panel__category-block">
      {filterKeys.map(field => {
        return (
          <div className="mobile-filter-panel__name" key={field}>
            <span
              className={`mobile-filter-panel__name-item ${
                field === selectedField ? "selected" : ""
              }`}
              onClick={() => {
                setIsShowContent(!isShowContent);
                setSelectedField(field === selectedField ? "" : field);
              }}
            >
              {labelToChinese[field]}
              <Icon
                name={
                  field === selectedField
                    ? "lenovo-arrow-up"
                    : "lenovo-arrow-down"
                }
                color={field === selectedField ? "#c72c21" : "#92929D"}
              />
            </span>
          </div>
        );
      })}
      {filters[selectedField] && (
        <>
          <div
            className={`mobile-filter-panel__content ${type !== INSTITUTION &&
              "mobile-filter-panel__content--origin"}`}
          >
            <div className="mobile-filter-panel__wrapper">
              <div className="mobile-filter-panel__options">
                {filters[selectedField].map((item, i) => {
                  const activeClass = item.active ? "active" : "";

                  return (
                    <span
                      key={i}
                      className={`mobile-filter-panel__option ${activeClass}`}
                      onClick={() => {
                        setIsEnableSelectedInFilters(true);
                        toggleFiltersActive(selectedField, item.name);
                      }}
                    >
                      {item.active && <span>&#10003;</span>}
                      {item.name}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="mobile-filter-panel__action">
              <a onClick={() => resetFiltersInMobile()}>重置</a>
              <a onClick={() => setSelectedField("")}>确定({count})</a>
            </div>
          </div>
          <div
            className={`mobile-filter-panel__layer ${type !== INSTITUTION &&
              "mobile-filter-panel__layer--origin"}`}
            onClick={() => {
              setSelectedField("");
            }}
          />
        </>
      )}
    </div>
  );
});

const Category = observer(({ field }) => {
  const {
    filters,
    toggleFiltersActive,
    isCustomizeDate,
    setIsEnableSelectedInFilters,
    fetchDatabaseList,
    setTimeZone,
  } = useContext(databaseStore);

  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const { RangePicker } = DatePicker;
  const fieldRef = useRef(null);
  const styles =
    isShowCollapse && !isShowAll
      ? { overflow: "hidden", maxHeight: "35px" }
      : { height: `${fieldRef.current && fieldRef.current.scrollHeight}px` };

  useLayoutEffect(() => {
    const categoryHeight = fieldRef.current.clientHeight;
    if (categoryHeight > 40) {
      setIsShowCollapse(true);
    }
  }, []);

  return (
    <div className="database-filter-panel__category-block">
      <div className="database-filter-category__name">
        <span
          className={`database-filter-category__name-item ${
            field === TECHNOLOGIES ? "highlight" : ""
          }`}
        >
          {labelToChinese[field]}
        </span>
      </div>
      <div
        className={`database-filter-category__items ${field}`}
        style={styles}
        ref={fieldRef}
      >
        {filters[field] &&
          filters[field].map((item, i) => {
            const activeClass = item.active ? "active" : "";
            return (
              <React.Fragment key={i}>
                <Tooltip title={item.tips} placement="bottom" key={i}>
                  <span
                    key={i}
                    className={`database-filter-category__text ${activeClass}`}
                    onClick={() => {
                      setIsEnableSelectedInFilters(true);
                      toggleFiltersActive(field, item.name);
                    }}
                  >
                    {item.name}
                  </span>
                </Tooltip>
                {isCustomizeDate && item.name === "自定义" && (
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={(time, timeString) => {
                      if (timeString[0] !== "") {
                        setTimeZone(timeString);
                        fetchDatabaseList();
                      }
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
      </div>
      {isShowCollapse && (
        <div className="database-filter-panel__category-collapses">
          <Collapses
            openedText="收起"
            closedText="展开"
            isOpen={false}
            onChange={() => setIsShowAll(!isShowAll)}
          />
        </div>
      )}
    </div>
  );
});

export default observer(FilterSearchPanel);
