import React from "react";

const SVG = ({
  fill = "#F9EAB0",
  width = "100%",
  className = "",
  viewBox = "0 0 36 36",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>delete-n</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="hint"
        transform="translate(-333.000000, -1738.000000)"
        fillRule="nonzero"
      >
        <g id="Group-2" transform="translate(230.000000, 329.000000)">
          <g id="tips" transform="translate(30.000000, 1391.000000)">
            <g id="Group-4" transform="translate(73.000000, 18.000000)">
              <g id="hint">
                <path
                  d="M13.862912,3.9424 L16.4864,3.9424 C17.2781554,3.9424 17.92,4.58424458 17.92,5.376 L17.92,15.0528 C17.92,15.8445554 17.2781554,16.4864 16.4864,16.4864 L12.9024,16.4864 C12.6769543,16.4871274 12.4648093,16.5931999 12.32896,16.77312 L9.511936,20.529152 C9.37526819,20.6926822 9.17312019,20.7871889 8.96,20.7871889 C8.74687981,20.7871889 8.54473181,20.6926822 8.408064,20.529152 L5.59104,16.77312 C5.45519067,16.5931999 5.24304571,16.4871274 5.0176,16.4864 L1.4336,16.4864 C0.641844582,16.4864 0,15.8445554 0,15.0528 L0,5.376 C0,4.58424458 0.641844582,3.9424 1.4336,3.9424 L13.862912,3.9424 Z"
                  id="Shape"
                  fill="#0062FF"
                />
                <path
                  d="M7.8848,16.128 C7.68686116,16.128 7.5264,15.9675388 7.5264,15.7696 L7.5264,15.0528 C7.5264,14.8548612 7.68686116,14.6944 7.8848,14.6944 C8.08273884,14.6944 8.2432,14.8548612 8.2432,15.0528 L8.2432,15.7696 C8.2432,15.9675388 8.08273884,16.128 7.8848,16.128 Z"
                  id="Shape"
                  fill="#464F5D"
                />
                <path
                  d="M10.0352,16.128 C9.83726116,16.128 9.6768,15.9675388 9.6768,15.7696 L9.6768,15.0528 C9.6768,14.8548612 9.83726116,14.6944 10.0352,14.6944 C10.2331388,14.6944 10.3936,14.8548612 10.3936,15.0528 L10.3936,15.7696 C10.3936,15.9675388 10.2331388,16.128 10.0352,16.128 Z"
                  id="Shape"
                  fill="#464F5D"
                />
                <g
                  id="Group"
                  transform="translate(1.040200, 0.000000)"
                  fill="#F7B915"
                >
                  <path
                    d="M2.1854,2.15040004 C2.10398575,2.15043817 2.02498453,2.12275614 1.9614,2.0719104 L0.1694,0.6383104 C0.0148097546,0.514598616 -0.0102221844,0.288990245 0.1134896,0.1344 C0.237201384,-0.0201902454 0.462809755,-0.0452221844 0.6174,0.0784896 L2.4094,1.5120896 C2.52830962,1.60717631 2.57416454,1.76701298 2.52374916,1.91067671 C2.47333376,2.05434043 2.33765299,2.15047143 2.1854,2.15040004 Z"
                    id="Shape"
                  />
                  <path
                    d="M13.6542,2.15040004 C13.501947,2.15047143 13.3662662,2.05434043 13.3158508,1.91067671 C13.2654355,1.76701298 13.3112904,1.60717631 13.4302,1.5120896 L15.2222,0.0784896 C15.3767902,-0.0452221844 15.6023986,-0.0201902454 15.7261104,0.1344 C15.8498222,0.288990245 15.8247902,0.514598616 15.6702,0.6383104 L13.8782,2.0719104 C13.8146155,2.12275614 13.7356143,2.15043817 13.6542,2.15040004 Z"
                    id="Shape"
                  />
                  <path
                    d="M0.3934,12.544 C0.24114701,12.5440714 0.105466239,12.4479404 0.0550508385,12.3042767 C0.00463543808,12.160613 0.0504903849,12.0007763 0.1694,11.9056896 L1.9614,10.4720896 C2.06140236,10.3920621 2.1965025,10.3714643 2.31580952,10.4180551 C2.43511654,10.464646 2.52050485,10.5713472 2.53980952,10.6979655 C2.55911418,10.8245838 2.50940236,10.9518829 2.4094,11.0319104 L0.6174,12.4655104 C0.553815471,12.5163561 0.474814252,12.5440382 0.3934,12.544 Z"
                    id="Shape"
                  />
                  <path
                    d="M15.4462,12.544 C15.3647857,12.5440382 15.2857845,12.5163561 15.2222,12.4655104 L13.4302,11.0319104 C13.2756098,10.9081986 13.2505778,10.6825902 13.3742896,10.528 C13.4980014,10.3734098 13.7236098,10.3483778 13.8782,10.4720896 L15.6702,11.9056896 C15.7891096,12.0007763 15.8349645,12.160613 15.7845492,12.3042767 C15.7341338,12.4479404 15.598453,12.5440714 15.4462,12.544 Z"
                    id="Shape"
                  />
                  <path
                    d="M1.4686,6.0928 L0.3934,6.0928 C0.195461146,6.0928 0.035,5.93233884 0.035,5.7344 C0.035,5.53646116 0.195461146,5.376 0.3934,5.376 L1.4686,5.376 C1.66653885,5.376 1.827,5.53646116 1.827,5.7344 C1.827,5.93233884 1.66653885,6.0928 1.4686,6.0928 Z"
                    id="Shape"
                  />
                  <path
                    d="M15.4462,6.0928 L14.371,6.0928 C14.1730612,6.0928 14.0126,5.93233884 14.0126,5.7344 C14.0126,5.53646116 14.1730612,5.376 14.371,5.376 L15.4462,5.376 C15.6441388,5.376 15.8046,5.53646116 15.8046,5.7344 C15.8046,5.93233884 15.6441388,6.0928 15.4462,6.0928 Z"
                    id="Shape"
                  />
                </g>
                <path
                  d="M11.1104,11.8272 L11.1104,13.9776 C11.1104,14.5714165 10.6290165,15.0528 10.0352,15.0528 L7.8848,15.0528 C7.29098345,15.0528 6.8096,14.5714165 6.8096,13.9776 L6.8096,11.8272 L11.1104,11.8272 Z"
                  id="Shape"
                  fill="#95A5A5"
                />
                <path
                  d="M13.9776018,5.0176 C13.9787786,6.40550146 13.4022428,7.73127413 12.386304,8.676864 C11.5548658,9.47893005 11.0929303,10.5896515 11.1104,11.744768 L11.1104,12.544 L6.8096,12.544 L6.8096,11.805696 C6.82653171,10.6222728 6.35760728,9.4836414 5.512192,8.65536 C4.39167443,7.60189001 3.82028932,6.09010024 3.963904,4.558848 C4.21172549,1.89050947 6.51261625,-0.112123623 9.1896305,0.010516972 C11.8666448,0.133157567 13.9747908,2.33777947 13.9776018,5.0176 Z"
                  id="Shape"
                  fill="#F7B915"
                />
                <path
                  d="M8.2432,11.4688 C8.04526116,11.4688 7.8848,11.3083388 7.8848,11.1104 L7.8848,10.0352 C7.8848,9.83726116 8.04526116,9.6768 8.2432,9.6768 C8.44113884,9.6768 8.6016,9.83726116 8.6016,10.0352 L8.6016,11.1104 C8.6016,11.3083388 8.44113884,11.4688 8.2432,11.4688 Z"
                  id="Shape"
                  fill="#F29C1F"
                />
                <path
                  d="M8.2432,8.6016 C8.04526116,8.6016 7.8848,8.44113884 7.8848,8.2432 L7.8848,7.8848 C7.8848,7.68686116 8.04526116,7.5264 8.2432,7.5264 C8.44113884,7.5264 8.6016,7.68686116 8.6016,7.8848 L8.6016,8.2432 C8.6016,8.44113884 8.44113884,8.6016 8.2432,8.6016 Z"
                  id="Shape"
                  fill="#F29C1F"
                />
                <path
                  d="M9.6768,11.4688 C9.47886116,11.4688 9.3184,11.3083388 9.3184,11.1104 L9.3184,10.0352 C9.3184,9.83726116 9.47886116,9.6768 9.6768,9.6768 C9.87473884,9.6768 10.0352,9.83726116 10.0352,10.0352 L10.0352,11.1104 C10.0352,11.3083388 9.87473884,11.4688 9.6768,11.4688 Z"
                  id="Shape"
                  fill="#F29C1F"
                />
                <path
                  d="M9.6768,8.6016 C9.47886116,8.6016 9.3184,8.44113884 9.3184,8.2432 L9.3184,7.8848 C9.3184,7.68686116 9.47886116,7.5264 9.6768,7.5264 C9.87473884,7.5264 10.0352,7.68686116 10.0352,7.8848 L10.0352,8.2432 C10.0352,8.44113884 9.87473884,8.6016 9.6768,8.6016 Z"
                  id="Shape"
                  fill="#F29C1F"
                />
                <path
                  d="M5.7344,4.30080002 C5.71129381,4.30080002 5.6882436,4.29852742 5.6655872,4.2939904 C5.47164571,4.2560753 5.34498801,4.06833327 5.3824512,3.874304 C5.7139712,2.1679616 7.3213952,1.4884352 7.3894912,1.46048 C7.50786785,1.41163122 7.64331295,1.42964628 7.74480556,1.50773909 C7.84629816,1.5858319 7.8984192,1.7121383 7.88153516,1.83907989 C7.86465111,1.96602149 7.78132705,2.07431282 7.6629504,2.1231616 C7.6493312,2.1285376 6.3422464,2.6905088 6.0859904,4.010496 C6.0533908,4.17894766 5.90597705,4.30066546 5.7344,4.30080002 Z"
                  id="Shape"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
