import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import circleCooperStore from "../../stores/circleCooperStore";
import { Icon } from "../shared";

interface Props {}

const Info: React.FC<Props> = () => {
  const { combinesDetail } = useContext(circleCooperStore);

  return (
    <div className="cooperation-detail__info">
      {combinesDetail.user && (
        <div className="cooperation-detail__info__section cooperation-detail__info__partner">
          <p className="info__partner-title">合作者身份:</p>
          <div className="info__partner-info">
            <Icon name="cooperation-card-user" size={16} />
            <div className="partner-info">
              <p className="info-name">{combinesDetail.user.fullName}</p>
              <p className="info-department">
                {combinesDetail.user.company}
                <span>
                  {combinesDetail.user.department}
                  {combinesDetail.user.positionShow &&
                  combinesDetail.user.position
                    ? "-" + combinesDetail.user.position
                    : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(Info);
