import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="74.029%"
          y1="13.625%"
          x2="17.604%"
          y2="82.489%"
          id="wjjyhusiqa"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#D0D9F2" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="vlu5j5ahcb">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="xp0vw6j39c"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#DFE2FF" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="zndo7wtn8e"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.737254902 0 0 0 0 0.760784314 0 0 0 0 0.850980392 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="a3cxsf5r7d" cx="4.412" cy="15.588" r="4.412" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle fill="url(#wjjyhusiqa)" cx="16.471" cy="3.529" r="3.529" />
        <g transform="translate(1.176)">
          <circle fill="url(#vlu5j5ahcb)" cx="8.824" cy="8.824" r="8.824" />
          <g fill="#FFF" fillRule="nonzero">
            <path d="M8.81 6.254a.66.66 0 0 0-.59.345l-.377.722-.845.115a.643.643 0 0 0-.53.422.592.592 0 0 0 .166.636l.61.561-.144.793a.601.601 0 0 0 .262.606.682.682 0 0 0 .693.047l.755-.375.755.375c.221.11.49.092.693-.047a.599.599 0 0 0 .262-.607l-.144-.792.611-.561a.591.591 0 0 0 .167-.636.644.644 0 0 0-.53-.422l-.845-.115-.378-.722a.66.66 0 0 0-.59-.345zm0 .672.36.69c.097.183.282.31.496.339l.808.11-.584.538a.597.597 0 0 0-.19.549l.14.758-.724-.358a.691.691 0 0 0-.612 0l-.723.358.138-.758a.598.598 0 0 0-.19-.549l-.584-.538.807-.11c.215-.03.4-.156.496-.34l.361-.69zM5.692 14.57c-.698.384-1.575-.09-1.575-.853V6.663c0-1.08.93-1.957 2.076-1.957h5.258c1.147 0 2.077.876 2.077 1.957v3.529l-4.664 2.63-3.172 1.748z" />
            <path
              d="M13.53 13.702v-3.51l-4.665 2.63 3.082 1.728c.698.391 1.582-.083 1.582-.848z"
              opacity=".5"
            />
          </g>
        </g>
        <use fillOpacity=".2" fill="url(#xp0vw6j39c)" xlinkHref="#a3cxsf5r7d" />
        <use fill="#000" filter="url(#zndo7wtn8e)" xlinkHref="#a3cxsf5r7d" />
      </g>
    </>
  </svg>
);

export default SVG;
