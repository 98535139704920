import React, { useContext, useEffect, useLayoutEffect } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./style.scss";
import {
  Nav,
  Footer,
  Icon,
  BuzzLine,
  CooperationCard,
  AvatarCard,
  CodeEntry,
  Empty,
} from "../shared/index";
import { Carousel, Tabs } from "antd";
import { openNewTab } from "../../utils/tools";
import homeStore from "../../stores/homeStore";
import InstitutionResultPanel from "../database/InstitutionResultPanel";
import SolutionResultPanel from "../database/SolutionResultPanel";
import ExpertResultPanel from "../database/ExpertResultPanel";
import databaseStore from "../../stores/databaseStore";
import globalStore from "../../stores/globalStore";
import { isMobile } from "../../utils/tools";
import { useHistory } from "react-router-dom";

interface Props {
  [key: string]: any;
}

const { TabPane } = Tabs;

const Home: React.FC<Props> = () => {
  const history = useHistory();
  const {
    homeData,
    fetchHomeData,
    cooLoading,
    expressLatest,
    fetchExpressData,
  } = useContext(homeStore);
  const { initializeType, fetchDatabaseList } = useContext(databaseStore);
  const { banners, fetchGlobalBannersData } = useContext(globalStore);
  const onChange = (key: string) => {
    initializeType(key);
    fetchDatabaseList();
    setTimeout(() => {
      hideFn();
    }, 0);
  };

  const hideFn = () => {
    if (document.querySelector(".database-list-panel")) {
      (document
        .querySelector(".sub-library-body")
        .querySelectorAll(".database-list-panel") as any).forEach(
        (item: any) => {
          item.style.marginTop = 0;
          item.style.marginBottom = 0;
        }
      );
    }
  };

  useEffect(() => {
    fetchGlobalBannersData();
    initializeType("institution", true);
    fetchDatabaseList();
    fetchHomeData();
    fetchExpressData();
  }, []);

  useLayoutEffect(() => {
    hideFn();
  }, []);

  return (
    <div className="lenovo-home">
      <Helmet>
        <title>联想数字生态平台</title>
      </Helmet>
      <Nav bgc="#fff">
        <div className="lenovo-home__content">
          <CodeEntry />
          <Carousel autoplay infinite className="banner-carousel">
            {banners &&
              banners.map((item: any, index: number) => (
                <a
                  key={index}
                  className="content__carousel-item"
                  href={item.url}
                  target="_blank"
                >
                  <img src={item.coverUrl} alt="ad" />
                </a>
              ))}
          </Carousel>
          <div
            className="lenovo-home__content__entry"
            style={banners.length === 0 ? { top: 0 } : {}}
          >
            <div
              className="content__entry-item"
              onClick={() => openNewTab("/library/institutions", history)}
            >
              <Icon size={40} name="lenovo-entry-1" />
              <Icon size={40} name="lenovo-entry-hover-1" />
              <p className="content__entry-item-title">成员企业信息库</p>
              {/* <p className="content__entry-item-desc">
                产品、方案、案例、专家等
              </p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
            <div
              className="content__entry-item"
              onClick={() => {
                localStorage.setItem("tbd", "true");
                openNewTab("/library/institutions", history);
              }}
            >
              <Icon size={40} name="lenovo-entry-2" />
              <Icon size={40} name="lenovo-entry-hover-2" />
              <p className="content__entry-item-title">新投企业介绍</p>
              {/* <p className="content__entry-item-desc">最新投资企业</p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
            <div
              className="content__entry-item"
              onClick={() => openNewTab("/activity", history)}
            >
              <Icon size={40} name="lenovo-entry-3" />
              <Icon size={40} name="lenovo-entry-hover-3" />
              <p className="content__entry-item-title">往期活动</p>
              {/* <p className="content__entry-item-desc">精彩活动回顾、资料获取</p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
            <div
              className="content__entry-item"
              onClick={() => openNewTab("/activity", history)}
            >
              <Icon size={40} name="lenovo-entry-4" />
              <Icon size={40} name="lenovo-entry-hover-4" />
              <p className="content__entry-item-title">最新活动</p>
              {/* <p className="content__entry-item-desc">精彩活动预告、报名</p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
            <div
              className="content__entry-item"
              onClick={() => openNewTab("/cooperation", history)}
            >
              <Icon size={40} name="lenovo-entry-5" />
              <Icon size={40} name="lenovo-entry-hover-5" />
              <p className="content__entry-item-title">生态合作</p>
              {/* <p className="content__entry-item-desc">生态合作进展</p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
            <div
              className="content__entry-item"
              onClick={() => openNewTab("/circle", history)}
            >
              <Icon size={40} name="lenovo-entry-6" />
              <Icon size={40} name="lenovo-entry-hover-6" />
              <p className="content__entry-item-title">参与圈子</p>
              {/* <p className="content__entry-item-desc">
                加入主题交流圈，话题交流
              </p> */}
              <div className="content__entry-item-btn">
                立即进入
                <Icon
                  className="arrow-right-normal"
                  size={10}
                  name="lenovo-arrow-right"
                />
                <Icon
                  className="arrow-right-hover"
                  color="white"
                  size={10}
                  name="lenovo-arrow-right"
                />
              </div>
            </div>
          </div>
          <div
            className={`lenovo-home__content__hot ${(!homeData.circle ||
              !homeData.circle.length) &&
              "lenovo-home__content__hot--empty"}`}
          >
            <div className="sub-cooperation-header">
              <p className="content__hot-title">圈子</p>
              <p
                className="sub-cooperation-all"
                onClick={() => openNewTab("/circle", history)}
              >
                查看全部
                <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
              </p>
            </div>
            <div className="content__hot-content">
              {homeData.circle.slice(0, 3).map((item: any, index: number) => (
                <Link
                  className="hot-item"
                  key={index}
                  to={`/circle/${item.id}`}
                  target="_blank"
                >
                  <BuzzLine isBold buzzItem={{ content: item.topic.title }} />
                  <div className="hot-item__bottom">
                    <AvatarCard
                      theme="person"
                      shape="square"
                      data={{
                        name: item.name,
                        avatarUrl: item.coverUrl,
                      }}
                      avatarSize={24}
                    />
                    <p className="hot-item__date">{item.topic.dateDesc}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          {expressLatest && (
            <div className="lenovo-home__content__cooperation">
              <div className="sub-cooperation-header">
                <p className="content__hot-title">合作快报</p>
                <p
                  className="sub-cooperation-all"
                  onClick={() => openNewTab("/cooperation", history)}
                >
                  查看全部
                  <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
                </p>
              </div>
              <div
                className="content__cooperation-content"
                onClick={() => openNewTab("/cooperation", history)}
              >
                <div className="cooperation-content-img">
                  <img src={expressLatest.coverUrl} alt="" />
                </div>
                <div className="cooperation-content-content">
                  <div className="cooperation-content-top">
                    <p className="cooperation-content-title">
                      {expressLatest.title}
                    </p>
                    {!isMobile() && (
                      <p className="cooperation-content-time">
                        {expressLatest.pubyears}
                      </p>
                    )}
                  </div>
                  <p className="cooperation-content-bottom">
                    {expressLatest.summary}
                  </p>
                  {isMobile() && (
                    <p className="cooperation-content-time">
                      {expressLatest.pubyears}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="lenovo-home__content__sub">
            <div className="content__sub-cooperation">
              <div className="sub-cooperation-header sub-cooperation-header--sp">
                <p>寻求合作</p>
                <p
                  className="sub-cooperation-all"
                  onClick={() => openNewTab("/cooperation", history)}
                >
                  查看全部
                  <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
                </p>
              </div>
              {!homeData.cooperation || !homeData.cooperation.length ? (
                !cooLoading && (
                  <div className="sub-cooperation-empty">
                    <Empty title="合作需求收集中" />
                  </div>
                )
              ) : (
                <>
                  <div
                    className={`sub-cooperation-body ${homeData.cooperation
                      .length === 1 && "sub-cooperation-body1"}`}
                  >
                    {homeData.cooperation
                      .slice(0, 3)
                      .map((item: any, index: number) => (
                        <CooperationCard bordered key={index} data={item} />
                      ))}
                  </div>
                </>
              )}
            </div>
            <div className="content__sub-library">
              <div className="sub-library-header sub-cooperation-header--sp">
                <p>信息库最新收录</p>
                <p
                  className="sub-cooperation-all"
                  style={isMobile() ? { marginRight: 0 } : {}}
                  onClick={() => openNewTab("/library/institutions", history)}
                >
                  查看全部
                  <Icon color="#8d98bc" size={8} name="lenovo-arrow-right" />
                </p>
              </div>
              <div className="sub-library-body">
                <Tabs
                  defaultActiveKey="institution"
                  onChange={onChange}
                  destroyInactiveTabPane
                >
                  <TabPane tab="企业" key="institution">
                    <InstitutionResultPanel
                      usePagination={false}
                      useTitle={false}
                    />
                  </TabPane>
                  <TabPane tab="方案" key="solution">
                    <SolutionResultPanel
                      usePagination={false}
                      useTitle={false}
                      width={["30%", "25%", "25%", "20%"]}
                    />
                  </TabPane>
                  <TabPane tab="专家" key="expert">
                    <ExpertResultPanel usePagination={false} useTitle={false} />
                  </TabPane>
                </Tabs>
                <div
                  className="sub-library-enter--mobile"
                  onClick={() => openNewTab("/library/institutions", history)}
                >
                  进入信息库→
                </div>
              </div>
            </div>
          </div>

          <div className="sub-common-footer">
            <div
              className="sub-cooperation-footer common-footer--1"
              onClick={() => openNewTab("/cooperation", history)}
            >
              进入「合作」查看全部
              <Icon color="#da0010" size={8} name="lenovo-arrow-right" />
            </div>
            <div
              className="sub-library-footer common-footer--2"
              onClick={() => openNewTab("/library/institutions", history)}
            >
              进入「信息库」查看全部
              <Icon color="#da0010" size={8} name="lenovo-arrow-right" />
            </div>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(Home);
