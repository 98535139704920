import React, { useContext } from "react";

import { IconHeading, EmptyImport } from "../shared";
import { SOLUTION_DESC } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";

const SolutionIntro = () => {
  const {
    data: {
      tabs: { solutionIntro },
    },
    isSolutionDescTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <div
      className="database-detail__block database-detail__intro"
      id={SOLUTION_DESC}
    >
      <IconHeading icon="lenovo-institution-intro" title="方案描述" />
      {isSolutionDescTabContentEmpty ? (
        <div className="database-detail__block__content">
          <EmptyImport />
        </div>
      ) : (
        <div
          className="database-detail__block__content"
          dangerouslySetInnerHTML={{ __html: `${solutionIntro}` }}
        />
      )}
    </div>
  );
};

export default SolutionIntro;
