import React from "react";

const SVG = ({ width = "100%", className = "", viewBox = "0 0 16 16" }) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>document_5</title>
    <desc>Created with Sketch.</desc>
    <g id="第六稿" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="document_5">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="16"
          height="16"
        />
        <path
          d="M2.5,0.5 C2.3125,0.5 2.1125,0.575 1.975,0.725 C1.8375,0.875 1.75,1.0625 1.75,1.25 L1.75,14.75 C1.75,14.9375 1.825,15.1375 1.975,15.275 C2.125,15.425 2.3125,15.5 2.5,15.5 L13.5,15.5 C13.6875,15.5 13.8875,15.425 14.025,15.275 C14.175,15.125 14.25,14.9375 14.25,14.75 L14.25,4.75 L10,0.5 L2.5,0.5 Z"
          id="Path"
          fill="#4A8DFF"
        />
        <path
          d="M10.8305156,12.259375 C11.1671562,12.6280625 11.78125,12.3898906 11.78125,11.890625 L11.78125,6.078125 L10.6875,6.078125 L10.6875,10.4805937 L8.51323437,8.09921875 C8.296375,7.86170312 7.922375,7.86170312 7.70551562,8.09921875 L5.53125,10.4805937 L5.53125,6.078125 L4.4375,6.078125 L4.4375,11.890625 C4.4375,12.3899062 5.05159375,12.6280781 5.38823437,12.259375 L8.109375,9.27901562 L10.8305156,12.259375 Z"
          id="Path"
          fill="#FFFFFF"
          fillRule="nonzero"
        />
        <path
          d="M14.25,4.75 L10.75,4.75 C10.5625,4.75 10.3625,4.675 10.225,4.525 C10.075,4.3875 10,4.1875 10,4 L10,0.5 L14.25,4.75 Z"
          id="Path"
          fill="#E5F0FF"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
