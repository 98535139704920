import React from "react";

const SVG = ({
  fill = "#C72C21",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill="#FFF"
                    d="M.8 10.4h4l.8 1.6h4.8l.8-1.6h4v4.8H.8v-4.8z"
                    transform="translate(-250 -943) translate(0 134) translate(220 511) translate(30 293) translate(0 5)"
                  />
                  <path
                    fill={fill}
                    d="M15.2 7.2c-.442 0-.8.358-.8.8v1.6h-3.2c-.303 0-.58.171-.716.442L9.906 11.2H6.094l-.578-1.158C5.38 9.772 5.103 9.6 4.8 9.6H1.6V8c0-.442-.358-.8-.8-.8-.442 0-.8.358-.8.8v7.2c0 .442.358.8.8.8h14.4c.442 0 .8-.358.8-.8V8c0-.442-.358-.8-.8-.8zM1.6 14.4v-3.2h2.706l.578 1.158c.136.27.413.442.716.442h4.8c.303 0 .58-.171.716-.442l.578-1.158H14.4v3.2H1.6zM7.558 3.116l.194-.098-.541 3.25c-.072.436.222.848.658.921.044.008.088.01.132.01.384 0 .723-.276.788-.667l.8-4.8c.05-.298-.072-.597-.314-.776C9.033.778 8.71.75 8.442.884l-1.6.8c-.395.198-.555.679-.358 1.074.199.395.68.554 1.074.358m-4.124-.95c.156.156.361.234.566.234.205 0 .41-.078.566-.234.312-.313.312-.819 0-1.132l-.8-.8c-.313-.312-.819-.312-1.132 0-.312.313-.312.819 0 1.132l.8.8zM12 2.4c.205 0 .41-.078.566-.234l.8-.8c.312-.313.312-.819 0-1.132-.313-.312-.819-.312-1.132 0l-.8.8c-.312.313-.312.819 0 1.132.156.156.361.234.566.234m0 2.4c0 .442.358.8.8.8h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8h-1.6c-.442 0-.8.358-.8.8m-10.4.8h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H1.6c-.442 0-.8.358-.8.8 0 .442.358.8.8.8"
                    transform="translate(-250 -943) translate(0 134) translate(220 511) translate(30 293) translate(0 5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
