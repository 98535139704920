import React, { useContext } from "react";
import { observer } from "mobx-react";

import InstitutionIntro from "./InstitutionIntro";
import { Menu } from "../shared";
import ProductSolutionIntro from "./ProductSolutionIntro";
import InstitutionMateria from "./InstitutionMateria";
import DatabaseDetailStore from "../../stores/databaseDetailStore";
import InstitutionExpert from "./InstitutionExpert";

const InstitutionTabs = () => {
  const {
    isInstitutionMateriaTabContentEmpty,
    scrolledDomId,
    scrollTo,
    institutionTabs,
    isInstitutionIntroTabContentEmpty,
    isProductSolutionsTabContentEmpty,
    isInstitutionExpertTabContentEmpty,
  } = useContext(DatabaseDetailStore);

  return (
    <div>
      <Menu
        items={institutionTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      {!isInstitutionIntroTabContentEmpty && <InstitutionIntro />}
      {!isProductSolutionsTabContentEmpty && <ProductSolutionIntro />}
      {!isInstitutionExpertTabContentEmpty && <InstitutionExpert />}
      {!isInstitutionMateriaTabContentEmpty && <InstitutionMateria />}
    </div>
  );
};

export default observer(InstitutionTabs);
