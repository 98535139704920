import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import databaseDetailStore from "../../stores/databaseDetailStore";
import { Tag, Collapse } from "../shared";
import ExpertMember from "./ExpertMember";

const SolutionHeader = () => {
  const {
    data: { header },
  } = useContext(databaseDetailStore);

  const hasTechnologies = header.technologies.length > 0;
  const hasIndustries = header.industries.length > 0;
  const hasWebiste = header.websiteUrl.length > 0;
  const hasApplyFormats = header.applyFormats && header.applyFormats.length > 0;
  const hasAiFileds = header.aiFields && header.aiFields.length > 0;

  return (
    <div className="solution-header">
      <div className="solution-header__title">
        <div className="solution-header__title__name">
          {header.brand ? `${header.name}·${header.brand}` : header.name}
        </div>
        <div className="solution-header__title__tags">
          {header.fields.map((field, i) => (
            <Tag key={i}>{field}</Tag>
          ))}
        </div>
      </div>
      <Collapse line="two" height={50} className="solution-header__summary">
        {header.summary}
      </Collapse>

      {hasTechnologies && (
        <div className="solution-header__keywords">
          热门技术：
          {header.technologies.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )}

      {hasIndustries && (
        <div className="solution-header__keywords">
          行业：
          {header.industries.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )}

      {hasApplyFormats && (
        <div className="institution-header__keywords">
          产品和解决方案：
          {header.applyFormats.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )}

      {hasAiFileds && (
        <div className="institution-header__keywords">
          智能领域：
          {header.aiFields.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      )}

      {hasWebiste && (
        <div className="solution-header__keywords solution-header__website text-limit--one">
          介绍链接：
          <a href={header.websiteUrl} target="_blank">
            {header.websiteUrl}
          </a>
        </div>
      )}

      {header.producer && (
        <div className="solution-header__producer">
          <span className="solution-header__producer__title">提供方</span>
          <div className="solution-header__producer-content">
            <Link
              to={`/library/institutions/${header.producer.id}`}
              className="solution-header__producer__avtar"
              target="_blank"
            >
              <img src={header.producer.logoUrl} alt="" />
            </Link>
            <div className="solution-header__producer__brief">
              <Link
                to={`/library/institutions/${header.producer.id}`}
                target="_blank"
              >
                {header.producer.name}
              </Link>
              {/* {header.producer.categoryCn && (
                <span className="institution-header__title__tags--category solution-header__title__tags--category">
                  {header.producer.categoryCn}
                </span>
              )} */}
              {header.producer.industries
                .concat(header.producer.field ? header.producer.field : [])
                .slice(0, 5)
                .map((field, i) => (
                  <Tag key={i}>{field}</Tag>
                ))}
              <Collapse
                line="one"
                height={22}
                className="solution-header__producer__summary"
              >
                {header.producer.summary}
              </Collapse>
              <ExpertMember
                extraClassName="expert__container--solution"
                experts={header.producer.experts}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SolutionHeader);
