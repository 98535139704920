import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="16.626%"
          y1="16.647%"
          x2="63.395%"
          y2="64.589%"
          id="1ay9tacwka"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mfgzb17gcd">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="fq1z9nen4e"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-49.6%"
          width="199.2%"
          height="199.2%"
          filterUnits="objectBoundingBox"
          id="t63zhq153c"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-2.8%"
          y="-2.8%"
          width="105.7%"
          height="105.7%"
          filterUnits="objectBoundingBox"
          id="l42ugpflzg"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="bjtogzzdxb" cx="7.059" cy="7.059" r="7.059" />
        <circle id="tzlb3kf3wf" cx="31.176" cy="31.176" r="8.824" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#1ay9tacwka)" xlinkHref="#bjtogzzdxb" />
          <use fill="#000" filter="url(#t63zhq153c)" xlinkHref="#bjtogzzdxb" />
        </g>
        <g transform="translate(2.353)">
          <circle fill="url(#mfgzb17gcd)" cx="17.647" cy="17.647" r="17.647" />
          <path
            d="m24.705 12.682-1.257-.038c-.8-.024-3.17-.287-4.498-1.203-2.635-1.815-6.261-.18-6.413-.109l-.772.355v16.548h1.811v-7.578c.923-.262 3.055-.447 3.946.165 2.224 1.534 5.708 1.647 5.856 1.652l1.328.036-.001-9.828z"
            fill="#FFF"
          />
        </g>
        <g>
          <use
            fillOpacity=".2"
            fill="url(#fq1z9nen4e)"
            xlinkHref="#tzlb3kf3wf"
          />
          <use fill="#000" filter="url(#l42ugpflzg)" xlinkHref="#tzlb3kf3wf" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
