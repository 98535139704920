import React, { useContext } from "react";
import { observer } from "mobx-react";
import "./style.scss";

import activityDetailStore from "../../stores/activityDetailStore";

interface Props {
  [key: string]: any;
}

const ActivityNews: React.FC<Props> = () => {
  const { detailData } = useContext(activityDetailStore);
  return (
    <div className="activity-detail-news">
      <p className="activity-detail-news__title">相关新闻</p>
      {detailData.eventDrafts &&
        detailData.eventDrafts.length > 0 &&
        detailData.eventDrafts.map((item: any) => (
          <div className="activity-detail-news__item" key={item.id}>
            <img
              className="activity-detail-news__item-img"
              src={item.coverUrl}
              alt="coverUrl"
            />
            <div className="activity-detail-news__item-content">
              <p className="activity-detail-news__item-title">{item.title}</p>
              <a
                className="activity-detail-news__item-more"
                href={item.url}
                target="_blank"
              >
                了解详情→
              </a>
            </div>
          </div>
        ))}
    </div>
  );
};

export default observer(ActivityNews);
