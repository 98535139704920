import React, { useState, useEffect } from "react";

import "./style.scss";

import getBrowserInfo from "../../../utils/getBrowserInfo";
import pollMonitoring from "../../../utils/pollMonitoring";
import Icon from "../icon/Icon";
// import collect from "../../../assets/images/collect.png";

const collect = `${window.location.origin}/collect.png`;

interface Props {}

const CollectModal: React.FC<Props> = () => {
  console.log(getBrowserInfo());
  const [isShow, setIsShow] = useState(pollMonitoring());
  const [delayShow, setDelayShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDelayShow(true);
    }, 1500);
  }, [{}]);

  const BrowserInfo = getBrowserInfo();
  return isShow && delayShow ? (
    <>
      <div className="collect-modal">
        <i onClick={() => setIsShow(false)}>
          <Icon name="close" size={18} />
        </i>
        {["IE", "Edge"].includes(BrowserInfo.type) && (
          <div className="collect-modal__body-ie">
            <Icon name="ie" size={362} />
            <i className="arrow arrow-ie">
              <Icon name="arrow" size={37} />
            </i>
            <p className="title title-ie">常来看看！</p>
            <p className="desc">
              点击上方
              <Icon name="star" size={18} />
              ，收藏本站至收藏夹，随时查询相关信息。
            </p>
          </div>
        )}
        {BrowserInfo.type === "Chrome" && (
          <div className="collect-modal__body-chrome">
            <Icon name="chrome" size={348} />
            <i className="arrow">
              <Icon name="arrow" size={37} />
            </i>
            <p className="title">常来看看！</p>
            <p className="desc">
              点击上方
              <Icon name="star" size={18} />
              ，收藏本站至收藏夹，随时查询相关信息。
            </p>
          </div>
        )}
        {!["IE", "Edge", "Chrome"].includes(BrowserInfo.type) && (
          <div className="collect-modal__body-other">
            <i className="icon">
              <Icon name="info-database" size={50} />
              <Icon name="star" size={23} />
            </i>
            <div className="collect-modal__body-other__content">
              <p>常来看看！</p>
              <p>收藏本站至收藏夹，随时查询相关信息。</p>
            </div>
          </div>
        )}
      </div>
      <div className="collect-modal--mobile">
        {BrowserInfo.type === "WeixinBrowser" && (
          <i className="arrow">
            <Icon name="arrow" size={39} color="#ffffff" />
          </i>
        )}
        <div
          className={`collect-modal--mobile__content ${
            BrowserInfo.type === "WeixinBrowser" ? "" : "other-browser__content"
          }`}
        >
          <i className="close" onClick={() => setIsShow(false)}>
            <Icon name="close" size={18} />
          </i>
          <div>
            {BrowserInfo.type === "WeixinBrowser" ? (
              <img src={collect} alt="logo" />
            ) : (
              <i className="info-database">
                <Icon name="info-database" size={56} />
              </i>
            )}
            <div className="collect-modal--mobile__content-text">
              <p>常来看看！</p>
              <p>
                {BrowserInfo.type === "WeixinBrowser"
                  ? "收藏本站，随时查询相关信息。"
                  : "添加本站至桌面及收藏夹，随时查询相关信息。"}
              </p>
            </div>
          </div>
        </div>
        <div
          className="collect-modal--mobile__layer"
          onClick={() => setIsShow(false)}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

CollectModal.defaultProps = {};

export default CollectModal;
