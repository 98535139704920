import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={(17 / 18) * width}
    viewBox="0 0 18 17"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="数据库桌面端"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="联想创投--收藏---Chrome"
        transform="translate(-1071.000000, -201.000000)"
        fillRule="nonzero"
      >
        <g id="Group-9" transform="translate(996.000000, 98.000000)">
          <g id="Group-7" transform="translate(20.000000, 72.000000)">
            <g id="start" transform="translate(55.000000, 31.000000)">
              <path
                d="M8.88671875,14.3164062 L4.00505209,16.8830729 C3.81783338,16.98126 3.59111285,16.9646818 3.42016847,16.840305 C3.2492241,16.7159282 3.16368424,16.5053102 3.19949652,16.2969618 L4.1311632,10.8614062 L0.182274297,7.0119618 C0.0307462601,6.86438283 -0.0238317637,6.64356177 0.0414978254,6.44238439 C0.106827414,6.241207 0.280728671,6.09458144 0.49005209,6.06418402 L5.94782986,5.27140625 L8.38838541,0.325850703 C8.48191352,0.13605675 8.67513129,0.0158676467 8.88671875,0.0158676467 C9.09830621,0.0158676467 9.29152398,0.13605675 9.38505209,0.325850703 L11.8256076,5.27140625 L17.2833854,6.06418402 C17.4927088,6.09458144 17.6666101,6.241207 17.7319397,6.44238439 C17.7972693,6.64356177 17.7426912,6.86438283 17.5911632,7.0119618 L13.6422743,10.8619618 L14.5744965,16.2969618 C14.6102069,16.5053783 14.5245064,16.7159966 14.3534248,16.8402695 C14.1823432,16.9645424 13.9555473,16.9809202 13.7683854,16.8825174 L8.88671875,14.3164062 Z"
                id="Path"
                fill="#FFC500"
              />
              <path
                d="M8.88671875,14.3164063 L4.00505209,16.8830729 C3.81783338,16.98126 3.59111285,16.9646818 3.42016847,16.840305 C3.2492241,16.7159282 3.16368424,16.5053102 3.19949652,16.2969618 L4.1311632,10.8614062 C4.83671875,7.13473959 5.44227432,5.27140625 5.94782986,5.27140625 C6.7061632,5.27140625 13.6422743,10.384184 13.6422743,10.8614062 C13.6422743,11.1797396 12.0567188,12.3314063 8.88671875,14.3164062 L8.88671875,14.3164063 Z"
                id="Path"
                fill="#FED902"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
