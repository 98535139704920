import moment from "moment";
import {
  INSTITUTION,
  SOLUTION,
  BUSINESS_CASE,
  EXPERT,
} from "../constants/pro_constants";
import parseRichStr from "../utils/parseRichStr";
import { TAGS_OBJ } from "../constants/map";

const formatDetailData = (type, initData) => {
  if (type === INSTITUTION) {
    const { data } = initData;
    return {
      header: {
        avatar: data.logoUrl,
        shareLogoUrl: data.shareLogoUrl,
        avatarLink: data.website,
        name: data.name,
        contactable: data.assigned,
        award2019List: data.award2019_list || [],
        aliasName: data.nameEn,
        invest: data.tbd ? "新投" : "",
        // categoryCn: data.categoryCn,
        avatarDesc: data.roleDesc
          ? data.roleDesc.replace(/(服务于)(.*)(的.*)/, "$2").split("/")
          : [],
        tags: data.tags.map((tag: string) => ({
          key: TAGS_OBJ[tag],
          value: tag,
        })),
        fields: data.industries.concat(data.fields),
        companySize: data.companySize,
        summary: data.summary,
        website: data.website,
        technologies: data.technologies,
        applyFormats: data.applyFormats,
        registerName: data.meta_card && data.meta_card.registeredName,
        foundedAt:
          data.meta_card &&
          moment.unix(data.meta_card.foundedAt).format("YYYY.MM.DD"),
        officialWebsite: data.meta_card && data.meta_card.website,
        region: data.meta_card && data.meta_card.location,
      },
      tabs: {
        institutionIntro: parseRichStr(data.desc),
        productSolutions:
          data.solutions &&
          data.solutions.map(item => {
            return {
              id: item.id,
              name: item.name,
              businessCases: item.business_cases,
              fields: [...new Set(item.fields)],
            };
          }),
        relatedFile: {
          name: data.attachment && data.attachment.fileName,
          url: data.attachment && data.attachment.fileUrl,
          type:
            data.attachment &&
            data.attachment.fileName
              .split(".")
              .pop()
              .toLowerCase(),
        },
        experts: data.experts,
      },
      level: data.completeness,
      likeCount: data.like,
    };
  }

  if (type === SOLUTION) {
    return {
      header: {
        id: initData.id,
        name: initData.name,
        fields: [...new Set(initData.fields)],
        brand: initData.brand,
        summary: initData.summary,
        technologies: initData.technologies,
        applyFormats: initData.applyFormats,
        aiFields: initData.aiFields,
        websiteUrl: initData.website || "",
        producer: initData.producer,
        industries: initData.industries,
      },
      tabs: {
        solutionIntro: parseRichStr(initData.desc),
        solutionFeature: parseRichStr(initData.feature),
        techArchitecture: parseRichStr(initData.architecture),
        businessCases: initData.business_cases,
        relatedFile: {
          name: initData.attachment && initData.attachment.fileName,
          url: initData.attachment && initData.attachment.fileUrl,
          type:
            initData.attachment &&
            initData.attachment.fileName &&
            initData.attachment.fileName
              .split(".")
              .pop()
              .toLowerCase(),
        },
      },
      level: initData.completeness,
      likeCount: initData.like,
    };
  }

  if (type === BUSINESS_CASE) {
    return {
      level: initData.completeness,
      header: {
        name: initData.name,
        fields: [...new Set(initData.fields)],
        summary: initData.summary,
        technologies: initData.technologies,
        solutions: initData.solutions.map(item => {
          return {
            id: item.id,
            title: item.name,
            avatarURL: item.producer.logoUrl,
            producerName: item.producer.name,
            shareLogoUrl: item.producer.shareLogoUrl,
          };
        }),
        keyData: initData.keyData.map(ele => ({
          key: Object.keys(ele)[0],
          value: Object.values(ele)[0],
        })),
        websiteUrl: initData.website || "",
      },
      tabs: {
        caseDetailIntro: parseRichStr(initData.desc),
        projectBackground: parseRichStr(initData.background),
        relatedFile: {
          name: initData.attachment && initData.attachment.fileName,
          url: initData.attachment && initData.attachment.fileUrl,
          type:
            initData.attachment &&
            initData.attachment.fileName &&
            initData.attachment.fileName
              .split(".")
              .pop()
              .toLowerCase(),
        },
      },
      likeCount: initData.like,
    };
  }
  if (type === EXPERT) {
    const { data } = initData;
    return {
      header: data,
      tabs: null,
      likeCount: data.like,
    };
  }
};

export { formatDetailData };
