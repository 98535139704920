import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { Carousel } from "antd";
import { useHistory } from "react-router-dom";

import "./style.scss";
import {
  Nav,
  Footer,
  Icon,
  BuzzLine,
  CodeEntry,
  Empty,
  Notify,
} from "../shared/index";
import { isMobile, goAuth } from "../../utils/tools";
import AuthStore from "../../stores/authStore";
import GlobalStore from "../../stores/globalStore";
import CircleStore from "../../stores/circleStore";
import { postCirclesInfo } from "../../api";
import lockedImg from "../../assets/images/filter-locked-icon.svg";
import lWxCode from "../../assets/images/l-wx-img.png";

interface Props {
  [key: string]: any;
}

const Circle: React.FC<Props> = () => {
  const history = useHistory();
  const { isAuthUser, isLogin } = useContext(AuthStore);
  const {
    banners,
    fetchGlobalBannersData,
    setVisibleState,
    setDialogProp,
    revertDialogProps,
  } = useContext(GlobalStore);
  const { circleData, fetchCircleData, loading } = useContext(CircleStore);
  // 审核中：item.checking、加入：item.joined
  const buzzLength = isMobile() ? 1 : 3;

  useEffect(() => {
    fetchGlobalBannersData();
    fetchCircleData();
  }, []);

  const hook = async (id: any) => {
    try {
      await postCirclesInfo({ id });
      revertDialogProps();
      setDialogProp("lenovoWxTitle", "加入圈子申请审核中");
      setDialogProp(
        "lenovoWxContent",
        "加入圈子的申请结果将通过服务号通知，请留意服务号消息"
      );
      setDialogProp("lenovoWxCodeImg", lWxCode);
      setDialogProp(
        "lenovoWxBottomTip",
        "长按识别二维码返回服务号，留意后续通知"
      );
      setVisibleState("lenovoWx", true);

      fetchGlobalBannersData();
      fetchCircleData();
    } catch (error) {
      error.message &&
        Notify.error({
          message: error.message,
          duration: 3,
        });
    }
  };

  const joinCircle = async (id, name) => {
    try {
      await postCirclesInfo({ id });
      Notify.success({
        message: `加入${name}圈子成功`,
        duration: 3,
      });
      history.push(`/circle/${id}`);
    } catch (error) {
      error.message &&
        Notify.error({
          message: error.message,
          duration: 3,
        });
    }
  };

  return (
    <div className="lenovo-circle">
      <Helmet>
        <title>圈子 | 联想数字生态平台</title>
      </Helmet>
      <Nav>
        <div className="lenovo-circle__content">
          <CodeEntry />
          <Carousel autoplay infinite className="banner-carousel">
            {banners &&
              banners.map((item: any, index: number) => (
                <a
                  key={index}
                  className="content__carousel-item"
                  href={item.url}
                  target="_blank"
                >
                  <img src={item.coverUrl} alt="" />
                </a>
              ))}
          </Carousel>
          <div className="lenovo-circle__content__entry">
            <div className="content__entry-title">全部圈子</div>
            {!isAuthUser &&
              circleData &&
              circleData.list &&
              circleData.list.length > 0 && (
                <div className="filter-locked-panel">
                  <img src={lockedImg} alt="lockedImg" />
                  <p>成为联想创投数字生态认证用户即可参与圈子讨论</p>
                  <p
                    onClick={() => {
                      goAuth(history, isLogin);
                    }}
                  >
                    去认证→
                  </p>
                </div>
              )}
            <div
              className={`content__entry-list ${circleData.list.length === 0 &&
                "content__entry-list--empty"}`}
            >
              {circleData && circleData.list && circleData.list.length > 0
                ? circleData.list.map((item: any, index: number) => (
                    <div
                      className="content__entry-item"
                      key={index}
                      onClick={() => {
                        if (
                          isAuthUser &&
                          !item.joined &&
                          !item.checking &&
                          item.audit
                        ) {
                          // 尚未申请 && 需要审核的圈子状态
                          hook(item.id);
                        } else {
                          history.push(`/circle/${item.id}`);
                        }
                      }}
                    >
                      <div
                        className={`content__entry-item-header ${
                          !isAuthUser ? "content__entry-item-header-small" : ""
                        }`}
                      >
                        <img src={item.coverUrl} alt="" />
                        <div className="content__entry-item-header__main">
                          <div className="item-header__main-header">
                            <p className="item-header__main-header-title">
                              {item.name}
                            </p>
                            <p
                              className={`item-header__main-header-btn ${
                                !isAuthUser
                                  ? "gray-btn"
                                  : isAuthUser && !item.joined && !item.checking
                                  ? "join-btn"
                                  : isAuthUser && item.checking
                                  ? "audit-btn"
                                  : isAuthUser && item.joined && !item.checking
                                  ? "enter-btn"
                                  : ""
                              }`}
                              onClick={(e: React.BaseSyntheticEvent) => {
                                if (
                                  isAuthUser &&
                                  !item.joined &&
                                  !item.checking &&
                                  !item.audit
                                ) {
                                  e.stopPropagation();
                                  joinCircle(item.id, item.name);
                                }
                              }}
                            >
                              {(!isAuthUser ||
                                (isAuthUser && item.checking)) && (
                                <Icon
                                  name="circle-small-lock"
                                  size={12}
                                  color={!isAuthUser ? "#B2B9D2" : "#da0010"}
                                />
                              )}
                              {!isAuthUser ||
                              (isAuthUser && !item.joined && !item.checking)
                                ? "加入圈子"
                                : isAuthUser && item.checking
                                ? "审核中"
                                : isAuthUser && item.joined && !item.checking
                                ? "进入圈子"
                                : ""}
                            </p>
                          </div>
                          <p className="item-header__main-content">
                            {item.summary}
                          </p>
                          {isAuthUser && item.joined && item.users && (
                            <div className="item-header__main-footer">
                              <div className="item-header__main-footer-person">
                                {item.users.length > 0 &&
                                  `已有${item.users.length}人加入：`}
                              </div>
                              <div className="item-header__main-footer-list">
                                {/* 去圈子 */}
                                {item.users.length > 0 && (
                                  <Carousel
                                    autoplay
                                    infinite
                                    dotPosition="left"
                                    dots={false}
                                  >
                                    {item.users.map(
                                      (userItem: any, userIndex: number) => (
                                        <div
                                          className="item-header__main-footer-item"
                                          key={userIndex}
                                        >
                                          <Icon
                                            name="cooperation-card-user"
                                            size={11}
                                          />
                                          <p className="item-header__main-footer-info">
                                            <span className="item-header__main-footer-department">
                                              {userItem.company}
                                            </span>
                                            {userItem.positionShow &&
                                              userItem.position}
                                          </p>
                                        </div>
                                      )
                                    )}
                                  </Carousel>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="content__entry-item__buzz-list">
                        {!isMobile() && (
                          <p className="content__entry-item__buzz-title">
                            圈子热议
                          </p>
                        )}
                        {item.topics &&
                          item.topics
                            .slice(0, buzzLength)
                            .map((topicItem: any, topicIndex: number) => (
                              <div
                                className="content__entry-item__buzz-item"
                                key={topicIndex}
                              >
                                <BuzzLine
                                  isBold
                                  isTime={isMobile() ? "br" : "show"}
                                  buzzItem={{
                                    content: topicItem.title,
                                    time: topicItem.beginDate,
                                  }}
                                />
                              </div>
                            ))}
                      </div>
                    </div>
                  ))
                : !loading && <Empty title="圈子搭建中，请耐心等待" />}
            </div>
          </div>
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(Circle);
