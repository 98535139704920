import dayjs from "dayjs";

const formatCircleCooperData = (initData: any) => ({
  ...initData,
  updatedTime:
    initData.updatedAt && dayjs(initData.updatedAt).format("YYYY-MM-DD"),
  breadcrumbs: [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: initData.title,
      path: `/cooperation/detail/${initData.id}`,
    },
  ],
});

export { formatCircleCooperData };
