import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import "./style.scss";

import ActivityDateImg from "../../assets/images/activity-date.png";
import ActivityAddressImg from "../../assets/images/activity-address.png";
import ActivityStore from "../../stores/activityStore";
import { openNewTab, isMobile, getQuery } from "../../utils/tools";
import { Empty } from "../shared/index";

interface Props {
  [key: string]: any;
}

const ActivityAside: React.FC<Props> = () => {
  const state: "pending" | "fulfilled" = getQuery("state");
  const { getActivityNewest, activityNewest, newsLoading } = useContext(
    ActivityStore
  );

  useEffect(() => {
    getActivityNewest();
  }, []);

  return !isMobile() || (isMobile() && activityNewest.length > 0) ? (
    <div className="activity-detail-aside">
      <p className="detail-title">最新活动</p>
      <div className="detail-aside-item--wrapper">
        {activityNewest.length > 0
          ? activityNewest.map((item: any, index: number) => (
              <div
                className={`detail-aside-item ${activityNewest.length === 1 &&
                  "detail-aside-item-one"}`}
                key={index}
                onClick={() =>
                  openNewTab(
                    `/activity/${item.id}?state=${
                      item.status === "报名中" ? "pending" : "fulfilled"
                    }`
                  )
                }
              >
                <div className="detail-aside-item__img">
                  <img src={item.coverUrl} alt="coverUrl" />
                  <div
                    className={`aside-item-state ${item.state === "看回放" &&
                      "aside-item-state--end"}`}
                  >
                    {item.state}
                  </div>
                </div>
                <div className="detail-aside-item__info">
                  <p className="item__info-title">{item.title}</p>
                  <div className="item__info-line-wrap">
                    <p className="item__info-line">
                      <img
                        className="item__info-img-1"
                        src={ActivityDateImg}
                        alt="ActivityDateImg"
                      />
                      {item.date.m}
                    </p>
                    <p className="item__info-line">
                      <img
                        className="item__info-img-2"
                        src={ActivityAddressImg}
                        alt="ActivityAddressImg"
                      />
                      {item.address.join(" / ")}
                    </p>
                  </div>
                </div>
              </div>
            ))
          : !newsLoading &&
            (state === "pending" ? (
              <div className="main__empty">
                <Empty title="暂无活动" />
              </div>
            ) : (
              <div className="main__empty">
                <Empty
                  title="还没有最新活动哦"
                  subTitle="关注「联想未来 CLUB」了解活动最新消息"
                />
              </div>
            ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(ActivityAside);
