import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import "./style.scss";
import { Nav, Footer, Breadcrumbs, CodeEntry } from "../shared/index";
import Icon from "../shared/icon/Icon";
import { isMobile } from "../../utils/tools";
import cooperationStore from "../../stores/cooperationStore";
import globalStore from "../../stores/globalStore";
import authStore from "../../stores/authStore";
import Main from "./Main";
import Handle from "./Handle";

interface Props {}

const RespondCooperation: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { cooperatesDetail, fetchCooperatesRepliesData } = useContext(
    cooperationStore
  );
  const { setVisibleState } = useContext(globalStore);
  const { isAuthUser } = useContext(authStore);

  useEffect(() => {
    !isAuthUser && setVisibleState("toAuth", true);
    fetchCooperatesRepliesData(id);
  }, []);

  return (
    <div className="lenovo-res-cooperation lenovo-cooperation-detail">
      <Helmet>
        <title>{`${cooperatesDetail.title} | 联想数字生态平台`}</title>
      </Helmet>
      <Nav bgc={`${isMobile() ? "#fff" : "#f5f6f7"}`}>
        <div className="lenovo-res-cooperation__content">
          <CodeEntry />
          <Breadcrumbs
            items={cooperatesDetail.breadcrumbs}
            separator={<Icon name="breadcrumbs-triangle" size={5} />}
          />
          <Main />
          <Handle />
        </div>
        <Footer />
      </Nav>
    </div>
  );
};

export default observer(RespondCooperation);
