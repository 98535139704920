import { action, observable } from "mobx";
import { createContext } from "react";

import { formatDetailData } from "../adapter/formatPostCooperData";
import {
  postACooperate,
  combinesACooperate,
  fetchCooperateFilter,
  fetchCooperatesDetail,
  fetchCombinesDetail,
} from "../api";
import dayjs from "dayjs";

type Types = "pub" | "rePub";
interface CooperateInfoProps {
  isCom: boolean;
  type: Types;
  params: any;
}

class PostCooperStore {
  @observable public initValues: any = {};
  @observable public filters: any = {
    scenarios: [],
    categories: [],
    circles: [],
  };
  @observable public cooperateInfo: CooperateInfoProps = {
    isCom: false,
    // 是否定向合作详情接口
    type: "pub",
    // 发布或重新发布
    params: {},
    // 普通发布空对象 {}
    // partner_id: 圈子合作需要,某人id
    // id: 重新发布需要,当前详情id
  };
  @observable public breadcrumbs: any = [
    {
      title: "合作",
      path: "/cooperation",
    },
    {
      title: "发布合作",
      path: `/cooperation/post`,
    },
  ];

  @observable public formFields: any = [
    {
      name: "title",
      label: "需求简述",
      message: "请输入简要合作需求",
      placeholder: "在此输入简要合作需求",
    },
    {
      name: "summary",
      label: "合作细节",
      message: "请输入合作细节",
      placeholder: "输入合作细节",
    },
    {
      name: "scenarios",
      label: "行业场景",
      message: "请选择行业场景",
      placeholder: "选择行业场景",
      filter: "scenarios",
    },
    {
      name: "categories",
      label: "合作类型",
      message: "请选择合作类型",
      placeholder: "选择合作类型",
      filter: "categories",
    },
    {
      name: "address",
      // label: "选择区域",
      // message: "请选择区域",
      // placeholder: "选择区域",
      label: "输入区域",
      message: "请输入区域",
      placeholder: "输入区域",
      filter: "address",
    },
    {
      name: "effective_date",
      label: "选择有效期",
      message: "请选择有效期",
      placeholder: "选择有效期",
    },
    {
      name: "circle_ids",
      label: "发布到圈子",
      message: "发布到圈子和发布到大厅必须二选一",
    },
  ];

  @action
  setCooperateInfo = (obj: CooperateInfoProps) => {
    this.cooperateInfo = obj;
  };

  @action
  getFilters = async () => {
    const res = await fetchCooperateFilter();
    this.filters = res.data.data;
  };

  @action
  goCooperate = async (data: any) => {
    const { isCom, type, params } = this.cooperateInfo;
    const api: any = {
      pub: isCom ? combinesACooperate : postACooperate,
      rePub: isCom ? combinesACooperate : postACooperate,
    };
    let obj = {};
    if (!isCom) {
      const index = data.circle_ids.indexOf("发布到大厅");
      if (index > -1) {
        obj = {
          public: true,
          circle_ids: data.circle_ids.filter(
            (item: any) => item !== "发布到大厅"
          ),
        };
      } else {
        obj = {
          public: false,
          circle_ids: data.circle_ids,
        };
      }
      data.address = data.address && data.address.split(",");
      data.effective_date = dayjs(data.effective_date).format();
    } else {
      data.content = data.summary;
    }
    console.log({ ...data, ...obj });
    const field = isCom ? "combine" : "cooperate";
    await api[type]({
      [field]: { ...data, ...obj, ...params },
    });
  };

  @action
  fetchData = async () => {
    const { isCom, params } = this.cooperateInfo;
    const api = isCom ? fetchCombinesDetail : fetchCooperatesDetail;
    const res = await api(params.id);
    this.initValues = formatDetailData(isCom ? res.data.combine : res.data);
  };
}

export default createContext(new PostCooperStore());
