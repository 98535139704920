import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    viewBox="0 0 14 15"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="To/-Topic-model"
        transform="translate(-20.000000, -212.000000)"
        fill="#DA0010"
        fillRule="nonzero"
      >
        <g id="Group-7" transform="translate(20.000000, 211.000000)">
          <g id="Group" transform="translate(0.000000, 1.000000)">
            <path
              d="M2.35197069,15 C3.54506005,15 3.52646804,12.6393035 3.52646804,12.6393035 C3.52646804,12.6393035 5.8566947,10.3524654 5.8566947,12.6393035 C5.8566947,14.9262525 2.35197069,15 2.35197069,15 L2.35197069,15 Z M0,6.92520775 C0,3.10049862 3.13405157,0 7.000056,0 C10.8660284,0 14,3.10051445 14,6.92520775 C14,10.7498852 10.8659964,13.850463 7.000024,13.850463 C3.13401957,13.850463 0,10.7498852 0,6.92520775 L0,6.92520775 Z M5.41222222,11 L5.74222222,9.65 L6.84222222,9.65 L6.51222222,11 L7.85222222,11 L8.18222222,9.65 L9.24222222,9.65 L9.24222222,8.48 L8.45222222,8.48 L8.92222222,6.66 L9.93222222,6.66 L9.93222222,5.5 L9.21222222,5.5 L9.58222222,4 L8.25222222,4 L7.87222222,5.5 L6.78222222,5.5 L7.15222222,4 L5.81222222,4 L5.44222222,5.5 L4.34222222,5.5 L4.34222222,6.65 L5.17222222,6.65 L4.71222222,8.48 L3.65222222,8.48 L3.65222222,9.65 L4.41222222,9.65 L4.08222222,11 L5.41222222,11 Z M7.19222222,8.55 L5.93222222,8.55 L6.43222222,6.57 L7.69222222,6.57 L7.19222222,8.55 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
