import React from "react";

const SVG = ({
  fill = "#8D98BC",
  width = "100%",
  className = "",
  viewBox = "0 0 16 13",
}) => (
  <svg
    width={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="nonzero"
      d="M14.799 12c-.847-2.605-3.298-4.466-6.19-4.52a12.04 12.04 0 0 0-1.024.024v2.574c.002.008.004.016.004.023a.115.115 0 0 1-.114.115.11.11 0 0 1-.097-.055v.011L1.08 5.756h.033a.115.115 0 0 1-.05-.217l6.312-4.426v.011a.114.114 0 0 1 .097-.054c.062 0 .114.051.114.114a.121.121 0 0 1-.003.023v2.497c.289-.014.592-.019.91-.012 3.593.06 6.506 3.084 6.506 6.69.002.556-.07 1.1-.201 1.618zM7.645 1v.02h-.04l.04-.02zm-.005 9.367-.03-.02h.03v.02z"
    />
  </svg>
);

export default SVG;
