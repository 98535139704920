import { formatSpDate } from "../utils/tools";

const formatFilterData = initData => ({
  ...initData,
  status: [
    {
      label: "全部",
      value: "",
      checked: true,
    },
    ...initData.status.map((item: any) => ({
      label: item,
      value: item,
    })),
  ],
  tags: [
    {
      label: "全部",
      value: "",
      checked: true,
    },
    ...initData.tags.map((item: any) => ({
      label: item,
      value: item,
    })),
  ],
});

const formatListData = initData =>
  initData.map((item: any) => ({
    ...item,
    date: formatSpDate(item.beginAt, true),
  }));

const formatNewestData = initData =>
  initData.map((item: any) => ({
    ...item,
    date: formatSpDate(item.beginAt, true),
  }));

export { formatFilterData, formatListData, formatNewestData };
