import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="17.097%"
          y1="19.845%"
          x2="75.774%"
          y2="76.269%"
          id="5okot2612a"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#D0D9F2" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="kghrqut29b">
          <stop stopColor="#C6C7DD" offset="0%" />
          <stop stopColor="#9CA4C0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="17.097%"
          y1="19.845%"
          x2="75.774%"
          y2="76.269%"
          id="qg9450r9mc"
        >
          <stop stopColor="#F1F3FF" offset="0%" />
          <stop stopColor="#DFE2FF" offset="100%" />
        </linearGradient>
        <filter
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          filterUnits="objectBoundingBox"
          id="2b295r1yue"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.737254902 0 0 0 0 0.760784314 0 0 0 0 0.850980392 0 0 0 0.5 0"
            in="shadowInnerInner1"
          />
        </filter>
        <path id="4ihuazqq0d" d="M12.941 12.353H20V20h-7.059z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="url(#5okot2612a)" d="M0 0h7.059v7.647H0z" />
        <path
          d="M1.176 18.5v-1.277c0-.176.143-.318.32-.32.192-.003.379-.005.62-.005V5.97c0-1.391.39-1.964 1.692-2.483 1.782-.711 3.56-1.43 5.35-2.126 1.243-.483 2.339.009 2.714 1.211.089.286.09.606.092.91.005 4.259.003 8.518.003 12.776v.634h1.99v-.626c0-2.668 0-5.337.002-8.005.001-.947.36-1.037 1.368-.456.602.347 1.24.625 1.878.898.523.223.72.54.713 1.11-.03 2.155-.012 4.31-.012 6.466v.618h.596c.177 0 .322.144.322.321v1.281c0 .177-.145.321-.322.321H1.497a.318.318 0 0 1-.32-.318zm3.886-9.477H9.02a.968.968 0 0 0 .968-.966.968.968 0 0 0-.968-.966H5.062a.968.968 0 0 0-.969.966c0 .533.434.966.969.966zm.004 4.928h3.938a.975.975 0 0 0 .976-.974.975.975 0 0 0-.976-.973H5.066a.975.975 0 0 0-.69 1.662.976.976 0 0 0 .69.285z"
          fill="#A3AAC5"
          fillRule="nonzero"
        />
        <path
          d="M11.682 15.717h-.891v-.633c0-4.259.002-8.518-.003-12.777 0-.303-.003-.624-.092-.909C10.32.195 9.224-.296 7.98.187 6.192.88 4.413 1.6 2.631 2.312 1.33 2.83.94 3.403.94 4.794v10.93c-.24 0-.429.002-.62.004A.322.322 0 0 0 0 16.05v1.277c0 .177.144.321.322.321h7.924a13.43 13.43 0 0 0 3.436-1.93zM3.885 5.914h3.958a.967.967 0 1 1 0 1.932H3.885a.968.968 0 0 1-.968-.966c0-.533.434-.966.968-.966zM2.913 11.8c0-.538.438-.974.976-.974h3.938c.539 0 .976.436.976.974a.975.975 0 0 1-.976.973H3.89a.976.976 0 0 1-.976-.973zm9.868 2.963a13.279 13.279 0 0 0 3.783-6.836c-.114-.166-.288-.293-.536-.4-.639-.273-1.275-.552-1.877-.899-1.008-.582-1.367-.49-1.368.456-.003 2.56-.002 5.12-.002 7.68z"
          fill="url(#kghrqut29b)"
          transform="translate(1.176 1.176)"
        />
        <path
          d="M6.14 13.95H5.065a.975.975 0 0 1-.976-.973c0-.537.437-.973.976-.973h3.938c.118 0 .232.022.337.06a13.387 13.387 0 0 0 2.626-2.787c0-1.932 0-3.864-.003-5.795 0-.304-.002-.625-.092-.91-.375-1.202-1.471-1.694-2.714-1.21-1.79.694-3.568 1.415-5.35 2.126-1.303.52-1.693 1.092-1.693 2.483v8.949a13.294 13.294 0 0 0 4.024-.97zM5.061 7.09H9.02a.967.967 0 1 1 0 1.932H5.062a.968.968 0 0 1-.969-.966c0-.533.434-.966.969-.966z"
          fill="#E8ECFC"
          fillRule="nonzero"
          opacity=".4"
        />
        <path
          d="M4.226 8.542a.967.967 0 0 1 .837-1.45H6.31a13.326 13.326 0 0 0 4.013-5.896 2.407 2.407 0 0 0-1.164.166c-1.79.694-3.57 1.415-5.351 2.126-1.303.52-1.693 1.092-1.693 2.483V9.5c.736-.26 1.44-.58 2.11-.958z"
          fill="#E8ECFC"
          fillRule="nonzero"
          opacity=".4"
        />
        <g>
          <use
            fillOpacity=".2"
            fill="url(#qg9450r9mc)"
            xlinkHref="#4ihuazqq0d"
          />
          <use fill="#000" filter="url(#2b295r1yue)" xlinkHref="#4ihuazqq0d" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
