import React, { useEffect, useContext } from "react";
import {
  useLocation,
  // useHistory
} from "react-router-dom";
import { observer } from "mobx-react";

import {
  MessageBoardDialog,
  LenovoWxDialog,
  ToAuthDialog,
} from "./components/shared/index";
import authStore from "./stores/authStore";
import globalStore from "./stores/globalStore";
import * as ActiveStorage from "@rails/activestorage";
import { isMobile, getQuery } from "./utils/tools";
import getBaseName from "./utils/getBaseName";
import VConsole from "vconsole";
import vhCheck from "vh-check";
declare const wxDefaultConfig;
declare const wx;

interface Props {
  [key: string]: any;
}

const wechat = () => {
  wx.config({
    debug: false,
    ...wxDefaultConfig,
    jsApiList: [
      "onMenuShareTimeline",
      "onMenuShareAppMessage",
      "onMenuShareQZone",
    ],
  });
};

const Global: React.FC<Props> = () => {
  const location = useLocation();
  // const history = useHistory();
  // console.log(history);
  const {
    setGlobalVar,
    visibleTypes,
    setVisibleState,
    getExpress,
    hideAllDialog,
  } = useContext(globalStore);
  const { passLogin, isHadUserInfo } = useContext(authStore);

  useEffect(() => {
    sessionStorage.removeItem("isOld");
    wechat();
    ActiveStorage.start();

    const model = getQuery("model");
    if (model === "debug") {
      new VConsole();
    }

    require("trix");
    require("@rails/actiontext");
    vhCheck({
      cssVarName: "browser-address-bar",
      force: false,
      bind: true,
      redefineVh: false,
      updateOnTouch: false,
      onUpdate: function noop() {},
    });

    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    const style = document.styleSheets[0];
    if (!isMac) {
      style.insertRule(
        `::-webkit-scrollbar{
      background: rgba(0, 0, 0, 0);
      width: 6px;
      height: 6px;
    }`,
        0
      );
      style.insertRule(
        `::-webkit-scrollbar-thumb{
      border-radius: 1em;
      background-color: rgba(37, 36, 36, 0.57);
    }`,
        0
      );
      style.insertRule(
        `::-webkit-scrollbar-track{
      border-radius: 1em;
      background-color: rgba(0, 0, 0, 0);
    }`,
        0
      );
    }
    const basename = getBaseName();
    //@ts-ignore
    window.open = (function(open) {
      return function(url, replace = "") {
        return open.call(window, `${basename}${url}`, replace);
      };
    })(window.open);
  }, []);

  useEffect(() => {
    if (passLogin && !isHadUserInfo) {
      // history.push("/profile?type=add");
    }
  }, [passLogin]);

  useEffect(() => {
    hideAllDialog();
    const inL = location.pathname.includes("library");
    if (inL) {
      (document.querySelector("#lenovo") as any).style.height = isMobile()
        ? "100%"
        : "unset";
    } else {
      (document.querySelector("#lenovo") as any).style.height = "100%";
    }
    setGlobalVar("inLibrary", inL);
    setGlobalVar(
      "inList",
      [
        "/library/solutions",
        "/library/institutions",
        "/library/experts",
      ].includes(location.pathname)
    );
    getExpress();
  }, [location.pathname]);

  return (
    <>
      <i className="lenovo-global" />
      <MessageBoardDialog
        visible={visibleTypes.activityMessage}
        onCancel={() => setVisibleState("activityMessage", false)}
      />
      <LenovoWxDialog />
      <ToAuthDialog />
    </>
  );
};

export default observer(Global);
