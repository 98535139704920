import React from "react";

const SVG = ({
  width = "100%",
  className = "",
  viewBox = "0 0 40 40",
  onClick = () => {},
}) => (
  <svg
    onClick={onClick}
    width={width}
    viewBox={viewBox}
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <>
      <defs>
        <linearGradient
          x1="63.981%"
          y1="17.233%"
          x2="44.558%"
          y2="53.914%"
          id="vl3g7z3sna"
        >
          <stop stopColor="#FBFF5B" offset="0%" />
          <stop stopColor="#FF7A00" offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b3eosongpd">
          <stop stopColor="#FF8279" offset="0%" />
          <stop stopColor="#EA1515" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="11.385%"
          y1="19.845%"
          x2="80.248%"
          y2="76.269%"
          id="egq6fe604e"
        >
          <stop stopColor="#F54E49" offset="0%" />
          <stop stopColor="#FBDCDD" offset="98.129%" />
        </linearGradient>
        <filter
          x="-49.6%"
          y="-49.6%"
          width="199.2%"
          height="199.2%"
          filterUnits="objectBoundingBox"
          id="hhyobr4y0c"
        >
          <feGaussianBlur
            stdDeviation="3.5"
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset
            dx="7"
            dy="7"
            in="shadowBlurInner1"
            result="shadowOffsetInner1"
          />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 0.360132431 0 0 0 0 0.360132431 0 0 0 0.455930056 0"
            in="shadowInnerInner1"
          />
        </filter>
        <filter
          x="-2.8%"
          y="-2.8%"
          width="105.7%"
          height="105.7%"
          filterUnits="objectBoundingBox"
          id="fwx2mj7hag"
        >
          <feOffset dy="-1" in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.984313725 0 0 0 0 0.0274509804 0 0 0 0 0.0196078431 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <circle id="wmlubw16cb" cx="32.941" cy="7.059" r="7.059" />
        <circle id="s8hpt79c9f" cx="8.824" cy="31.176" r="8.824" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <use fill="url(#vl3g7z3sna)" xlinkHref="#wmlubw16cb" />
          <use fill="#000" filter="url(#hhyobr4y0c)" xlinkHref="#wmlubw16cb" />
        </g>
        <g transform="translate(2.353)">
          <circle fill="url(#b3eosongpd)" cx="17.647" cy="17.647" r="17.647" />
          <g fill="#FFF" fillRule="nonzero">
            <path d="M17.621 12.508a1.319 1.319 0 0 0-1.18.69l-.756 1.443-1.69.232a1.286 1.286 0 0 0-1.06.844c-.157.448-.028.942.332 1.27l1.223 1.123-.29 1.586c-.087.465.117.936.523 1.213A1.364 1.364 0 0 0 16.11 21l1.51-.748L19.13 21c.443.222.98.185 1.385-.093.41-.28.61-.745.524-1.213l-.287-1.585 1.223-1.123c.362-.332.488-.818.333-1.27a1.289 1.289 0 0 0-1.062-.844l-1.689-.232-.756-1.443a1.319 1.319 0 0 0-1.18-.69zm-.003 1.344.724 1.38c.192.365.562.618.99.678l1.617.22-1.17 1.076c-.31.284-.451.694-.377 1.097l.277 1.518-1.447-.717c-.383-.19-.84-.19-1.223 0l-1.447.717.276-1.518a1.195 1.195 0 0 0-.378-1.097l-1.17-1.075 1.615-.221c.428-.059.799-.312.99-.678l.723-1.38zm-6.232 15.287c-1.396.77-3.15-.18-3.15-1.704V13.327c0-2.162 1.859-3.915 4.153-3.915h10.515c2.294 0 4.153 1.753 4.153 3.915v7.058l-9.327 5.26-6.344 3.494z" />
            <path
              d="M27.059 27.404v-7.02l-9.329 5.26 6.165 3.456c1.396.782 3.164-.165 3.164-1.696z"
              opacity=".5"
            />
          </g>
        </g>
        <g>
          <use
            fillOpacity=".2"
            fill="url(#egq6fe604e)"
            xlinkHref="#s8hpt79c9f"
          />
          <use fill="#000" filter="url(#fwx2mj7hag)" xlinkHref="#s8hpt79c9f" />
        </g>
      </g>
    </>
  </svg>
);

export default SVG;
