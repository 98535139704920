import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import Icon from "../icon/Icon";
import "./style.scss";
import authStore from "../../../stores/authStore";
import cooperationStore from "../../../stores/cooperationStore";

interface Props {
  style?: React.CSSProperties;
  data: any;
  type?: string;
  join?: boolean;
  checking?: boolean;
  bordered?: boolean;
}

const CooperationCard: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { style, data, type, join, checking, bordered } = props;
  const { isAuthUser } = useContext(authStore);
  const { creator, reply, cooperatesDetail } = useContext(cooperationStore);
  const btned =
    isAuthUser && (!type || type !== "circle" || (type === "circle" && join));

  return (
    <div
      className={`cooperation-card 
      ${bordered && "cooperation-card__border-gray"} 
      ${type === "circle" &&
        (!join || checking) &&
        "cooperation-card__dis-btn"} 
      `}
      style={style}
      onClick={() => {
        // isAuthUser && history.push(`/cooperation/detail/${data.id}`);
        if (type === "circle") {
          !(!join || checking) &&
            history.push(`/cooperation/detail/${data.id}`);
        } else {
          history.push(`/cooperation/detail/${data.id}`);
        }
      }}
    >
      <Icon
        name={`${
          data.status === "开放"
            ? "cooperation-card-open"
            : data.status === "已结束"
            ? "cooperation-card-over"
            : ""
        }`}
        size={36}
      />
      <div className="cooperation-card__header">
        <p>来自</p>
        <div>
          {data.circles &&
            data.circles.length > 0 &&
            data.circles.slice(0, 2).map((item: any, index: number) => (
              <div className="cooperation-card__circle-item" key={index}>
                {item.name}
              </div>
            ))}
          {(!data.circles || data.circles.length == 0) && (
            <div className="cooperation-card__circle-item">联想未来CLUB</div>
          )}
        </div>
        {data.circles && data.circles.length > 2 && <p>...</p>}
        <p>圈子</p>
      </div>
      <div className="cooperation-card__main">
        <div className="main-title">{data.title}</div>
        {btned && <div className="main-content">{data.summary}</div>}
        <div className="main-tags">
          {data.categories &&
            data.categories.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
          {data.scenarios &&
            data.scenarios.map((item: any, index: number) => (
              <p className="tags-item" key={index}>
                {item}
              </p>
            ))}
        </div>
        {data.user && btned && (
          <div className="main-info">
            <Icon name="cooperation-card-user" size={16} />
            <p className="info-name">{data.user.fullName}</p>
            <p className="info-department">{data.userPosition}</p>
          </div>
        )}
      </div>
      <div className="cooperation-card__footer">
        <p className="expiry-date">
          有效期：{`${data.deadline ? data.deadline : "一年"}`}
        </p>
        {!(creator && cooperatesDetail.approval === "passed") && !data.creator && (
          <div
            className={`response-btn ${
              !creator && btned && data.status === "开放"
                ? "start-theme"
                : !creator && btned && data.status !== "开放"
                ? "over-theme"
                : "over-theme--auth"
            }`}
            onClick={(e: React.BaseSyntheticEvent) => {
              if (cooperatesDetail.status === "开放") {
                e.stopPropagation();
                if (type === "circle") {
                  !(!join || checking) &&
                    history.push(
                      `/cooperation/postscript/${cooperatesDetail.id}`
                    );
                } else {
                  history.push(
                    `/cooperation/postscript/${cooperatesDetail.id}`
                  );
                }
              }
            }}
          >
            {!btned && <Icon name="circle-small-lock" size={12} />}
            {creator && reply.schedule === "success"
              ? "已达成合作"
              : "立即响应"}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(CooperationCard);
