export const breadcrumbs = [
  {
    title: "企业",
    path: "/library/institutions",
  },
  {
    title: "方案",
    path: "/library/solutions",
  },
  {
    title: "专家",
    path: "/library/experts",
  },
];
