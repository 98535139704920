import React, { useContext } from "react";

import { observer } from "mobx-react";
import "./style.scss";
import circleCooperStore from "../../stores/circleCooperStore";
import closeSvg from "../../assets/images/circle-state-close.svg";
import rightSvg from "../../assets/images/circle-state-right.svg";
import questionSvg from "../../assets/images/circle-state-question.svg";

interface Props {}

const State: React.FC<Props> = () => {
  const { combinesDetail } = useContext(circleCooperStore);
  return (
    <div
      className={`
    cooperation-detail__state 
    ${
      combinesDetail.schedule === "rejected"
        ? "cooperation-detail__state--bg-red"
        : combinesDetail.schedule === "success"
        ? "cooperation-detail__state--bg-green"
        : combinesDetail.schedule === "proceed" ||
          combinesDetail.schedule === "pending"
        ? "cooperation-detail__state--bg-gray"
        : ""
    }
    `}
    >
      <img
        src={
          combinesDetail.schedule === "rejected"
            ? closeSvg
            : combinesDetail.schedule === "success"
            ? rightSvg
            : combinesDetail.schedule === "proceed" ||
              combinesDetail.schedule === "pending"
            ? questionSvg
            : ""
        }
        width="16px"
        alt="state"
      />
      {combinesDetail.schedule === "rejected"
        ? "未达成合作"
        : combinesDetail.schedule === "success"
        ? "已达成合作"
        : combinesDetail.schedule === "proceed"
        ? "等待平台建群，请留意服务号通知"
        : combinesDetail.schedule === "pending"
        ? "待回应"
        : ""}
    </div>
  );
};

export default observer(State);
